
export default class ModificationArray {
    static modification(value) {
      let results = value.map( (item) => {
        return item.name ? { label: item.name , value : item.id } : { label: item.display_name , value : item.id };
      });
      
      return results;
    }

    static modificationIdioma(value) {
      let results = value.map( (item) => {
        return { label: item.description.en , value : item.id };
      });

      return results;
    }

    static modificationDescriptionsPricelis(value) {
      let results = value.map( (item) => {
        return { label: item.description, value: item.description}
      });

      return results;
    }

    static modificationTranslate(value) {
      let results = value.map( (item) => {
          return { label: item, value: item }
      });

      return results;
    }
    
    static modificationName(value) {
        let results = value.map( (item) => {
            return { label: item.name, value: item.name}
        });
        
        return results;
    }

    static modificationDialDescription(value) {
      let results = value.map( (item) => {
        return { label: item.description , value : item.id };
      });

      return results;
    }
    static modificationBigSelect(value) {
      let results = value.map( (item) => {
        return { label: item.label , value : item.value };
      });

      return results;
    }
    
}