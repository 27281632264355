/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import { Checkbox } from '@material-ui/core';

class ModalNew3cx extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      url: '',
      license: '',
      login: '',
      password: '',
      notes: '',
      simultaneousCalls: '',
      support: '',
      serverMegacall: '',
      isServerChecked: false,
      isSupportChecked: false,
      licenses: [{ label: 'STANDARD', value: 1 }, { label: 'PRO', value: 2 }, { label: 'ENTERPRISE', value: 3 }],
      simultaneousCallsNumbers: [
        { label: '8', value: 8 },
        { label: '16', value: 16 },
        { label: '24', value: 24 },
        { label: '32', value: 32 },
        { label: '48', value: 48 },
        { label: '64', value: 64 },
        { label: '96', value: 96 },
        { label: '128', value: 128 },
        { label: '192', value: 192 },
        { label: '256', value: 256 },
        { label: '512', value: 512 },
        { label: '1024', value: 1024 },
      ],
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.generatePassword = this.generatePassword.bind(this);
  }

  componentDidMount() {}

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  generatePassword() {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let string_length = 12;
    let randomstring = '';
    for (let i=0; i<string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
    }

    this.setState({ password: randomstring });
  }

  handleChange(e, nameI, index) {
    const { isServerChecked, isSupportChecked } = this.state;



    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isServerChecked });
        break;
      case 3:
        this.setState({ [nameI]: !isSupportChecked });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async save() {
    const {
      url,
      license,
      username,
      password,
      notes,
      simultaneousCalls,
      isSupportChecked,
      isServerChecked,
    } = this.state;
    const {
      customersId: { response },
    } = this.props;

    const object = {
      url: url,
      licenses: license.label,
      username: username,
      password: password,
      notes: notes,
      number_simultaneous_calls: simultaneousCalls.label,
      support: isSupportChecked ? 1 : 0,
      server_megacall: isServerChecked ? 1 : 0,
    };
 
    const results = await DataApi.New3cx(response, object);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has not been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.get3CX();
    await this.toggle();
    this.setState({
      url: '',
      license: '',
      username: '',
      password: '',
      notes: '',
      simultaneousCalls: '',
      support: '',
      serverMegacall: '',
    });
  }

  render() {
    const {
      url,
      license,
      username,
      password,
      notes,
      simultaneousCalls,
      isServerChecked,
      isSupportChecked,
      licenses,
      simultaneousCallsNumbers,
    } = this.state;

    return (
      <React.Fragment>
        <div className="container-button">
          <h3>3CX Portals</h3>
          <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
            <i className=" mdi mdi-plus-circle-outline"></i> Add a Portal
          </a>
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>New Portal</ModalHeader>
          <ModalBody>
            <Input nameI={'url'} value={url} change={this.handleChange} name={'Gateway'} />
            <Input nameI={'username'} value={username} change={this.handleChange} name={'Username'} />
            <Input nameI={'password'} value={password} change={this.handleChange} name={'Password'} />
            <input
              value="Generate Password"
              type="submit"
              onClick={this.generatePassword}
              className="mb-2 ml-5 offset-5 btn btn-outline-primary"
            />
            <SelectCmx
              index={1}
              nameI={'license'}
              value={license}
              change={e => this.handleChange(e, 'license', 1)}
              name={'License'}
              options={licenses}
            />
            <div className="mt-2" />
            <SelectCmx
              index={1}
              nameI={'simultaneousCalls'}
              value={simultaneousCalls}
              change={e => this.handleChange(e, 'simultaneousCalls', 1)}
              name={'Simultaneous Calls'}
              options={simultaneousCallsNumbers}
            />

            <FormGroup>
              <label className="mt-2">Notes</label>
              <textarea
                className="form-control"
                id="notes"
                value={notes}
                name="notes"
                nameI="notes"
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Support</label>
                  <Checkbox
                    onChange={e => this.handleChange(e, 'isSupportChecked', 3)}
                    name="Support"
                    nameI="isSupportChecked"
                    isChecked={isSupportChecked}
                    index={3}
                    className="ml-2"
                  />
                </div>
                <div className="form-group">
                  <label>Megacall Server</label>
                  <Checkbox
                    onChange={e => this.handleChange(e, 'isServerChecked', 2)}
                    name="Megacall Server"
                    nameI="isServerChecked"
                    isChecked={isServerChecked}
                    index={2}
                    className="ml-2"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.save}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNew3cx;
