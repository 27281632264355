/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
    Component,
} from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2';


import Input from '../../Utils/Input';
import SelectCmx from '../../Utils/Select';
import DataApi from '../../../../megacall/services/UsersServices/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Validations from '../../../../megacall/utilsFunctions/Validations';

class ModalNewUser extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            username: '',
            email: '',
            value_role_type: '',
            role_type: '',
            color: 'invalidate',
            colorEmail: 'invalidateEmail',
        };
        
        this.callApi = this.callApi.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.send = this.send.bind(this);
    }
    
    async componentDidMount() {
        await this.callApi();
    }
    
    async callApi() {
        const select = await DataApi.ListRoles('all');
        let arreglo_role_type = ModificationArray.modification(select.data);
        this.setState({ role_type: arreglo_role_type });
    }
    
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
        if (!this.state.modal) {
            this.setState({ username: '', email: '', value_role_type: '' });
        }
    }
    
    handleChange(e, nameI, index) {
        if (index === 2) {
            this.setState({ [nameI]: e });
        } else {
            this.setState({ [e.target.name]: e.target.value });
        }
    }
    
    async send() {
        const { username, email, value_role_type } = this.state;
        
        const object = {
            username: username,
            email: email,
            role_id: value_role_type.value,
        };
        
        const validateInput = Validations.validationInput([
            { value: username, name: 'username' },
        ]);

        const validateSelect = Validations.validationSelect([
            { value: value_role_type, name: 'value_role_type' },
        ]);

        const validationEmail = Validations.validationEmail(
            { value: email, name: 'email' },
        );

        if (validateInput && validateSelect && validationEmail) {
            const response = await DataApi.SetNewUsers(object);
           
            this.setState(prevState => ({
                modal: !prevState.modal,
            }));
            if (response.data === 200) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'The new user has been created successfully. Sent credentials.',
                    showConfirmButton: false,
                    timer: 1500,
                });
                
            } else if ( response.data === 500) {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'There was a problem trying to create the new user. ',
                    text: 'A credential already exists',
                    showConfirmButton: false,
                    timer: 2500,
                });
            }
           
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Some mandatory fields have not been filled',
                showConfirmButton: false,
                timer: 1500,
            });
        }
    }
    
    render() {
        const { username, email, value_role_type, role_type, color, colorEmail } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <h3>User list</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add User
                    </a>
                </div>
                <Modal className="modal-new-agents" isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>New User</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Input name={'Username'} nameI={'username'} color={color} value={username} state={username}
                                   change={this.handleChange} />
                            <Input name={'Email'} nameI={'email'} color={colorEmail} value={email} state={email}
                                   change={this.handleChange} />
                            <SelectCmx name={'Roles'} nameI={'value_role_type'} validate={'validation'}
                                       value={value_role_type} options={role_type} index={2}
                                       change={this.handleChange} id={'sel'} className="" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.send}>Create</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewUser;