import React from 'react';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataApiSupplier from '../../../../megacall/services/SuppliersServices/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import Input from '../../Utils/Input';

class ModalMultiEditPriceTranslate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      price: '',
      charge: '',
      id: '',
      loader: false,
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchTranslate = this.searchTranslate.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e, nameI, index) {
    console.log(e);
    switch (index) {
      case 1:
        this.setState({ [nameI]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async searchTranslate(e) {
    this.setState({ value: e.target.value });

    setTimeout(() => {
      const { value } = this.state;

      if (value.length >= 2) {
        DataApiSupplier.SearchTranslate({ text: value }).then(items => {
          if (items.data[0].description) {
            const arrayTranslate = items.data[0].description.split(',');

            const translate = ModificationArray.modificationTranslate(arrayTranslate);

            this.setState({ description: translate });
          }
        });
      }
    }, 500);
  }

  async save() {
    const { peak_rate, loader } = this.state;
    const { arrayMultiDescription, arrrayId } = this.props;
    // if (arrayMultiDescription.length > 0 || arrrayId.length > 0) {
    const object = {
      supplier_id: this.props.supplier_id,
      peak: peak_rate,
      arrayMultiDescription: arrayMultiDescription.length > 0 ? arrayMultiDescription : arrrayId,
    };

    console.log(object);

    // const result = await DataApi.UpdatePriceTranslateDescription(object);
    const result = await DataApi.UpdatePriceList(object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });

      this.props.getSuppliersData();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    console.log(this.props.arrrayId);
    const { description, loader, peak_rate } = this.state;
    const { arrayMultiDescription, arrrayId } = this.props;

    return (
      <>
        <div className="ml-3">
          {description != null ? (
            <button className="btn btn-primary" onClick={this.toggle}>
              {' '}
              MultiEdit a Peak Rate
            </button>
          ) : (
            <button className="btn btn-primary" onClick={this.toggle}>
              {' '}
              Edit Selected
            </button>
          )}
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Multiedit</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <label>New Peak Rate</label>
              <input
                value={peak_rate}
                onChange={e => this.handleChange(e, 'peak_rate')}
                className="form-control mb-3"
                // placeholder=""
                name="peak_rate"
              />

              <br />
              {loader === true ? (
                <div className="container-item">
                  <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
                </div>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                Send
              </Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalMultiEditPriceTranslate;
