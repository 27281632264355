import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SelectCmx from '../../../Utils/Select';

class ModalAddBono extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,      
      bonos: '',
      bono_select: ''
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
  }
   
  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  initialState() {
    const list = [
      {label:'Landline 2.500', value: 'L2500'},
      {label:'Landline 5.000', value: 'L5000'},
      {label:'Landline 10.000', value: 'L10000'},
      {label:'Landline 15.000', value: 'L15000'},
      {label:'Landline 20.000', value: 'L20000'},
      {label:'Landline 25.000', value: 'L25000'},
      {label:'Landline 30.000', value: 'L30000'},
      {label:'Mixed 2.500', value: 'M2500'},
      {label:'Mixed 5.000', value: 'M5000'},
      {label:'Mixed 10.000', value: 'M10000'},
      {label:'Mixed 15.000', value: 'M15000'},
      {label:'Mixed 20.000', value: 'M20000'},
      {label:'Mixed 25.000', value: 'M25000'},
      {label:'Mixed 30.000', value: 'M30000'},
    ];

    this.setState({
      bonos: list,
    });
  }

  handleChange(e, nameI) {    
    this.setState({ [nameI]: e });
  }

  async save() {

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { bonos, bono_select } = this.state;

    return (
      <>
        <button type="submit" className="btn btn-primary" onClick={this.toggle}>
          <i className=" mdi mdi-table-plus mdi-22px"></i> Add Plan
        </button>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Add Plans</ModalHeader>
            <React.Fragment>
              <ModalBody>
                <SelectCmx index={1} nameI={'bono_select'}
                  value={bono_select}
                  change={this.handleChange}
                  name={'Plans List'}
                  options={bonos} />
                <br />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.save}>
                  Save
                </Button>{' '}
              </ModalFooter>
            </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalAddBono;