import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';
import { MDBIcon } from 'mdbreact';
import ModificationArray from '../../../../../megacall/utilsFunctions/ModificationArray';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import CheckBox from '../../../Utils/Checkbox';

class ModalEditExtensionPbx extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      username: '',
      password: '',
      configured: '0',
      getaways: '',
      getaways_all: '',
      notes: '',
      ext_type: '',
      ext_type_all: '',
      id: '',
      isProvisioned: false,
      login: '',
      auth_provision: '',
      added_date: '',
      disabled_date: ''
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.generatePassword = this.generatePassword.bind(this);
    this.initialState = this.initialState.bind(this);
    this.update = this.update.bind(this);
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  initialState() {
    const { gateways, ext_type, data } = this.props;

    const getaways_all = ModificationArray.modification(gateways);
    const ext_type_all = ModificationArray.modification(ext_type);

    this.setState({
      id: data.id,
      username: data.username,
      password: data.auth,
      configured: data.configured,
      getaways: { label: data.gateways_display_name, value: data.gateways_id },
      notes: data.notes,
      ext_type: { label: data.ext_types_display_name, value: data.ext_types_id },
      getaways_all: getaways_all,
      ext_type_all: ext_type_all,
      isProvisioned: data.provision === 1 ? true : false,
      login: data.login,
      auth_provision: data.password,
      added_date: data.added,
      disabled_date: data.disabled_date
    });
  }

  handleChange(e, nameI, index) {
    const { isProvisioned } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isProvisioned });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  generatePassword(index) {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let string_length = 12;
    let randomstring = '';
    for (let i=0; i<string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
    }

    if(index === 1) {
      this.setState({ password: randomstring });
    } else {
      this.setState({ auth_provision: randomstring });
    }
  }

  async update() {
    const { username, password, getaways, notes, ext_type, configured, id, isProvisioned, login, auth_provision,  added_date, disabled_date } = this.state;
    const { customersId } = this.props;

    const object = {
      username: username,
      auth: password,
      gateway_id: getaways.value,
      ext_type_id: ext_type.value,
      notes: notes,
      configured: configured,
      customer_id: customersId,
      provision: isProvisioned ? 1 : 0,
      login: login,
      password: auth_provision,
      added_date: added_date,
      disabled_date: disabled_date
    };
    
    /*const object2 = {
      action: 'updated',
      username: username,
      auth: password,
      gateway_id: getaways.value,
      ext_type_id: ext_type.value,
      notes: notes,
      configured: configured,
      customer_id: customersId,
    };*/

    const response = await DataApi.UpdateExtensionPBX(id, object);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
      //this.props.getAllInfo(3, object2);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.toggle();
    this.props.getExtensionPbx();
  }

  render() {
    const { username, password, getaways_all, getaways, notes, ext_type, ext_type_all, isProvisioned, login, auth_provision,  added_date, disabled_date } = this.state;

    return (
      <React.Fragment>
        <div className="container-button">
          <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Edit Extensiones PBX</ModalHeader>
          <ModalBody>
            <Input nameI={'username'} value={username} change={this.handleChange} name={'Username'} />
            <Input nameI={'password'} value={password} change={this.handleChange} name={'Password'} />
            <input value="Generate Password" onClick={() => this.generatePassword(1)} type="submit" />
            <br />
            <Input nameI={'added_date'} value={added_date} change={this.handleChange} name={'Added'} type={'date'}/>
            <Input nameI={'disabled_date'} value={disabled_date} change={this.handleChange} name={'Disabled'} type={'date'}/>
            <label>Configured</label>
            <select name="configured" className="form-control" onChange={this.handleChange}>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
            <SelectCmx
              index={1}
              nameI={'getaways'}
              value={getaways}
              change={this.handleChange}
              name={'Getaways'}
              options={getaways_all}
            />
            <SelectCmx
              index={1}
              nameI={'ext_type'}
              value={ext_type}
              change={this.handleChange}
              name={'Ext Type'}
              options={ext_type_all}
            />
            <br/>
            <div className="d-flex">
              <CheckBox
                nameI={'isProvisioned'}
                toggleChange={this.handleChange}
                isChecked={isProvisioned}
                index={2}
              />
              <label className="ml-2">Provisioned?</label>
            </div>
            {isProvisioned ? (
              <>
              <Input nameI={'login'} value={login} change={this.handleChange} name={'User Provisioned'} />
              <Input nameI={'auth_provision'} value={auth_provision} change={this.handleChange} name={'Password Provisioned'} />
              <input value="Generate Password" onClick={() => this.generatePassword(2)} type="submit" />
              <br />
              </>
            ) : ''}
            <label>Notes</label>
            <textarea
              className="form-control"
              id="notes"
              value={notes}
              name="notes"
              onChange={e => this.handleChange(e)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.update}>
              Update
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditExtensionPbx;
