import UrlServices from "../UrlServices";
import axios from "axios";
import Headboard from '../Headboard';

export default class CallsApi {
    static API_URL_GLOBAL = UrlServices.universal;
    
    /** Locks */
    static async DESTROYLOCKS(id, modelTypes) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + `locks/destroyLocks/${id}/${modelTypes}`, null, await Headboard.HEADERS());
    
        return response;
    }
}


