/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Card, CardFooter, CardBody, CardHeader } from 'reactstrap';

import DataApi from '../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

import Validations from '../../../megacall/utilsFunctions/Validations';
import Input from '../../elements/Utils/Input';

import Loader from 'react-loader-spinner';

class monthlyReportView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: '',
      data: [],
      date: '',
      color: 'invalidate',
      spin: false,
    };
    this.getReport = this.getReport.bind(this);
  }

  async componentDidMount() {}

  async getReport(page = 1) {
    const { date, customer_id } = this.state;
    this.setState({ spin: true });

    const validateInput = Validations.validationInput([{ value: date, name: 'Date' }]);

    const dateToSplited = date.split('-', 3);
    const toYear = dateToSplited[0];
    const toMonth = dateToSplited[1];

    const object = {
      date: date,
      toYear: toYear,
      toMonth: toMonth,
      customer_id: customer_id,
    };
    
    if (validateInput) {
      const response = await DataApi.getReport(object);
      if (response) {
        this.setState({ spin: false });
        this.setState({
          data: response.data[0],
          totalBases: response.data[1],
          totalTaxes: response.data[2],
          totals: response.data[3],
        });
      }
     
    }
  }

  async generateInvoices() {
    const { data, date } = this.state;

    const dateToSplited = date.split('-', 3);
    const toYear = dateToSplited[0];
    const toMonth = dateToSplited[1];
    const object = {
      data: data,
      date: date,
      toYear: toYear,
      toMonth: toMonth,
    };

    Swal.fire({
      title: 'Are you sure you want to generate invoices and charge customers?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Generate and make the charges!',
    }).then(async result => {
      if (result.isConfirmed) {
        this.setState({ spin: true });
        const response = await DataApi.makeInvoices(object);


        if (response.status === 200) {
          this.setState({ spin: false });
          Swal.fire('Generated!', 'Your invoices has been generated and emails has been sent to customers.', 'success');
          this.props.history.push('/invoice');
        }
      }
    });
  }

  async handleChangeFilter(e, index) {
    switch (index) {
      case 1:
        this.setState({ date: e.target.value });
        setTimeout(() => {
          this.getReport();
        }, 500);
        break;
      case 2:
        this.setState({ customer_id: e.target.value });
        setTimeout(() => {
          this.getReport();
        }, 5000);
        break;
        default:
          break;
    }
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  render() {
    const { data, color, spin, totals, totalBases, totalTaxes } = this.state;

    // if (!data) {
    //   return null;
    // }

    return (
      <React.Fragment>
        <div className="card-body">
          <div className="row justify-content-between top-information">
            <div className="col-sm-5">
              <div className="form-group offset-2">
                {/* <label>Date:</label> */}
                <Input change={e => this.handleChangeFilter(e, 1)} type="date" name="Date" color={color} />
              </div>
            </div>
            {/* <div className="col-sm-3">
              <div className="form-group">
                <label>Customer Id:</label>
                <input
                  onChange={e => this.handleChangeFilter(e, 2)}
                  type="text"
                  name="customerid"
                  className="form-control"
                  placeholder="Customer Id"
                />
              </div>
            </div> */}
          </div>
          {data.length > 0 ? (
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xl-4">
                <div className="card widget-block  rounded bg-primary2 border">
                  <div className="card-block text-white  container-item ">
                    <h4 className="text-white mt-2">{totalBases.toFixed(2)}</h4>
                    <p>Total Base</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-4 mb-3">
                <div className="card widget-block  rounded bg-primary2 border">
                  <div className="card-block text-white container-item">
                    <h4 className="text-white mt-2">{totalTaxes.toFixed(2)}</h4>
                    <p>Total Taxes</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-4 col-xl-4">
                <div className="card widget-block  rounded bg-primary2 border">
                  <div className="card-block text-white  container-item">
                    <h4 className=" mt-2">{totals.toFixed(2)}</h4>
                    <p>Totals</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {data.length > 0 ? (
            <div className="col-sm-3 mt-4 offset-1">
              <a className="btn btn-success " onClick={() => this.generateInvoices()}>
                <i className="mdi mdi-export"></i>
                Generate Invoices
              </a>
            </div>
          ) : (
            ''
          )}

          <div className="col-sm-2 mt-4"></div>
        </div>
        <div className="container-fluid d-flex">
          <div className="row justify-content-center">
            {spin === false ? (
              data.map((item, index) => (
                <Card className="col-3 mt-3 mb-3 mr-3 ml-3">
                  <CardHeader className="bg-white">
                    <h3>
                      {' '}
                      {item.customer_id} - {item.customer_name}
                    </h3>
                  </CardHeader>
                  <CardBody>
                    {item.charges.map((charge, index) => (
                      <>
                        {charge.product_name ? (
                          <div className="border-bottom border-5 border-primary mb-3 mt-3">
                            {charge.product_name ? (
                              <tr className="mt-2 mb-2">
                                <b>Product Name: {charge.product_name}</b>
                              </tr>
                            ) : (
                              ''
                            )}
                            {charge.text ? (
                              <tr className="mt-2 mb-2">
                                <b> Text: </b> {charge.text}
                              </tr>
                            ) : (
                              ''
                            )}
                            {charge.total ? (
                              <tr className="mt-2 mb-2">
                                <b> Total: {charge.total.toFixed(2)}</b>
                              </tr>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        {charge.total_call_sale ? (
                          <tr>
                            <b> Total Call Sale: {charge.total_call_sale.toFixed(2)}</b>
                          </tr>
                        ) : (
                          ''
                        )}
                      </>
                    ))}
                  </CardBody>
                  <CardFooter className="d-flex justify-content-between bg-white">
                    <h3>
                      Base:
                      <br /> <span className="text-primary">{item.base.toFixed(2)}</span>{' '}
                    </h3>
                    <h3>
                      Taxes:
                      <br /> <span className="text-primary">{item.taxes.toFixed(2)}</span>
                    </h3>
                    <h3>
                      Total:
                      <br /> <span className="text-primary">{item.totalsProduct.toFixed(2)}</span>
                    </h3>
                  </CardFooter>
                </Card>
              ))
            ) : (
              <>
                <div className="mt-5 offset-5">
                  <div className="offset-5">
                    <div className="offset-5">
                      <Loader type="TailSpin" color="#4c84ff" height="460" width="460" className="offset-5" />
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item">{item.number}</td>
                  <td className="container-item">{item.customer_id}</td>
                  <td className="container-item">
                    <input
                      type="checkbox"
                      defaultChecked={item.inmediate}
                      value={item.inmediate}
                      checked={item.inmediate}
                    />
                  </td>
                  <td className="container-item">{item.date}</td>
                  <td className="container-item">{item.total.toFixed(2)}</td>
                  <td className="container-item">
                    <div className="row offset-3">
                      {item.rectify === 0 ? (
                        <a onClick={() => this.props.history.push(`/invoice/rectify/${item.id}`)} className="ml-5">
                          <i className="mdi mdi-square-edit-outline mdi-24px"></i>
                        </a>
                      ) : (
                        // <EditInvoice className="ml-5" item={item} getInvoices={this.getInvoices} invoiceId={item.id} />
                        ''
                      )}
                    </div>
                  </td>
                </tr>
              ))} */}
          </div>
        </div>

        {/* <Pagination
          totalItemsCount={total}
          onChange={page => this.getInvoices(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
          lastPageText="Last"
        /> */}
      </React.Fragment>
    );
  }
}

export default monthlyReportView;
