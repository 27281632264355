/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Table } from 'reactstrap';

import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class TableInvoices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      to_date: '',
      from_date: '',
      invoiceNumber: '',
    };
    this.getInvoices = this.getInvoices.bind(this);
    this.getInvoiceInfo = this.getInvoiceInfo.bind(this);
    this.getInvoiceEmail = this.getInvoiceEmail.bind(this);
  }

  async componentDidMount() {
    await this.getInvoices();
  }

  async getInvoices(page = 1) {
    const { customersId } = this.props;
    const { from_date, to_date, invoiceNumber } = this.state;

    const object = {
      from_date: from_date,
      to_date: to_date,
      invoiceNumber: invoiceNumber,
      customer_id: customersId.response,
    };

    const response = await DataApi.getInvoices(page, object);

    this.setState({ data: response.data });
  }

  async handleChangeFilter(e, index) {
    switch (index) {
      case 1:
        this.setState({ from_date: e.target.value });
        setTimeout(() => {
          this.getInvoices();
        }, 50);
        break;
      case 2:
        this.setState({ to_date: e.target.value });
        setTimeout(() => {
          this.getInvoices();
        }, 50);
        break;
      case 3:
        this.setState({ invoiceNumber: e.target.value });
        setTimeout(() => {
          this.getInvoices();
        }, 50);
        break;
      default:
        break;
    }
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async getInvoiceInfo(id, date, inmediate) {
    const object = {
      invoiceNumber: id,
    };

    const results = await DataApi.getInvoiceInfo(object);

    if (results.status === 200) {
      this.setState({ productInvoice: [results.data[0]], invoiceInfo: results.data[1], customerInfo: results.data[2] });
      this.generatePDF(date, inmediate);
    }
  }

  getInvoiceEmail(id, date, inmediate) {
    Swal.fire({
      title: 'Send Email?',
      text: 'Do you want to send the invoice to the customer?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send',
    }).then(result => {
      if (result.isConfirmed) {
        this.generateEmail(id, date, inmediate);
      }
    });
  }

  async generateEmail(id, date, inmediate) {
    const object = {
      invoiceNumber: id,
    };

    const results = await DataApi.getInvoiceInfo(object);
    if (results.status === 200) {
      const dateToSplited = date.split('-', 3);
      const toYear = dateToSplited[0];
      const toMonth = dateToSplited[1];
      const payload = {
        productInvoice: [results.data[0]],
        invoiceInfo: results.data[1],
        customerInfo: results.data[2],
        toYear: toYear,
        toMonth: toMonth,
        inmediate: inmediate,
        email: inmediate,
      };

      const retorno = await DataApi.generate(payload);
      if (retorno.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'The invoice was sent',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'It seems there was an error',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'It seems there was an error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async generatePDF(date, inmediate) {
    const { productInvoice, invoiceInfo, customerInfo } = this.state;

    const dateToSplited = date.split('-', 3);
    const toYear = dateToSplited[0];
    const toMonth = dateToSplited[1];

    const object = {
      productInvoice: productInvoice,
      invoiceInfo: invoiceInfo,
      customerInfo: customerInfo,
      toYear: toYear,
      toMonth: toMonth,
      inmediate: inmediate,
    };
 
    const results = await DataApi.generate(object);
    if (results.status === 200) {
      let urlRela = results;
      let url = `https://manager-kosmos.megacall.es/storage${urlRela.data.split('storage')[1]}`;

      window.open(url, '_blank');
    }
  }

  render() {
    const {
      data: { data },
    } = this.state;
    const { current_page, total, per_page } = this.state.data;
    const { lock } = this.props;
    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="card-body">
            <div className="row justify-content-between top-information"> 
              <div className="col-sm-3">
                <div className="form-group">
                  <label>From Date:</label>
                  <input
                    onChange={e => this.handleChangeFilter(e, 1)}
                    type="date"
                    name="from_date"
                    className="form-control"
                    placeholder="From"
                  />
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group">
                  <label>To Date:</label>
                  <input
                    onChange={e => this.handleChangeFilter(e, 2)}
                    type="date"
                    name="to_date"
                    className="form-control"
                    placeholder="To"
                  />
                </div>
              </div>

              <div className="col-sm-3">
                <div className="form-group">
                  <label>Invoice Number</label>
                  <input
                    index={1}
                    nameI={'invoiceNumber'}
                    onChange={e => this.handleChangeFilter(e, 3)}
                    className="form-control"
                    placeholder="Invoice Number"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-input-modal mb-5"></div>
          <div className="container-table-all">
            <Table className="table table-stripped">
              <thead className="container-item">
                <tr>
                  <th scope="container-item">Invoice Number</th>
                  <th scope="container-item">Inmediate</th>
                  <th scope="container-item">Date</th>
                  <th scope="container-item">Total</th>
                  <th scope="container-item">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="container-item">{item.number}</td>
                    <td className="container-item">{item.inmediate === 1 ? 'Yes' : 'No'}</td>
                    <td className="container-item">{item.date}</td>
                    <td className="container-item">{item.total.toFixed(2)}</td>
                    <td className="container-item action-td">
                      <a className="ml-2" onClick={() => this.getInvoiceInfo(item.id, item.date, item.inmediate)}>
                        <i className="mdi mdi-file-pdf-box-outline mdi-24px text-red"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Pagination
            totalItemsCount={total}
            onChange={page => this.getInvoices(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
            lastPageText="Last"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="card-body">
          <div className="row justify-content-between top-information">
            <div className="col-sm-3">
              <div className="form-group">
                <label>From Date:</label>
                <input
                  onChange={e => this.handleChangeFilter(e, 1)}
                  type="date"
                  name="from_date"
                  className="form-control"
                  placeholder="From"
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label>To Date:</label>
                <input
                  onChange={e => this.handleChangeFilter(e, 2)}
                  type="date"
                  name="to_date"
                  className="form-control"
                  placeholder="To"
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label>Invoice Number</label>
                <input
                  index={1}
                  nameI={'invoiceNumber'}
                  onChange={e => this.handleChangeFilter(e, 3)}
                  className="form-control"
                  placeholder="Invoice Number"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-input-modal mb-5"></div>
        <div className="container-table-all">
          <Table className="table table-stripped">
            <thead className="container-item">
              <tr>
                <th scope="container-item">Invoice Number</th>
                <th scope="container-item">Inmediate</th>
                <th scope="container-item">Date</th>
                <th scope="container-item">Total</th>
                <th scope="container-item">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item">{item.number}</td>
                  <td className="container-item">{item.inmediate === 1 ? 'Yes' : 'No'}</td>
                  <td className="container-item">{item.date}</td>
                  <td className="container-item">{item.total.toFixed(2)}</td>
                  <td className="container-item action-td">
                    <a className="ml-2" onClick={() => this.getInvoiceInfo(item.id, item.date, item.inmediate)}>
                      <i className="mdi mdi-file-pdf-box-outline mdi-24px text-red"></i>
                    </a>
                    <a className="ml-2" onClick={() => this.getInvoiceEmail(item.id, item.date, item.inmediate)}>
                      <i className="mdi mdi-email-send-outline mdi-24px text-blue"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          totalItemsCount={total}
          onChange={page => this.getInvoices(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
          lastPageText="Last"
        />
      </React.Fragment>
    );
  }
}

export default TableInvoices;
