import UrlServices from '../UrlServices';
import axios from "axios";
import Headboard from '../Headboard';

export default class CallsApi {
    static API_URL = UrlServices.universal;
    
    /** Packages */
    static async LISTPACKAGE(page) {
        const response = await axios.post(CallsApi.API_URL + `price/packages/list?page=${page}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async LISTSELECT() {
        const response = await axios.get(CallsApi.API_URL + 'price/packages/show', await Headboard.HEADERS());
        
        return response;
    }
    
    static async SAVEPACKGES(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/packages/create', object, await Headboard.HEADERS());
        
        return response;
    }
    static async COPYPACKGES(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/packages/copy', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async DESTROYPACKAGES(id) {
        const response = await axios.post(CallsApi.API_URL + `price/packages/destroy/${id}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async GETPACKAGESPREFIXTRANSLATE(id) {
        const response = await axios.post(CallsApi.API_URL + `price/packages/list-packages-prefix-translates/${id}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async UPDATEPACKAGES(id, object) {
        const response = await axios.post(CallsApi.API_URL + `price/packages/update/${id}`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    /** Suppliers prices */
    static async LISTSUPLIERSPRICES() {
        const response = await axios.get(CallsApi.API_URL + 'price/suppliers-prices/show', await Headboard.HEADERS());
    
        return response;
    }
    
    static async GETPRICELIST(id, page) {
        const response = await axios.post(CallsApi.API_URL + `price/suppliers-prices/list/${id}?page=${page}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    /** Customer Price */
    static async LISTSELECTCUSTOMERPRICE() {
        const response = await axios.get(CallsApi.API_URL + 'price/customer-price/show', await Headboard.HEADERS());
        
        return response;
    }
    
    static async GETCUSTOMERPRICE(id, page, object) {
        const response = await axios.post(CallsApi.API_URL + `price/customer-price/list/${id}?page=${page}`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async UPDATECUSTOMERPRICE(id, object) {
        const response = await axios.post(CallsApi.API_URL + `price/customer-price/update/${id}`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async DESTROYCUSTOMERPRICES(id) {
        const response = await axios.post(CallsApi.API_URL + `price/customer-price/destroy/${id}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async GETDESCRIPTIONS() {
        const response = await axios.get(CallsApi.API_URL + 'price/customer-price/select-descriptions', await Headboard.HEADERS());
        
        return response;
    }
    
    static async CREATECUSTOMERPRICES(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/customer-price/create', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async DOWNLOADPDF(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/customer-price/generator-pdf', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async DOWNLOADTXT(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/customer-price/generator-txt', object, await Headboard.HEADERTXT());
        
        return response;
    }
    
    static async DESTROY(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/customer-price/destroy-price', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async UPDATEMULTICUSTOMERPRICE(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/customer-price/multi-edit', object, await Headboard.HEADERS());
        
        return response;
    }

    static async NEWCREATECUSTOMERPRICE(object) {
        const response = await axios.post(CallsApi.API_URL + `price/customer-price/import/new`, object, await Headboard.HEADERSFILE());
        return response;
    }

    static async IMPORTUPDATE(id, file) {
        const response = await axios.post(CallsApi.API_URL + `price/customer-price/import/update/${id}`, file, await Headboard.HEADERSFILE());
        return response;
    }

    static async CHANGETYPE(id, object) {
        const response = await axios.post(CallsApi.API_URL + `price/customer-price/change-type/${id}`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    /** Prefix Translates */
    static async GETPREFIXTRANSLATE(page, object) {
        const response = await axios.post(CallsApi.API_URL + `price/prefix-translates/list?page=${page}`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async UPDATEPRICETRANSLATE(id, object) {
        const response = await axios.post(CallsApi.API_URL + `price/prefix-translates/update/${id}`, object, await Headboard.HEADERS());
        
        return response;
    }


    static async UPDATEPRICELIST(object) {
        const response = await axios.post(CallsApi.API_URL + `price/priceListUpdate`, object, await Headboard.HEADERS());
        
        return response;
    }

    
    static async UPDATEPRICETRANSLATEDESCRIPTION( object) {
        const response = await axios.post(CallsApi.API_URL + `price/prefix-translates/updateDescription`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async SAVEPRICETRANSLATE(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/create', object, await Headboard.HEADERS());
        
        return response;
    }

    static async DELETEPRICETRANSLATE(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/deleteDescription', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async SAVEDESCRIPTION(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/descriptions/create', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async EXPORTCSVPRICETRANSLATE(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/export-csv', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async EXPORTCSVDESCRIPTION(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/descriptions/export-csv', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async IMPORTCSVPRICETRANSLATE(file) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/import-csv', file, await Headboard.HEADERSFILE());
        
        return response;
    }
    
    static async IMPORTCSVDESCRIPTION(file) {
        console.log('file 2')
        console.log(file)
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/descriptions/import-csv', file, await Headboard.HEADERSFILE());
        
        return response;
    }
    
    static async GETAUDITS() {
        const response = await axios.get(CallsApi.API_URL + 'price/prefix-translates/audits', await Headboard.HEADERS());
        
        return response;
    }

    static async GETLISTPAISES() {
        const response = await axios.get(CallsApi.API_URL + 'price/prefix-translates/descriptions/countries', await Headboard.HEADERS());
        return response;
    }
    static async GETCOMPAREDESC(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/descriptions/list', object, await Headboard.HEADERS());
        
        return response;
    }
    static async CONFIRMDESC(id) {
        const response = await axios.get(CallsApi.API_URL + `price/prefix-translates/descriptions/confirm/${id}`, await Headboard.HEADERS());
        
        return response;
    }
    static async UPDATEDESC(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/prefix-translates/descriptions/update', object, await Headboard.HEADERS());
        
        return response;
    }
    static async GETMYLISTDESC(country) {
        const response = await axios.get(CallsApi.API_URL + `price/prefix-translates/descriptions/selects/${country}`, await Headboard.HEADERS());
        return response;
    }


    /** Sippy **/
    static async IMPORTEXPORT(id, file) {
        const response = await axios.post(CallsApi.API_URL + `price/customer-price/import/export/${id}`, file, await Headboard.HEADERSFILE());
        return response;
    }

    static async GETLISTDESC() {
        const response = await axios.get(CallsApi.API_URL + 'price/prefix-translates/selects', await Headboard.HEADERS());
        return response;
    }

    /** Check Prices **/
    static async GETCHECKPRICE(object) {
        const response = await axios.post(CallsApi.API_URL + 'price/check/list', object, await Headboard.HEADERS());
        
        return response;
    }
}