import React from 'react';

import { Col, Row } from 'reactstrap';

import Swal from 'sweetalert2';

import Input from '../../elements/Utils/Input';
import SelectCmx from '../../elements/Utils/Select';
import CheckBox from '../../elements/Utils/Checkbox';
import DataApi from '../../../megacall/services/AgentsServices/DataApi';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import Validations from '../../../megacall/utilsFunctions/Validations';

export default class FormsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      name_agents: '',
      contact_name: '',
      contact_number: '',
      address: '',
      email: '',
      invoice_prefix: '',
      notes: '',
      website: '',
      phone: '',
      other_phone: '',
      fax: '',
      email_pdf: '',
      enabled: '',
      isCheckedNegative: true,
      isCheckedReport: true,
      isCheckedCdr: true,
      isCheckedInvoice: true,
      isCheckedDisable: true,
      customer_spend_report: '',
      agents: '',
      currencies: '',
      vats: '',
      accuracies: '',
      agentType: '',
      chargeTypes: '',
      customerPrices: '',
      languages: '',
      value_customer: '',
      value_language: '',
      value_currencies: '',
      value_vat: '',
      value_accuracy: '',
      value_agent_type: '',
      value_charge_type: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    const { agents, currencies, vats, accuracies, agentType, chargeTypes, customerPrices, languages } = this.props;

    let arreglado_customers = ModificationArray.modification(customerPrices);
    let arreglado_language = ModificationArray.modification(languages);
    let arreglado_currencies = ModificationArray.modification(currencies);
    let arreglado_vat = ModificationArray.modification(vats);
    let arreglado_accuracy = ModificationArray.modification(accuracies);
    let arreglado_agentType = ModificationArray.modification(agentType);
    let arreglado_chargeType = ModificationArray.modification(chargeTypes);

    this.setState({
      id: agents[0].id,
      agents: agents[0],
      name_agents: agents[0].name_agents,
      contact_name: agents[0].contact_name,
      contact_number: agents[0].contact_number,
      email: agents[0].email,
      address: agents[0].address,
      invoice_prefix: agents[0].invoice_prefix,
      notes: agents[0].notes,
      website: agents[0].website,
      phone: agents[0].phone,
      fax: agents[0].fax,
      email_pdf: agents[0].email_pdf,
      other_phone: agents[0].other_phone,
      currencies: arreglado_currencies,
      vats: arreglado_vat,
      accuracies: arreglado_accuracy,
      agentType: arreglado_agentType,
      chargeTypes: arreglado_chargeType,
      customerPrices: arreglado_customers,
      languages: arreglado_language,
      color: 'invalidate',
      colorEmail: 'invalidateEmail',
      value_customer: { label: agents[0].customer_prices_name, value: agents[0].customer_price_id },
      value_language: { label: agents[0].lenguaje, value: agents[0].language_id },
      value_currencies: { label: agents[0].moneda, value: agents[0].currency_id },
      value_vat: { label: agents[0].taxes, value: agents[0].vat_id },
      value_accuracy: { label: agents[0].charge_accuracies_name, value: agents[0].charge_accuracy_id },
      value_agent_type: { label: agents[0].agent_types_name, value: agents[0].agent_types_id },
      isCheckedNegative: agents[0].no_error_on_negative === 0 ? 'false' : 'true',
    });

    if (agents[0].charge_type_id === 1) {
      this.setState({ value_charge_type: { label: chargeTypes[0].name, value: agents[0].charge_type_id } });
    } else if (agents[0].charge_type_id === 2) {
      this.setState({ value_charge_type: { label: chargeTypes[1].name, value: agents[0].charge_type_id } });
    } else {
      this.setState({ value_charge_type: { label: chargeTypes[2].name, value: agents[0].charge_type_id } });
    }

    !agents[0].customer_spend_report
      ? this.setState({ isCheckedReport: false })
      : this.setState({ isCheckedReport: true });
    !agents[0].no_error_on_negative
      ? this.setState({ isCheckedNegative: false })
      : this.setState({ isCheckedNegative: true });
    !agents[0].email_cdrs ? this.setState({ isCheckedCdr: false }) : this.setState({ isCheckedCdr: true });
    !agents[0].invoicing_enabled
      ? this.setState({ isCheckedInvoice: false })
      : this.setState({ isCheckedInvoice: true });
    !agents[0].enabled ? this.setState({ isCheckedDisable: true }) : this.setState({ isCheckedDisable: false });
  }

  async onKeyPress(e) {
    if (e.which === 13) {
      await this.send();
    }
  }

  async send() {
    const {
      value_customer,
      fax,
      email_pdf,
      value_language,
      value_currencies,
      value_vat,
      value_accuracy,
      value_agent_type,
      value_charge_type,
      name_agents,
      contact_name,
      contact_number,
      address,
      email,
      invoice_prefix,
      notes,
      website,
      phone,
      other_phone,
      isCheckedInvoice,
      isCheckedCdr,
      isCheckedReport,
      isCheckedNegative,
      id,
      isCheckedDisable,
    } = this.state;

    const object = {
      name: name_agents,
      language_id: value_language.value,
      customer_price_id: value_customer.value,
      contact_name: contact_name,
      contact_number: contact_number,
      address: address,
      email: email,
      currency_id: value_currencies.value,
      vat_id: value_vat.value,
      charge_accuracy_id: value_accuracy.value,
      type_id: value_agent_type.value,
      invoice_prefix: invoice_prefix,
      value_charge_type: value_charge_type.value,
      notes: notes,
      invoicing_enabled: isCheckedInvoice === false ? '0' : '1',
      email_cdrs: isCheckedCdr,
      spend_report_required: isCheckedReport === false ? '0' : '1',
      no_error_on_negative: isCheckedNegative === false ? '0' : '1',
      website: website,
      phone: phone,
      other_phone: other_phone,
      fax: fax,
      email_pdf: email_pdf,
      id: id,
    };

    /** Aqui validamos los campos requeridos */
    const validateInput = Validations.validationInput([
      { value: name_agents, name: 'name_agents' },
      { value: contact_name, name: 'contact_name' },
      { value: address, name: 'address' },
    ]);

    const validateSelect = Validations.validationSelect([
      { value: value_language, name: 'value_language' },
      { value: value_vat, name: 'value_vat' },
      { value: value_accuracy, name: 'value_accuracy' },
      { value: value_agent_type, name: 'value_agent_type' },
      { value: value_currencies, name: 'value_currencies' },
    ]);

    const validationEmail = Validations.validationEmail({ value: email, name: 'email' });

    if (validateInput && validateSelect && validationEmail) {
      const response = await DataApi.SEND(object, id);

      if (response.data === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Your work has not been saved',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Missing fields to fill',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  handleChange(e, nameI, index) {
    const { isCheckedCdr, isCheckedInvoice, isCheckedNegative, isCheckedReport, isCheckedDisable } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isCheckedInvoice });
        break;
      case 3:
        this.setState({ [nameI]: !isCheckedCdr });
        break;
      case 4:
        this.setState({ [nameI]: !isCheckedReport });
        break;
      case 5:
        this.setState({ [nameI]: !isCheckedNegative });
        break;
      case 6:
        this.setState({ [nameI]: !isCheckedDisable });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  render() {
    const {
      vats,
      accuracies,
      agentType,
      chargeTypes,
      customerPrices,
      value_customer,
      fax,
      email_pdf,
      value_language,
      value_currencies,
      currencies,
      languages,
      value_vat,
      value_accuracy,
      value_agent_type,
      value_charge_type,
      name_agents,
      contact_name,
      contact_number,
      address,
      email,
      invoice_prefix,
      website,
      phone,
      other_phone,
      isCheckedInvoice,
      isCheckedCdr,
      isCheckedReport,
      isCheckedNegative,
      color,
      colorEmail,
    } = this.state;

    if (!color) return null;

    return (
      <Row onKeyUp={this.onKeyPress}>
        <Col md={{ size: 12 }}>
          <div className="container-form-agents">
            <h3>Main Details</h3>
            <div className="row">
              <div className="col-sm-6">
                <Input
                  color={color}
                  nameI={'name_agents'}
                  value={name_agents}
                  change={this.handleChange}
                  name={'Name'}
                  state={name_agents}
                />
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-6">
                    <Input
                      color={color}
                      nameI={'contact_name'}
                      value={contact_name}
                      change={this.handleChange}
                      name={'Contact name'}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      nameI={'contact_number'}
                      value={contact_number}
                      change={this.handleChange}
                      name={'Contact number'}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <SelectCmx
                  validate={'validation'}
                  color={color}
                  index={1}
                  nameI={'value_language'}
                  value={value_language}
                  change={this.handleChange}
                  name={'Language'}
                  options={languages}
                  id="sel"
                />
              </div>

              <div className="col-sm-4">
                <SelectCmx
                  index={1}
                  nameI={'value_customer'}
                  value={value_customer}
                  change={this.handleChange}
                  name={'Prices list'}
                  options={customerPrices}
                  id="sel"
                />
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Date</label>
                  <input type="text" className="form-control" placeholder="" />
                </div>
              </div>
            </div>
            <br />
            <div className="card-body pt-5 border-top">
              <h3>Basic Info</h3>
              <br />
              <div className="row">
                <div className="col-sm-6">
                  <Input color={color} nameI={'address'} value={address} change={this.handleChange} name={'Address'} />
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-12">
                      <Input
                        color={colorEmail}
                        nameI={'email'}
                        value={email}
                        change={this.handleChange}
                        name={'Email'}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <SelectCmx
                    validate={'validation'}
                    color={color}
                    index={1}
                    nameI={'value_vat'}
                    value={value_vat}
                    change={this.handleChange}
                    name={'Vat'}
                    options={vats}
                    id={'sel'}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectCmx
                    validate={'validation'}
                    color={color}
                    index={1}
                    nameI={'value_accuracy'}
                    value={value_accuracy}
                    change={this.handleChange}
                    name={'Charge accuracy'}
                    options={accuracies}
                    id={'sel'}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectCmx
                    validate={'validation'}
                    color={color}
                    index={1}
                    nameI={'value_agent_type'}
                    value={value_agent_type}
                    change={this.handleChange}
                    name={'Agent type'}
                    options={agentType}
                    id={'sel'}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectCmx
                    validate={'validation'}
                    color={color}
                    index={1}
                    nameI={'value_currencies'}
                    value={value_currencies}
                    change={this.handleChange}
                    name={'Currency'}
                    options={currencies}
                    id={'sel'}
                  />
                </div>
                <div className="col-sm-4 mt-2">
                  <Input
                    nameI={'invoice_prefix'}
                    value={invoice_prefix}
                    change={this.handleChange}
                    name={'Invoice prefix'}
                    id={'sel'}
                  />
                </div>
                <div className="col-sm-4">
                  <SelectCmx
                    index={1}
                    nameI={'value_charge_type'}
                    value={value_charge_type}
                    change={this.handleChange}
                    name={'Charge Type'}
                    options={chargeTypes}
                    id={'sel'}
                  />
                </div>
              </div>
            </div>
            <div className="card-body pt-5 border-top">
              <h3>Flags</h3>
              <br />
              <div className="form-group row">
                <div className="col-sm-6">
                  <div className="container-check-box-agents">
                    <CheckBox
                      nameI={'isCheckedInvoice'}
                      toggleChange={this.handleChange}
                      isChecked={isCheckedInvoice}
                      index={2}
                    />
                    <label className="form-check-label">
                      Invoicing enabled
                      <div className="control-indicator"></div>
                    </label>
                  </div>

                  <div className="container-check-box-agents">
                    <CheckBox
                      nameI={'isCheckedCdr'}
                      toggleChange={this.handleChange}
                      isChecked={isCheckedCdr}
                      index={3}
                    />
                    <label className="form-check-label">
                      Email cdrs
                      <div className="control-indicator"></div>
                    </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="container-check-box-agents">
                    <CheckBox
                      nameI={'isCheckedReport'}
                      toggleChange={this.handleChange}
                      isChecked={isCheckedReport}
                      index={4}
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Customer spend Report
                      <div className="control-indicator"></div>
                    </label>
                  </div>

                  <div className="container-check-box-agents">
                    <CheckBox
                      nameI={'isCheckedNegative'}
                      toggleChange={this.handleChange}
                      isChecked={isCheckedNegative}
                      index={5}
                    />
                    <label className="form-check-label">
                      No error on negative
                      <div className="control-indicator"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="card-body pt-5 border-top"> */}
            {/* <h3>WL Invoices</h3>
                            <br />
                            <div className="form-group row mb-6">
                                <label htmlFor="coverImage" className="col-sm-4 col-lg-2 col-form-label">Company
                                    Logo</label>
                                <div className="col-sm-8 col-lg-6">
                                    <div className="custom-file mb-1">
                                        <input type="file" className="custom-file-input" id="coverImage"
                                               required="" />
                                        <label className="custom-file-label" htmlFor="coverImage">Choose
                                            file...</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Input nameI={'website'} value={website} change={this.handleChange} name={'Website'} />
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Phone</label>
                                        <input type="text" className="form-control" placeholder="" />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <Input type={'number'} nameI={'phone'} value={phone} change={this.handleChange}
                                           name={'Phone'} />
                                </div>
                                <div className="col-sm-4">
                                    <Input type={'number'} nameI={'fax'} value={fax} change={this.handleChange} name={'Fax'} />
                                </div>
                                <div className="col-sm-4">
                                    <Input nameI={'email_pdf'} value={email_pdf} change={this.handleChange}
                                           name={'Invoice prefix'} />
                                </div>
                                <div className="col-sm-4">
                                    <Input type={'number'} nameI={'other_phone'} value={other_phone} change={this.handleChange}
                                           name={'Other phone'} />
                                </div>
                                
                            </div> */}

            <div className="form-footer pt-5 border-top">
              <button onClick={this.send} type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>
          {/* </div> */}
        </Col>
      </Row>
    );
  }
}
