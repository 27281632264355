import CallsApi from '../Login/CallApi';

export default class DataApi {
    static async Login(object) {
        const response = await CallsApi.LOGIN(object);
        return response;
        
    }
    
    static async User() {
        const response = await CallsApi.GETUSERPIRICIPALI();
        
        return response;
    }
}