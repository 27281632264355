import React from 'react';

import {
    Table,
} from 'reactstrap';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import ModalNewExtTypes from './Modal/ModalNewExtTypes';
import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';

class TableExtTypes extends React.Component {
    constructor(props) {
        super(props);
        
        
        this.state = {
            data: '',
        };
        this.getExtTypes = this.getExtTypes.bind(this);
    }
    
    async componentDidMount() {
        await this.getExtTypes();
    }
    
    async getExtTypes() {
        const response = await DataApi.GetListExtTypes();
        
        this.setState({ data: response.data });
    }
    
    
    async updateExtTypes(item) {
        const object = {
            enabled: item.enabled ? 0 : 1,
            updated_at: new Date().toJSON().slice(0, 19).replace('T', ' '),
        };
        
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                DataApi.UpdateExtTypes(item.id, object).then(() => {
                    this.getExtTypes();
                });
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success',
                );
            }
        });
    }
    
    
    render() {
        const { data } = this.state;
        
        if (!data)
            return null;
        
        return (
            <React.Fragment>
                <div className="container-input-modal">
                    <ModalNewExtTypes getExtTypes={this.getExtTypes} />
                </div>
    
                <br/>
                <br/>
                <div className="container-table-all">
                    <Table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Display Name</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.display_name}</td>
                                <td className="container-item-4">
                                    <MDBIcon icon="trash" size="lg"
                                             onClick={() => this.updateExtTypes(item)} />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </React.Fragment>
        );
    }
}

export default TableExtTypes;

