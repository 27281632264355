import CallApi from './CallsApi';

export default class DataApi {
  /** vat */
  static async ListSelect() {
    const results = await CallApi.GETLISTSELECT();

    return results;
  }

  static async ListVats(id) {
    const results = await CallApi.GETVATS(id);

    return results;
  }

  static async SaveRate(object) {
    const results = await CallApi.SAVERATE(object);

    return results;
  }

  static async UpdateRate(id, object) {
    const results = await CallApi.UPDATERATE(id, object);

    return results;
  }

  /** International Number Type */
  static async GetListInternational() {
    const results = await CallApi.GETLISTINTERNATIONAL();

    return results;
  }

  static async UpdateInternationalNumber(id, object) {
    const results = await CallApi.UPDATEINTERNATIONALNUMBERTYPE(id, object);

    return results;
  }

  static async NewInternationalNumber(object) {
    const results = await CallApi.NEWINTERNATIONALNUMBERTYPE(object);

    return results;
  }

  static async DestroyNumberInternational(id) {
    const results = await CallApi.DESTROYINTERNATIONALNUMBERTYPE(id);

    return results;
  }

  /** Ext Types */
  static async GetListExtTypes() {
    const results = await CallApi.GETLISTEXTTYPES();

    return results;
  }

  static async UpdateExtTypes(id, object) {
    const results = await CallApi.UPDATEEXTTYPES(id, object);

    return results;
  }

  static async NewExtTypes(object) {
    const results = await CallApi.NEWEXTTYPES(object);

    return results;
  }

  /** Gateways */
  static async GetListGateways(page, json) {
    const results = await CallApi.GETLISTGETAWAYS(page, json);

    return results;
  }

  static async DestroyGateway(id) {
    const results = await CallApi.DESTROYGATEWAY(id);

    return results;
  }

  static async NewGateway(object) {
    const results = await CallApi.NEWGATEWAY(object);

    return results;
  }

  /** Locks */
  static async GetLocks(page) {
    const results = await CallApi.GETLOCKS(page);

    return results;
  }

  static async DestroyLocks(id) {
    const results = await CallApi.DESTROYLOCKS(id);

    return results;
  }

  /** Products */
  static async GetProducts(page, object) {
    const results = await CallApi.GETLISTPRODUCTS(page, object);

    return results;
  }

  static async NewProduct(object) {
    const results = await CallApi.NEWPRODUCT(object);

    return results;
  }

  static async DestroyProducts(id) {
    const results = await CallApi.DESTROYPRODUCTS(id);

    return results;
  }

  static async UpdateProducts(id, object) {
    const results = await CallApi.UPDATEPRODUCTS(id, object);

    return results;
  }

  /** Currency Exchanges */
  static async ListCurrencyExchange() {
    const results = await CallApi.LISTCURRENCYEXCHANGES();

    return results;
  }

  static async NewCurrencyExchange(object) {
    const results = await CallApi.NEWCURRENCYEXCHANGES(object);

    return results;
  }

  //AUDITS

  static async GetAudits(object, page) {
    const results = await CallApi.GETAUDITS(object, page);
    return results;
  }

  //Registers Numbers
  static async GetNumbers(page, object) {
    const results = await CallApi.GETLISTNUMBERS(page, object);
    return results;
  }

  //MEGAMAIL

  static async getEmailsByLang(object) {
    const results = await CallApi.GETEMAILBYLANG(object);
    return results;
  }

  //REPORTS
  static async getSelects() {
    const results = await CallApi.GETSELECTS();
    return results;
  }
  static async getData(object) {
    const results = await CallApi.GETDATA(object);
    return results;
  }
}
