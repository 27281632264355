import React from 'react';

import { Card } from 'react-bootstrap';

import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Checkbox from '../../../elements/Utils/Checkbox';
import Input from '../../../elements/Utils/Input';
import Select from 'react-select';
import { Table } from 'reactstrap';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import { CSVLink } from 'react-csv';
class CheckPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      header: [],
      customer_price_all: [],
      supplier_all: '',
      supplier_check: [],
      customer_check: [],
      select_check: {label: 'Customer & Supplier' , value : 1},
      load: false,
      description: '',
      options_all: [{label: 'Customer & Supplier' , value : 1}, {label: 'Supplier' , value : 2}, {label: 'Customer' , value : 3}]
    };

    this.initialData = this.initialData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getReport = this.getReport.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  async componentDidMount() {
    await this.initialData();
  }

  async initialData() {
    const rest_supplier = await DataApi.ListSuppliersPrices();
    const rest_customerPrice = await DataApi.ListSelectCustomerPrice();

    const suppliers = ModificationArray.modification(rest_supplier.data);
    
    this.setState({ customer_price_all: rest_customerPrice.data, supplier_all: suppliers });
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ supplier_check: e });
        break;
      case 2:
        this.setState({ select_check: e});
        break;
      case 3:
        this.setState({ customer_check: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }
  async getReport() {
    const { select_check, supplier_check, customer_check, description } = this.state;

    if (select_check.value === 1 && ((customer_check === null || customer_check.length === 0) || (supplier_check === null || supplier_check.length === 0))) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'You must select a customer prices and suppliers prices.',
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (select_check.value === 2 && (supplier_check === null || supplier_check.length === 0)) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'You must select a suppliers prices.',
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (select_check.value === 3 && (customer_check === null || customer_check.length === 0)) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'You must select a customer prices.',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      this.setState({ load: true });
      const object = {
        useCustomer: select_check.value,
        suppliers: supplier_check,
        customer: customer_check,
        description: description,
      };

      const response = await DataApi.getCheckPrice(object);
      this.setState({ data: response.data.data, header: response.data.columns, load: false });
    }
  }

  async onKeyPress(e) {
    if (e.which === 13) {
      console.log("LLAMADO");
    await this.getReport();
    }
  }

  render() {
    const {
      data,
      header,
      customer_price_all,
      supplier_all,
      select_check,
      supplier_check,
      customer_check,
      load,
      description,
      options_all,
    } = this.state;

    if (load) {
      return (
        <div
          className="container-item mt-5 position-absolute offset-3"
          style={{ zIndex: '1000', backgroundColor: '#f2f5f9', width: '600px' }}
        >
          <Loader type="ThreeDots" color="#4c84ff" width={300} className="top-100 start-100 mt-3" />
          <div className="d-flex justify-content-center ">
            <h2 className="ml-3" style={{ color: '#4c84ff' }}>
              LOADING...
            </h2>
          </div>
        </div>
      );
    }

    return (
      <>
        <div>
          <h1 className="title-table">COMPARE PRICE LIST</h1>
        </div>

        <Card className="container-fluid ml-3 row">
          <div className="container-input-modal d-flex col-12">
            <div className="col-3">
              <Select
                className="form-group "
                closeMenuOnSelect={false}
                defaultValue={select_check}
                options={options_all}
                onChange={e => this.handleChange(e, null, 2)}
              />
            </div>
          </div>

          <div className="col-12"  onKeyUp={this.onKeyPress}>
            <div className="row">
              <div className="col-6">
                {select_check.value !== 3 ? (
                  <>
                    <label>Supplier Prices</label>
                    <Select
                      className="form-group "
                      isMulti
                      closeMenuOnSelect={false}
                      defaultValue={supplier_check}
                      options={supplier_all}
                      onChange={e => this.handleChange(e, null, 1)}
                    />
                  </>
                ) : (
                  <>
                    
                    
                  </>
                )}
              </div>
              <div className="col-6">
                {select_check.value !== 2 ? (
                  <>
                    <label>Customer Prices</label>
                    <Select
                      className="form-group "
                      isMulti
                      closeMenuOnSelect={false}
                      defaultValue={customer_check}
                      options={customer_price_all}
                      onChange={e => this.handleChange(e, null, 3)}
                    />
                  </>
                ) : (
                  <>
                    
                  </>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <Input
                  nameI={'description'}
                  value={description}
                  change={this.handleChange}
                  placeholder={'Description filter'}
                />
              </div>
            </div>

            <div className="">
              <button type="submit" className="btn btn-primary mt-4 mb-5" onClick={() => this.getReport()}>
                <i className=" mdi mdi-feature-search-outline"></i> Compare
              </button>
              {data !== null && data.length !== 0 ? (
                <CSVLink
                  data={data}
                  filename={'compare-prices-report.csv'}
                  className="btn btn-success mt-4 mb-5"
                  target="_blank"
                >
                  <i className=" mdi mdi-progress-download"></i> Download CSV
                </CSVLink>
              ) : (
                ''
              )}
            </div> {/*  */}
          </div>

          <div className="container-table-all">
            <Table className="table-responsive table-striped" responsive>
              <thead responsive="true">
                <tr>
                  {header.map((item, index) => (
                    <th key={index} scope="col">
                      {item}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    {header.map((column, i) => (
                      <td key={i}>{item[column]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </>
    );
  }
}

export default CheckPrice;
