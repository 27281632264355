
import React, { Component } from 'react';
import { Table } from 'reactstrap';

class TableRegisters extends Component {
        
  render() {
    const { data: { data } } = this.props;
    
    if (!data)
        return null;
    
    return (
      <Table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Number</th>
            <th scope="col">Name</th>
            <th scope="col">Document</th>
            <th scope="col">Contact number</th>
          </tr>
        </thead>
        {data.map((item, index) => (
          <tr key={index}>
            <td className="col-principal">{item.number}</td>
            <td>{item.user_name}</td>
            <td>{item.user_document}</td>
            <td>{item.contact_number}</td>
          </tr>
        ))}
      </Table>
    );
  }

}

export default TableRegisters;
