/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import DataApi from '../../../../megacall/services/AgentsServices/DataApi';

import Swal from 'sweetalert2';

import Input from '../../Utils/Input';
import SelectCmx from '../../Utils/Select';
import { MDBIcon } from 'mdbreact';

class ModalCommissions extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            percentage: '',
            description: '',
            allDescriptions: '',
            id_commision: '',
            startDate: '',
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.callApi = this.callApi.bind(this);
        this.initialState = this.initialState.bind(this);
        this.save = this.save.bind(this);
    }
    
    async initialState() {
        const { indexModal } = this.props;
        
        if (indexModal === 1){
            const results = await this.callApi();
    
    
            //let allDescriptions = ModificationArray.modificationIdioma(results.data);
    
    
            const { percentage, until, description, product_id, id } = this.props;
    
            this.setState({
                percentage: percentage,
                startDate: until,
                description: { label: description, value: product_id },
                allDescriptions: results.data,
                id_commision: id,
            });
        }else {
            const result = await this.callApi();
    
            //let items = ModificationArray.modificationIdioma(result.data);
    
            this.setState({allDescriptions: result.data});
        }
    }
    
    
    async callApi() {
        const result = await DataApi.ProductCommission();
        
        return result;
    }
    
    toggle() {
        this.initialState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e, nameI, index) {
        
        switch (index) {
            case 1:
                this.setState({ [nameI]: e });
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
                break;
        }
    }
    
    async save() {
        const { indexModal, idAgents } = this.props;
        const { id_commision, percentage, description: { value }, startDate } = this.state;
        
        if (indexModal === 1) {
            const json = {
                product_id: value,
                percentage: percentage,
                until: startDate,
            };
    
            const result = await DataApi.SendCommission(json, id_commision);
    
            if (result.status === 200) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500,
                });
            } else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Your work has error saved',
                    showConfirmButton: false,
                    timer: 1500,
                });
            }
            
            await this.props.getAgentsData();
            this.setState({startDate: '', description: "", percentage: ""});
        }else {
            const json = {
                product_id: value,
                percentage: percentage,
                model_id: idAgents,
                until: startDate,
            }
    
            let response = await DataApi.CreateCommission(json);
    
            if(response.data === 200) {
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                });
            }else {
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Your work has error saved',
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            await this.props.getAgentsData();
        }
        
        this.setState({startDate: "", description: "", percentage: ""});
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    render() {
        const { percentage, description, allDescriptions, startDate } = this.state;
        const { indexModal } = this.props;
        
        return (
            <React.Fragment>
                {indexModal === 2 ?
                    <div className="container-button">
                        <h3>User Commissions</h3>
                        <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                            <i className=" mdi mdi-plus-circle-outline"></i> Add Commissions
                        </a>
                    </div>
                    : <MDBIcon icon="edit" size="lg" onClick={this.toggle} />}
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>Edit</ModalHeader>
                    <ModalBody>
                        <Input nameI={'startDate'} value={startDate} change={this.handleChange}
                               name={'Until'} type={'date'}/>
                        <SelectCmx index={1} nameI={'description'} value={description} change={this.handleChange}
                                   name={'Product'} options={allDescriptions} />
                        <Input type={'number'} nameI={'percentage'} value={percentage} change={this.handleChange}
                               name={'Percentage'} />
                    </ModalBody>
                    <ModalFooter>
                        <input className="btn btn-primary" onClick={this.save} type="button" value="Save" />
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalCommissions;
