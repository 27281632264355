/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Settings/DataApi';
import Swal from "sweetalert2";

class ModalNewGateway extends React.Component{
    constructor(props) {
        super(props);
        
        
        this.state = {
            modal: '',
            name: ''
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }
    
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async save() {
        const { name } = this.state;
        
        const object = {
            display_name: name,
            enabled: 1,
            created_at: new Date().toJSON().slice(0, 19).replace('T', ' '),
        };
        
        const response = await DataApi.NewGateway(object);
        
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'has been saved successfully',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'save error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        await this.props.getGatewaysData();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    render() {
        const { name } = this.state;
        
        return(
            <React.Fragment>
                <div className="container-button">
                    <h3>Gateway List</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add Gateway
                    </a>
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>New Gateway</ModalHeader>
                    <ModalBody>
                        <Input nameI={'name'} value={name} change={this.handleChange} name={'Name'} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewGateway;