/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import Validations from '../../../../../megacall/utilsFunctions/Validations';
import { MDBIcon } from 'mdbreact';

class ModalNewCharge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      receivedDate: '',
      value: 0,
      notes: '',
      productsType: '',
      product: '',
      units: '',
      rate: '',
      immediate_invoice: false,
      immediate_balance: false,
      net_value: true,
      agent_commision: false,
      recurrent: false,
      product_invoice: false,
      disabled: false,
      disabledTo: false,
      immediateInvoiceChecked: false,
      text: '',
      text_details: '',
      until: '',
      percentage: '',
      productsToInvoice: '',
      TotalToSend: '',
      gotMainInfo: false,
      color: 'invalidate',
      from_date: '',
    };
    this.toggle = this.toggle.bind(this);
    this.send = this.send.bind(this);
    this.initialState = this.initialState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addProductToInvoice = this.addProductToInvoice.bind(this);
    this.getAgentCommision = this.getAgentCommision.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.inputClean = React.createRef();
  }

  componentDidMount() {
    this.initialState();
  }

  initialState() {
    var fecha = new Date(); //Fecha actual
    var mes = fecha.getMonth() + 1; //obteniendo mes
    var dia = fecha.getDate(); //obteniendo dia
    var año = fecha.getFullYear(); //obteniendo año
    if (dia < 10) dia = '0' + dia; //agrega cero si el menor de 10
    if (mes < 10) mes = '0' + mes; //agrega cero si el menor de 10

    this.setState({ from_date: año + '-' + mes + '-' + dia });
    // const date = document.getElementById('currentDate');
    // date.value = año + '-' + mes + '-' + dia;
    // document.getElementById('currentDate').value = año + '-' + mes + '-' + dia;
  }

  toggle() {
    const { totalToSend } = this.state;
    this.initialState();
    this.setState({
      gotMainInfo: false,
      from_date: this.state.from_date,
      to_date: '',
      immediate_invoice: false,
      product_invoice: false,
      immediate_balance: false,
      recurrent: false,
      disabled: false,
      disabledTo: false,
      totalToSend: '',
      productsToInvoice: '',
    });
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
    if (totalToSend >= 1) {
      this.state.TotalToSend[1].splice(0, 1);
    }
  }

  async getAgentCommision(productId) {
    const { customersId } = this.props;
    const object = {
      productId: productId,
      customersId: customersId,
    };
    const response = await DataApi.getAgentCommision(object);
    var date = new Date();
    var currentDate = date.toISOString().substring(0, 10);

    if (response.data.length > 0 && response.data[0].until > currentDate) {
      const inputCmmsA = document.getElementById('inputComissionUntil');
      const inputCmmsPer = document.getElementById('inputComissionPer');
      const agentCmms = document.getElementById('agentComm');
      agentCmms.checked = true;
      agentCmms.value = true;
      inputCmmsA.disabled = false;
      inputCmmsPer.disabled = false;
      this.setState({
        agent_commision: true,
        until: response.data[0].until,
        percentage: response.data[0].percentage,
      });
    }
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });

        if (e.sale > 0) {
          this.setState({ units: 1, rate: e.sale });
        } else {
          this.setState({ units: '', rate: '' });
        }

        this.getAgentCommision(e.value);
        break;
      case 2:
        this.setState({ [nameI]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }
  handleCheckbox(index) {
    const { net_value } = this.state;
    var date = new Date();
    var currentDate = date.toISOString().substring(0, 10);

    switch (index) {
      case 1:
        const immBlncc = document.getElementById('immediateBalance');
        if (immBlncc.checked === true) {
          immBlncc.value = true;
          const recurrInput = document.getElementById('recurrent');
          recurrInput.disabled = true;
          recurrInput.checked = false;
          this.setState({
            immediate_balance: true,
            // disabled: true,
            // disabledTo: true,
            from_date: currentDate,
            to_date: currentDate,
            recurrent: false,
          });
        } else if (immBlncc.checked === false) {
          const recurrInput = document.getElementById('recurrent');
          immBlncc.value = false;
          recurrInput.disabled = false;
          recurrInput.value = false;
          recurrInput.checked = false;
          this.setState({
            immediate_balance: false,
            disabled: false,
            disabledTo: false,
            from_date: '',
            to_date: '',
            recurrent: false,
          });
        }

        break;
      case 2:
        const immInv = document.getElementById('immediateInvoice');
        const immBlnc = document.getElementById('immediateBalance');
        if (immInv.checked === true) {
          immInv.checked = true;
          immInv.value = true;
          immBlnc.checked = true;
          immBlnc.value = true;
          immBlnc.disabled = true;
          this.setState({
            immediate_invoice: true,
            immediate_balance: true,
            // disabled: true,
            // disabledTo: true,
            from_date: currentDate,
            to_date: currentDate,
          });
        } else if (immInv.checked === false) {
          immInv.checked = false;
          immInv.value = false;
          immBlnc.checked = false;
          immBlnc.value = false;
          immBlnc.disabled = false;
          this.setState({
            immediate_invoice: false,
            immediate_balance: false,
            disabled: false,
            disabledTo: false,
            from_date: '',
            to_date: '',
          });
        }

        //RESTAR BALANCE Y CREAR FACTURA INMEDIATA
        break;
      case 3:
        const prdIn = document.getElementById('productInvoice');
        if (prdIn.checked === true) {
          const recurrInput = document.getElementById('recurrent');
          const immInv = document.getElementById('immediateInvoice');
          const immBlnc = document.getElementById('immediateBalance');
          prdIn.checked = true;
          prdIn.value = true;
          immInv.value = true;
          immInv.checked = true;
          immInv.disabled = true;
          immBlnc.value = true;
          immBlnc.checked = true;
          immBlnc.disabled = true;
          recurrInput.disabled = true;
          recurrInput.checked = false;
          recurrInput.value = false;
          this.setState({
            immediate_invoice: true,
            product_invoice: true,
            immediate_balance: true,
            disabled: true,
            disabledTo: true,
            from_date: currentDate,
            to_date: currentDate,
            recurrent: false,
          });
        } else if (prdIn.checked === false) {
          const recurrInput = document.getElementById('recurrent');
          recurrInput.disabled = false;
          this.setState({ product_invoice: false });
          const immInv = document.getElementById('immediateInvoice');
          // const immBlnc = document.getElementById('immediateBalance');
          immInv.disabled = false;
        }
        break;
      case 4:
        // const dateToInput = document.getElementById('toDate');
        const recurrInput = document.getElementById('recurrent');

        if (recurrInput.checked === true) {
          const immInv = document.getElementById('immediateInvoice');
          const immBlnc = document.getElementById('immediateBalance');
          const prdIn = document.getElementById('productInvoice');
          this.setState({
            disabledTo: true,
            to_date: '',
            recurrent: true,
            immediate_balance: false,
            immediate_invoice: false,
            product_invoice: false,
          });
          prdIn.checked = false;
          prdIn.disabled = true;
          prdIn.value = false;
          immBlnc.checked = false;
          immBlnc.disabled = true;
          immBlnc.value = false;
          immInv.checked = false;
          immInv.disabled = true;
          immInv.value = false;
          recurrInput.value = true;
        } else if (recurrInput.checked === false) {
          const immInv = document.getElementById('immediateInvoice');
          const immBlnc = document.getElementById('immediateBalance');
          const prdIn = document.getElementById('productInvoice');
          recurrInput.value = false;
          this.setState({ recurrent: false, disabledTo: false });
          immBlnc.disabled = false;
          prdIn.disabled = false;
          immInv.disabled = false;
        }

        break;
      case 5:
        const agentCmms = document.getElementById('agentComm');
        const inputCmmsA = document.getElementById('inputComissionUntil');
        const inputCmmsPer = document.getElementById('inputComissionPer');
        if (agentCmms.checked === true) {
          this.setState({ agent_commision: true });
          agentCmms.value = true;
          inputCmmsA.disabled = false;
          inputCmmsPer.disabled = false;
        } else {
          this.setState({ agent_commision: false });
          agentCmms.value = false;
          inputCmmsA.disabled = true;
          inputCmmsPer.disabled = true;
        }

        break;
      case 6:
        this.setState({ net_value: !net_value });
        break;
      default:
        break;
    }
  }

  async send() {
    const { customersId } = this.props;
    const object = {
      TotalToSend: this.state.TotalToSend,
    };
    // const { immediate_invoice, product_invoice, recurrent, to_date, immediate_balance } = this.state;
    // const immInv = document.getElementById('immediateInvoice');
    // const immBlnc = document.getElementById('immediateBalance');
    // const prdIn = document.getElementById('productInvoice');
    // const recurrInput = document.getElementById('recurrent');
    // const object2 = {
    //   action: 'create',
    //   total: this.state.TotalToSend[1],
    // };
    // console.log(immediate_invoice, immediate_balance, recurrent, product_invoice, to_date);
    // if (
    //   immediate_invoice === false &&
    //   product_invoice === false &&
    //   recurrent === false &&
    //   immediate_balance === false &&
    //   to_date === ''
    // ) {
    //   Swal.fire({
    //     position: 'center',
    //     icon: 'warning',
    //     title: "You didn't check any type and there isn't to_date",
    //     showConfirmButton: false,
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes',
    //   });
    // } else {
    const results = await DataApi.sendAndGenerate(customersId.response, object);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your charges has been created',
        showConfirmButton: false,
        timer: 1500,
      });
      this.props.getCharges();
      // this.props.getAllInfo(4, object2);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    // }
    await this.props.getBalance();
  }

  async save() {
    const {
      product,
      from_date,
      immediate_invoice,
      immediate_balance,
      product_invoice,
      to_date,
      text_details,
      text,
      rate,
      units,
      recurrent,
      net_value,
      notes,

      percentage,
    } = this.state;

    if (immediate_invoice === false && recurrent === false && product_invoice === false) {
      const productsToPush = [
        {
          product: product.value,
          units: units,
          rate: rate,
          text: text,
          text_details: text_details,
          notes: notes,
          percentage: percentage,
        },
      ];

      this.setState({ productsToInvoice: [...this.state.productsToInvoice, ...productsToPush] });

      const MainInfo = {
        immediate_invoice: immediate_invoice,
        immediate_balance: immediate_balance,
        product_invoice: product_invoice,
        recurrent: recurrent,
        net_value: net_value,
        from_date: from_date,
        to_date: to_date,
      };
      if (immediate_balance === true) {
        const object = {
          action: 'created',
          product: product.label,
          units: units,
          rate: rate,
          text: text,
          text_details: text_details,
          notes: notes,
          percentage: percentage,
        };
        if (immediate_invoice === false) {
          this.props.getAllInfo(4, object);
        }
      }

      this.setState({ TotalToSend: [MainInfo, [...this.state.productsToInvoice, ...productsToPush]] });

      this.setState({
        product: '',
        units: '',
        rate: '',
        text: '',
        text_details: '',
        notes: '',
        percentage: '',
      });
      this.inputClean.current.value = '';
    } else if (immediate_invoice === true && recurrent === false) {
      if (immediate_balance === true) {
        const object = {
          action: 'created',
          product: product.label,
          units: units,
          rate: rate,
          text: text,
          text_details: text_details,
          notes: notes,
          percentage: percentage,
        };
        if (immediate_invoice === false) {
          this.props.getAllInfo(4, object);
        }
      }
      this.setState({
        product: '',
        units: '',
        rate: '',
        text: '',
        text_details: '',
        notes: '',
        percentage: '',
      });
      this.inputClean.current.value = '';
    } else if (recurrent === true) {
      const productsToPush = [
        {
          product: product.value,
          units: units,
          rate: rate,
          text: text,
          text_details: text_details,
          notes: notes,
          percentage: percentage,
        },
      ];

      this.setState({ productsToInvoice: [...this.state.productsToInvoice, ...productsToPush] });

      const MainInfo = {
        immediate_invoice: immediate_invoice,
        immediate_balance: immediate_balance,
        product_invoice: product_invoice,
        recurrent: recurrent,
        net_value: net_value,
        from_date: from_date,
        to_date: to_date,
      };

      this.setState({ TotalToSend: [MainInfo, [...this.state.productsToInvoice, ...productsToPush]] });
      if (immediate_balance === true) {
        const object = {
          action: 'create',
          product: product.label,
          units: units,
          rate: rate,
          text: text,
          text_details: text_details,
          notes: notes,
          percentage: percentage,
        };
        if (immediate_invoice === false) {
          this.props.getAllInfo(4, object);
        }
      }

      this.inputClean.current.value = '';
    }

    if (
      immediate_invoice === false &&
      recurrent === false &&
      product_invoice === false &&
      immediate_balance === false
    ) {
      this.setState({ product: this.state.product, units: this.state.units, rate: this.state.rate });

      Swal.fire({
        position: 'center',
        icon: 'error',
        title: "You didn't check any type",
        showConfirmButton: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
      });
    } else {
      setTimeout(() => {
        // console.log(this.state.TotalToSend);
        this.send();
      }, 500);
      await this.toggle();
    }
  }

  async addProductToInvoice() {
    const {
      product,
      from_date,
      immediate_invoice,
      immediate_balance,
      product_invoice,
      to_date,
      text_details,
      text,
      rate,
      units,
      recurrent,
      net_value,
      notes,

      percentage,
    } = this.state;

    const validateInput = Validations.validationInput([
      { value: units, name: 'units' },
      { value: rate, name: 'rate' },
    ]);
    const validateSelect = Validations.validationSelect([{ value: product, name: 'product' }]);

    if (validateInput && validateSelect) {
      this.setState({ gotMainInfo: true });

      if (immediate_invoice === true) {
        const productsToPush = [
          {
            product: product.value,
            productName: product.label,
            units: units,
            rate: rate,
            text: text,
            text_details: text_details,
            notes: notes,
            percentage: percentage,
          },
        ];

        this.setState({ productsToInvoice: [...this.state.productsToInvoice, ...productsToPush] });

        if (immediate_balance === true) {
          const object = {
            action: 'create',
            product: product.label,
            productName: product.label,
            units: units,
            rate: rate,
            text: text,
            text_details: text_details,
            notes: notes,
            percentage: percentage,
          };
          if (immediate_invoice === false) {
            this.props.getAllInfo(4, object);
          }
        }
        const MainInfo = {
          immediate_invoice: immediate_invoice,
          immediate_balance: immediate_balance,
          product_invoice: product_invoice,
          recurrent: recurrent,
          net_value: net_value,
          from_date: from_date,
          to_date: to_date,
        };

        this.setState({ TotalToSend: [MainInfo, [...this.state.productsToInvoice, ...productsToPush]] });
        // console.log(this.state.TotalToSend);

        this.setState({
          product: '',
          productName: '',
          units: '',
          rate: '',
          text: '',
          text_details: '',
          notes: '',
          percentage: '',
        });
        this.inputClean.current.value = '';
      }

      // if (recurrent === true) {
      //   const MainInfo = {
      //     immediate_invoice: immediate_invoice,
      //     immediate_balance: immediate_balance,
      //     product_invoice: product_invoice,
      //     recurrent: recurrent,
      //     net_value: net_value,
      //     from_date: from_date,
      //     to_date: to_date,
      //   };
      // }
    }
  }
  SeeChart() {
    return console.log(this.state.TotalToSend);
  }

  //DESCOMENTAR PARA FUNCION DE "CARRITO"
  removeFromCart(index) {
    const { TotalToSend } = this.state;
    TotalToSend[1].splice(index, 1);
    this.setState({ totalToSend: TotalToSend[1] });
    this.setState({ productsToInvoice: TotalToSend[1] });
    this.setState({
      product: '',
      productName: '',
      units: '',
      rate: '',
      text: '',
      text_details: '',
      notes: '',
      percentage: '',
    });
  }

  render() {
    const {
      from_date,
      to_date,
      immediate_invoice,
      product,
      immediate_balance,
      recurrent,
      product_invoice,
      net_value,
      rate,
      units,
      agent_commision,
      disabled,
      disabledTo,
      percentage,
      until,
      gotMainInfo,
      color,
      TotalToSend,
    } = this.state;
    const { productTypes } = this.props;
    return (
      <React.Fragment>
        <div className="container-button">
          <h3>Charges List</h3>
          <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
            <i className=" mdi mdi-plus-circle-outline"></i> New Charge
          </a>
        </div>
        <Modal className="modal-new-agents" isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>New Charge</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12 mt-3">
                <label>Product</label>
                <SelectCmx
                  index={1}
                  nameI={'product'}
                  options={productTypes}
                  value={product}
                  change={(e) => this.handleChange(e, 'product', 1)}
                  validate={'validation'}
                  color={color}
                  id="sel"
                />
              </div>
              {gotMainInfo === false ? (
                <>
                  <div className="col-sm-12 mt-3">
                    <div className="row">
                      <div className="col-4">
                        <Input
                          type={'date'}
                          value={from_date}
                          change={(e) => this.handleChange(e, 'from_date', 2)}
                          name={'From date'}
                          disabled={disabled}
                          id="currentDate"
                        />
                      </div>
                      <div className="col-4">
                        <Input
                          type={'date'}
                          value={to_date}
                          change={(e) => this.handleChange(e, 'to_date', 2)}
                          name={'To date'}
                          disabled={disabledTo}
                          id="toDate"
                        />
                      </div>
                      <div className="col-4 container-item">
                        <input
                          type={'checkbox'}
                          nameI={'immediate_balance'}
                          value={immediate_balance}
                          onChange={() => this.handleCheckbox(1)}
                          name={'Immediate balance'}
                          className="mt-5 mr-1"
                          id="immediateBalance"
                        />
                        <label>Immediate balance</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-3">
                        <label>Net Value</label>
                        <input
                          type={'checkbox'}
                          nameI={'net_value'}
                          value={net_value}
                          onClick={() => this.handleCheckbox(6)}
                          name={'Net Value'}
                          defaultChecked={net_value}
                          className="ml-2"
                        />
                      </div>
                      <div className="col-sm-3">
                        <label>Immediate Invoice</label>
                        <input
                          type={'checkbox'}
                          nameI={'immediate_invoice'}
                          value={immediate_invoice}
                          onClick={() => this.handleCheckbox(2)}
                          name={'Immediate Invoice'}
                          className="ml-2"
                          id="immediateInvoice"
                        />
                      </div>
                      <div className="col-sm-3">
                        <label>Product Invoice</label>
                        <input
                          type={'checkbox'}
                          nameI={'product_invoice'}
                          value={product_invoice}
                          onClick={() => this.handleCheckbox(3)}
                          name={'Product Invoice'}
                          className="ml-2"
                          id="productInvoice"
                        />
                      </div>
                      <div className="col-sm-3">
                        <label>Recurrent</label>
                        <input
                          type={'checkbox'}
                          nameI={'recurrent'}
                          value={recurrent}
                          onClick={() => this.handleCheckbox(4)}
                          name={'Recurrent'}
                          className="ml-2"
                          id="recurrent"
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div className="col-sm-12 mt-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-4">
                      {/* <label>Units</label> */}
                      <Input
                        type="text"
                        color={color}
                        placeholder="Units"
                        value={units}
                        change={(e) => this.handleChange(e, 'units', 2)}
                        name="Units"
                        ref={this.inputClean}
                      />
                    </div>
                    <div className="col-sm-4">
                      {/* <label>Rate</label> */}
                      <Input
                        type="text"
                        color={color}
                        placeholder="Rate"
                        value={rate}
                        change={(e) => this.handleChange(e, 'rate', 2)}
                        name="Rate"
                        ref={this.inputClean}
                      />
                    </div>
                    <div className="col-sm-4">
                      <label>Value</label>
                      <input
                        type="text"
                        className="form-control text-success"
                        placeholder="Value"
                        name="value"
                        value={units * rate}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="card-body pt-5 border-top">
              <div className="row">
                <div className="col-sm-12">
                  <Input
                    nameI={'text'}
                    change={(e) => this.handleChange(e, 'text', 2)}
                    name={'Text'}
                    placeholder="Text"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Input
                    type="textarea"
                    nameI={'text_details'}
                    change={(e) => this.handleChange(e, 'text_details', 2)}
                    name={'Text details'}
                    placeholder="Text Details"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <input
                    type={'checkbox'}
                    nameI={'agent_commision'}
                    value={agent_commision}
                    onClick={() => this.handleCheckbox(5)}
                    name={'Agent Commision'}
                    className="mt-4 mr-1"
                    id="agentComm"
                  />
                  <label>Agent Commision</label>
                </div>
                <div className="col-sm-4">
                  <label>Until</label>
                  <input
                    type="date"
                    nameI={'until'}
                    onChange={this.handleChange}
                    name={'Until'}
                    placeholder="Until"
                    id="inputComissionUntil"
                    disabled={true}
                    className="form-control"
                    defaultValue={until}
                  />
                </div>
                <div className="col-sm-4">
                  <label>Percentage</label>
                  <input
                    nameI={'percentage'}
                    onChange={this.handleChange}
                    name={'Percentage'}
                    placeholder="Percentage"
                    className="form-control"
                    id="inputComissionPer"
                    disabled={true}
                    defaultValue={percentage}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Input
                    type="textarea"
                    nameI={'notes'}
                    change={this.handleChange}
                    name={'Notes'}
                    placeholder="Notes"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {(immediate_invoice === true && product_invoice === true) || immediate_invoice === true ? (
              <>
                {' '}
                <button onClick={() => this.addProductToInvoice()} type="submit" className="btn btn-success">
                  Add Product
                </button>
                <div className="col-12">
                  {/* <button onClick={() => this.SeeChart()} type="submit" className="btn btn-success">
                    SeeChart
                  </button> */}

                  {TotalToSend ? (
                    // DESCOMENTAR PARA FUNCION CARRITO
                    <>
                      <div className="row container-item">
                        <h3 className="mt-1 mb-2">Products Added</h3>
                      </div>

                      <Table className="">
                        <thead className="container-item">
                          <tr>
                            <th>Product</th>
                            <th>Unit</th>
                            <th>Price</th>
                            <th>Total</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="container-item">
                          {TotalToSend[1].map((item, index) => (
                            <>
                              <tr key={index}>
                                <td className="">{item.productName}</td>
                                <td className="">{item.units}</td>
                                <td className="">{item.rate}</td>
                                <td className="">{item.units * item.rate}</td>
                                <td>
                                  <MDBIcon
                                    icon="trash"
                                    size="lg"
                                    className="trash ml-3"
                                    onClick={() => this.removeFromCart(index)}
                                  />
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </>
            ) : (
              ''
            )}

            {immediate_invoice === true ? (
              <button onClick={() => this.save()} className="btn btn-primary">
                Save and generate Invoice
              </button>
            ) : (
              <button onClick={() => this.save()} className="btn btn-primary">
                Save
              </button>
            )}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewCharge;
