/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Select from 'react-select';
import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';

class ModalCambioCustomerPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      name: '',
      type: '',
      type_all: [{ label: 'Voip', value: 0 }, { label: 'Dialer', value: 1 }],
      id: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.initialState = this.initialState.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  initialState() {
    const actual_type = this.props.type;

    this.setState({
      name: this.props.name,
      type: { label: actual_type === 1 ? 'Dialer' : 'Voip', value: actual_type },
      id: this.props.id,
    });
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async save() {
    const { type, id } = this.state;

    const object = {
      type: type,
    };

    const response = await DataApi.CambiaType(id, object);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { type, name, type_all } = this.state;

    return (
      <React.Fragment>
        <button onClick={this.toggle} className="btn btn-primary">
          Change Type
        </button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Change Type Customer Price</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <Select
                value={type}
                options={type_all}
                onChange={e => this.handleChange(e, 'type', 1)}
                className="mb-3"
              />
              <Input nameI={'name'} value={name} name={'Prefix'} type={'text'} disabled />

              <br />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                Send
              </Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalCambioCustomerPrice;
