import React from 'react';
import ModalEditExtensionPbx from './Modal/ModalEditExtensionPbx';
import { MDBIcon } from 'mdbreact';

class SubTableExtensionPbx extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: '',
    };
  }

  componentDidMount() {
    const { checkBox } = this.props;

    this.setState({ checkBox: checkBox });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.checkBox !== this.props.checkBox) {
      const { checkBox } = this.props;

      this.setState({ checkBox: checkBox });
    }
  }

  changeCheck(id, username) {
    const { checkBox } = this.state;

    if (checkBox) {
      this.setState({ checkBox: !checkBox });
    } else {
      this.setState({ checkBox: 'success' });
    }

    this.props.checkReduce(id, username);
  }

  render() {
    const { checkBox } = this.state;
    const { index, item, gateways, ext_type, response, lock } = this.props;

    if (lock) {
      return (
        <React.Fragment>
          <tr
            key={index}
            onClick={() => this.changeCheck(item.id, item.username)}
            id={`color-${item.id}`}
            className={`delete chekc-${checkBox}`}
          >
            {/* <td className="container-item-0"></td> */}
            <td className="container-item-1">{item.enabled === 1 ? 'Yes' : 'No'}</td>
            <td className="container-item-2">{item.gateways_display_name}</td>
            <td className="container-item-3">{item.ext_types_display_name}</td>
            <td className="container-item-4">{item.username}</td>
            <td className="container-item-5">{item.auth}</td>
            <td className="container-item-7">{item.configured === 1 ? 'Yes' : 'No'}</td>
            <td className="container-item-8">{item.added}</td>
            <td className="container-item-9">{item.disabled_date}</td>
            <td className="container-item-10">{item.notes}</td>
          </tr>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <tr
          key={index}
          onClick={() => this.changeCheck(item.id, item.username)}
          id={`color-${item.id}`}
          className={`delete chekc-${checkBox}`}
        >
          <td className="container-item-0"></td>
          <td className="container-item-1">{item.enabled === 1 ? 'Yes' : 'No'}</td>
          <td className="container-item-2">{item.gateways_display_name}</td>
          <td className="container-item-3">{item.ext_types_display_name}</td>
          <td className="container-item-4">{item.username}</td>
          <td className="container-item-5">{item.auth}</td>
          <td className="container-item-7">{item.configured === 1 ? 'Yes' : 'No'}</td>
          <td className="container-item-8">{item.added}</td>
          <td className="container-item-9">{item.disabled_date}</td>
          <td className="container-item-10">{item.notes}</td>

          <td className="container-item action-td">
            <div className="d-flex ml-4">
              <ModalEditExtensionPbx
                data={item}
                customersId={response}
                getExtensionPbx={this.props.getExtensionPbx}
                gateways={gateways}
                ext_type={ext_type}
                style={{ cursor: 'pointer' }}
                getAllInfo={this.props.getAllInfo}
              />
              {item.enabled === 1 ? null : (
                <MDBIcon
                  icon="trash"
                  className="red-text ml-3"
                  size="lg"
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.props.destroyExtension(item.id)}
                />
              )}
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default SubTableExtensionPbx;
