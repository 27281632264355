/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Settings/DataApi';
import Swal from "sweetalert2";

class ModalNewRate extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: '',
            items: '',
            value: '',
        };
        this.toggle = this.toggle.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }
    
    initializeState() {
        const { vat } = this.props;
        
        this.setState({
            items: vat,
        });
    }
    
    toggle() {
        this.initializeState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e) {
        this.setState({ [e.target.name] : e.target.value });
    }
    
    async save() {
        const { items, value } = this.state;
        
        const object = {
            value: value,
            vat_id: items.value,
            created_at: new Date().toJSON().slice(0, 19).replace('T', ' ')
        }
        
        const response = await DataApi.SaveRate(object);
        
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'has been saved successfully',
                showConfirmButton: false,
                timer: 1500
            });
        }else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'save error',
                showConfirmButton: false,
                timer: 1500
            });
        }
        
        await this.props.listVats(items.value);
    
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    render() {
        const { value } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <h3>Vat Rate List</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add Vat Rate
                    </a>
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>New Vat Rate</ModalHeader>
                    <ModalBody>
                        <Input type={'number'} nameI={'value'} value={value} change={this.handleChange} name={'Value'} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewRate;