import React from 'react';

import { Table } from 'reactstrap';
import { MDBIcon } from 'mdbreact';

import ModalCommissions from '../Modal/ModalCommissions';

class TableCommissions extends React.Component {
    
    render() {
        const { data } = this.props.data;
        
        if (!data) {
            return null;
        }
        
        return (
            <React.Fragment>
                <Table className="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col">Description</th>
                        <th scope="col">Percentage</th>
                        <th scope="col">Until</th>
                        <th scope="col">Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <th scope="row">{item.description_en}</th>
                            <td>{item.percentage}</td>
                            <td>{item.until}</td>
                            <td>
                                <ModalCommissions
                                    initialState={this.initialState}
                                    getAgentsData={this.props.getAgentsData}
                                    id={item.id}
                                    until={item.until}
                                    percentage={item.percentage}
                                    description={item.description_en}
                                    product_id={item.product_id}
                                    indexModal={1}
                                />
                                <MDBIcon icon="trash" size="lg" className="trash ml-3"
                                         onClick={() => this.props.destroy(item.id)} />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </React.Fragment>
        );
    }
}

export default TableCommissions;
