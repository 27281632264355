/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import ModificationArray from '../../../../../megacall/utilsFunctions/ModificationArray';
import SelectCmx from '../../../Utils/Select';
import CheckBox from '../../../Utils/Checkbox';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalNewExtensionPbx extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      username: '',
      password: '',
      configured: '0',
      gateways: '',
      getaways_all: '',
      notes: '',
      ext_type: '',
      ext_type_all: '',
      isProvisioned: false,
      login: '',
      auth_provision: '',

      userName_error:'',
      password_error:'',
      gateways_error:'',
      extType_error:'',
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.generatePassword = this.generatePassword.bind(this);
    this.initialState = this.initialState.bind(this);
    this.save = this.save.bind(this);
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  initialState() {
    const { gateways, ext_type } = this.props;

    const getaways_all = ModificationArray.modification(gateways);
    const ext_type_all = ModificationArray.modification(ext_type);

    this.setState({
      getaways_all: getaways_all,
      ext_type_all: ext_type_all,
      username: '',
      password: '',
      configured: '0',
      gateways: '',
      notes: '',
      ext_type: '',
      isProvisioned: false,
      login: '',
      auth_provision: '',
    });
  }

  handleChange(e, nameI, index) {
    const { isProvisioned } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isProvisioned });
        break;  
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  generatePassword(index) {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let string_length = 12;
    let randomstring = '';
    for (let i=0; i<string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
    }

    if(index === 1) {
      this.setState({ password: randomstring });
    } else {
      this.setState({ auth_provision: randomstring });
    }
  }

  async save() {
    this.setState({
      userName_error:'',
      password_error:'',
      
      extType_error:'',
      gateways_error:'',
    });

    const { username, password, gateways, notes, ext_type, configured, isProvisioned, login, auth_provision} = this.state;
    const { customersId } = this.props;

    if(!username.includes('*')){
      this.setState((state) => ({...state, userName_error: 'Username must include an asterisk'}));
      return;
    }

    if(ext_type.value === 1 && password == '' || ext_type.value === 2 && password == '') {
        this.setState((state) => ({...state, password_error: 'You must enter a password'}));
        return;
    }
    
    if(gateways == ''){
      this.setState((state) => ({...state, gateways_error: 'You must select a gateway'}));
      return;
    }

    if(ext_type == ''){
      this.setState((state) => ({...state, extType_error: 'You must select a extension type'}));
      return;
    }

    

    const object = {
      username: username,
      auth: password,
      gateway_id: gateways.value,
      ext_type_id: ext_type.value,
      notes: notes,
      configured: configured,
      provision: isProvisioned ? 1 : 0,
      login: login,
      password: auth_provision
    };

    let object2 = {
      action: '',
      username: '',
      auth: '',
      gateway_id: '',
      ext_type_id: '',
      notes: '',
      configured: '',
    } 

    if (ext_type.value === 1 || ext_type.value === 2) {
       object2 = {
        action: 'created',
        username: username,
        auth: password,
        gateway_id: gateways.value,
        ext_type_id: ext_type.value,
        notes: notes,
        configured: configured,
      };
    }
   
    
    // console.log(ext_type.value)

    const response = await DataApi.NewExtensionPBX(customersId, object);

    if (response.data.data === 'Number already exist') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'The extension is enabled in other customer!',
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (response.data.data === 'successfull') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });

      if(ext_type.value === 1 || ext_type.value === 2) {
        this.props.getAllInfo(3, object2);
      }
      
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.toggle();
    this.props.getExtensionPbx();
  }

  render() {
    const { username, password, getaways_all, gateways, notes, ext_type, ext_type_all, isProvisioned, login, auth_provision } = this.state;

    return (
      <React.Fragment>
        <div className="container-button">
          <h3> Extension PBX List</h3>
          <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
            <i className=" mdi mdi-plus-circle-outline"></i> Add Extension PBX
          </a>
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>New Extensiones PBX</ModalHeader>
          <ModalBody>
            <Input invalid={this.state.userName_error} feedbackError={this.state.userName_error} nameI={'username'} value={username} change={this.handleChange} name={'Username'} />
            <Input invalid={this.state.password_error} feedbackError={this.state.password_error} nameI={'password'} value={password} change={this.handleChange} name={'Password'} />
            <input value="Generate Password" onClick={() => this.generatePassword(1)} type="submit" />
            <br />
            <label className="mt-2">Configured</label>
            <select name="configured" className="form-control" onChange={this.handleChange}>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </select>
           
            

            <SelectCmx
              index={1}
              nameI={'ext_type'}
              value={ext_type}
              change={this.handleChange}
              name={'Ext Type'}
              options={ext_type_all}
              invalid={this.state.extType_error}
              feedbackError={this.state.extType_error}
            />
            {this.state.extType_error != '' && <div className='text-red'><small>{this.state.extType_error}</small></div>}

            <SelectCmx
              index={1}
              nameI={'gateways'}
              value={gateways}
              change={this.handleChange}
              name={'Gateways'}
              options={getaways_all}
             invalid={this.state.gateways_error}
             feedbackError={this.state.gateways_error}
            />
            {this.state.gateways_error != ''  && <div className='text-red'><small>{this.state.gateways_error}</small></div>}

            <br/>
            <div className="d-flex">
              <CheckBox
                nameI={'isProvisioned'}
                toggleChange={this.handleChange}
                isChecked={isProvisioned}
                index={2}
              />
              <label className="ml-2">Provisioned?</label>
            </div>
            {isProvisioned ? (
              <>
              <Input nameI={'login'} value={login} change={this.handleChange} name={'User Provisioned'} />
              <Input nameI={'auth_provision'} value={auth_provision} change={this.handleChange} name={'Password Provisioned'} />
              <input value="Generate Password" onClick={() => this.generatePassword(2)} type="submit" />
              <br />
              </>
            ) : ''}
            <label className="mt-2">Notes</label>
            <textarea
              className="form-control"
              id="notes"
              value={notes}
              name="notes"
              onChange={e => this.handleChange(e)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.save}>
              Save
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewExtensionPbx;
