import React from 'react';
import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import ModalValues from './modalValues';
import SelectCmx from '../../../elements/Utils/Select';
import Input from '../../../elements/Utils/Input';

class TableAudits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      date: '',
      type: '',
      types: [
        { label: 'ALL', value: '' },
        { label: 'Agent', value: 1 },
        { label: 'Supplier', value: 2 },
        { label: 'Customer', value: 3 },
        { label: 'Diallers', value: 4 },
        { label: 'VoipLine', value: 5 },
        { label: 'SipTrunk', value: 6 },
        { label: 'CustomerTresCX', value: 7 },
        { label: 'Commission', value: 8 },
        { label: 'Did', value: 9 },
        { label: 'CustomerCharge', value: 10 },
      ],
    };
    this.getAudit = this.getAudit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.getAudit();
  }

  handlePageClick(page) {
    const offset = page * this.state.perPage;
    const dataMostrar = this.state.data.slice(offset, offset + this.state.perPage);

    this.setState({
      currentPage: page,
      offset: offset,
      dataMostrar: dataMostrar,
    });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async getAudit(page = 1) {
    const { customersId } = this.props;
    const { date, type } = this.state;

    const object = {
      date: date,
      type: type,
      id: customersId.response,
    };

    const response = await DataApi.GetAudits(page, object);

    this.setState({
      data: response.data,
    });
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ type: e.value });
        setTimeout(() => {
          this.getAudit();
        }, 500);
        break;

      case 2:
        this.setState({ date: e.target.value });

        setTimeout(() => {
          this.getAudit();
        }, 500);
        break;
      default:
        break;
    }
  }

  render() {
    const {
      data: { data },
      date,

      types,
    } = this.state;
    const { total, current_page, per_page } = this.state.data;
    const { lock } = this.props;
    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container-fluid"></div>

          <div className="container-search-customers">
            <SelectCmx
              value={types.value}
              change={e => this.handleChange(e, 'type', 1)}
              placeholder={'Type'}
              options={types}
            />
            <Input
              type={'date'}
              nameI={'date'}
              value={date}
              change={e => this.handleChange(e, 'date', 2)}
              placeholder={'Date'}
            />
          </div>
          <div className="container-table-all">
            <Table className="table table-stripped">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">User</th>
                  <th scope="col">Event</th>
                  <th scope="col">Type</th>
                  <th scope="col">Tags</th>
                  <th scope="col">Old Values</th>
                  <th scope="col">New Values</th>
                </tr>
              </thead>

              <tbody>
                {data.map(
                  (item, index) => (
                    
                    (
                      <tr key={index}>
                        <td className="container-item-10">{item.created_at}</td>
                        <td className="container-item-11">{item.user_id}</td>
                        <td className="container-item-11">{item.event}</td>
                        <td className="container-item-11">{item.auditable_type}</td>
                        <td className="container-item-11">{item.tags}</td>
                        <td className="container-item-11">
                          {' '}
                          <ModalValues
                            value={item.old_values}
                            nameModal={'Old Values'}
                            auditableType={item.auditable_type}
                          />
                        </td>
                        <td className="container-item-11">
                          <ModalValues
                            value={item.new_values}
                            nameModal={'New Values'}
                            auditableType={item.auditable_type}
                          />
                        </td>
                      </tr>
                    )
                  )
                )}
              </tbody>
            </Table>
          </div>
          <Pagination
            totalItemsCount={total}
            onChange={page => this.getAudit(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-fluid"></div>

        <div className="container-search-customers">
          <SelectCmx
            value={types.value}
            change={e => this.handleChange(e, 'type', 1)}
            placeholder={'Type'}
            options={types}
          />
          <Input
            type={'date'}
            nameI={'date'}
            value={date}
            change={e => this.handleChange(e, 'date', 2)}
            placeholder={'Date'}
          />
        </div>
        <div className="container-table-all">
          <Table className="table table-stripped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">User</th>
                <th scope="col">Event</th>
                <th scope="col">Type</th>
                <th scope="col">Tags</th>
                <th scope="col">Old Values</th>
                <th scope="col">New Values</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item-10">{item.created_at}</td>
                  <td className="container-item-11">{item.user_id}</td>
                  <td className="container-item-11">{item.event}</td>
                  <td className="container-item-11">{item.auditable_type}</td>
                  <td className="container-item-11">{item.tags}</td>
                  <td className="container-item-11">
                    {' '}
                    <ModalValues value={item.old_values} nameModal={'Old Values'} auditableType={item.auditable_type} />
                  </td>
                  <td className="container-item-11">
                    <ModalValues value={item.new_values} nameModal={'New Values'} auditableType={item.auditable_type} />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          totalItemsCount={total}
          onChange={page => this.getAudit(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableAudits;
