/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import DataApi from '../../../../megacall/services/SuppliersServices/DataApi';
import Swal from 'sweetalert2';
import Input from '../../Utils/Input';

class ModalMultiEdit extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            items: '',
            description: '',
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initializeState = this.initializeState.bind(this);
        this.send = this.send.bind(this);
    }
    
    initializeState() {
        const { arrayMultiEdit } = this.props;
        
        this.setState({
            items: arrayMultiEdit,
        });
    }
    
    toggle() {
        this.initializeState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async send() {
        const { items, description } = this.state;
        const object = {
            items: items,
            description: description
          };
        const results = await DataApi.SaveDescriptions(object)
 
        if (results.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
            this.props.deleteState();
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Your work not has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    render() {
        const { description } = this.state;
        
        
        return (
            <React.Fragment>
                <a onClick={this.toggle} className="btn btn-secondary btn-sm text-uppercase mt-6">
                    <i className=" mdi mdi-tooltip-edit"></i> Multi Edit
                </a>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>Edit Price</ModalHeader>
                    <ModalBody>
                        
                        <Input nameI={'description'} value={description} change={this.handleChange}
                               name={'Description'} />
                    
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.send}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalMultiEdit;
