/* eslint-disable array-callback-return */
import React from 'react';

import { MDBIcon } from 'mdbreact';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import Select from 'react-select';
import Swal from 'sweetalert2';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import gif from '../../../../assets/images/tenor.gif';

class ModalEditPackages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      name: '',
      minute: '',
      customer_price: '',
      prefix_translates: '',
      prefix_translates_all: '',
      customer_price_all: '',
      multiSelect: '',
      customer_price_id: '',
      customer: '',
      prefix: '',
      customerPriceSelect: '',
      load: false,
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
  }

  async toggle() {
    await this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.customer !== this.state.customer) {
    }
  }

  async initialState() {
    const { data, select_customer_price, select_description } = this.props;

    select_customer_price.map(item => {
      if (item.value === data.customer_price_id) {
        let customer = item;

        this.setState({ customer: customer });
      }
    });

    const response = await DataApi.GetPackagesPrefixTranslate(data.id);

    const prefix = ModificationArray.modificationTranslate(Object.values(response.data));

    this.setState({
      prefix: prefix,
      name: data.name,
      minute: data.minutes,
      customer_price_id: data.customer_price_id,
      prefix_translates_all: select_description,
      customer_price_all: select_customer_price,
    });
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ multiSelect: e });
        break;
      case 2:
        this.setState({ customerPriceSelect: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async save() {
    this.setState({ load: true });
    const { data } = this.props;
    const { prefix, name, minute, customer, customerPriceSelect, multiSelect } = this.state;

    const object = {
      name: name,
      minutes: minute,
      updated_at: new Date()
        .toJSON()
        .slice(0, 19)
        .replace('T', ' '),
      customer_price_id: customerPriceSelect ? customerPriceSelect.value : customer.value, //es el id del select,
      descriptions: multiSelect ? multiSelect : prefix,
    };

    const result = await DataApi.UpdatePackges(data.id, object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Saved successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Problems saving',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.props.getPackages();
    await this.props.initialListSelect();

    this.setState({ load: false });
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { name, minute, prefix_translates_all, customer_price_all, customer, prefix, load } = this.state;

    if (load) {
      return <img src={gif} alt="loading..." />;
    }

    return (
      <>
        <div className="container-button">
          <MDBIcon icon="edit" size="lg" className="edit" onClick={this.toggle} />
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>New Package</ModalHeader>
          {customer_price_all.length === 0 ? null : (
            <React.Fragment>
              <ModalBody>
                <Input nameI={'name'} value={name} change={this.handleChange} name={'Name'} />
                <Input nameI={'minute'} value={minute} change={this.handleChange} name={'Minutes'} type={'number'} />
                <label>Customer Price</label>
                <Select
                  defaultValue={customer}
                  options={customer_price_all}
                  onChange={e => this.handleChange(e, null, 2)}
                />
                <br />
                <label>Prefix Translates</label>
                <Select
                  isMulti
                  defaultValue={prefix}
                  options={prefix_translates_all}
                  onChange={e => this.handleChange(e, null, 1)}
                />
                <br />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.save}>
                  Send
                </Button>{' '}
              </ModalFooter>
            </React.Fragment>
          )}
        </Modal>
      </>
    );
  }
}

export default ModalEditPackages;
