import React from 'react';

import { Table } from 'reactstrap';

import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import ModalEditDialler from './Modal/ModalEditDialler';
import ModalNewDialler from './Modal/ModalNewDialler';
import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';
import Input from '../../Utils/Input';

class TableDiallers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      url: '',
      contact: '',
      login: '',
    };
    this.getDiallers = this.getDiallers.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.destroyDiallers = this.destroyDiallers.bind(this);
    this.sort = this.sort.bind(this);
  }

  async componentDidMount() {
    await this.getDiallers();
  }

  async getDiallers(page = 1) {
    const {
      customersId: { response },
    } = this.props;

    const results = await DataApi.GetDiallers(page, response);

    this.setState({ data: results.data });
  }

  async destroyDiallers(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyDiallers(id);
        this.getDiallers();
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  async handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    setTimeout(() => {
      this.getDiallers();
    }, 500);
  }

  async sort(sortBy) {
    const { customersId, index } = this.props;
    const { order } = this.state;
    this.setState({ order: !order });
    const page = 1;

    const object = {
      sortBy: sortBy,
      order: order,
    };
    const results = await DataApi.GetDiallers(page, customersId.response, object);

    this.setState({ data: results.data });
  }

  render() {
    const { customersId, lock } = this.props;
    const {
      data: { data },
      url,
      contact,
      login,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container-search-diallers">
            <Input nameI={'url'} value={url} change={this.handleChange} placeholder={'Url'} />
            <Input nameI={'contact'} value={contact} change={this.handleChange} placeholder={'Contact Centre Name'} />
            <Input nameI={'login'} value={login} change={this.handleChange} placeholder={'Login'} />
          </div>

          <Table className="table table-striped">
            <thead style={{ cursor: 'pointer ' }}>
              <tr style={{ cursor: 'pointer ' }}>
                <th scope="col" onClick={() => this.sort('url')}>
                  Url
                </th>
                <th scope="col" onClick={() => this.sort('contact_centre_name')}>
                  Contact Center Name
                </th>
                <th scope="col" onClick={() => this.sort('login')}>
                  Login
                </th>
                <th scope="col" onClick={() => this.sort('password')}>
                  Password
                </th>
                <th scope="col">Notes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item-2">{item.url}</td>
                  <td className="container-item-4">{item.contact_centre_name}</td>
                  <td className="container-item-4">{item.login}</td>
                  <td className="container-item-4">{item.password}</td>
                  <td className="container-item-4">{item.notes}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItemsCount={total}
            onChange={page => this.getEmails(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-input-modal">
          <ModalNewDialler customersId={customersId} getDiallers={this.getDiallers} />
        </div>

        <div className="container-search-diallers">
          <Input nameI={'url'} value={url} change={this.handleChange} placeholder={'Url'} />
          <Input nameI={'contact'} value={contact} change={this.handleChange} placeholder={'Contact Centre Name'} />
          <Input nameI={'login'} value={login} change={this.handleChange} placeholder={'Login'} />
        </div>

        <Table className="table table-striped">
          <thead style={{ cursor: 'pointer' }}>
            <tr>
              <th scope="col" onClick={() => this.sort('url')}>
                Url
              </th>
              <th scope="col" onClick={() => this.sort('contact_centre_name')}>
                Contact Center Name
              </th>
              <th scope="col" onClick={() => this.sort('login')}>
                Login
              </th>
              <th scope="col" onClick={() => this.sort('password')}>
                Password
              </th>
              <th scope="col">Notes</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="container-item-2">{item.url}</td>
                <td className="container-item-4">{item.contact_centre_name}</td>
                <td className="container-item-4">{item.login}</td>
                <td className="container-item-4">{item.password}</td>
                <td className="container-item-4">{item.notes}</td>
                <td className="container-item-5 action-td d-flex">
                  <ModalEditDialler
                    data={item}
                    customersId={customersId}
                    getDiallers={this.getDiallers}
                    style={{ cursor: 'pointer' }}
                  />
                  <MDBIcon
                    icon="trash"
                    size="lg"
                    className="red-text ml-3"
                    onClick={() => this.destroyDiallers(item.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          totalItemsCount={total}
          onChange={page => this.getEmails(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableDiallers;
