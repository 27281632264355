/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';

class ModalNewDescription extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            description_name: '',
        };
        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initialState = this.initialState.bind(this);
    }

    initialState() {
    }

    toggle() {
        this.initialState();

        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    handleChange(e, nameI, index) {
        switch (index) {
            case 1:
                this.setState({ [nameI]: e });
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
                break;
        }
    }

    async save() {
        const { description_name } = this.state;

        const object = {
            description: description_name,
        };

        console.log(object)
        const result = await DataApi.SaveDescription(object);

        if (result.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        }

        // this.props.getLista();

        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }

    render() {
        const { description_name } = this.state;

        return (
            <React.Fragment>
                <div className="container-button">
                    <h3>Manage descriptions</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add Description
                    </a>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>New Description</ModalHeader>
                    <React.Fragment>
                        <ModalBody>
                            <Input
                                nameI={'description_name'}
                                value={description_name}
                                change={this.handleChange}
                                name={'Description'}
                                type={'text'} />
                            <br />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.save}>Send</Button>{' '}
                        </ModalFooter>
                    </React.Fragment>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewDescription;