import React from 'react';

import { Col, Row } from 'reactstrap';

import IndexTablePriceTranslate from '../../../elements/PriceElements/IndexTablePriceTranslate';
import IndexGestionDesc from '../../../elements/PriceElements/IndexGestionDesc';
import { Tab, Tabs } from 'react-bootstrap';

class PrefixTranslates extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            audits: '',
        };
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="tab-wrapper">
                    <div className='container-fluid'>
                        <div className="row">
                            <div className="col-sm-12">
                                <Tabs defaultActiveKey="edit" mountOnEnter={true}>
                                    <Tab eventKey="edit" title="Prefix Translates">
                                        <div className="tab-item-wrapper">
                                            <Row>
                                                <Col sm="12">
                                                    <IndexTablePriceTranslate />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="description" title="Descriptions">
                                        <div className="tab-item-wrapper">
                                            <IndexGestionDesc />
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PrefixTranslates;