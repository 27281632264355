import React, { Component } from 'react';

// import Input from '../../elements/Utils/Input/index';

import SelectCmx from '../../elements/Utils/Select';
import DataApi from '../../../megacall/services/Calls/DataApi';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import EditReasonAll from './Modal/editReasonAll';
import EditOverrides from './Modal/editReasonOverrides';
import Swal from 'sweetalert2';

class Overrides extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateFrom: '',
      dateTo: '',
      supplier: '',
      suppliers: '',
      customerId: '',
      originNumber: '',
      destinyNumber: '',
      reason: '',
      isViewed: '',
      data: '',
      checkAll: [],
      checkBox: false,
      isChecked: false,
      loader: false,
      checkTypes: [{ label: 'Viewed', value: 0 }, { label: 'Not Viewed', value: 1 }],
    };
    this.getOverrides = this.getOverrides.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getSuppliersData = this.getSuppliersData.bind(this);
    this.checkProof = this.checkProof.bind(this);
    this.inputType = React.createRef();
    this.inputDateFrom = React.createRef();
    this.inputDateTo = React.createRef();
    this.inputViewed = React.createRef();
    this.inputSupplier = React.createRef();
    this.inputCustomerId = React.createRef();
    this.inputOriginNumber = React.createRef();
    this.inputDestinyNumber = React.createRef();
    this.unCheckSaved = this.unCheckSaved.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

    // this.Check = React.createRef();
    this.cleanAll = this.cleanAll.bind(this);
  }

  async componentDidMount() {
    await this.getSuppliersData();
    const { checkAll } = this.state;
    this.setState({ [checkAll]: [''] });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async cleanAll() {
    this.inputDateFrom.current.value = '';
    this.inputDateTo.current.value = '';
    this.inputCustomerId.current.value = '';
    this.inputOriginNumber.current.value = '';
    this.inputDestinyNumber.current.value = '';
    this.setState({ supplier: { label: '', value: '' } });
    this.setState({ isViewed: { label: '', value: '' } });
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e.value });

        break;
      case 2:
        this.setState({ [e.target.name]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }

    setTimeout(() => {}, 300);
  }

  async handleChangeFilter(e, index) {
    // e.persist();

    switch (index) {
      case 1:
        this.setState({ customerId: e.target.value });
        setTimeout(() => {
          this.getOverrides(1);
        }, 50);
        break;
      case 2:
        this.setState({ originNumber: e.target.value });
        setTimeout(() => {
          this.getOverrides(1);
        }, 50);
        break;
      case 3:
        this.setState({ destinyNumber: e.target.value });
        setTimeout(() => {
          this.getOverrides(1);
        }, 50);
        break;
      case 4:
        this.setState({ supplier: e.value });
        setTimeout(() => {
          this.getOverrides(1);
        }, 50);
        break;
      case 5:
        this.setState({ viewed: e.value });
        setTimeout(() => {
          this.getOverrides(1);
        }, 50);
        break;
      default:
        this.setState({ name: e.target.value });
        setTimeout(() => {
          this.getOverrides(1);
        }, 50);
        break;
    }
  }

  async getSuppliersData(page = 1) {
    const results = await DataApi.GetSuppliers(page);
    console.log(results);
    this.setState({ suppliers: results.data });
  }

  async getOverrides(page = 1) {
    const { dateFrom, dateTo, originNumber, destinyNumber, supplier, isViewed, customerId, data } = this.state;

    if (!data) {
      this.setState({ loader: true });
    }

    // if (load === true) {
    //   this.setState({ loader: true });
    // }

    const json = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      originNumber: originNumber,
      destinyNumber: destinyNumber,
      supplier: supplier,
      isViewed: isViewed,
      customerId: customerId,
      sortBy: this.state.sortBy ? this.state.sortBy : '',
      order: !this.state.order,
    };

    const results = await DataApi.GetOverrides(page, json);

    this.setState({ total: results.data.total });
    this.setState({ per_page: results.data.per_page });
    this.setState({ current_page: results.data.current_page });
    this.setState({ data: results.data.data !== [''] ? results.data.data : null });
    if (results.data.data.length > 0) {
      this.setState({ loader: false });
    } else {
      this.setState({ loader: false });

      Swal.fire({
        title: 'There is no data from this date',
        text: 'please check another date.',
        icon: 'question',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      });
    }

    // setTimeout(() => {
    //   for (let index = 0; index < data.length; index++) {
    //     let nameId = 'check' + index;
    //     let checkie = document.getElementById(nameId);
    //     checkAll.splice(index, 1);
    //     checkie.checked = false;
    //   }
    // }, 1000);
  }

  unCheckSaved() {
    const { data } = this.state;

    setTimeout(() => {
      for (let index = 0; index < data.length; index++) {
        let nameId = 'check' + index;
        let checkie = document.getElementById(nameId);
        if (checkie) {
          checkie.checked = false;
        }
      }
      let chk = document.getElementById('chk');

      if (chk.checked === true) {
        chk.checked = false;
        chk.value = '';
      }
      this.setState({ checkAll: [] });
    }, 500);
  }

  async checkAll() {
    const { checkAll, data } = this.state;
    let chk = document.getElementById('chk');

    if (chk.checked === true) {
      for (let index = 0; index < data.length; index++) {
        let nameId = 'check' + index;
        let checkie = document.getElementById(nameId);
        if (checkAll.includes(checkie.value) === false) {
          checkAll.push(parseInt(checkie.value));
          // checkie.addEventListener('click', this.checkProof(parseInt(checkie.value)))
          checkie.checked = true;
        }
      }
      this.setState({ checkAll: checkAll });
    } else {
      this.setState({ checkAll: [] });
      for (let index = 0; index < data.length; index++) {
        let nameId = 'check' + index;
        let checkie = document.getElementById(nameId);
        checkie.checked = false;
      }
    }
  }

  async checkProof(id) {
    const { checkAll, isChecked } = this.state;

    this.setState({
      isChecked: !isChecked,
    });

    if (checkAll.includes(id) === false) {
      checkAll.push(id);
    } else {
      let indexOut = checkAll.indexOf(id);
      checkAll.splice(indexOut, 1);
    }

    this.setState({ checkAll: checkAll });
  }

  async checkOne() {
    const { isChecked } = this.state;
    if (isChecked === true) {
      this.setState({ isChecked: !isChecked });
    } else if (isChecked === false) {
      this.setState({ isChecked: !isChecked });
    }
  }

  async sort(sortBy) {
    const { order, dateFrom, dateTo, originNumber, destinyNumber, supplier, isViewed, customerId } = this.state;
    this.setState({ order: !order, sortBy: sortBy });
    const page = 1;

    const json = {
      dateFrom: dateFrom,
      dateTo: dateTo,
      originNumber: originNumber,
      destinyNumber: destinyNumber,
      supplier: supplier,
      isViewed: isViewed,
      customerId: customerId,
      sortBy: sortBy,
      order: order,
    };

    const results = await DataApi.GetOverrides(page, json);

    this.setState({ total: results.data.total });
    this.setState({ per_page: results.data.per_page });
    this.setState({ current_page: results.data.current_page });
    this.setState({ data: results.data.data !== [''] ? results.data.data : null });
    if (results.data.data.length > 0) {
      this.setState({ loader: false });
    }
  }

  async onKeyPress(e) {
    if (e.which === 13) {
      console.log("LLAMADO");
    await this.getOverrides();
    }
  }

  render() {
    const {
      supplier,
      suppliers,
      total,
      current_page,
      per_page,
      data,
      dateFrom,
      dateTo,
      isViewed,
      checkTypes,
      checkAll,
      isChecked,
      loader,
    } = this.state;

    // if (loader) {
    //   return (
    //     <>
    //       <div className="container-item ">
    //         <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
    //         <label className="h1">Loading</label>
    //       </div>
    //     </>
    //   );
    // }

    return (
      <>
        <div className="content-wrapper container-fluid ml-3">
          <div className="content">
            {' '}
            <div className="breadcrumb-wrapper">{/* <h1>OVERRIDES</h1> */}</div>
            <div className="row">
              <div className="col-12">
                <div className="card card-default ">
                  <div className="card-header container-item ">
                    <h2>Overrides</h2>
                  </div>

                  <div className="card-body " onKeyUp={this.onKeyPress}>
                    <div className="row top-information">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>From:</label>
                          <input
                            onChange={e => this.handleChange(e, 'dateFrom', 2)}
                            type="date"
                            name="dateFrom"
                            className="form-control"
                            placeholder="From"
                            ref={this.inputDateFrom}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>To:</label>
                          <input
                            onChange={e => this.handleChange(e, 'dateTo', 2)}
                            type="date"
                            name="dateTo"
                            className="form-control"
                            placeholder="To"
                            ref={this.inputDateTo}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Viewed</label>
                          <SelectCmx
                            index={1}
                            nameI={'isViewed'}
                            value={isViewed.value}
                            options={checkTypes}
                            change={e => this.handleChange(e, 'isViewed', 1)}
                            ref={this.inputViewed}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Supplier</label>
                          <SelectCmx
                            index={1}
                            nameI={'supplier'}
                            value={supplier.value}
                            options={suppliers}
                            change={e => this.handleChangeFilter(e, 4)}
                            ref={this.inputSupplier}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row top-information">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Customer ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer ID"
                            onChange={e => this.handleChangeFilter(e, 1)}
                            ref={this.inputCustomerId}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Origin Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Origin Number"
                            onChange={e => this.handleChangeFilter(e, 2)}
                            ref={this.inputOriginNumber}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Destiny Number</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Destiny Number"
                            onChange={e => this.handleChangeFilter(e, 3)}
                            ref={this.inputDestinyNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 justify-content-center mt-4 ">
                      <button type="submit" className="btn btn-warning" onClick={this.cleanAll}>
                        Reset
                      </button>
                      {dateFrom && dateTo && (isViewed === 0 || isViewed === 1) ? (
                        <button type="submit" className="btn btn-primary" onClick={this.getOverrides}>
                          Search
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loader === false ? (
              <>
                {data.length > 0 ? (
                  <>
                    <div className="row">
                      <div className="col-12">
                        <div className="card card-default">
                          <div className="card-body">
                            <div className="row" style={{ height: '70px', width: '100%' }}>
                              {/* <div className="d-flex position-relative" style={{ height: '50px' }}> */}
                              <div className="col-6">
                                <h3 className="ml-3">Override List</h3>
                              </div>
                              {checkAll.length > 0 ? (
                                <div className="col-6 d-flex flex-row-reverse">
                                  {' '}
                                  <EditReasonAll
                                    checkAll={checkAll}
                                    getOverrides={this.getOverrides}
                                    unCheckSaved={this.unCheckSaved}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                            <div className="table-wrap mt-3 table-responsive">
                              <table className="table3 table-bordered container-item ">
                                <thead className="">
                                  <tr>
                                    <th scope="col">
                                      {isViewed === 1 ? (
                                        <input
                                          type="checkbox"
                                          id="chk"
                                          onClick={() => this.checkAll()}
                                          defaultChecked={isChecked}
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </th>
                                    <th onClick={() => this.sort('Viewed')}>Viewed</th>
                                    <th onClick={() => this.sort('Reason')}>Reason</th>
                                    <th onClick={() => this.sort('Date')}>Date</th>
                                    <th onClick={() => this.sort('Price List')}>Price List</th>
                                    <th onClick={() => this.sort('From Number')}>From Number</th>
                                    <th onClick={() => this.sort('To Number')}>To Number</th>
                                    <th onClick={() => this.sort('Description')}>Description</th>
                                    <th onClick={() => this.sort('Duration')}>Duration</th>
                                    <th onClick={() => this.sort('Customer ID')}>Customer ID</th>
                                    <th onClick={() => this.sort('Supplier ID')}>Supplier ID</th>
                                    <th onClick={() => this.sort('Call Cost Rate')}>Call Cost Rate</th>
                                    <th onClick={() => this.sort('Call Sale Rate')}>Call Sale Rate</th>
                                    <th onClick={() => this.sort('Call Cost')}>Call Cost</th>
                                    <th onClick={() => this.sort('Call Sale')}>Call Sale</th>
                                    <th onClick={() => this.sort('% Margin')}>% Margin</th>
                                    <th onClick={() => this.sort('Call Agent Rate')}>Call Agent Rate</th>
                                    <th onClick={() => this.sort('Call Agent')}>Call Agent</th>
                                    <th onClick={() => this.sort('Last Updated')}>Last Updated</th>
                                    <th onClick={() => this.sort('Edit Reason')}>Edit Reason</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.map((item, index) =>
                                    item.call_sale < item.call_cost || item.call_agent < item.call_cost ? (
                                      <tr className="bg-danger container-item" style={{ cursor: 'default' }}>
                                        <td>
                                          {item.checked === 1 ? (
                                            <input
                                              type="checkbox"
                                              defaultChecked={isChecked}
                                              id={'check' + index}
                                              value={item.id}
                                              onClick={() => this.checkProof(item.id)}
                                              ref={this.inputType}
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </td>
                                        <td className="visto">
                                          <input type="checkbox" id={'c' + index} name="cc" checked={item.checked} />
                                          <label for={'c' + index}>
                                            <span style={{ cursor: 'default' }}></span>
                                          </label>
                                        </td>
                                        <td>{item.reason}</td>
                                        <td>{item.call_date}</td>
                                        <td>{item.customerPriceName}</td>
                                        <td>{item.origin_number}</td>
                                        <td>{item.destination_number}</td>
                                        <td>{item.description}</td>
                                        <td>{item.duration}</td>
                                        <td>{item.customer_id}</td>
                                        <td>{item.supplier_id}</td>
                                        {item.call_cost_rate * 1.5 > item.call_sale_rate ? (
                                          <td className="bg-fucsia">{item.call_cost_rate}</td>
                                        ) : (
                                          <td>{item.call_cost_rate}</td>
                                        )}
                                        {(item.call_sale_rate / item.call_cost_rate) * 100 - 100 < 15 ? (
                                          <td className="bg-salmon">{item.call_sale_rate}</td>
                                        ) : (
                                          <td>{item.call_sale_rate}</td>
                                        )}

                                        <td>{item.call_cost}</td>
                                        <td>{item.call_sale}</td>
                                        <td>{item.margin}</td>
                                        <td>{item.call_agent_rate}</td>
                                        <td>{item.call_agent}</td>
                                        <td>{item.username}</td>
                                        <td>
                                          {item.checked === 1 ? (
                                            ''
                                          ) : (
                                            <EditOverrides
                                              id={item.id}
                                              getOverrides={this.getOverrides}
                                              reason={item.reason}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr className="container-item" style={{ cursor: 'default' }}>
                                        <td>
                                          {item.checked === 1 ? (
                                            <input
                                              type="checkbox"
                                              defaultChecked={isChecked}
                                              id={'check' + index}
                                              value={item.id}
                                              onClick={() => this.checkProof(item.id)}
                                            />
                                          ) : (
                                            ''
                                          )}
                                        </td>
                                        <td className="visto" style={{ cursor: 'default' }}>
                                          <input type="checkbox" id={'c' + index} name="cc" checked={item.checked} />
                                          <label for={'c' + index} style={{ cursor: 'default' }}>
                                            <span style={{ cursor: 'default' }}></span>
                                          </label>
                                        </td>
                                        <td>{item.reason}</td>
                                        <td>{item.call_date}</td>
                                        <td>{item.customerPriceName}</td>
                                        <td>{item.origin_number}</td>
                                        <td>{item.destination_number}</td>
                                        <td>{item.description}</td>
                                        <td>{item.duration}</td>
                                        <td>{item.customer_id}</td>
                                        <td>{item.supplier_id}</td>
                                        <td>{item.call_cost_rate}</td>
                                        <td>{item.call_sale_rate}</td>
                                        <td>{item.call_cost}</td>
                                        <td>{item.call_sale}</td>
                                        <td>{item.margin}</td>
                                        <td>{item.call_agent_rate}</td>
                                        <td>{item.call_agent}</td>
                                        <td>{item.username}</td>
                                        <td>
                                          {item.checked === 1 ? (
                                            ''
                                          ) : (
                                            <EditOverrides
                                              id={item.id}
                                              getOverrides={this.getOverrides}
                                              reason={item.reason}
                                            />
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                              <Pagination
                                totalItemsCount={total}
                                onChange={page => this.getOverrides(page)}
                                activePage={current_page}
                                itemsCountPerPage={per_page}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              <div className="container-item ">
                <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
                <label className="h1">Loading</label>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Overrides;
