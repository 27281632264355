/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */

export default class Validations {
  static validationInput(items) {
    const arrayValidations = [];
    const isBelowThreshold = currentValue => currentValue === true;

    items.map((item, index) => {
      if (item.value) {
        arrayValidations.push(true);
        document.getElementsByClassName('invalidate')[index].classList.add('is-valid');
        document.getElementsByClassName('invalidate')[index].classList.remove('is-invalid');
      } else {
        arrayValidations.push(false);
        document.getElementsByClassName('invalidate')[index].classList.add('is-invalid');
        document.getElementsByClassName('invalidate')[index].classList.remove('is-valid');
      }
    });

    const validate = arrayValidations.every(isBelowThreshold);

    if (validate) return true;
  }

  static validationEmail(items) {
    const regular = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const validate = regular.test(items.value);

    if (validate) {
      document.getElementsByClassName('invalidateEmail')[0].classList.add('is-valid');
      document.getElementsByClassName('invalidateEmail')[0].classList.remove('is-invalid');
      return true;
    } else {
      document.getElementsByClassName('invalidateEmail')[0].classList.add('is-invalid');
      document.getElementsByClassName('invalidateEmail')[0].classList.remove('is-valid');
    }
  }

  static validationSelect(items) {
    const arrayValidations = [];
    const isBelowThreshold = currentValue => currentValue === true;

    items.map((item, i) => {
      if (item.value.label) {
        arrayValidations.push(true);
        const elem = document.getElementById('sel');
       
        elem.style.border = '1px solid #28a745';
      } else {
        arrayValidations.push(false);
        const elem = document.getElementById('sel');
        elem.style.border = '1px solid #dc3545';
      }
    });

    const validate = arrayValidations.every(isBelowThreshold);

    if (validate) return true;
  }
}
