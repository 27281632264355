/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Icon from '@mdi/react';
import { mdiInboxArrowUp, mdiInboxArrowDown, mdiPageNextOutline } from '@mdi/js';

import Swal from 'sweetalert2';
// import Input from '../../elements/Utils/Input/index';
import CallsStorage from '../../../megacall/services/Token/CallsStorage';

import DataApi from '../../../megacall/services/Calls/DataApi';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import Loader from 'react-loader-spinner';


class Cdr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      PbxFile: '',
      SippyFile: '',
      CallingCardFile: '',
      MagraFile: '',
      VoipFile: '',
      dateCdr: '',
      loading: false,
      loadedList: [],
      readyforProcess: false,
      disable: false,
      status: '',
      loader: false,
    };

    this.callApi = this.callApi.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.process = this.process.bind(this);
    this.import = this.import.bind(this);
    this.onChange = this.onChange.bind(this);
    this.inputFileRefPbx = React.createRef();
    this.inputFileRefSippy = React.createRef();
    this.inputFileRefCalling = React.createRef();
    this.inputFileRefMagra = React.createRef();
    this.inputFileRefVoip = React.createRef();
    this.InputDate = React.createRef();
    this.Check = React.createRef();
    this.cleanAll = this.cleanAll.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem('credentials')) {
      const expires = localStorage.getItem('expires');
      if (expires < Date.now()) {
        this.logout();
      }
    }
  }

  logout() {
    CallsStorage.RemoveStorage('credentials', 'expires');
    this.setState({ pass: false });
    window.location.replace('/');
  }

  async callApi() {
    const select = await DataApi.ListRoles('office');
    let arreglo_role_type = ModificationArray.modification(select.data);
    this.setState({ role_type: arreglo_role_type });
  }

  handleChange(e, nameI, index) {
    if (index === 2) {
      this.setState({ [nameI]: e });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  }

  onChange(e, index) {
    const { textInputFile } = this.state;

    const file = e.target.files;

    switch (index) {
      case 1:
        this.setState({ PbxFile: file[0], loading: true, [textInputFile]: file[0].name });
        break;
      case 2:
        this.setState({ SippyFile: file[0], loading: true, [textInputFile]: file[0].name });
        break;
      case 3:
        this.setState({ CallingCardFile: file[0], loading: true, [textInputFile]: file[0].name });
        break;
      case 4:
        this.setState({ MagraFile: file[0], loading: true, [textInputFile]: file[0].name });
        break;
      case 5:
        this.setState({ VoipFile: file[0], loading: true, [textInputFile]: file[0].name });
        break;
      default:
        break;
    }
  }

  async import(e) {
    const {
      PbxFile,
      SippyFile,
      CallingCardFile,
      MagraFile,
      VoipFile,
      dateCdr,
      loadedList,
    } = this.state;
    this.setState({ disable: true });
    const formData = new FormData();
    formData.append('PbxFile', PbxFile);
    formData.append('SippyFile', SippyFile);
    formData.append('CallingCardFile', CallingCardFile);
    formData.append('MagraFile', MagraFile);
    formData.append('VoipFile', VoipFile);
    formData.append('cdr_date', dateCdr);

    const response = await DataApi.LoadCdr(formData);

    this.setState({
      loadedList: [...response.data.lista, ...loadedList],
      readyforProcess: true,
      PbxFile: '',
      SippyFile: '',
      CallingCardFile: '',
      MagraFile: '',
      VoipFile: '',
      dateCdr: '',
    });

    this.setState({ disable: false });
  }

  async cleanAll() {
    this.inputFileRefPbx.current.value = '';
    this.inputFileRefSippy.current.value = '';
    this.inputFileRefCalling.current.value = '';
    this.inputFileRefMagra.current.value = '';
    this.inputFileRefVoip.current.value = '';
    this.InputDate.current.value = '';
  }

  async process() {
    this.setState({ loader: true });
    const response = await DataApi.ProcessFiles();
    if (response.status === 200) {
      this.setState({ loadedList: [] });
    }

    this.setState({ status: '' });
    const interval = setInterval(() => {
      this.checkStatusProcess();

      setTimeout(() => {
        if (this.state.status === '200') {
          this.setState({ loader: false, readyforProcess: false });

          clearInterval(interval);
        }
      }, 1000);
    }, 15000);
  }

  async checkStatusProcess() {
    const response = await DataApi.checkStatus();

    if (response.data[1] === 200) {
      const check_error = await DataApi.showError();
      this.setState({ status: '200' });
      Swal.fire({
        title: check_error.data[0],
        icon: check_error.data[1] === 200 ? 'success' : 'error',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
      });

      return response.data[1];
    }
  }

  render() {
    const {
      PbxFile,
      SippyFile,
      CallingCardFile,
      MagraFile,
      VoipFile,
      dateCdr,
      loadedList,
      readyforProcess,
      disable,
      loader,
      pass,
    } = this.state;

    if (pass === false) {
      return null;
    }
    
    if (disable) {
      return (
        <>
          <div className="container-item ">
            <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
            <label className="h1">Loading</label>
          </div>
        </>
      );
    }

    return (
      <React.Fragment>
        <div className="content-wrapper">
          <div className="content">
            <div className="breadcrumb-wrapper container-item">
              <h2>  Load / Process CDRs   </h2>
            </div>
            <div className="col-lg-12">
              <div className="mb-5">
                <label className="ml-5">Date of CDRs</label>
                <div className="ml-5" style={{ width: '500px' }}>
                  <input
                    type={'date'}
                    name="dateCdr"
                    id="archivo"
                    className="form-control"
                    onChange={e => this.handleChange(e, 'dateCdr')}
                    ref={this.InputDate}
                  />
                </div>
              </div>
            </div>

            <div className="row container-fluid ml-4">
              <div className="col-lg-4 mb-4">
                <div className="card card-default">

                  <div className="card-header justify-content-between align-items-center card-header-border-bottom">
                    <div className="media align-items-center ustify-content-between">
                      <div className="d-flex  align-items-center media-icon iconbox-45  text-white">
                        <Icon path={mdiInboxArrowUp} title="Outbound" size={1.3} horizontal color="#29cc97" />
                      </div>
                      <div className="media-body pr-3">
                        <a className="mt-1 mb-1 font-size-15 text-dark">
                          <h4 className="mt-2 ml-2">Outbound</h4>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="card-default">
                    <div className="media py-3 align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-center mr-3"></div>
                      <div className="media-body">

                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="custom-file mb-1">
                              <input
                                type="file"
                                id="formFileMultiple"
                                name="customFile"
                                className="mb-1 custom-file-input form-control"
                                onChange={e => this.onChange(e, 1)}
                                ref={this.inputFileRefPbx}
                                multiple
                              />
                              <label className="custom-file-label" for="formFileMultiple">
                                {PbxFile ? PbxFile.name : 'PBX...'}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="custom-file mb-1">
                              <input
                                type="file"
                                id="coverImage"
                                name="customFile"
                                className="mb-1 custom-file-input"
                                onChange={e => this.onChange(e, 2)}
                                ref={this.inputFileRefSippy}
                                multiple
                              />
                              <label className="custom-file-label" for="coverImage">
                                {SippyFile ? SippyFile.name : 'Sippy...'}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="custom-file mb-1">
                              <input
                                type="file"
                                id="coverImage"
                                name="customFile"
                                className="mb-1 custom-file-input"
                                onChange={e => this.onChange(e, 3)}
                                ref={this.inputFileRefCalling}
                                multiple
                              />
                              <label className="custom-file-label" for="coverImage">
                                {CallingCardFile ? CallingCardFile.name : 'Calling Card...'}
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className="col-lg-4">
                <div className="card card-default">

                  <div className="card-header justify-content-between align-items-center card-header-border-bottom">
                    <div className="media align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-center  media-icon iconbox-45 text-white">
                        <Icon path={mdiInboxArrowDown} title="Inbound" size={1.3} horizontal color="#4c84ff" />
                      </div>
                      <div className="media-body pr-3">
                        <a className="mt-1 mb-1 font-size-15 text-dark">
                          <h4 className="mt-2 ml-2">Inbound</h4>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="card-default">
                    <div className="media py-3 align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-center mr-3"></div>
                      <div className="media-body">

                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="custom-file mb-1">
                              <input
                                type="file"
                                id="coverImage"
                                name="customFile"
                                className="mb-1 custom-file-input"
                                onChange={e => this.onChange(e, 4)}
                                ref={this.inputFileRefMagra}
                                multiple
                              />
                              <label className="custom-file-label" for="coverImage">
                                {MagraFile ? MagraFile.name : 'Magrathea...'}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <div className="form-group">
                            <div className="custom-file mb-1">
                              <input
                                type="file"
                                id="coverImage"
                                name="customFile"
                                className="mb-1 custom-file-input"
                                onChange={e => this.onChange(e, 5)}
                                ref={this.inputFileRefVoip}
                                multiple
                              />
                              <label className="custom-file-label" for="coverImage">
                                {VoipFile ? VoipFile.name : 'Voiped...'}
                              </label>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                
                <div className="ml-5 col-lg-6 mt-4 ">
                  <button type="submit" className="btn btn-warning" onClick={this.cleanAll}>
                    Reset
                  </button>
                  { dateCdr ? (
                    <button type="submit" className="btn btn-primary" onClick={e => this.import(e)}>
                      Load
                    </button>
                  ) : null}
                </div>
              </div>

            </div>

            {readyforProcess === true ? (
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <div className="card card-default">
                    {loader === false ? (
                      <>
                        <div className="card-header card-header-border-bottom d-flex justify-content-between">
                          <h2>Pending Files</h2>

                          <a className="btn btn-outline-orange btn-sm text-uppercase" onClick={this.process}>
                            <Icon
                              path={mdiPageNextOutline}
                              title="Outbound"
                              size={1}
                              className="mr-1"
                              horizontal
                              color="orange"
                            />
                            Process CDRs
                          </a>
                        </div>

                        <div className="card-body">
                          <div className="row top-information">
                            <div className="">
                              <div className="">
                                {loadedList.map(item => (
                                  <>
                                    <li className="">{item}</li>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className=" container-item mt-5">
                        <Loader type="TailSpin" color="#4c84ff" height="160" width="160" className="" />
                        <label className="h1 mb-5">Working...</label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Cdr;
