import React, { Component } from 'react';

// import Input from '../../elements/Utils/Input/index';

import DataApi from '../../../megacall/services/Calls/DataApi';
import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import EditAnomaliesModal from './Modal/editAnomaliesModal';

class Anomalies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [''],
      supplier: '',
      suppliers: '',
      callInfo: [''],
      customerPriceName: '',
      agentCustomerPriceName: '',
      loader: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getAnomalies = this.getAnomalies.bind(this);
    this.getCallInfo = this.getCallInfo.bind(this);
    this.reprocess = this.reprocess.bind(this);
  }

  toggle() {
    this.getAnomalies();
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e.value });
        break;
      case 2:
        this.setState({ [e.target.name]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }

    setTimeout(() => {}, 300);
  }

  async getAnomalies(page = 1) {
    const results = await DataApi.GetAnomalies(page);

    this.setState({ total: results.data.total });
    this.setState({ per_page: results.data.per_page });
    this.setState({ current_page: results.data.current_page });
    this.setState({ data: results.data.data });
    console.log(results.data.data);
  }

  async getCallInfo(id, nameTable) {
    const json = {
      nameTable,
      id,
    };
    const results = await DataApi.GetCallInfo(json);


    // setTimeout(() => {this.setState({ callDatas: results.data[0] })}, 300);

    this.setState({ callInfo: results.data[0] });
    // const supplierIdi = ModificationArray.modification(this.state.callInfo.supplier_id);

    if (results.data[1] && results.data[2] != null) {
      this.setState({ customerPriceName: results.data[1].customerPriceName });
      this.setState({ agentCustomerPriceName: results.data[2].agentCustomerPriceName });
    }
  }

  async reprocess() {
    this.setState({ loader: true });
    const results = await DataApi.reprocessAnomalies();
    if (results.status === 200) {
      this.setState({ loader: false });
      await this.getAnomalies();
    }
  }

  async getSuppliersData(page = 1) {
    //const { name, id, enabled } = this.state;

    const results = await DataApi.GetSuppliers(page);

    this.setState({ suppliers: results.data });
  }

  async componentDidMount() {
    await this.getAnomalies();
    await this.getSuppliersData();
  }

  render() {
    const {
      data,
      total,
      per_page,
      current_page,
      suppliers,
      callInfo,
      customerPriceName,
      agentCustomerPriceName,
      loader,
    } = this.state;

    console.log(suppliers)
    return (
      <>
        <div className="content-wrapper">
          <div className="content">
            {' '}
            <div className="breadcrumb-wrapper mb-5 ml-1 container-item">
              <h1>Anomalies</h1>
            </div>
            <div className="row container-fluid ml-2">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-header card-header-border-bottom d-flex justify-content-between">
                    <h2>List</h2>
                    {data ? (
                      <button
                        href="#"
                        className="btn btn-outline-orange btn-sm text-uppercase"
                        onClick={() => this.reprocess()}
                      >
                        <i className=" mdi mdi-progress-check"></i> Reprocess
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                  {loader === false ? (
                    <>
                      <div className="mt-5 ml-5">
                        <div className="table-wrap ">
                          <table className="table table-striped ">
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Detected Error</th>
                                <th>Actions</th>
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item, index) => (
                                <>
                                  <tr>
                                    <th scope="row">{item.id}</th>
                                    <td>{item.error}</td>
                                    <td>
                                      <EditAnomaliesModal
                                        item={item}
                                        suppliers={suppliers}
                                        getCallInfo={this.getCallInfo}
                                        callsData={this.state.callsData}
                                        callInfo={callInfo}
                                        customerPriceName={customerPriceName}
                                        agentCustomerPriceName={agentCustomerPriceName}
                                        getAnomalies={this.getAnomalies}
                                      />
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </table>
                          <Pagination
                            totalItemsCount={total}
                            onChange={page => this.getAnomalies(page)}
                            activePage={current_page}
                            itemsCountPerPage={per_page}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className=" container-item mt-5">
                      <Loader type="TailSpin" color="#4c84ff" height="160" width="160" className="" />
                      <label className="h1 mb-5">Reprocessing...</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Anomalies;
