import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Card from '@material-ui/core/Card';
import SelectCmx from '../../../elements/Utils/Select';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import TableVats from '../../../elements/SettingsElements/Vats/TableVats';
import ModalNewRate from '../../../elements/SettingsElements/Vats/Modal/ModalNewRate';

class Vat extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            vat_all: '',
            vat: '',
            data: '',
        };
        this.getSelect = this.getSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.listVats = this.listVats.bind(this);
    }
    
    async componentDidMount() {
        await this.getSelect();
    }
    
    async getSelect() {
        const results = await DataApi.ListSelect();
        
        const items = ModificationArray.modification(results.data);
        
        this.setState({ vat_all: items });
    }
    
    async handleChange(e, nameI, index) {
        this.setState({ [nameI]: e });
        
        await this.listVats(e.value);
    }
    
    async listVats(id) {
        const results = await DataApi.ListVats(id);
        
        this.setState({ data: results });
    }
    
    render() {
        const { vat, vat_all, data } = this.state;
        
        if (!vat_all)
            return null;
        
        return (
            <React.Fragment>
                <div>
                    <Row>
                        <h1 className="title-table">VAT</h1>
                        <Col md={12}>
                            <Card>
                                <div className="container-input-modal">
                                    {vat ? <ModalNewRate listVats={this.listVats} vat={vat} /> : null}
                                </div>
                                <div className="container-search-customer-price">
                                    <SelectCmx nameI={'vat'} value={vat} change={this.handleChange} name={'Vat'}
                                               options={vat_all} />
                                </div>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <div className="container-table-all">
                                    <TableVats vat={vat} listVats={this.listVats} data={data} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default Vat;



