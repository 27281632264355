import React from 'react';

import { MDBIcon } from 'mdbreact';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalEditCommissionCustomer extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            until: '',
            percentage: '',
            description: '',
            product_all: '',
            id: ''
        };
        this.toggle = this.toggle.bind(this);
        this.update = this.update.bind(this);
        this.initialState = this.initialState.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    initialState() {
        const { data, product } = this.props;
                
        this.setState({
            id: data.id,
            product_all: product,
            until: data.until,
            percentage: data.percentage,
            description: { label: data.description_en, value: data.products_id },
        });
    }
    
    toggle() {
        this.initialState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e, nameI, index) {
        switch (index) {
            case 1:
                this.setState({ [nameI]: e });
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
                break;
        }
    }
    
    async update() {
        const { until, percentage, description, id } = this.state;
        
        const object = {
            until: until,
            percentage: percentage,
            description: description.value,
        };
        
        const response = await DataApi.UpdateCommissions(id, object);
    
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Your work has error saved',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        await this.props.getCommissions();
        await this.toggle();
    }
    
    render() {
        const { until, percentage, description, product_all } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>Edit Commissions</ModalHeader>
                    <ModalBody>
                        <Input nameI={'until'} value={until} change={this.handleChange}
                               name={'Until'} type={'date'}/>
                        <Input nameI={'percentage'} value={percentage} change={this.handleChange}
                               name={'Percentage'} />
                        <SelectCmx index={1} nameI={'description'} value={description}
                                   change={this.handleChange}
                                   name={'Products'} options={product_all} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.update}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEditCommissionCustomer;