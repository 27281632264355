import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Swal from 'sweetalert2';

import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/SuppliersServices/DataApi';
import { MDBIcon } from 'mdbreact';

class ModalEditPriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      modal: false,
      prefix: '',
      description: '',
      connection_charge: '',
      peak_rate: '',
      charge_seconds: '',
      prefix_translates_id: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initializeState = this.initializeState.bind(this);
    this.send = this.send.bind(this);
  }

  componentDidMount() {
    this.initializeState();
  }

  initializeState() {
    const { id, prefix, description, connection_charge, peak_rate, charge_seconds, prefix_translates_id } = this.props;

    this.setState({
      id: id,
      prefix: prefix,
      description: description,
      connection_charge: connection_charge,
      peak_rate: peak_rate,
      charge_seconds: charge_seconds,
      prefix_translates_id: prefix_translates_id,
    });
  }

  toggle() {
    this.initializeState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async send() {
    const { id, connection_charge, peak_rate, charge_seconds, prefix_translates_id } = this.state;

    const json = {
      peak_rate: peak_rate,
      connection_charge: connection_charge,
      charge_seconds: charge_seconds,
    };

    const result = await DataApi.UpdateSupplerPrefix(id, prefix_translates_id, json);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.props.getSuppliersData();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { prefix, description, connection_charge, peak_rate, charge_seconds } = this.state;

    return (
      <>
        <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Edit Price</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>Prefix</label>
              <input type="text" className="form-control" placeholder={prefix} disabled />
            </div>
            <div className="form-group">
              <label>Description</label>
              <input type="text" className="form-control" placeholder={description} disabled />
            </div>
            <Input
              nameI={'connection_charge'}
              type={'number'}
              value={connection_charge}
              change={this.handleChange}
              name={'Connection Charge'}
            />
            <Input
              nameI={'peak_rate'}
              type={'number'}
              value={peak_rate}
              change={this.handleChange}
              name={'Peak Rate'}
            />
            <Input
              nameI={'charge_seconds'}
              type={'number'}
              value={charge_seconds}
              change={this.handleChange}
              name={'Charge Seconds'}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.send}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ModalEditPriceList;
