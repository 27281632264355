/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import DataApi from '../../../../../megacall/services/Settings/DataApi';
import Swal from 'sweetalert2';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';

class ModalNewProducts extends React.Component {
    constructor(props) {
        super(props);
        
        
        this.state = {
            modal: '',
            name_es: '',
            name_en: '',
            cost: '',
            sale: ''
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }
    
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value });
    }
    
    async save() {
        const { name_es, name_en, cost, sale } = this.state;
        
        const object = {
            description: {
                es_ES: name_es,
                en: name_en,
            },
            desc_es: name_es,
            desc_en: name_en,
            cost: cost,
            sale: sale
        };
        
        const response = await DataApi.NewProduct(object);
        
        
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'has been saved successfully',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'save error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        await this.props.getProducts();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    
    render() {
        const { name_es, name_en, cost, sale } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <h3>Product List</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add Product
                    </a>
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>New Product</ModalHeader>
                    <ModalBody>
                        <Input nameI={'name_es'} value={name_es} change={this.handleChange} name={'Español'} />
                        <Input nameI={'name_en'} value={name_en} change={this.handleChange} name={'English'} />
                        <Input type="number" nameI={'cost'} value={cost} change={this.handleChange} name={"Cost"} />
                        <Input type="number" nameI={'sale'} value={sale} change={this.handleChange} name={"Sale"} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewProducts;