import React, { Component } from 'react';

import FormsSuppliers from './FormsSuppliers';
import PriceList from '../../pages/Suppliers/PriceList';
import CallsStorage from '../../../megacall/services/Token/CallsStorage';
import { Col } from 'reactstrap';
import Input from '../../elements/Utils/Input';
import DataApi from '../../../megacall/services/SuppliersServices/DataApi';
import TableImportPriceList from './TableImportPriceList';
import DataApiLock from '../../../megacall/services/Locks/DataApi';
import Switch from 'react-switch';

import { Tab, Tabs } from 'react-bootstrap';

class TabsSuppliers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      activeTab: '1',
      item: '',
      vat: '',
      audits: '',
      lock: '',
      vatSupplier: '',
      enabled: false,
      countPriceList: '',
      countAudit: '',
      stat: '',
    };
    this.toggle = this.toggle.bind(this);
    this.callApi = this.callApi.bind(this);
    this.destroyLocks = this.destroyLocks.bind(this);
    this.callCountTabs = this.callCountTabs.bind(this);
    this.desactiveSuppliers = this.desactiveSuppliers.bind(this);
    this.callCountTabs = this.callCountTabs.bind(this);
  }

  async componentWillMount() {
    await this.callApi();
    await this.callCountTabs();
    if (localStorage.getItem('credentials')) {
      const expires = localStorage.getItem('expires');
      const expira = new Date(expires);
      const hoy = new Date();
      if (expira.getTime() < hoy.getTime()) {
        this.logout();
      }
    }
  }

  logout() {
    CallsStorage.RemoveStorage('credentials', 'expires');

    this.setState({ pass: false });
  }

  async componentWillUnmount() {
    await this.destroyLocks();
  }

  async callCountTabs() {
    const id = await this.props.location.state.response;

    const countAll = await DataApi.CountSuppliers(id);

    this.setState({
      id: id,
      countPriceList: countAll.data.priceList,
      countAudit: countAll.data.audit,
    });
  }

  renderTitle() {
    const { countAudit } = this.state;

    return countAudit === 0 ? (
      <span>Audit</span>
    ) : (
      <span>
        Audit <span className="badge badge-danger badge-pill ">{countAudit}</span>
      </span>
    );
  }

  /*
   *  1 USER
   *  2 SUPPLIER
   *  3 AGENT
   *  4 CUSTOMER
   */
  async destroyLocks() {
    const { item, lock } = this.state;

    if (lock === 200) {
      await DataApiLock.DestroyLock(item.id, 2);
    }
  }

  async callApi() {
    const state = await this.props.location.state;
    const data = await DataApi.GetSuppliersShow(state.response);
    const items = await DataApi.GetSelectSuppliers();

    if (data.data.status === 200) {
      this.setState({
        id_enable: data.data.supplier.length === 0 ? null : data.data.supplier[0].id,
        audits: data.data.audits,
        item: data.data.supplier.length === 0 ? null : data.data.supplier[0],
        vat: items.data,
        id: state.response,
        lock: data.data.status,
        enabled: state.enabled ? true : false,
        stat: state.enabled,
        vatSupplier:
          data.data.supplier.length === 0
            ? null
            : {
                label: data.data.supplier[0].vats_display_name,
                value: data.data.supplier[0].vats_id,
              },
      });
    } else {
      this.setState({
        lock: data.data.status,
        userBlocked: data.data.info[1].username,
        supplierInfo: data.data.supplierInfo[0],
      });
    }
    if (this.state.stat === 1) {
      this.setState({
        stat: 'status: enabled',
      });
    } else if (this.state.stat === 0) {
      this.setState({
        stat: 'status: disabled',
      });
    }
  }

  async desactiveSuppliers() {
    const { enabled, id } = this.state;

    if (enabled) {
      await DataApi.DesactiveSuppliers(id, 1);
    } else {
      await DataApi.DesactiveSuppliers(id, 0);
    }
  }

  async handleChange(e, nameI, index) {
    const { enabled } = this.state;

    this.setState({ [nameI]: !enabled });
    setTimeout(() => {
      this.desactiveSuppliers();
    }, 500);
    if (enabled === false) {
      this.setState({ stat: 'status: enabled' });
    } else if (enabled === true) {
      this.setState({ stat: 'status: disabled' });
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { vat, item, id, lock, vatSupplier, enabled, stat, userBlocked, supplierInfo } = this.state;

    if (lock === 404) {
      return (
        <div className="container-item">
          <h2 className="mb-5 text-red">Supplier blocked by {userBlocked}</h2>

          {this.state.supplierInfo ? (
            <>
              <div className="container-fluid">
                <div className="container-input-form">
                  <Input value={supplierInfo.name} name={'Name'} disabled />
                  <Input value={supplierInfo.sippy_name} name={'Name in Sippy'} disabled />
                  <Input className="noPadding" value={supplierInfo.vats_display_name} name={'Vats'} disabled />
                </div>
                <div className="container-input-form">
                  <Input type={'number'} value={supplierInfo.min_call_cost} name={'Min. call cost'} disabled />
                  <Input
                    type={'number'}
                    value={supplierInfo.inbound_mobile_price}
                    name={'Inbound Mobile Price'}
                    disabled
                  />
                  <Input
                    type={'number'}
                    value={supplierInfo.inbound_landline_price}
                    name={'Inbound Landline Price'}
                    disabled
                  />
                </div>
                <div className="container-text-form">
                  <label>Notes</label>
                  <textarea className="form-control" value={supplierInfo.notes} name="notes" disabled />
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      if (!item)
        return (
          <>
            <div className="text-center mt-5">
              <div
                className="spinner-border text-primary mt-5 "
                style={{ width: '10rem', height: '10rem', marginTop: '1500px' }}
                role="status"
              ></div>
            </div>
          </>
        );

      return (
        <>
          <div className="container-title-tabs">
            <h3>
              <span className="style-text-edit">{id} </span>
              <span>{item.name}</span>
            </h3>
            <div className="checkbox">
              <label>
                <label className="h5 mr-4">{stat}</label>
                <Switch onColor="#29cc97" onChange={e => this.handleChange(e, 'enabled', 6)} checked={enabled} />
              </label>
            </div>
          </div>

          <div className="tab-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <Tabs defaultActiveKey="edit" mountOnEnter={true}>
                    <Tab eventKey="edit" title="Edit">
                      <div className="tab-item-wrapper">
                        <FormsSuppliers item={item} vat={vat} vatSupplier={vatSupplier} />
                      </div>
                    </Tab>

                    <Tab eventKey="priceList" title="Price List">
                      <div className="tab-item-wrapper">
                        <Col sm="12">
                          <PriceList id={item.id} fecha_update={item.last_price_update} />
                        </Col>
                      </div>
                    </Tab>

                    <Tab eventKey="import" title="Import price list">
                      <div className="tab-item-wrapper">
                        <Col sm="12">
                          <TableImportPriceList id={id} last_exchange={item.exchange_rate} fecha_update={item.last_price_update} />
                        </Col>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default TabsSuppliers;
