/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import ModalEditPriceTranslate from './Modal/ModalEditPriceTranslate';
import Swal from 'sweetalert2';
import DataApi from '../../../megacall/services/Price/DataApi';


class TablePriceTranslate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      load: false,
      selected: false,
      arrayMultiDescription: [],
    };

    this.importCsv = this.importCsv.bind(this);
    this.onChange = this.onChange.bind(this);
    this.multiPrice = this.multiPrice.bind(this);
    this.deletePriceTranslate = this.deletePriceTranslate.bind(this);
  }

  componentDidUpdate(prevProps) {

    if (prevProps.arrayMultiDescription !== this.props.arrayMultiDescription) {
      if (!this.props.arrayMultiDescription.includes(this.props.item.id)) {
        this.setState({selected: false});
      } else {
        this.setState({selected: true});
      }
    }

    // if (prevProps.arrayId !== this.props.arrayId) {
    //   if (this.props.arrayId.includes(this.props.item.id)) {
    //     this.setState({selected: true});
    //   }
    // }

  }

  async importCsv() {
    this.setState({ load: true });
    const { file } = this.state;

    const formData = new FormData();

    formData.append('file', file);

    this.props.getPrefixTranslates();

    this.setState({ load: false });
  }

  onChange(e) {
    const file = e.target.files;

    this.setState({ file: file[0] });
  }

  multiPrice(selected, id) {

    this.setState({ selected: !selected });
    this.props.multiEdit(id);

  }

  async deletePriceTranslate(id) {

    Swal.fire({
      title: `Are you sure to delete ${id}?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.deletePriceTranslate({arrayMultiDescription: [id]}).then(() => {
          this.props.getPrefixTranslates();
        });
        Swal.fire('Deleted!', `${id} has been deleted.`, 'success');
      }
    });
  }
  

  render() {
    const { item } = this.props;
    const { load, selected } = this.state;

    if (!item) {
      return null;
    }

    if (load) {
      return <h1>ESTAMOS BUSCANDO!</h1>;
    }

    return (
      <React.Fragment>
        <tr key={item.id} className="col-principal">
          <td>{item.id}</td>
          <td>{item.prefix}</td>
          <td onClick={() => this.multiPrice(selected, item.id)} className={`price-change ${selected ? 'selected': ''}`} id="multi-price">
            {item.description}
          </td>
          <td>
            <ModalEditPriceTranslate data={item} getPrefixTranslates={this.props.getPrefixTranslates} />
            <a onClick={() => this.deletePriceTranslate(item.id)} className="ml-3">
              <i className="mdi mdi-trash-can-outline text-danger mdi-24px"></i>
            </a>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default TablePriceTranslate;
