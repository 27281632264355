import CallsApi from './CallsApi';

export default class DataApi {
    
    static async GetSuppliers(page, json) {
        const result = await CallsApi.GETSUPPLIERS(page, json);
        
        return result;
    }
    
    static async GetSelectSuppliers() {
        const results = await CallsApi.GETSELECT();
        
        return results;
    }
    
    static async GetSuppliersShow(id) {
        const results = await CallsApi.GETSUPPLIERSSHOW(id);
        
        return results;
    }
    
    static async SetNewSuppliers(object) {
        const results = await CallsApi.SETSUPPLIERS(object);
        
        return results;
    }
    
    static async UpdateSupplier(id, object) {
        const results = await CallsApi.UPDATESUPPLIERSE(id, object);
        
        return results;
    }
    
    /** PREFIX */
    static async GetSupplierPrefix(id, page, object) {
        const results = await CallsApi.SETSUPPLIERSEPREFIX(id, page, object);
        
        return results;
    }

    static async GetAllSuppliers(id) {
        const results = await CallsApi.GETALLSUPPLIERS(id);

        return results;
    }

    static async DestroySupplerPrefix(id, prefix_translates_id) {
        const results = await CallsApi.DESTROYSUPPLIERSEPREFIX(id, prefix_translates_id);
        
        return results;
    }
    
    static async UpdateSupplerPrefix(id, prefix_translates_id, object) {
        const results = await CallsApi.UPDATESUPPLIERSEPREFIX(id, prefix_translates_id, object);
        
        return results;
    }
    
    /** Modal Price List **/
    static async InsertSuppilerPrefix(object) {
        const results = await CallsApi.INSERTSUPPLIERPREFIX(object);
        
        return results;
    }
    
    /** Sippy **/
    static async ImportExport(id, file) {
        const results = await CallsApi.IMPORTEXPORT(id, file);
        
        return results;
    }
    
    /** Import Price */
    static async Load(object) {
        const results = await CallsApi.LOAD(object);
        
        return results;
    }
    
    static async Results(page, object) {
        const results = await CallsApi.RESULTS(page, object);
        
        return results;
    }

    static async ResultsAll(object) {
        const results = await CallsApi.RESULTSALL(object);

        return results;
    }
    
    static async Select() {
        const results = await CallsApi.SELECT();
        
        return results;
    }
    
    static async DeleteTemporal(id) {
        const results = await CallsApi.DELETE(id);
        
        return results;
    }
    
    static async SearchTranslate(id) {
        const results = await CallsApi.SEARCHTRANSLATE(id);
        
        return results;
    }
    
    static async Count(object) {
        const results = await CallsApi.COUNT(object);
        
        return results;
    }
    
    static async PostIgnorePrefix(object) {
        const results = await CallsApi.IGNORE(object);
        
        return results;
    }
    
    static async PostSave(object) {
        const results = await CallsApi.POSTSAVE(object);
        
        return results;
    }
    
    static async SaveDescriptions(object) {
        const results = await CallsApi.SAVEDESCRIPTIONS(object);
        
        return results;
    }
    
    static async DesactiveSuppliers(id, data) {
        const results = await CallsApi.DESACTIVESUPPLIERS(id, data);
        
        return results;
    }
    
    /** Count */
    static async CountSuppliers(id) {
        const results = await CallsApi.COUNTSUPPLIERS(id);
        
        return results;
    }

}