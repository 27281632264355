import React from 'react';

import { Table } from 'reactstrap';

class TableVats extends React.Component {
    
    render() {
        const { data: { data } } = this.props;
        
        if (!data)
            return null;
        
        return (
            <Table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">VALUE</th>
                    <th scope="col">ENABLED</th>
                </tr>
                </thead>
                {
                    data.map((item, index) => (
                        <tr key={index}>
                            <td className="container-item-2 col-principal">{item.value}</td>
                            <td className="container-item-3">{item.enabled ? 'Yes' : 'No'}</td>
                        </tr>
                    ))
                }
            </Table>
        );
    }
}

export default TableVats;

