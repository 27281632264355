/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ModalEditDid from './Modal/ModalEditDid';
import { MDBIcon } from 'mdbreact';

class SubTableDid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: '',
    };
  }

  componentDidMount() {
    const { checkBox } = this.props;

    this.setState({ checkBox: checkBox });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.checkBox !== this.props.checkBox) {
      const { checkBox } = this.props;

      this.setState({ checkBox: checkBox });
    }
  }

  changeCheck(id, number) {
    const { checkBox } = this.state;

    if (checkBox) {
      this.setState({ checkBox: !checkBox });
      // this.props.getDid();
    } else {
      this.setState({ checkBox: 'success' });
      // this.props.getDid();
    }
    setTimeout(() => {
      this.props.checkReduce(id, number);
    }, 300);
  }

  render() {
    const { item, index, indexDid, customersId, lock } = this.props;
    const { checkBox } = this.state;

    if (lock) {
      return (
        <React.Fragment>
          <tr
            key={index}
            onClick={() => this.changeCheck(item.id, item.number)}
            id={`color-${item.id}`}
            className={`delete chekc-${checkBox}`}
          >
            <td className="container-item-2">{item.enabled === 1 ? 'Yes' : 'No'}</td>
            <td className="container-item-2">{item.number}</td>
            <td className="container-item-2">{item.added}</td>
            <td className="container-item-2">{item.disabled_date}</td>
            <td className="container-item-2">{item.suppliers_name}</td>
            <td className="container-item-2">{item.notes}</td>
            <td className="container-item-2">{item.international_number_types_name}</td>
            <td className="container-item-2">{item.pointing_to}</td>
            <td className="container-item-2">{item.charge_inbound}</td>
          </tr>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <tr
          key={index}
          onClick={() => this.changeCheck(item.id, item.number)}
          id={`color-${item.id}`}
          className={`delete chekc-${checkBox}`}
        >
          {/* <td className="container-item-2"></td> */}
          <td className="container-item-2">{item.enabled === 1 ? 'Yes' : 'No'}</td>
          <td className="container-item-2">{item.number}</td>
          <td className="container-item-2">{item.added}</td>
          <td className="container-item-2">{item.disabled_date}</td>
          <td className="container-item-2">{item.suppliers_name}</td>
          <td className="container-item-2">{item.notes}</td>
          <td className="container-item-2">{item.international_number_types_name}</td>
          <td className="container-item-2">{item.pointing_to}</td>
          <td className="container-item-2">{item.charge_inbound}</td>
          <td className="container-item-5 ">
            <div className="d-flex ml-4">
              <ModalEditDid
                index={indexDid}
                data={item}
                customersId={customersId}
                getDid={this.props.getDid}
                style={{ cursor: 'pointer' }}
              />
              {item.enabled === 1 ? null : (
                <a>
                  <MDBIcon
                    className="ml-3 mt-2 red-text"
                    icon="trash"
                    size="lg"
                    onClick={() => this.props.destroyDid(item.id, item.number, item.suppliers_name)}
                    style={{ cursor: 'pointer' }}
                  />
                </a>
              )}
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default SubTableDid;
