import React from 'react';
import { Table } from 'reactstrap';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import ModalEdit3cx from './Modal/ModalEdit3cx';
import ModalNew3cx from './Modal/ModalNew3cx.jsx';
import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';

class Table3cxPortal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      url: '',
      username: '',
      password: '',
      simultaneouscalls: '',
      created_at: '',
      notes: '',
      licenses: '',
      server_megacall: '',
      support: '',
      id: '',
      updated_at: '',
    };
    this.get3CX = this.get3CX.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.destroy3cx = this.destroy3cx.bind(this);
  }

  async componentDidMount() {
    await this.get3CX();
    // const { data: { data }, customersId } = this.props;
    // this.setState({
    //     support: data.data[0].support ? true : false,
    //     server_megacall: data.data[0].server_megacall ? true : false,
    //   });
  }

  async sort(sortBy) {
    let { click } = this.state;
    const { customersId } = this.props;
    const { order } = this.state;
    this.setState({ order: order, sortBy: sortBy });

    if (click >= 2) {
      this.setState({ order: !order });
    }

    const object = {
      sortBy: sortBy,
      order: order,
    };
    const results = await DataApi.Get3cx(customersId.response, object);
    click++;
    this.setState({
      data: results.data,
      support: this.state.data.support ? false : true,
      server_megacall: this.state.data.server_megacall ? false : true,
      click: click,
    });
  }

 

  async get3CX() {
    const {
      customersId: { response },
    } = this.props;

    const { order, sortBy } = this.state;

    const object = {
      sortBy: sortBy,
      order: order,
    };
    const results = await DataApi.Get3cx(response, object);

    this.setState({
      data: results.data,
      support: this.state.data.support ? false : true,
      server_megacall: this.state.data.server_megacall ? false : true,
    });
  }

  async destroy3cx(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.Destroy3cx(id);
        this.get3CX();
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  async handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    setTimeout(() => {
      this.get3CX();
    }, 300);
  }

  render() {
    const { customersId, lock } = this.props;
    const { data } = this.state;
    // const { current_page, total, per_page } = this.state.data;
    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <Table responsive>
            <thead className="container-item">
              <tr>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Username
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Password
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Gateway
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Start Date
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Update Date
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Notes
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  License
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Simultaneous calls
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Support
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Megacall Server
                </th>
              </tr>
            </thead>
            <tbody className="container-item">
              {data.map((item, index) => (
                <tr key={index} className="">
                  <td className="">{item.username}</td>
                  <td className="">{item.password}</td>
                  <td className="">{item.url}</td>
                  <td className="">{new Date(item.created_at).toLocaleDateString()}</td>
                  <td className="">{new Date(item.updated_at).toLocaleDateString()}</td>
                  <td className="">{item.notes}</td>
                  <td className="">{item.licenses}</td>
                  <td className="">{item.number_simultaneous_calls}</td>
                  <td className="">
                    <input
                      type="checkbox"
                      value={this.state.support}
                      checked={item.support}
                      name="support"
                      color="default"
                      size="small"
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      value={this.state.server_megacall}
                      checked={item.server_megacall}
                      color="default"
                      size="small"
                      name="server_megacall"
                      inputProps={{ top: '0' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-input-modal">
          <ModalNew3cx customersId={customersId} get3CX={this.get3CX} />
        </div>

        <Table responsive>
          <thead className="container-item">
            <tr>
              <th scope="col" onClick={() => this.sort('username')}>
                Username
              </th>
              <th scope="col" onClick={() => this.sort('password')}>
                Password
              </th>
              <th scope="col" onClick={() => this.sort('gateway')}>
                Gateway
              </th>
              <th scope="col" onClick={() => this.sort('start_date')}>
                Start Date
              </th>
              <th scope="col" onClick={() => this.sort('update_date')}>
                Update Date
              </th>
              <th scope="col" onClick={() => this.sort('')}>
                Notes
              </th>
              <th scope="col" onClick={() => this.sort('license')}>
                License
              </th>
              <th scope="col" onClick={() => this.sort('sim_calls')}>
                Simultaneous calls
              </th>
              <th scope="col" onClick={() => this.sort('support')}>
                Support
              </th>
              <th scope="col" onClick={() => this.sort('server')}>
                Megacall Server
              </th>
              <th scope="col actions">Actions</th>
            </tr>
          </thead>
          <tbody className="container-item">
            {data.map((item, index) => (
              <tr key={index} className="">
                <td className="">{item.username}</td>
                <td className="">{item.password}</td>
                <td className="">{item.url}</td>
                <td className="">{new Date(item.created_at).toLocaleDateString()}</td>
                <td className="">{new Date(item.updated_at).toLocaleDateString()}</td>
                <td className="">{item.notes}</td>
                <td className="">{item.licenses}</td>
                <td className="">{item.number_simultaneous_calls}</td>
                <td className="">
                  <input
                    type="checkbox"
                    value={this.state.support}
                    checked={item.support}
                    name="support"
                    color="default"
                    size="small"
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    value={this.state.server_megacall}
                    checked={item.server_megacall}
                    color="default"
                    size="small"
                    name="server_megacall"
                    inputProps={{ top: '0' }}
                  />
                </td>

                <td className="container-item action-td d-flex">
                  <ModalEdit3cx
                    data={item}
                    created_at={new Date(item.created_at).toLocaleDateString()}
                    updated_at={new Date(item.updated_at).toLocaleDateString()}
                    customersId={customersId}
                    get3cx={this.get3CX}
                    style={{ cursor: 'pointer' }}
                  />
                  <MDBIcon
                    icon="trash"
                    size="lg"
                    className="red-text ml-3"
                    onClick={() => this.destroy3cx(item.id)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default Table3cxPortal;
