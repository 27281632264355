import React from 'react';

import { Input } from 'reactstrap';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    const { nameI, index } = this.props;

    this.props.toggleChange(null, nameI, index);
  }

  render() {
    const { isChecked, name } = this.props;

    return (
      <React.Fragment>
        <div className="d-flex">
          <Input type="checkbox" checked={isChecked} onClick={this.handleChange} />
          <label className="ml-2">{name}</label>
        </div>
      </React.Fragment>
    );
  }
}

export default Checkbox;
