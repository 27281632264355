import React from 'react';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import { Table } from 'reactstrap';
import { MDBIcon } from 'mdbreact';

class TableLocks extends React.Component {
    constructor(props) {
        super(props);
        
        this.destroyLocks = this.destroyLocks.bind(this);
    }
    
    async destroyLocks(id) {
        await DataApi.DestroyLocks(id);
        
        await this.props.getLocks();
    }
    
    render() {
        const { data: { data } } = this.props;
        
        if (!data)
            return null;
        
        return (
            <Table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Lock Type</th>
                    <th scope="col">Record</th>
                    <th scope="col">User</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="col-principal">{item.date}</td>
                        <td>{item.display_name}</td>
                        <td>{item.record_id}</td>
                        <td>{item.username}</td>
                        <td>
                            <MDBIcon icon="trash" size="lg" className="trash"
                                     onClick={() => this.destroyLocks(item.id)} />
                        </td>
                    </tr>
                ))}
            </Table>
        );
    }
}

export default TableLocks;