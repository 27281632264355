import UrlServices from '../UrlServices';
import axios from 'axios';
import Headboard from '../Headboard';

export default class CallApi {
  static API_URL_GLOBAL = UrlServices.universal;

  /** Customers */
  static async LISTCUSTOMERS(page, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/list?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETCUSTOMERFORMS(id) {
    const response = await axios.post(CallApi.API_URL_GLOBAL + `customers/show/${id}`, null, await Headboard.HEADERS());

    return response;
  }

  static async UPDATECUSTOMERS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWCUSTOMERS(object) {
    const response = await axios.post(CallApi.API_URL_GLOBAL + 'customers/create', object, await Headboard.HEADERS());

    return response;
  }
  static async SENDINFOMAIL(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/infoMail/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async SENDFINALEMAIL(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/mailFinal`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async DONTSENDINFO(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/dontSendEmail`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async GETALLEMAILINFO() {
    const response = await axios.get(CallApi.API_URL_GLOBAL + 'customers/allMailInfo', await Headboard.HEADERS());

    return response;
  }

  static async GETEMAILINFO(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/getInfoMail`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETSELECTS() {
    const response = await axios.get(CallApi.API_URL_GLOBAL + 'customers/select', await Headboard.HEADERS());

    return response;
  }

  static async CUSTOMERDESACTIVE(id, enabled) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/disabled-customer/${id}/${enabled}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Contacts */
  static async GETCONTACTS(page, id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/contacts/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWCONTACTS(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + 'customers/contacts/create',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATECONTACTS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/contacts/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYCONTACT(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/contacts/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Emails */
  static async GETEMAILS(page, id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/emails/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWEMAILS(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/emails/create`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEEMAILS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/emails/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYEMAILS(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/emails/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Did */
  static async GETDIDINTERNATIONAL(page, id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/did-numbers/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETDIDORDERS(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/did-numbers/listOrders/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async UPDATEORDERSTATUS(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/did-numbers/updateStatus`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async SENDEMAILORDERNOTE(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/did-numbers/sendEmailOrder`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETDIDCALLINGCARD(page, id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/calling-card/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETSELECT() {
    const response = await axios.get(CallApi.API_URL_GLOBAL + 'customers/selects', await Headboard.HEADERS());

    return response;
  }

  static async NEWDID(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + 'customers/did-numbers/create',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWDIDMOBILE(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + 'customers/calling-card/create',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEDID(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/did-numbers/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEDIDMOBILE(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/calling-card/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYDID(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }


  static async ENABLEDCHANGE(id, type, array) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/enabled/${id}/${type}`,
      array,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async CREATEEMAILS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/mail/create/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async LISTEMAILS(id, type) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/mail/list/${id}/${type}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async EMAILSDESTROY(id, idCustomers, type) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/mail/destroy/${id}/${idCustomers}/${type}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETIDDID(id, type) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/id-all-did/${id}/${type}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Diallers */
  static async GETDIALLERS(page, customer_id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/diallers/list/${customer_id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEDIALLER(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/diallers/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWDIALLER(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/diallers/create/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYDIALLERS(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/diallers/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** 3xc */
  static async GET3CX(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/3cx/list/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATE3CX(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/3cx/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEW3CX(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/3cx/create/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROY3CX(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/3cx/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** SipTrunk */
  static async GETSipTrunk(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sipTrunk/list/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATESipTrunk(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sipTrunk/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async SENDCREDENTIALS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sipTrunk/sendCredentials/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATESipTrunkCustomer(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sipTrunk/updateCustomer/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWSipTrunk(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sipTrunk/create/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYSipTrunk(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sipTrunk/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async ENABLEDCHANGESIPTRUNK(type, array) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sipTrunk/enabled/${type}`,
      array,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Extension PBX */
  static async GETEXTENSIONPBX(page, id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWEXTENSIONPBX(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/create/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEEXTENSIONPBX(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEUSERNAME(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/edit/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async SENDCREDENTIAL(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/sendCredentials/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYEXTENSIONPBX(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async ENABLEDCHANGEEXTENSION(type, array, id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/enabled/${type}/${id}`,
      array,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETIDALL(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/id-all/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  //PAYMENTS
  static async GETPAYMENTS(id, page, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/payments/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }
  static async NEWPAYMENT(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/payments/create/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }
  static async SENDEMAIL(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/payments/sendEmail/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }
  static async DESTROYCPAYMENTS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/payments/destroy/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async GETPAYMENTSTYPES() {
    const response = await axios.get(CallApi.API_URL_GLOBAL + `customers/payments/types`, await Headboard.HEADERS());
    return response;
  }
  static async UPDATEBALANCE(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/payments/updateBalance`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  //CHARGES
  static async GETPRODUCTTYPES(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/charges/types`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async GETCHARGES(id, page, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/charges/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async SENDANDGENERATE(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/charges/invoice/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async UPDATECHARGE(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/charges/update/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }
  static async DESTROYCHARGE(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/charges/destroy/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }
  static async GETCHARGEINFO(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/charges/chargeInfo/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async GETAGENTCOMMISION(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/charges/getAgentComm`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  //INVOICES
  static async GETINVOICES(page, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/invoices/list?page=${page}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async GETINVOICEINFO(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/invoices/invoiceInfo`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }
  static async GENERATE(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/invoices/generate`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async GETREPORT(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/invoices/monthlyInfo`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async GETINFOINVOICES(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/invoices/infoInvoices`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }
  static async MAKEINVOICES(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/invoices/makeCharges`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  static async UPDATEINVOICE(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/invoices/update/${id}`,
      object,
      await Headboard.HEADERS()
    );
    return response;
  }

  /** Commissions */
  static async GETCOMMISSIONS(id, page) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/commissions/list/${id}?page=${page}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATECOMMISSIONS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/commissions/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWCOMMISSIONS(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/commissions/create/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYCOMMISSIONS(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/commissions/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Notes */
  static async GETNOTES(id, page, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/notes/list/${id}?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async UPDATENOTES(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/notes/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWNOTES(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/notes/create/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYNOTES(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/notes/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Audits */
  static async GETAUDITS(page, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/audit/list?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Count */
  static async COUNT(id) {
    const response = await axios.get(CallApi.API_URL_GLOBAL + `customer/count/${id}`, await Headboard.HEADERS());

    return response;
  }

  /** Packages */
  static async GETPACKAGES(id, page) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/list/package/${id}?page=${page}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async UPDATEPACKAGES(id, object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/update/package/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async UPDATEMINUTES(object) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + 'customers/modify/package',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYPACKAGE(id) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/destroy/package/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async SENDEXTCREDENTIAL(type, array) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/extensions-pbx/sendPassword/${type}`,
      array,
      await Headboard.HEADERS()
    );

    return response;
  }
  static async GETLOGS(page, object) {
    const response = await axios.post(CallApi.API_URL_GLOBAL + `logs?page=${page}`, object, await Headboard.HEADERS());

    return response;
  }
  static async OPEN(object) {
    const response = await axios.post(CallApi.API_URL_GLOBAL + `open`, object, await Headboard.HEADERS());

    return response;
  }
  static async SENDSUSPEND(CustomerId) {
    const response = await axios.post(
      CallApi.API_URL_GLOBAL + `customers/sendSuspend`,
      CustomerId,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async GETBALANCELOGS(page, id, object) {
    const response = await axios.post(CallApi.API_URL_GLOBAL + `customers/log-balance/${id}?page=${page}`,  object, await Headboard.HEADERS());

    return response;
  }
}
