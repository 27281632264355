import React from 'react';

class SearchAgents extends React.Component {

    render() {
      const { selectOne, selectTwo } = this.props;

          return(
            <div className="container-search-agents">
                <div className="form-control-one-agents">
                    <input
                      className="form-control form-agents-one"
                      placeholder="Name"
                      onChange={(e) => this.props.hanleChangeFilter(e)}
                    />

                    <input
                      className="form-control form-agents-one"
                      placeholder="Contact name"
                      onChange={(e) => this.props.hanleChangeFilter(e, 1)}
                    />
                    <input
                      className="form-control"
                      placeholder="Contact number"
                      onChange={(e) => this.props.hanleChangeFilter(e, 2)}
                    />
                </div>

                <div className="form-control-two-agents">
                    <input
                      className="form-control"
                      placeholder="Email"
                      onChange={(e) => this.props.hanleChangeFilter(e, 3)}
                    />
                    <div className="form-check form-check-inline inline-agents">
                        <input
                          className="form-check-input"
                          type="radio"
                          value={'1'}
                          name="contact"
                          onChange={(e) => {
                            this.props.hanleChangeFilter(e, 4)
                          }}
                          checked={selectOne}
                        />
                        <label className="form-check-label">Enable&nbsp;</label>
                        <input
                          className="form-check-input"
                          type="radio"
                          value={'0'}
                          name="contact"
                          onChange={(e) => {
                            this.props.hanleChangeFilter(e, 5);
                          }}
                          checked={selectTwo}
                        />
                        <label className="form-check-label">Disable&nbsp;</label>
                    </div>
                </div>
              </div>
          );
      }
}

export default SearchAgents;