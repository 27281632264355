import CallsApi from './CallsApi';

export default class DataApi {

    // CDR
    static async LoadCdr(object) {
        const results = await CallsApi.LOADCDR(object);
        
        return results;
    }
    static async ProcessFiles() {
        const results = await CallsApi.ProcessFiles();
        
        return results;
    }

    static async GetSuppliers() {
        const result = await CallsApi.GETSUPPLIERS();
        
        return result;
    }

    static async GetCalls(page ,json) {
        const result = await CallsApi.GETCALLS(page, json);
        
        return result;
    }
    static async checkStatus() {
        const result = await CallsApi.CHECKSTATUS();
        
        return result;
    }
    static async showError() {
        const result = await CallsApi.SHOWERROR();
        
        return result;
    }


    //Anomalies
    static async GetAnomalies(page) {
        const result = await CallsApi.GETANOMALIES(page);
        
        return result;
    }
    static async GetCallInfo(json) {
        const result = await CallsApi.GETCALLINFO(json);
        
        return result;
    }
    static async UpdateCdr(json) {
        const result = await CallsApi.UPDATECDR(json);
        
        return result;
    }
    static async reprocessAnomalies(page) {
        const result = await CallsApi.REPROCESSANOMALIES();
        
        return result;
    }




    //Overrides

    static async GetOverrides(page, json) {
        const result = await CallsApi.GETOVERRIDES(page, json);
        
        return result;
    }
    static async updateReason(json) {
        const result = await CallsApi.UPDATEREASON(json);
        
        return result;
    }
    static async updateReasonMass(json) {
        const result = await CallsApi.UPDATEREASONMASS(json);
        
        return result;
    }

    static async reprocessCDR(json) {
        const result = await CallsApi.REPROCESSCDR(json);
        
        return result;
    }

    //Charges

    static async getCalls(json) {
        const result = await CallsApi.GETCALLSREPORT( json);
        
        return result;
    }
    static async processUpdate(json) {
        const result = await CallsApi.PROCESSUPDATE( json);
        
        return result;
    }

}