import React from 'react';

import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import { Card, CardFooter, CardBody, CardHeader, Button } from 'reactstrap';

import DataApi from '../../../megacall/services/Price/DataApi';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import ModalModifyPrefix from './Modal/ModalModifyPrefix';

import Swal from 'sweetalert2';
import ModalNewDescription from './Modal/ModalNewDescription';
import { CSVLink } from 'react-csv';

class IndexGestionDesc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      countries: '',
      country: '',
      csv: '',
      file: null,
      load: false,
      select: [{ label: 'FALSO', value: 0 }],
      totals: 0
    };

    this.onChange = this.onChange.bind(this);
    this.importCsv = this.importCsv.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.getSelect = this.getSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getLista = this.getLista.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  onChange(e) {
    const file = e.target.files;
    this.setState({ file: file[0] });
  }

  async importCsv() {
    this.setState({ load: true });
    const { file } = this.state;

    const formData = new FormData();
    formData.append('file', file);

    console.log('formData')
    console.log(formData)
    const result = await DataApi.ImportCsvDescription(formData);

    if (result.status === 200) {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
        });
    } else {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Your work has been saved',
            showConfirmButton: false,
            timer: 1500,
        });
    }

    // this.getLista();

    this.setState({ load: false, file: null });
  }

  async exportCsv() {
    const response = await DataApi.ExportCsvDescription();
    console.log('response')
    console.log(response)
    this.setState({ csv: response.data });
  }

  async componentDidMount() {
    try {
      await this.getSelect();
    } catch (error) {
      console.log(error)
    }

    try {
      await this.exportCsv();
    } catch (error) {
      console.log(error)
    }
  }

  async getSelect() {
    const countries = await DataApi.GetListCountries();

    this.setState({ countries: ModificationArray.modification(countries.data) });
  }

  async handleChange(e) {
    this.setState({ country: e });
    setTimeout(() => {
      this.getLista();
    }, 50);
  }

  async getLista() {
    this.setState({ load: true });

    const { country } = this.state;

    const object = {
      country: country
    };
    const results = await DataApi.GetCompareDesc(object);
    const descriptions = await DataApi.GetListDesc(country.label);

    this.setState({ data: results.data.item, totals: results.data.total, load: false, select: ModificationArray.modification(descriptions.data) });
  }

  async confirm() {
    const { data } = this.state;
    const result = await DataApi.ConfirmPrefix(data.prefix_id);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Description confirmed',
        showConfirmButton: false,
        timer: 1500,
      });
      setTimeout(() => {
        this.getLista();
      }, 50);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Problems found',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  render() {
    const { data, countries, country, csv, load, select, totals } = this.state;

    if (load) {
      return (
        <div
          className="container-item mt-5 position-absolute offset-3"
          style={{ zIndex: '1000', backgroundColor: '#f2f5f9', width: '600px' }}
        >
          <Loader type="ThreeDots" color="#4c84ff" width={300} className="top-100 start-100 mt-3" />
          <div className="d-flex justify-content-center ">
            <h2 className="ml-3" style={{ color: '#4c84ff' }}>
              LOADING...
            </h2>
          </div>
        </div>
      );
    }

    return (
      <>
        <div>
          <Row>
            <Col>
              <div className="container-input-modal">
                <ModalNewDescription getLista={this.getLista} select={select} />
              </div>
              <div className="container-agents-search">
                <CSVLink
                  data={csv}
                  filename={'description.csv'}
                  className="btn btn-success btn-sm text-uppercase mt-6"
                  target="_blank"
                  style={{
                    alignSelf: 'flex-end'
                  }}
                >
                  <i className=" mdi mdi-progress-download"></i> Download CSV
                </CSVLink>

                <div className="col-lg-4 mt-5">
                  <div 
                  className="input-group"
                  style={{
                    alignItems: 'center'
                  }}
                  >
                    <input
                      type="file"
                      className="form-control"
                      onChange={this.onChange}
                      aria-label="File input with dropdown button"
                    />
                    <div className="input-group-append position-relative">
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-toggle="dropdown"
                        onClick={this.importCsv}
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-display="static"
                      >
                        <i className=" mdi mdi-import"></i> Import CSV
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />

              {/* <div className="container-search">
                <div className="col-lg-12">
                  <div className="row ml-3">
                    <div className="col-lg-12">
                      <label>Countries</label>
                      <Select defaultValue={country} options={countries} onChange={e => this.handleChange(e)} />
                    </div>
                  </div>
                  <br />
                </div>
              </div> */}

              {/* {data !== null && data.length !== 0 ? (
                <div className="container-search">
                  <div className="col-lg-12">
                    <div className="row ml-3">
                      <div className="col-lg-12">
                        <h4>Total to review: {totals}</h4>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="container">
                <div className="row justify-content-center">
                  {(data !== null && data.length !== 0) ?
                    <Card className="col-lg-12 ml-3">
                      <CardHeader className="bg-white">
                        <h3>{data.prefix_mega} - {data.desc_mega}</h3>
                      </CardHeader>
                      <CardBody>
                        <div className="border-bottom border-5 border-primary mb-3 mt-3">
                          {data.desc_2014 ? (
                            <div className="mt-2 mb-2">
                              <b>Voicetrunk: {data.desc_2014}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2015 ? (
                            <div className="mt-2 mb-2">
                              <b>Magrathea: {data.desc_2015}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2019 ? (
                            <div className="mt-2 mb-2">
                              <b>IDT: {data.desc_2019}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2026 ? (
                            <div className="mt-2 mb-2">
                              <b>QuickCom: {data.desc_2026}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2027 ? (
                            <div className="mt-2 mb-2">
                              <b>Ventatel: {data.desc_2027}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2040 ? (
                            <div className="mt-2 mb-2">
                              <b>Voiped: {data.desc_2040}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2045 ? (
                            <div className="mt-2 mb-2">
                              <b>EtoileDiese: {data.desc_2045}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2064 ? (
                            <div className="mt-2 mb-2">
                              <b>DVX: {data.desc_2064}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2073 ? (
                            <div className="mt-2 mb-2">
                              <b>Telenort: {data.desc_2073}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2074 ? (
                            <div className="mt-2 mb-2">
                              <b>Vodafone: {data.desc_2074}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2077 ? (
                            <div className="mt-2 mb-2">
                              <b>CM.com: {data.desc_2077}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2079 ? (
                            <div className="mt-2 mb-2">
                              <b>Viatel: {data.desc_2079}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2080 ? (
                            <div className="mt-2 mb-2">
                              <b>DIDLogic: {data.desc_2080}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2082 ? (
                            <div className="mt-2 mb-2">
                              <b>Lexico: {data.desc_2082}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                          {data.desc_2085 ? (
                            <div className="mt-2 mb-2">
                              <b>Orbital: {data.desc_2085}</b>
                              <br />
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </CardBody>
                      <CardFooter className="d-flex justify-content-between bg-white">
                        <Button color="success" onClick={this.confirm}><i className="mdi mdi-check-all"></i> CONFIRM</Button>{' '}
                        <ModalModifyPrefix select={select} getLista={this.getLista} prefix_id={data.prefix_id} />
                      </CardFooter>
                    </Card>
                    : ''}
                </div>
              </div> */}

            </Col>
          </Row>
        </div>
      </>
    );
  }

}

export default IndexGestionDesc;