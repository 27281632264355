import React, { Component } from 'react';

import Input from '../../elements/Utils/Input';
import SelectCmx from '../../elements/Utils/Select';
import Validations from '../../../megacall/utilsFunctions/Validations';
import DataApi from '../../../megacall/services/AgentsServices/DataApi';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import TableReport from '../../elements/AgentsElements/TableReport';

import { Card } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import Loader from 'react-loader-spinner';

class monthlyCommission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      date: '',
      agent: '',
      selectAgent: '',
      color: 'invalidate',
      spin: false,
    };

    this.getReport = this.getReport.bind(this);
  }

  async componentWillMount() {
    const results = await DataApi.ListAgents('actives');
    let selectAgent = ModificationArray.modification(results.data);
    selectAgent.reverse();
    selectAgent.push({ label: 'All ', value: '' });
    selectAgent.reverse();
    this.setState({ selectAgent: selectAgent });
  }

  async getReport() {
    const { date, agent } = this.state;
    this.setState({ spin: true });

    const validateInput = Validations.validationInput([{ value: date, name: 'Date' }]);
    if (validateInput) {
      const object = {
        date: date,
        agent_id: agent.value,
      };
      const response = await DataApi.getReport(object);
      if (response) {
        this.setState({ spin: false, data: response.data });
      }
    } else {
      this.setState({ spin: false });
    }
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState(prevState => ({ ...prevState.nameI, [nameI]: e }));        
        break;
      case 2:
        this.setState({ date: e.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    const { data, spin, agent, selectAgent, color, date } = this.state;

    return (
      <React.Fragment>
        <Row>
          <h1 className="title-table">COMMISSION REPORT</h1>
          <Col md={12}>
            <Card>
              <div className="container-search">
                <div className="row justify-content-between top-information container-search-user">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <Input change={e => this.handleChange(e, 'Date', 2)} type="date" color={color} />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <SelectCmx value={agent} change={e => this.handleChange(e, 'agent', 1)} placeholder={'Agent'} options={selectAgent}/>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      {date.length !== 0 ? 
                        <button className="btn btn-primary mt-4" onClick={this.getReport}><i className=" mdi mdi-import"></i> Generate Report</button>
                      : 
                        <button className="btn btn-primary mt-4" disabled><i className=" mdi mdi-import"></i> Generate Report</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="container-table-all">
                {spin === false ? (
                  <TableReport data={data} />
                ) : (
                  <div className=" container-item mt-5">
                    <Loader type="TailSpin" color="#4c84ff" height="160" width="160" className="" />
                    <label className="h1 mb-5"></label>
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default monthlyCommission;