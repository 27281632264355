import React from 'react';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataApiSupplier from '../../../../megacall/services/SuppliersServices/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Select from 'react-select';
import Loader from 'react-loader-spinner';

class ModalMultiEditPriceTranslate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      price: '',
      charge: '',
      id: '',
      loader: false,
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchTranslate = this.searchTranslate.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async searchTranslate(e) {
    this.setState({ value: e.target.value });

    setTimeout(() => {
      const { value } = this.state;

      if (value.length >= 2) {
        DataApiSupplier.SearchTranslate({ text: value }).then(items => {
          if (items.data[0].description) {
            const arrayTranslate = items.data[0].description.split(',');

            const translate = ModificationArray.modificationTranslate(arrayTranslate);

            this.setState({ description: translate });
          }
        });
      }
    }, 500);
  }

  async save() {
    const { description_name, loader } = this.state;
    const { arrayMultiDescription, arrrayId } = this.props;
    if (arrayMultiDescription.length > 0 || arrrayId.length > 0) {
      const object = {
        description: description_name.value,
        arrayMultiDescription: arrayMultiDescription.length > 0 ? arrayMultiDescription : arrrayId,
      };

      const result = await DataApi.UpdatePriceTranslateDescription(object);

      if (result.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else if (this.props.description.length || this.props.prefix.length) {
      
      const object = {
        newDescription: description_name.value,
        oldDescription: this.props.description,
        prefix: this.props.prefix,
      };

      // const result = await DataApi.UpdatePriceTranslateDescription(object);

      // if (result.status === 200) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update it!',
      }).then(async result => {
        if (result.isConfirmed) {
          this.setState({ loader: true });
          await DataApi.UpdatePriceTranslateDescription(object).then(response => {
            if (response.status === 200) {
              Swal.fire('updated!', 'Your description has been updated.', 'success');
            }
          });

          setTimeout(() => {
            window.location.reload();
          }, 700);
        }
      });
      // Swal.fire({
      //   position: 'center',
      //   icon: 'success',
      //   title: 'Your work has been saved',
      //   showConfirmButton: false,
      //   timer: 1500,
      // });

      // setTimeout(() => {
      //   window.location.reload();
      // }, 700);
      // } else {
      //   Swal.fire({
      //     position: 'center',
      //     icon: 'error',
      //     title: 'Your work has been saved',
      //     showConfirmButton: false,
      //     timer: 2000,
      //   });
      // }
    }

    this.props.getPrefixTranslates();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { description, description_name, loader } = this.state;
    const { arrayMultiDescription, arrrayId } = this.props;

    return (
      <>
        <div className="ml-5">
          {description != null ? (
            <button className="btn btn-primary" onClick={this.toggle}>
              {' '}
              MultiEdit
            </button>
          ) : (
            <button className="btn btn-primary" onClick={this.toggle}>
              {' '}
              Edit All
            </button>
          )}
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Multiedit</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <Select
                value={description_name}
                options={description}
                onChange={e => this.handleChange(e, 'description_name', 1)}
                onKeyDown={this.searchTranslate}
                className="mb-3"
              />

              <br />
              {loader === true ? (
                <div className="container-item">
                  <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
                </div>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                Send
              </Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalMultiEditPriceTranslate;
