import React from 'react';
import { MDBIcon } from 'mdbreact';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalEditNotes extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            textNotes: '',
            id: ''
        };
        this.initialState = this.initialState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.update = this.update.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    
    toggle() {
        this.initialState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    initialState() {
        const { data } = this.props;
        
        this.setState({
            id: data.id,
            textNotes: data.text,
        });
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async update() {
        const { customersId } = this.props;
        const { textNotes, id } = this.state;
        
        const object = {
            text: textNotes,
            id: id
        };
        
        const response = await DataApi.UpdateNotes(customersId, object);
        
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Your work has error saved',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        this.toggle();
        this.props.getNotes();
    }
    
    render() {
        const { textNotes } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>Edit Notes</ModalHeader>
                    <ModalBody>
                        <Input nameI={'textNotes'} value={textNotes} change={this.handleChange}
                               name={'text'} type={'textarea'}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.update}>Update</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEditNotes;