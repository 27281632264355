import React from 'react';

import { Table } from 'reactstrap';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';

class TableGateways extends React.Component {
    constructor(props) {
        super(props);
        
        this.destroyGateway = this.destroyGateway.bind(this);
    }
    
    async destroyGateway(id) {
        
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                DataApi.DestroyGateway(id).then(() => {
                    this.props.getGatewaysData();
                });
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success',
                );
            }
        });
    }
    
    render() {
        const { data: { data } } = this.props;
        
        if (!data)
            return null;
        
        return (
            <Table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">Display Name</th>
                    <th scope="col">Actions</th>
                </tr>
                </thead>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="col-principal">{item.display_name}</td>
                        <td>
                            <MDBIcon icon="trash" size="lg" className="trash"
                                     onClick={() => this.destroyGateway(item.id)} />
                        </td>
                    </tr>
                ))}
            </Table>
        );
    }
}

export default TableGateways;

