import React from 'react';

import { FormGroup, Col, Row } from 'reactstrap';

import Swal from 'sweetalert2';

import Input from '../../elements/Utils/Input';
import SelectCmx from '../../elements/Utils/Select';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import DataApi from '../../../megacall/services/SuppliersServices/DataApi';

class FormsSuppliers extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            id: '',
            inbound_landline_price: '',
            inbound_mobile_price: '',
            min_call_cost: '',
            name: '',
            notes: '',
            sippy_name: '',
            vats: '',
            vats_all: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.send = this.send.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }
    
    async onKeyPress(e) {
        if (e.which === 13) {
            await this.send();
        }
    }
    
    componentDidMount() {
        const { item, vat, vatSupplier } = this.props;
        
        let arreglo_vat = ModificationArray.modification(vat);
        
        this.setState({
            vats: vatSupplier,
            vats_all: arreglo_vat,
            id: item.id,
            inbound_landline_price: item.inbound_landline_price,
            inbound_mobile_price: item.inbound_mobile_price,
            min_call_cost: item.min_call_cost,
            name: item.name,
            notes: item.notes,
            sippy_name: item.sippy_name,
        });
    }
    
    handleChange(e, nameI, index) {
        switch (index) {
            case 1:
                this.setState({ [nameI]: e });
                break;            
            default:
                this.setState({ [e.target.name]: e.target.value });
                break;
        }
    }
    
    async send() {
        const { id, inbound_landline_price, inbound_mobile_price,  min_call_cost, name, notes, sippy_name, vats, } = this.state;
        
        const json = {
            name: name,
            sippyName: sippy_name,
            vatId: vats.value,
            minCallCost: min_call_cost,
            notes: notes,
            inboundMobilePrice: inbound_mobile_price,
            inboundLandlinePrice: inbound_landline_price,
        };
        
        const response = await DataApi.UpdateSupplier(id, json);
        
        if (response.data === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'There was a problem, try again or contact support',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
    }
    
    render() {
        const { inbound_landline_price, inbound_mobile_price, min_call_cost, name, notes, sippy_name, vats, vats_all, } = this.state;
        
        if (!vats)
            return null;
        
        return (
            <Row onKeyUp={this.onKeyPress}>
                <Col md={{ size: 12 }}>
                    <FormGroup>
                        <div className="container-input-form">
                            <Input nameI={'name'} value={name} change={this.handleChange} name={'Name'} state={name} />
                            <Input nameI={'sippy_name'} value={sippy_name} change={this.handleChange} name={'Name in Sippy'} state={sippy_name} /> 
                            <SelectCmx index={1} nameI={'vats'} value={vats} change={this.handleChange} name={'Vats'} options={vats_all} />
                        </div>
                        <div className="container-input-form">
                            <Input nameI={'min_call_cost'} type={'number'} value={min_call_cost} change={this.handleChange} name={'Min. call cost'} state={min_call_cost} />
                            <Input nameI={'inbound_mobile_price'} type={'number'} value={inbound_mobile_price ? inbound_mobile_price : ''} change={this.handleChange} name={'Inbound Mobile Price'} state={inbound_mobile_price} />
                            <Input nameI={'inbound_landline_price'} type={'number'} value={inbound_landline_price ? inbound_landline_price : ''} change={this.handleChange} name={'Inbound Landline Price'} state={inbound_landline_price} />
                        </div>
                        <div className="container-text-form">
                            <label>Notes</label>
                            <textarea className="form-control" id="notes" value={notes ? notes : ''} name="notes" onChange={(e) => this.handleChange(e)} />
                        </div>
                    </FormGroup>
                    <div className="d-flex justify-content-end mt-5 button-form">
                        <button onClick={this.send} type="submit" className="btn btn-primary mb-2">Save</button>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default FormsSuppliers;