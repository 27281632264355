/* eslint-disable array-callback-return */
import React from 'react';

import { Card, Col, Row } from 'react-bootstrap';

import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Price/DataApi';
import TablePackages from '../../../elements/PriceElements/TablePackages';
import ModalNewPackages from '../../../elements/PriceElements/Modal/ModalNewPackages';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Swal from 'sweetalert2';

class Packages extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: '',
            select_customer_price: '',
            select_description: '',
        };
        this.getPackages = this.getPackages.bind(this);
        this.destroyPackages = this.destroyPackages.bind(this);
        this.initialListSelect = this.initialListSelect.bind(this);
    }
    
    async componentDidMount() {
        await this.getPackages();
        await this.initialListSelect();
    }
    
    /** inicializa el estado */
    async getPackages(page = 1) {
        const results = await DataApi.ListPackages(page);
        
        this.setState({ data: results.data });
    }
    
    /** inicializa los select */
    async initialListSelect() {
        const results = await DataApi.ListSelect();
        let arrayDescriptions = [];
        
        results.data.prefix_translate.map((data) => {
            arrayDescriptions.push(data.description);
        });
        
        const select_customer_price = await ModificationArray.modification(results.data.customer_prices);
        const select_description = await ModificationArray.modificationTranslate(arrayDescriptions);
        
        this.setState({ select_customer_price: select_customer_price, select_description: select_description,  });
    }
    
    async destroyPackages(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                DataApi.DestroyPackages(id);
                this.getPackages();
                this.initialListSelect();
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success',
                );
            }
        });
    }
    
    render() {
        const { data, select_customer_price, select_description } = this.state;
        const { current_page, total, per_page } = this.state.data;
        
        return (
            <div>
                <Row>
                    <h1 className="title-table">PACKAGES</h1>
                    <Col md={12}>
                        <Card>
                            <div className="container-input-modal">
                                <ModalNewPackages getPackages={this.getPackages} select_description={select_description}
                                                  select_customer_price={select_customer_price} />
                            </div>
                            <br/>
                            <div className="container-table-all">
                                <TablePackages data={data} getPackages={this.getPackages} select_description={select_description}
                                               select_customer_price={select_customer_price} initialListSelect={this.initialListSelect}
                                               destroyPackages={this.destroyPackages}/>
                            </div>
                            <Pagination
                                totalItemsCount={total}
                                onChange={(page) => this.getPackages(page)}
                                activePage={current_page}
                                itemsCountPerPage={per_page}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Packages;