/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Table } from 'reactstrap';
import { MDBIcon } from 'mdbreact';
import DataApi from '../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
// import { Divider } from '@material-ui/core';
// import { mdiDataMatrixEdit } from '@mdi/js';

class EmailsInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: '',
    };
    this.getEmailInfo = this.getEmailInfo.bind(this);
    this.deleteFromArray = this.deleteFromArray.bind(this);
    this.sendInfo = this.sendInfo.bind(this);
    this.dontSendInfo = this.dontSendInfo.bind(this);
  }

  componentDidMount() {
    const queryString = window.location + '';
    // console.log(queryString.substr(47));
    var split = queryString.split('/');
    const customer_id = split[split.length - 1];
    console.log(customer_id);
    this.setState({ customer_id: customer_id });

    setTimeout(() => {
      this.getEmailInfo();
    }, 500);
  }

  async getEmailInfo() {
    const { customer_id } = this.state;
    const object = {
      customer_id: customer_id,
    };
    console.log(object);
    const response = await DataApi.getEmailInfo(object);
    console.log(response);

    if (response.status === 200) {
      this.setState({
        did: response.data[1].did.length > 0 ? response.data[1].did : null,
        cc: response.data[1].cc.length > 0 ? response.data[1].cc : null,
        pbx: response.data[1].pbx.length > 0 ? response.data[1].pbx : null,
        charges: response.data[1].charges.length > 0 ? response.data[1].charges : null,
        userInfo: response.data[0],
      });
    }
    console.log(this.state.did);
    console.log(this.state.cc);
  }

  deleteFromArray(type, index) {
    console.log(index);
    const { pbx, cc, did, charges } = this.state;
    switch (type) {
      case 'did':
        did.splice(index, 1);
        this.setState({ did: did });
        break;
      case 'cc':
        cc.splice(index, 1);
        this.setState({ cc: cc });
        break;
      case 'pbx':
        pbx.splice(index, 1);
        this.setState({ pbx: pbx });
        break;
      case 'charges':
        charges.splice(index, 1);
        this.setState({ charges: charges });
        break;
      default:
        break;
    }
  }

  async sendInfo() {
    const { did, cc, pbx, userInfo, charges } = this.state;

    const object = {
      did: did != null ? did : [],
      cc: cc != null ? cc : [],
      pbx: pbx != null ? pbx : [],
      charges: charges != null ? charges : [],
      userInfo: userInfo,
    };

    const result = await DataApi.sendFinalInfo(object);

    if (result.status === 200) {
      Swal.fire({
        title: 'Your email has been sent',
        text: 'The customer has now all the info.',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      }).then(async result => {
        if (result.isConfirmed) {
          this.props.history.push('/customers');
        }
      });
    }
  }

  async dontSendInfo() {
    const { userInfo } = this.state;
    const object = {
      id: userInfo.id,
    };

    Swal.fire({
      title: 'Are you sure you dont want to send the email info?',
      text: 'The customer wont be notice..',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
      cancelButtonText: 'No!',
    }).then(async result => {
      if (result.isConfirmed) {
        const result = await DataApi.dontSendInfo(object);

        if (result.status === 200) {
          this.props.history.push('/customers');
        }
      }
    });
  }

  async componentWillUnmount() {
    this.setState({ cc: '', did: '', pbx: '', charges: '', userInfo: '' });
  }

  render() {
    const { did, cc, pbx, userInfo, charges } = this.state;

    return (
      <React.Fragment>
        <div className="container-item mb-5">
          <h1>CHANGES</h1>
        </div>
        <div className="container-fluid d-flex">
          {userInfo ? (
            <>
              <h3 className="ml-5 mt-3 mr-5">Customer id: {userInfo.customer_id}</h3>
              <button className="btn btn-warning ml-5 text-white" onClick={() => this.sendInfo()}>
                Send Email
              </button>
              <button className="btn btn-danger ml-5 text-white" onClick={() => this.dontSendInfo()}>
                Dont Send Email
              </button>
            </>
          ) : (
            ''
          )}
        </div>
        <div className="container">
          {did != null ? (
            <>
              <div className="container-item">
                <h3 className="mt-3">DID</h3>
              </div>
              <div className="container-table-all mt-5">
                <Table className="table table-striped">
                  <thead className="container-item">
                    <tr>
                      <th scope="col">Action</th>
                      <th scope="col">Number</th>
                      <th scope="col actions">Options</th>
                    </tr>
                  </thead>
                  <tbody className="container-item">
                    {did.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.action ? item.action : ''}</td>
                          {item.action === 'enabled' || item.action === 'disabled' ? (
                            item.number.map((numDid, index) => <p className="mt-3">{numDid ? numDid : ''}</p>)
                          ) : (
                            <td>{item.number}</td>
                          )}

                          <td>
                            <a>
                              <MDBIcon
                                className="ml-3 mt-2 red-text"
                                icon="trash"
                                size="lg"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.deleteFromArray('did', index)}
                              />
                            </a>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ''
          )}

          {cc != null ? (
            <>
              <div className="container-item">
                <h3 className="mt-3">Calling Card</h3>
              </div>
              <div className="container-table-all mt-5">
                <Table className="table table-striped">
                  <thead className="container-item">
                    <tr>
                      <th scope="col">Action</th>
                      <th scope="col">Number</th>
                      <th scope="col actions">Options</th>
                    </tr>
                  </thead>
                  <tbody className="container-item">
                    {cc.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.action ? item.action : ''}</td>
                          {item.action === 'enabled' || item.action === 'disabled' ? (
                            item.number.map((num, index) => <p className="mt-3">{num ? num : ''}</p>)
                          ) : (
                            <td>{item.number}</td>
                          )}

                          <td>
                            <a>
                              <MDBIcon
                                className="ml-3 mt-2 red-text"
                                icon="trash"
                                size="lg"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.deleteFromArray('cc', index)}
                              />
                            </a>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ''
          )}
          {pbx != null ? (
            <>
              <div className="container-item">
                <h3 className="mt-3">Extensions PBX</h3>
              </div>
              <div className="container-table-all mt-5">
                <Table className="table table-striped">
                  <thead className="container-item">
                    <tr >
                      <th scope="col">Action</th>
                      <th scope="col">Extension</th>
                      <th scope="col actions">Options</th>
                    </tr>
                  </thead>

                  <tbody className="container-item">
                    {pbx.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.action ? item.action : ''}</td>

                          {item.action === 'enabled' || item.action === 'disabled' ? (
                            item.username.map((ext, index) => <p className="mt-3">{ext ? ext : ''}</p>)
                          ) : (
                            <td>{item.username}</td>
                          )}
                          <td>
                            <a>
                              <MDBIcon
                                className="ml-3 mt-2 red-text"
                                icon="trash"
                                size="lg"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.deleteFromArray('pbx', index)}
                              />
                            </a>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ''
          )}
          {charges != null ? (
            <>
              <div className="container-item">
                <h3 className="mt-3">Charges</h3>
              </div>
              <div className="container-table-all mt-5">
                <Table className="table table-striped">
                  <thead className="container-item">
                    <tr >
                      <th scope="col">Action</th>
                      <th scope="col">Product</th>
                      <th scope="col">Total</th>
                      <th scope="col actions">Options</th>
                    </tr>
                  </thead>

                  <tbody className="container-item">
                    {charges.map((item, index) => (
                      <>
                        <tr>
                          <td>{item.action ? item.action : ''}</td>
                          <td>{item.product ? item.product : ''}</td>
                          <td>{(item.units ? item.units : '') * (item.rate ? item.rate : '')}</td>
                          <td>
                            <a>
                              <MDBIcon
                                className="ml-3 mt-2 red-text"
                                icon="trash"
                                size="lg"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.deleteFromArray('charges', index)}
                              />
                            </a>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default EmailsInfo;
