/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React from 'react';
import { Table, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Swal from 'sweetalert2';
import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import ModalNewDid from './Modal/ModalNewDid';
import Input from '../../Utils/Input';
import SubTableDid from './SubTableDid';
import SelectCmx from '../../Utils/Select/index';
import Kanban from './Kanban';

class TableDid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      indexDid: '',
      enabled: 3,
      number: '',
      checkBox: false,
      arrrayId: '',
      idCustomers: '',
      checkClass: '',
      tipo: '',
      info: '',
      order: '',
      sortBy: '',
      click: 1,
      showOrders: false,
      notes: null,
      options: [
        { label: 'enabled', value: 1 },
        { label: 'disabled', value: 0 },
        { label: 'ALL', value: 3 },
      ],
    };
    this.getDid = this.getDid.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.destroyDid = this.destroyDid.bind(this);
    this.saveCheck = this.saveCheck.bind(this);
    this.checkReduce = this.checkReduce.bind(this);
    this.changeHandl = this.changeHandl.bind(this);
    this.getDidOrders = this.getDidOrders.bind(this);
    this.updateState = this.updateState.bind(this);
    this.toggle = this.toggle.bind(this);
    this.sendEmailRejected = this.sendEmailRejected.bind(this);
  }

  /**
   *  Para mostrar Calling Card o Did Number se le pasa un indice al backend y dependiendo de cual se le pase muestra uno u otro
   *
   *  Indice 1: Did Number
   *  Indice 2: Calling Card
   * */
  async componentDidMount() {
    const { index, customersId } = this.props;
    await this.getDid(1);
    await this.getDidOrders();

    this.setState({ indexDid: index, idCustomers: customersId.response });
  }

  async getDid(page) {
    const { customersId, index } = this.props;
    const { enabled, number, sortBy, perPage } = this.state;

    const object = {
      enabled: enabled,
      number: number,
      first: this.state.first === false ? null : true,
      sortBy: sortBy,
      order: !this.state.order,
      perPage,
    };

    this.setState({ page: page });

    if (index === 1) {
      const results = await DataApi.GetDidInternational(page, customersId.response, object);
      this.setState({ data: results.data });
    } else {
      const results = await DataApi.GetDidCallingCard(page, customersId.response, object);
      this.setState({ data: results.data });
    }
  }

  async destroyDid(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyDid(id);
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
      this.getDid();
    });
  }

  async handleChange(e, index) {
    if (index) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });

      setTimeout(() => {
        this.getDid(1);
      }, 500);
    }
  }

  changeHandl(e) {
    // console.log(e.value);
    this.setState({ enabled: e.value });

    setTimeout(() => {
      this.getDid();
    }, 300);
  }

  async toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  /* TODO: checkAll y checkReduce se debe de pasar a una clase estatica se va utiliza en mas de una vista esta funcionalidad hace el checkbox de la tabla  */
  async checkAll() {
    const {
      data: { data },
      checkBox,
    } = this.state;
    const {
      customersId: { response },
      index,
    } = this.props;
    const itemsAll = [];
    const { number } = this.state;

    if (index === 1) {
      const allId = await DataApi.GetIdAllDid(response, 1);
      allId.data.id.map(data => {
        itemsAll.push(data.id);
      });
    } else {
      const allId = await DataApi.GetIdAllDid(response, 2);
      allId.data.id.map(data => {
        itemsAll.push(data.id);
      });
    }

    if (checkBox) {
      this.setState({ arrrayId: '', checkBox: !checkBox, checkClass: '' });
    } else if (number) {
      this.setState({ arrrayId: data, checkBox: !checkBox, checkClass: 'success' });
    } else {
      this.setState({ arrrayId: itemsAll, checkBox: !checkBox, checkClass: 'success' });
    }
  }

  async getDidOrders() {
    const { customersId } = this.props;

    const results = await DataApi.getDidOrders(customersId.response);
    this.setState({ dataOrder: results.data });

    const data = {
      lanes: [
        {
          id: 'pending',
          title: 'Pending',
          label: '',
          cards: [],
        },
        {
          id: 'ordering',
          title: 'Ordering',
          label: '',
          cardStyle: { backgroundColor: '#F4D03F !important' },
          cards: [],
        },

        {
          id: 'rejected',
          title: 'Rejected',
          label: '',
          cardStyle: { backgroundColor: '#FA325A !important' },
          cards: [],
        },
        {
          id: 'succesfull',
          title: 'Succesfull',
          draggable: false,
          label: '',
          cardStyle: { backgroundColor: '#27C11F !important' },
          cards: [],
        },
      ],
    };
    // console.log(data);
    results.data.forEach((element, key) => {
      if (element.notes != null) {
        this.setState({ shownotes: true });
      }

      switch (element.status) {
        case 1:
          data.lanes[0].cards = [
            ...data.lanes[0].cards,
            {
              id: 'card' + key + '_' + element.id,
              title: element.prefix + ' ' + element.country,
              description: element.prefix_type === 4 ? element.city : element.type,
              label: element.required === 0 ? '' : 'Doc. Req.',
              // style: { backgroundColor: element.required === 1 ? '#f27f21' : '' },
            },
            // element.required === 1 ? (data.lanes[0].cardStyle.backgroundColor = '#f27f21') : '',
          ];
          this.setState({ dataKanban: data });

          break;
        case 2:
          data.lanes[1].cards = [
            ...data.lanes[1].cards,
            {
              id: 'card' + key + '_' + element.id,
              title: element.prefix + ' ' + element.country,
              description: element.prefix_type === 4 ? element.city : element.type,
              label: element.required === 0 ? '' : 'Doc. Req.',
              // style: { backgroundColor: '#F4D03F' },
            },
          ];
          this.setState({ dataKanban: data });
          break;

        case 4:
          data.lanes[2].cards = [
            ...data.lanes[2].cards,
            {
              id: 'card' + key + '_' + element.id,
              title: element.prefix + ' ' + element.country,
              description: element.prefix_type === 4 ? element.city : element.type,
              label: element.required === 0 ? '' : 'Doc. Req.',
              // style: { backgroundColor: '#FA325A' },
            },
          ];
          this.setState({ dataKanban: data });
          break;
        case 5:
          data.lanes[3].cards = [
            ...data.lanes[3].cards,
            {
              id: 'card' + key + '_' + element.id,
              title: element.prefix + ' ' + element.country,
              description: element.prefix_type === 4 ? element.city : element.type,
              label: element.required === 0 ? '' : 'Doc. Req.',
              // style: { backgroundColor: '#27C11F ' },
            },
          ];
          this.setState({ dataKanban: data });
          break;
        default:
          break;
      }
    });

    if (data.lanes[0].cards.label === 'Doc. Req.') {
      data.lanes[0].cards.cardStyle = { backgroundColor: '#f27f21' };
    }
  }

  async updateState(fromLaneId, toLaneId, cardId, index) {
    let statusId = '';

    switch (toLaneId) {
      case 'pending':
        statusId = 1;
        break;
      case 'ordering':
        statusId = 2;
        break;

      case 'rejected':
        statusId = 3;
        break;
      case 'succesfull':
        statusId = 4;
        break;
      default:
        break;
    }

    const id = cardId.split('_');
    this.setState({ orderTochange: id[1] });
    const object = {
      orderId: id[1],
      newStatus: statusId,
    };

    const result = await DataApi.updateOrderStatus(object);
    // console.log(result);
    if (result.data === 'updated') {
      Swal.fire({
        title: 'Would you like',
        text: 'To notice this change to the customer?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.toggle();
        }
      });
    }
  }

  async sendEmailRejected() {
    const { notes, orderTochange } = this.state;
    const { customersId } = this.props;

    const data = {
      customer_id: customersId.response,
      notes: notes,
      prefix_id: orderTochange,
    };
    // console.log(data);
    const result = await DataApi.sendEmail(data);

    if (result.data === 'mail sent') {
      if (notes != null) {
        this.toggle();
        this.setState({ notes: null });
      }
      Swal.fire('Sent!', 'The email has been sent.', 'success');
    }
  }

  checkReduce(id, number) {
    const { arrrayId, info } = this.state;
    if (info.includes(number)) {
      // const a = info.filter(username);
      // console.log(a);
    } else {
      this.setState({ info: [...info, number] });
    }
    // this.setState({ info: { number: number } });
    // this.setState({ info: [...info,  number]});
    const index = arrrayId.indexOf(id);

    if (index === -1) {
      this.setState({ arrrayId: [...arrrayId, id] });
    } else {
      arrrayId.splice(index, 1);
      info.splice(index, 1);
      this.setState({ arrrayId: arrrayId, info: info });
      // this.setState({ arrrayId: arrrayId });
    }
  }

  /* TODO: se debe controlar que los botones no esten activos  */
  async saveCheck(type) {
    const { arrrayId, info } = this.state;
    const { customersId, index } = this.props;

    if (type === 1) {
      await DataApi.EnabledChange(customersId.response, 1, arrrayId);
      const object = {
        action: 'enabled',
        number: info,
      };
      this.setState({ info: '' });
      this.props.getAllInfo(index, object);
      this.setState({ arrrayId: '', checkBox: false, checkClass: 'false' });
      setTimeout(() => {
        this.getDid();
        this.saveCheck();
      }, 300);
    } else if (type === 2) {
      await DataApi.EnabledChange(customersId.response, 0, arrrayId);
      const object = {
        action: 'disabled',
        number: info,
      };
      this.setState({ info: '' });
      this.props.getAllInfo(index, object);
      this.setState({ arrrayId: '', checkBox: false, checkClass: 'false' });
      setTimeout(() => {
        this.getDid();
        this.saveCheck();
      }, 300);
    }

    let success = document.getElementsByClassName('delete');

    for (let i = 0; i < success.length; i++) {
      success[i].classList.remove('chekc-success');
    }

    this.setState({ arrrayId: '', checkBox: false, checkClass: '' });
    setTimeout(() => {
      this.getDid();
    }, 300);
  }

  async sort(sortBy) {
    const { customersId, index } = this.props;
    const { enabled, number, order } = this.state;
    let { click } = this.state;

    this.setState({ sortBy: sortBy, first: false });

    if (click >= 1) {
      if (this.state.order === true) {
        this.setState({ order: false });
      } else {
        this.setState({ order: true });
      }
    }

    if (index === 1) {
      const object = {
        sortBy: sortBy,
        order: order,
        enabled: enabled,
        number: number,
      };
      const results = await DataApi.GetDidInternational(this.state.page, customersId.response, object);

      this.setState({ data: results.data });
    } else {
      const object = {
        sortBy: sortBy,
        order: order,
        enabled: enabled,
        number: number,
      };
      const results = await DataApi.GetDidCallingCard(this.state.page, customersId.response, object);
      click++;

      this.setState({ data: results.data, click: click });
    }
  }

  render() {
    // let eventBus = undefined;

    // const setEventBus = handle => {
    //   eventBus = handle;
    // };
    const { customersId, index, lock } = this.props;
    const {
      data: { data },
      indexDid,
      number,
      checkClass,
      arrrayId,
      options,
      enabled,
      perPage,
      showOrders,
      countries,
      country,
      prefix,
      dataKanban,
      dataOrder,
      shownotes,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;
    let { fecha, fechafinal, horas } = this.state;
    // eventBus.publish({
    //   type: 'UPDATE_CARD',
    //   laneId: 'COMPLETED',
    //   card: { id: 'M1', title: 'Buy Milk (Updated)', label: '20 mins', description: 'Also set reminder (Updated)' },
    // });

    if (!data) {
      return null;
    }

    if (lock) {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-6">
              <Input nameI={'number'} value={number} change={this.handleChange} placeholder={'Number'} />
            </div>
            <div className="col-6 mt-3">
              <SelectCmx options={options} value={enabled} change={e => this.changeHandl(e)} className="mt-3" />
            </div>
          </div>

          <div className="container-table-all">
            <Table className="table table-striped">
              <thead className="container-item">
                <tr>
                  <th scope="col">Enabled</th>
                  <th scope="col" onClick={() => this.sort('number')}>
                    Number
                  </th>
                  <th scope="col" onClick={() => this.sort('added')}>
                    Added
                  </th>
                  <th scope="col" onClick={() => this.sort('disabled')}>
                    Disabled
                  </th>
                  <th scope="col" onClick={() => this.sort('supplier')}>
                    Supplier
                  </th>
                  <th scope="col">Notes</th>

                  <th scope="col" onClick={() => this.sort('InternationalNumber')}>
                    International Number Type
                  </th>
                  <th scope="col" onClick={() => this.sort('pointing')}>
                    Pointing To
                  </th>
                  <th scope="col" onClick={() => this.sort('chargeInbound')}>
                    Charge Inbound
                  </th>
                </tr>
              </thead>
              <tbody className="container-item">
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    <SubTableDid
                      item={item}
                      index={index}
                      indexDid={indexDid}
                      customersId={customersId}
                      checkBox={checkClass}
                      saveCheck={this.saveCheck}
                      destroyDid={this.destroyDid}
                      getDid={this.getDid}
                      checkReduce={this.checkReduce}
                      lock={this.props.lock}
                    />
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>

          <Pagination
            totalItemsCount={total}
            onChange={page => this.getDid(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="container-input-modal">
          {/* {dataKanban != null && index === 1 ? (
            <button
              className={`btn ${showOrders === true ? 'btn-success' : 'btn-primary'}`}
              onClick={() => this.setState({ showOrders: !showOrders })}
            >
              {showOrders === false ? 'Show Numbers Orders' : 'Show Numbers List'}
            </button>
          ) : (
            ''
          )} */}
        </div>
        {showOrders === false ? (
          <>
            <div className="container-input-modal">
              <ModalNewDid
                index={index}
                customersId={customersId}
                getDid={this.getDid}
                getAllInfo={this.props.getAllInfo}
              />
            </div>
            <div className="row">
              <div className="col-6">
                <Input nameI={'number'} value={number} change={this.handleChange} placeholder={'Number'} />
              </div>
              <div className="col-6 mt-3">
                <SelectCmx options={options} value={enabled} change={e => this.changeHandl(e)} className="" />
                <Input nameI={'perPage'} value={perPage} change={e => this.handleChange(e)} placeholder={'per page'} />
              </div>
            </div>

            {arrrayId.length > 0 ? (
              <div className="check-all-enabled ml-2 mb-1">
                <button className="btn btn-primary" value="Enables" onClick={() => this.saveCheck(1)}>
                  Enables
                </button>
                <button className="btn btn-primary" value="Disables" onClick={() => this.saveCheck(2)}>
                  Disables
                </button>
              </div>
            ) : (
              ''
            )}

            <div className="container-table-all">
              <Table className="table table-striped">
                <thead className="container-item">
                  <tr>
                    <th scope="col">Enabled</th>
                    <th scope="col" onClick={() => this.sort('number')}>
                      Number
                    </th>
                    <th scope="col" onClick={() => this.sort('added')}>
                      Added
                    </th>
                    <th scope="col" onClick={() => this.sort('disabled')}>
                      Disabled
                    </th>
                    <th scope="col" onClick={() => this.sort('supplier')}>
                      Supplier
                    </th>
                    <th scope="col">Notes</th>
                    <th scope="col" onClick={() => this.sort('InternationalNumber')}>
                      International Number Type
                    </th>
                    <th scope="col" onClick={() => this.sort('pointing')}>
                      Pointing To
                    </th>
                    <th scope="col" onClick={() => this.sort('chargeInbound')}>
                      Charge Inbound
                    </th>
                    <th scope="col actions">Actions</th>
                  </tr>
                </thead>
                <tbody className="container-item">
                  {data.map((item, index) => (
                    <React.Fragment key={index}>
                      <SubTableDid
                        item={item}
                        index={index}
                        indexDid={indexDid}
                        customersId={customersId}
                        checkBox={checkClass}
                        saveCheck={this.saveCheck}
                        destroyDid={this.destroyDid}
                        getDid={this.getDid}
                        checkReduce={this.checkReduce}
                      />
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            </div>

            <Pagination
              totalItemsCount={total}
              onChange={page => this.getDid(page)}
              activePage={current_page}
              itemsCountPerPage={per_page}
              itemClass="page-item"
              linkClass="page-link"
            />
          </>
        ) : (
          <>
            <div className="container-input-modal">
              <h3 className="mt-2">Orders Numbers List</h3>
            </div>
            <div className="row">
              <div className="col-6">
                <Input nameI={'prefix'} value={prefix} change={this.handleChange} placeholder={'Prefix'} />
              </div>
              <div className="col-6 mt-3">
                <SelectCmx options={countries} value={country} change={e => this.changeHandl(e)} className="" />
                {/* <Input nameI={'perPage'} value={perPage} change={e => this.handleChange(e)} placeholder={'per page'} /> */}
              </div>
            </div>
            {shownotes === true ? (
              <>
                <div className="container-fluid">
                  <h3 className="mb-5 mt-5">Order Notes</h3>
                  <Table className="table table-striped">
                    <thead className="container-item">
                      <tr>
                        <th scope="col">Prefix</th>
                        <th scope="col">Notes</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody className="container-item">
                      {dataOrder.map(
                        (item, index) => (
                          (fecha = item.created_at.split('T', '1')),
                          (horas = new Date(item.created_at)),
                          horas.toUTCString().split('2022'),
                          (fechafinal = fecha.toString().split('-')),
                          item.notes != null ? (
                            <tr>
                              <td>{item.prefix}</td>
                              <td>{item.notes}</td>
                              <td>
                                {' '}
                                {fechafinal[2] +
                                  '-' +
                                  fechafinal[1] +
                                  '-' +
                                  fechafinal[0] +
                                  ' ' +
                                  horas.toUTCString().split(fechafinal[0])[1]}
                              </td>
                            </tr>
                          ) : (
                            ''
                          )
                        )
                      )}
                    </tbody>
                  </Table>
                </div>
              </>
            ) : (
              ''
            )}
            <h3 className="mt-2">Orders List</h3>
            <Kanban data={dataKanban} onCardMoveAcrossLanes={this.updateState} />

            {data.map((item, index) => (
              <React.Fragment key={index}></React.Fragment>
            ))}

            <Modal isOpen={this.state.modal}>
              <ModalHeader
                close={
                  <button className="close" onClick={() => this.toggle()}>
                    ×
                  </button>
                }
                toggle={() => this.toggle()}
              >
                Text to send to the customer
              </ModalHeader>
              <ModalBody>
                <textarea
                  className="form-control"
                  placeholder="Write the text here"
                  id="floatingTextarea"
                  onChange={e => this.handleChange(e, 1)}
                  name="notes"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={e => this.sendEmailRejected()}>
                  Send
                </Button>{' '}
                <Button onClick={e => this.toggle()}>Cancel</Button>
              </ModalFooter>
            </Modal>
            {/* <Pagination
              totalItemsCount={total}
              onChange={page => this.getDid(page)}
              activePage={current_page}
              itemsCountPerPage={per_page}
              itemClass="page-item"
              linkClass="page-link"
            /> */}
          </>
        )}
      </React.Fragment>
    );
  }
}

export default TableDid;
