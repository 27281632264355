import React from 'react';

import { Col, Row } from 'react-bootstrap';
import Card from '@material-ui/core/Card';
import Pagination from 'react-js-pagination';

import SelectCmx from '../../../elements/Utils/Select';
import DataApi from '../../../../megacall/services/Price/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import TablePriceSuppliers from '../../../elements/PriceElements/TablePriceSuppliers';

class SuppliersPrices extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            priceList: '',
            priceListAll: '',
            data: '',
            id: '',
        };
        this.getSelect = this.getSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getListPrice = this.getListPrice.bind(this);
    }
    
    async componentDidMount() {
        await this.getSelect();
    }
    
    /** Inicializa select */
    async getSelect() {
        const results = await DataApi.ListSuppliersPrices();
        
        const items = ModificationArray.modification(results.data);
        
        this.setState({ priceListAll: items });
    }
    
    /** Cambia el estado y a la vez funciona como buscador */
    async handleChange(e, nameI, index) {
        this.setState({ [nameI]: e, id: e.value });
        
        setTimeout(() => {
            this.getListPrice(1);
        }, 50);
    }
    
    /** Inicializa el estado */
    async getListPrice(page = 1) {
        const { id } = this.state;
        
        const results = await DataApi.GetPrice(id, page);
        
        this.setState({ data: results.data });
    }
    
    render() {
        const { priceList, priceListAll, data } = this.state;
        const { current_page, total, per_page } = this.state.data;
        
        return (
            <React.Fragment>
                <div>
                    <Row>
                        <h1 className="title-table">SIPPLIERS PRICES</h1>
                        <Col md={12}>
                            <Card>
                                <div className="container-input-modal">
                                    <div className="container-button">
                                        <h3>Suppliers Prices List</h3>
                                    </div>
                                </div>
                                <div className="container-search-customer-price">
                                    <SelectCmx nameI={'priceList'} value={priceList} change={this.handleChange}
                                               name={'Suppliers'}
                                               options={priceListAll} />
                                </div>
                                <div className="container-search"></div>
                               
                                <div className="container-table-all">
                                    <TablePriceSuppliers data={data} />
                                </div>
                                <Pagination
                                    totalItemsCount={total}
                                    onChange={(page) => this.getListPrice(page)}
                                    activePage={current_page}
                                    itemsCountPerPage={per_page}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default SuppliersPrices;