import React from 'react';
import { CSVLink } from 'react-csv';

import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';

import DataApi from '../../../megacall/services/SuppliersServices/DataApi';
import TablePriceList from '../../elements/SuppliersElements/TablePriceList';

import { Col, Row } from 'reactstrap';
import SearchPriceList from '../../../megacall/components/Search/SearchPriceList';

import ModalNewPriceList from '../../elements/SuppliersElements/Modal/ModalNewPriceList';

import Loader from 'react-loader-spinner';

class PriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      csvLoading: false,
      file: null,
      data: '',
      isCheck: true,
      id: 0,
      percentage: '',
      until: '',
      description: '',
      product_id: '',
      start_date: '',
      untilApi: '',
      index: false,
      prefix: '',
      csv: '',
    };
    this.getSuppliersData = this.getSuppliersData.bind(this);
    this.destroy = this.destroy.bind(this);
    this.hanleChangeFilterCommission = this.hanleChangeFilterCommission.bind(this);
    this.changeState = this.changeState.bind(this);
    this.importExport = this.importExport.bind(this);
    this.onChange = this.onChange.bind(this);
    this.selectorRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ isCheck: false });
  }

  async getSuppliersData(page = 1) {
    const { id } = this.props;
    const { prefix, description } = this.state;

    const object = {
      prefix: prefix,
      description: description,
    };

    const response = await DataApi.GetSupplierPrefix(id, page, object);
    this.setState({ data: response.data });
  }

  async importCsv() {
    const { id } = this.props;
    const response = await DataApi.GetAllSuppliers(id);

    this.setState({ csv: response.data });
  }

  async handleButtonDownloadCsv() {
    this.setState({ csvLoading: true });
    await this.importCsv();
    this.selectorRef.current.link.click();
    this.setState({ csvLoading: false });
  }

  async destroy(id, prefix_translates_id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        DataApi.DestroySupplerPrefix(id, prefix_translates_id)
        .then((result => {
          this.getSuppliersData();
        }));

        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  async hanleChangeFilterCommission(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async changeState() {
    const { index } = this.state;

    this.setState({ index: !index });
  }

  onChange(e) {
    const file = e.target.files;

    this.setState({ file: file[0] });
  }

  async importExport() {
    const { id } = this.props;
    const { file } = this.state;

    this.setState({ loading: true });

    const formData = new FormData();

    formData.append('sippy_file', file);

    const response = await DataApi.ImportExport(id, formData);

    if (response.data.status !== 'error') {
      let element = document.createElement('a');

      element.setAttribute('href', response.data.page);
      element.setAttribute('download', 'download');
      element.style.display = 'none';

      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.setState({ loading: false });
    } else {
      this.setState({ loading: false });

      setTimeout(() => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'There has been a problem with the file, check for blanks.',
          showConfirmButton: true,
        });
      });
    }

    return response;
  }

  render() {
    const { data, loading } = this.state;
    const { current_page, total, per_page } = this.state.data;
    const { id, fecha_update } = this.props;

    if (loading) {
      return (
        <div
          className="container-item mt-5 position-absolute offset-3"
          style={{ zIndex: '1000', backgroundColor: 'white', width: '600px' }}
        >
          <Loader type="ThreeDots" color="#4c84ff" width={300} className="top-100 start-100 mt-3" />
          <div className="d-flex justify-content-center ">
            <h2 className="ml-3" style={{ color: '#4c84ff' }}>
              LOADING...
            </h2>
          </div>
        </div>
      );
    }

    return (
      <div>
        <Row>
          <Col md={12}>
            <div className="container-search-price">
              <SearchPriceList
                hanleChangeFilter={this.hanleChangeFilterCommission}
                importExport={this.importExport}
                onChange={this.onChange}
                getSuppliersData={this.getSuppliersData}
                description={this.state.description}
                prefix={this.state.prefix}
                importCsv={this.importCsv}
              />
              <div className="d-flex justify-content-end mt-4 mr-3">
                {this.state.csvLoading ? (
                  <>
                    <button
                      className={`btn btn-success btn-md `}
                      onClick={() => this.handleButtonDownloadCsv()}
                      style={{
                        width: '220px',
                        height: '39px',
                        marginTop: '4px',
                        marginLeft: '0px',
                        paddingTop: '11px',
                      }}
                    >
                      <i
                        className=""
                        style={{
                          width: '225px',
                          height: '39px',
                          marginTop: '4px',
                          marginLeft: '20px',
                          paddingTop: '11px',
                        }}
                      ></i>
                      DOWNLOADING...
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className={`btn btn-success btn-md`}
                      onClick={() => this.handleButtonDownloadCsv()}
                      style={{
                        width: '220px',
                        height: '39px',
                        marginTop: '4px',
                        marginLeft: '20px',
                        paddingTop: '11px',
                      }}
                    >
                      DOWNLOAD COMPLETE LIST
                    </button>
                  </>
                )}

                <ModalNewPriceList id={id} getSuppliersData={this.getSuppliersData} />
              </div>
            </div>

            <CSVLink
              data={this.state.csv}
              filename={'suppliers-price-list.csv'}
              className="btn btn-success btn-sm text-uppercase mt-6 invisible"
              target="_blank"
              style={{ height: '38px', marginTop: '0px', marginLeft: '0px', paddingTop: '0px' }}
              ref={this.selectorRef}
            >
              <i className=" mdi mdi-progress-download"></i> Download CSV
            </CSVLink>

            <TablePriceList
              destroy={this.destroy}
              data={data}
              getSuppliersData={this.getSuppliersData}
              fecha_update={fecha_update}
              description={this.state.description}
            />
            <Pagination
              totalItemsCount={total ? total : 0}
              onChange={(page) => this.getSuppliersData(page)}
              activePage={current_page}
              itemsCountPerPage={per_page}
              itemClass="page-item"
              linkClass="page-link"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default PriceList;
