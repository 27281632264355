/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import SelectCmx from '../../../Utils/Select';

class EditCharge extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rate: '',
      units: '',
      to_date: '',
      text: '',
      text_details: '',
      disabled: '',
      listed: '',
      options: [{ label: 'enabled', value: 1 }, { label: 'disabled', value: 0 }],
      enabled: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateCharge = this.updateCharge.bind(this);
    this.getChargeInfo = this.getChargeInfo.bind(this);
  }

  async toggle() {
    if (this.props.type === '1') {
      // await this.props.getCharges();
    }
    this.getChargeInfo();

    this.setState(prevState => ({
      modal: !prevState.modal,
      
    }));
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        // console.log(e.target.checked);
        const recuCheck = document.getElementById('checkie');
        if (e.target.checked === true) {
          recuCheck.value = true;
          this.setState({ recurrent: e.target.checked, disabled: true, to_date: '' });
        } else if (e.target.checked === false) {
          recuCheck.value = false;
          var date = new Date();
          var currentDate = date.toISOString().substring(0, 10);
          // console.log(date.getMonth());

          //HE PUESTO +1 en el principio de "esteMes" para los meses de 2 cifras comprobar en ENERO...
          var esteMes = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth();
          var mesPasado = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
          // console.log(mesPasado);
          // console.log(esteMes);

          var unMes = currentDate.replace(esteMes, mesPasado);

          this.setState({ recurrent: e.target.checked, disabled: false, to_date: unMes });
        }
        break;
      case 2:
        this.setState({ [nameI]: e.target.value });
        break;
      case 3:
        this.setState({ [nameI]: e });
        break;
      case 4:
          this.setState({ [nameI]: e });
          if(e.sale > 0) {
            this.setState({ units: 1, rate: e.sale });
          } else {
            this.setState({ units: '', rate: '' });
          }
          break;  
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async updateCharge() {
    const { units, rate, to_date, text, text_details, product, enabled } = this.state;
    const { customersId } = this.props;
    const json = {
      units: units,
      rate: rate,
      to_date: to_date,
      text: text,
      text_details: text_details,
      chargeId: this.props.chargeId,
      recurrent: this.state.recurrent === true || this.state.recurrent === 1 ? 1 : 0,
      product_id: product.value,
      enabled: enabled.value,
    };
    // console.log(json);
    const results = await DataApi.UpdateCharge(customersId.response, json);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The charge has been updated ',
        showConfirmButton: false,
        timer: 1500,
      });
      this.toggle();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'error updating the charge',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.props.getCharges();
    this.getChargeInfo();
  }

  async getChargeInfo() {
    const { customersId } = this.props;

    const object = {
      chargeId: this.props.chargeId,
    };
    const results = await DataApi.getChargeInfo(customersId.response, object);
    // console.log(results);
    if (results.status === 200) {
      this.setState({
        text: results.data.text,
        text_details: results.data.text_details,
        rate: results.data.rate,
        units: results.data.units,
        chargeId: this.props.chargeId,
        to_date: results.data.to_date,
        recurrent: results.data.recurrent === 1 ? true : false,
        product: { label: results.data.description_en, value: results.data.product_id },
        enabled:
          results.data.enabled === 1
            ? { label: 'enabled', value: results.data.enabled }
            : { label: 'disabled', value: results.data.enabled },
      });
    }
  }

  render() {
    const { to_date, units, rate, text, text_details, recurrent, product, options, enabled } = this.state;
    const { productTypes } = this.props;

    return (
      <>
        <tr>
          <a onClick={() => this.toggle()} className="">
            <i className="mdi mdi-square-edit-outline mdi-24px"></i>
          </a>
        </tr>

        <Modal isOpen={this.state.modal}>
          <ModalHeader className="" toggle={this.toggle}>
            Edit Charge
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Product</label>
                  <SelectCmx
                    index={1}
                    nameI={'product'}
                    options={productTypes}
                    value={product}
                    change={e => this.handleChange(e, 'product', 4)}
                    id="sel"
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>To Date</label>
                  {recurrent === true ? (
                    <input
                      type="date"
                      className="form-control"
                      value={to_date}
                      onChange={this.handleChange}
                      name="to_date"
                      id="dateTo"
                      disabled={true}
                    />
                  ) : (
                    <input
                      type="date"
                      className="form-control"
                      value={to_date}
                      onChange={this.handleChange}
                      name="to_date"
                      id="dateTo"
                      disabled={false}
                    />
                  )}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Units</label>
                  <input type="text" className="form-control" onChange={this.handleChange} name="units" value={units} />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Rate</label>
                  <input type="text" className="form-control" onChange={this.handleChange} name="rate" value={rate} />
                </div>
              </div>
              <div className="col-sm-12 mb-3">
                <input
                  type={'checkbox'}
                  className="mr-2"
                  onClick={e => this.handleChange(e, 'recurrent', 1)}
                  name="recurrent"
                  defaultChecked={recurrent}
                  id="checkie"
                />
                <label>Recurrent</label>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Text</label>
                  <input className="form-control" onChange={this.handleChange} name="text" value={text} />
                </div>
              </div>

              <label>Text Details</label>
              <textarea
                rows="3"
                className="form-control"
                onChange={this.handleChange}
                name="text_details"
                value={text_details}
              />
            </div>
            <SelectCmx
              index={1}
              nameI={'enabled'}
              options={options}
              value={enabled}
              change={e => this.handleChange(e, 'enabled', 3)}
              className="mt-3"
            />
          </ModalBody>
          <ModalFooter>
            <button className="btn btn-primary" onClick={this.updateCharge}>
              Update
            </button>{' '}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default EditCharge;
