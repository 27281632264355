/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Swal from 'sweetalert2';
import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';

class ModalEditCustomerPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      description: '',
      price: '',
      charge: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
  }

  initialState() {
    const { data } = this.props;

    this.setState({
      description: data.description,
      price: data.price.toFixed(4),
      charge: data.connection_charge,
      id: data.id,
    });
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async save() {
    const { price, charge, id } = this.state;

    const object = {
      price: price,
      charge: charge,
    };

    const response = await DataApi.UpdateCustomerPrice(id, object);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.props.getListCustomerPrice();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { description, price, charge } = this.state;

    return (
      <>
   
          <a onClick={this.toggle}>
            <i className="mdi mdi-square-edit-outline mdi-24px"></i>
          </a>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>New Package</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <Input value={description} name={'Description'} disabled />
              <Input nameI={'price'} value={price} change={this.handleChange} name={'Price'} type={'number'} />
              <Input
                nameI={'charge'}
                value={charge}
                change={this.handleChange}
                name={'Connection Charge'}
                type={'number'}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                Send
              </Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalEditCustomerPrice;
