/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalNewDialler extends React.Component {
    constructor(props) {
        super(props);
        
        
        this.state = {
            modal: false,
            url: '',
            centerName: '',
            login: '',
            password: '',
            notes: '',
        };
        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.generatePassword = this.generatePassword.bind(this);
    }
    
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    generatePassword() {
        let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let string_length = 12;
        let randomstring = '';
        for (let i=0; i<string_length; i++) {
        let rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum,rnum+1);
        }
        
        this.setState({ password: randomstring });
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async save() {
        const { url, centerName, login, password, notes } = this.state;
        const { customersId: { response } } = this.props;
        
        const object = {
            url: url,
            contact_centre_name: centerName,
            login: login,
            password: password,
            notes: notes,
        };
        
        const results = await DataApi.NewDialler(response, object);
    
         if (results.status === 200) {
             Swal.fire({
                 position: 'center',
                 icon: 'success',
                 title: 'Your work has been saved',
                 showConfirmButton: false,
                 timer: 1500,
             });
         } else {
             Swal.fire({
                 position: 'center',
                 icon: 'error',
                 title: 'Your work has error saved',
                 showConfirmButton: false,
                 timer: 1500,
             });
         }
         await this.props.getDiallers();
         await this.toggle();
         this.setState({ url: '', centerName: '', login: '', password: '', notes: '' });
    }
    
    render() {
        const { url, centerName, login, password, notes } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <h3> Diallers List</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add Diallers
                    </a>
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>New Diallers</ModalHeader>
                    <ModalBody>
                        <Input nameI={'url'} value={url} change={this.handleChange}
                               name={'Url'} />
                        <Input nameI={'centerName'} value={centerName} change={this.handleChange}
                               name={'Contact Center Name'} />
                        <Input nameI={'login'} value={login} change={this.handleChange}
                               name={'Login'} />
                        <Input nameI={'password'} value={password} change={this.handleChange}
                               name={'Password'} />
                        <input value="Generate Password" type="submit" onClick={this.generatePassword} />
                        <FormGroup>
                            <label>Notes</label>
                            <textarea className="form-control" id="notes" value={notes} name="notes"
                                      onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewDialler;