import React from 'react';
import ModalEditSipTrunk from './ModalEditSipTrunk';
import { MDBIcon } from 'mdbreact';

class SubTableSipTrunk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: '',
      arrrayId: '',
    };
  }

  componentDidMount() {
    const { checkBox } = this.props;

    this.setState({ checkBox: checkBox });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.checkBox !== this.props.checkBox) {
      const { checkBox } = this.props;

      this.setState({ checkBox: checkBox });
    }
  }

  changeCheck(id) {
    const { checkBox } = this.state;
    // console.log(id);
    if (checkBox) {
      this.setState({ checkBox: !checkBox });
    } else {
      this.setState({ checkBox: 'success' });
    }

    this.props.checkReduce(id);
  }

  render() {
    const { checkBox } = this.state;
    const { index, item, lock } = this.props;

    if (lock) {
      return (
        <React.Fragment>
          <tr
            key={index}
            onClick={() => this.changeCheck(item.id)}
            id={`color-${item.id}`}
            className={`delete chekc-${checkBox}`}
          >
            <td className="container-item-0">{item.enabled === 1 ? 'Yes' : 'No'}</td>
            <td className="container-item-1">{item.id_sippy}</td>
            <td className="container-item-2">{item.name_sippy}</td>
            <td className="container-item-3">{item.channels}</td>
            <td className="container-item-4">{item.sip_trunk_ip}</td>
            <td className="container-item-5">{item.username}</td>
            <td className="container-item-6">{item.password}</td>
            <td className="container-item-7">{item.gateways_display_name}</td>
            <td className="container-item-8">{item.limit_balance}</td>
            <td className="container-item-9">{item.auto_recharge}</td>
            <td className="container-item-10">{item.limit_daily}</td>
            <td className="container-item-11">
              <input
                type="checkbox"
                Value={item.send_copy === 1 ? true : false}
                checked={item.send_copy}
                name="support"
                color="default"
                size="small"
              />
            </td>
          </tr>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <tr
          key={index}
          onClick={() => this.changeCheck(item.id)}
          id={`color-${item.id}`}
          className={`delete chekc-${checkBox}`}
        >
          <td className="container-item-0">{item.enabled === 1 ? 'Yes' : 'No'}</td>
          <td className="container-item-1">{item.id_sippy}</td>
          <td className="container-item-2">{item.name_sippy}</td>
          <td className="container-item-3">{item.channels}</td>
          <td className="container-item-4">{item.sip_trunk_ip}</td>
          <td className="container-item-5">{item.username}</td>
          <td className="container-item-6">{item.password}</td>
          <td className="container-item-7">{item.gateways_display_name}</td>
          <td className="container-item-8">{item.limit_balance}</td>
          <td className="container-item-9">{item.auto_recharge}</td>
          <td className="container-item-10">{item.limit_daily}</td>
          <td className="container-item-11">
            <input
              type="checkbox"
              Value={item.send_copy === 1 ? true : false}
              checked={item.send_copy}
              name="support"
              color="default"
              size="small"
            />
          </td>
          <td className="container-item">
            <ModalEditSipTrunk
              data={item}
              customersId={this.props.customersId}
              getSipTrunk={this.props.getSipTrunk}
              style={{ cursor: 'pointer' }}
              gateways_all={this.props.gateways_all}
            />
            <MDBIcon
              icon="trash"
              size="lg"
              className="red-text ml-4"
              onClick={() => this.props.destroySipTrunk(item.id)}
              style={{ cursor: 'pointer' }}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default SubTableSipTrunk;
