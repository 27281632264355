/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Table } from 'reactstrap';

import Swal from 'sweetalert2';
import DataApi from '../../../megacall/services/Price/DataApi';
import SubTableCustomerPrice from '../SuppliersElements/SubTableCustomerPrice';
import ModalMultiEditCustomerPrice from './Modal/ModalMultiEditCustomerPrice';

class TableCustomerPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      arrayMultiPrice: [],
      arrayMultiCharge: [],
    };
    this.multiEdit = this.multiEdit.bind(this);
    this.destroyCustomePrice = this.destroyCustomePrice.bind(this);
  }

  async destroyCustomePrice(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyCustomerPriec(id).then(response => {
          if (response.status === 200) {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          }
        });
        this.props.getListCustomerPrice();
      }
    });
  }

  multiEdit(id, index) {
    const { arrayMultiCharge, arrayMultiPrice } = this.state;

    switch (index) {
      case 1:
        const indexArray = arrayMultiPrice.findIndex(data => data === id);

        if (indexArray < 0) {
          arrayMultiPrice.length === 0
            ? this.setState({ arrayMultiPrice: [id] })
            : this.setState({ arrayMultiPrice: [...arrayMultiPrice, id] });
        } else {
          arrayMultiPrice.splice(indexArray, 1);

          this.setState({ arrayMultiPrice: arrayMultiPrice });
        }
        break;
      default:
        const indexCharge = arrayMultiCharge.findIndex(data => data === id);

        if (indexCharge < 0) {
          arrayMultiCharge.length === 0
            ? this.setState({ arrayMultiCharge: [id] })
            : this.setState({ arrayMultiCharge: [...arrayMultiCharge, id] });
        } else {
          arrayMultiCharge.splice(indexCharge, 1);

          this.setState({ arrayMultiCharge: arrayMultiCharge });
        }
        break;
    }
  }

  render() {
    const {
      data: { data },
    } = this.props;
    const { arrayMultiPrice, arrayMultiCharge } = this.state;

    if (!data) return null;

    return (
      <React.Fragment>
        {arrayMultiPrice.length || arrayMultiCharge.length ? (
          <ModalMultiEditCustomerPrice
            getListCustomerPrice={this.props.getListCustomerPrice}
            arrayMultiPrice={arrayMultiPrice}
            arrayMultiCharge={arrayMultiCharge}
          />
        ) : (
          <React.Fragment>
            <div className=" ml-5">
              <button disabled className="btn btn-primary">
                Multiedit
              </button>
            </div>
          </React.Fragment>
        )}
        <div className="container-table-all">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">Price</th>
                <th scope="col">Connection Charge</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <SubTableCustomerPrice
                  destroyCustomePrice={this.destroyCustomePrice}
                  getListCustomerPrice={this.props.getListCustomerPrice}
                  index={index}
                  data={item}
                  multiEdit={this.multiEdit}
                />
              </React.Fragment>
            ))}
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default TableCustomerPrice;
