import React, { Component } from 'react';
import SelectCmx from '../../elements/Utils/Select/index';
// import Input from '../../elements/Utils/Input/index';
import DataApi from '../../../megacall/services/Calls/DataApi';
import Pagination from 'react-js-pagination';
// import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { CSVLink } from 'react-csv';

class CdrList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromDate: '',
      toDate: '',
      cdrType: '',
      types: [
        { label: 'PBX', value: 1 },
        { label: 'Calling Card', value: 3 },
        { label: 'Magrathea', value: 4 },
        { label: 'Voiped', value: 5 },
        { label: 'Citrus', value: 6 },
        { label: 'Swiftnet', value: 7 },
      ],
      supplier: '',
      suppliers: '',
      customerId: '',
      agentId: '',
      originNumber: '',
      destinyNumber: '',
      data: '',
      dayToday: '',
      minDayBefore: '',
      totalCalls: 0,
      totalDuration: 0,
      totalDurationSell: 0,
      totalCallCost: 0,
      outOfTax: 0,
      totalCallSale: 0,
      outOfTaxSale: 0,
      totalCallAgent: 0,
      outOfTaxAgent: 0,
      loading: false,
      toHide: false,
      dataMostrar: '',
      offset: 0,
      perPage: 1000,
      currentPage: 1,
      pageCount: 0,
      order: true,
      click: 1,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getSuppliersData = this.getSuppliersData.bind(this);
    this.getCallList = this.getCallList.bind(this);
    this.inputFrom = React.createRef();
    this.inputTo = React.createRef();
    this.inputType = React.createRef();
    this.inputSupplier = React.createRef();
    this.inputCustomerId = React.createRef();
    this.inputAgentId = React.createRef();
    this.inputOriginNumber = React.createRef();
    this.InputDestinyNumber = React.createRef();
    this.cleanAll = this.cleanAll.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.sort = this.sort.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  async componentDidMount() {
    await this.getSuppliersData();

    let minMonthBefore = new Date();
    minMonthBefore.setMonth(minMonthBefore.getMonth() - 6);

    this.setState({
      dayToday: new Date().toJSON().slice(0, 19).replace('T', ' ').split(' ')[0],
      minDayBefore: minMonthBefore.toJSON().slice(0, 19).replace('T', ' ').split(' ')[0],
    });
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e.value });
        break;
      case 2:
        this.setState({ [e.target.name]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }

    setTimeout(() => {}, 300);
  }

  async getSuppliersData() {
    const results = await DataApi.GetSuppliers();
    this.setState({ suppliers: results.data });
  }

  async cleanAll() {
    this.inputFrom.current.value = '';
    this.inputTo.current.value = '';
    this.inputCustomerId.current.value = '';
    this.inputAgentId.current.value = '';
    this.inputOriginNumber.current.value = '';
    this.InputDestinyNumber.current.value = '';
    this.setState({
      cdrType: { label: '', value: '' },
      supplier: { label: '', value: '' },
      data: '',
      dataMostrar: '',
    });
  }

  async getCallList() {
    this.setState({ loading: true, toHide: true, data: '', dataMostrar: '', offset: 0, pageCount: 0 });

    const { fromDate, toDate, cdrType, supplier, customerId, agentId, originNumber, destinyNumber } = this.state;
    const first = true;
    const json = {
      fromDate: fromDate,
      toDate: toDate,
      cdrType: cdrType,
      supplier: supplier,
      customerId: customerId,
      agentId: agentId,
      originNumber: originNumber,
      destinyNumber: destinyNumber,
      first,
    };

    const results = await DataApi.GetCalls(1, json);

    const dataMostrar = results.data[0].slice(this.state.offset, this.state.offset + this.state.perPage);

    this.setState({
      loading: false,
      toHide: false,
      data: results.data[0],
      dataMostrar: dataMostrar,
      totalCalls: results.data[0].length,
      totalDuration: results.data[1].totalDurationBuy,
      totalDurationSell: results.data[1].totalDurationSell,
      totalCallCost: results.data[1].totalCallCost.toFixed(4),
      outOfTax: results.data[1].outOfTax.toFixed(4),
      totalCallSale: results.data[1].totalCallSale.toFixed(4),
      outOfTaxSale: results.data[1].outOfTaxSale.toFixed(4),
      totalCallAgent: results.data[1].totalCallAgent.toFixed(4),
      outOfTaxAgent: results.data[1].outOfTaxAgent.toFixed(4),
      pageCount: Math.ceil(results.data[0].length / this.state.perPage),
    });
  }

  handlePageClick(page) {
    const offset = (page - 1) * this.state.perPage;
    const dataMostrar = this.state.data.slice(offset, offset + this.state.perPage);

    this.setState({
      currentPage: page,
      offset: offset,
      dataMostrar: dataMostrar,
    });
  }

  async sort(sortBy) {
    const { order } = this.state;
    const { fromDate, toDate, cdrType, supplier, customerId, agentId, originNumber, destinyNumber } = this.state;
    let { click } = this.state;

    this.setState({ order: order, sortBy: sortBy });

    if (click >= 2) {
      this.setState({ order: !order });
    }
    const page = 1;
    const json = {
      fromDate: fromDate,
      toDate: toDate,
      cdrType: cdrType,
      supplier: supplier,
      customerId: customerId,
      agentId: agentId,
      originNumber: originNumber,
      destinyNumber: destinyNumber,
      sortBy,
      order,
    };

    const results = await DataApi.GetCalls(page, json);
    const dataMostrar = results.data[0].slice(this.state.offset, this.state.offset + this.state.perPage);
    click++;
    this.setState({ dataMostrar: dataMostrar, click: click });
  }

  async onKeyPress(e) {
    if (e.which === 13) {
      await this.getCallList();
    }
  }

  render() {
    const {
      cdrType,
      types,
      supplier,
      suppliers,
      fromDate,
      toDate,
      dayToday,
      totalCalls,
      totalDuration,
      totalDurationSell,
      totalCallCost,
      outOfTax,
      totalCallSale,
      outOfTaxSale,
      totalCallAgent,
      outOfTaxAgent,
      dataMostrar,
      loading,
      toHide,
      data,
    } = this.state;

    return (
      <>
        <div className="content-wrapper">
          <div className="content">
            {' '}
            <div className="breadcrumb-wrapper container-item">
              <h1>CDRs List</h1>
            </div>
            <div className="row container-fluid ml-2" onKeyUp={this.onKeyPress}>
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-header container-item ">
                    <h2>Search</h2>
                  </div>

                  <div className="card-body ">
                    <div className="row justify-content-between top-information">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>From: <i style={{color: "red", fontSize:"0.8rem", position: "absolute"}}>* Required</i> </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="From"
                            name="fromDate"
                            onChange={this.handleChange}
                            max={dayToday}
                            ref={this.inputFrom}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>To:</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="To"
                            name="toDate"
                            onChange={this.handleChange}
                            max={dayToday}
                            ref={this.inputTo}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <SelectCmx
                            index={1}
                            nameI={'cdrType'}
                            value={cdrType.value}
                            options={types}
                            change={(e) => this.handleChange(e, 'cdrType', 1)}
                            name={'Type'}
                            ref={this.inputType}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <SelectCmx
                            index={1}
                            nameI={'supplier'}
                            value={supplier.value}
                            options={suppliers}
                            change={(e) => this.handleChange(e, 'supplier', 1)}
                            name={'Supplier'}
                            ref={this.inputSupplier}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row top-information">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Customer ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Customer ID"
                            name="customerId"
                            onChange={this.handleChange}
                            ref={this.inputCustomerId}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Agent ID</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Agent ID"
                            name="agentId"
                            onChange={this.handleChange}
                            ref={this.inputAgentId}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Origin Number</label>
                          <input
                            type="text"
                            name="originNumber"
                            className="form-control"
                            placeholder="Origin Number"
                            onChange={this.handleChange}
                            ref={this.inputOriginNumber}
                          />
                        </div>
                      </div>

                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Destiny Number</label>
                          <input
                            type="text"
                            name="destinyNumber"
                            className="form-control"
                            placeholder="Destiny Number"
                            onChange={this.handleChange}
                            ref={this.InputDestinyNumber}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 justify-content-center mt-4 ">
                      {/* <button type="reset" className="btn btn-warning" onClick={this.cleanAll}>
                        Reset
                      </button> */}
                      {fromDate && !toHide ? (
                        <button type="submit" className="btn btn-primary" onClick={this.getCallList}>
                          Search
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading ? (
              <div
                className="container-item mt-5 position-absolute offset-3"
                style={{ zIndex: '1000', backgroundColor: 'white', width: '600px' }}
              >
                <Loader type="ThreeDots" color="#4c84ff" width={300} className="top-100 start-100 mt-3" />
                <div className="d-flex justify-content-center ">
                  <h2 className="ml-3" style={{ color: '#4c84ff' }}>
                    LOADING...
                  </h2>
                </div>
              </div>
            ) : null}
            {dataMostrar ? (
              <div className="row mt-5 container-fluid ml-3">
                <div className="col-12">
                  <div className="card card-default">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-4 col-lg-4 col-xl-4">
                          <div className="card widget-block  rounded bg-primary2 border">
                            <div className="card-block text-white  container-item ">
                              <h4 className="text-white mt-2">{totalCalls}</h4>
                              <p>Total Calls</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4 mb-3">
                          <div className="card widget-block  rounded bg-primary2 border">
                            <div className="card-block text-white container-item">
                              <h4 className="text-white mt-2">{totalDuration}</h4>
                              <p>Total Duration Buy</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-xl-4">
                          <div className="card widget-block  rounded bg-primary2 border">
                            <div className="card-block text-white  container-item">
                              <h4 className=" mt-2">{totalDurationSell}</h4>
                              <p>Total Duration Sell</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-4">
                          <div className="media widget-media  rounded bg-white border border-primary2 ">
                            <div className="media-body align-self-center ml-md-5 mt-2">
                              <h4 className="text-primary mb-2">{totalCallCost}</h4>
                              <p className="text-secondary">Total Call Cost</p>
                            </div>

                            <div className="media-body align-self-center ml-md-5 mt-2">
                              <h4 className="text-primary mb-2">{outOfTax}</h4>
                              <p className="text-secondary">Out of Tax</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-4">
                          <div className="media widget-media rounded bg-white border border-primary2">
                            <div className="media-body align-self-center ml-md-5 mt-2">
                              <h4 className="text-primary mb-2">{totalCallSale}</h4>
                              <p className="text-secondary">Total Call Sale</p>
                            </div>

                            <div className="media-body align-self-center ml-md-5 mt-2">
                              <h4 className="text-primary mb-2">{outOfTaxSale}</h4>
                              <p className="text-secondary">Out of Tax</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-4">
                          <div className="media widget-media rounded bg-white border border-primary2">
                            <div className="media-body align-self-center ml-md-5 mt-2">
                              <h4 className="text-primary mb-2">{totalCallAgent}</h4>
                              <p className="text-secondary">Total Call Agent</p>
                            </div>

                            <div className="media-body align-self-center ml-md-5 mt-2">
                              <h4 className="text-primary mb-2">{outOfTaxAgent}</h4>
                              <p className="text-secondary">Out of Tax</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-8 mt-5 ml-4">
                          <CSVLink
                            data={data}
                            filename={'cdrList.csv'}
                            className="btn btn-orange btn-sm text-uppercase ml-3"
                            target="_blank"
                          >
                            {' '}
                            <i className=" mdi mdi-export"></i> Export CSV
                          </CSVLink>
                        </div>
                      </div>

                      <div className="container-fluid" style={{ marginLeft: '-40px' }}>
                        <table className="table2 table-bordered p-0">
                          <thead className="bg-primary2 text-white">
                            <tr className="">
                              <th>Date</th>
                              <th>CDR Type</th>
                              <th>From Number</th>
                              <th>To Number</th>
                              <th>Description</th>
                              <th>Duration</th>
                              <th>Sale Duration</th>
                              <th>Customer ID</th>
                              <th>Agent ID</th>
                              <th>Supplier ID</th>
                              <th>Call Cost Rate</th>
                              <th>Call Cost</th>
                              <th>Call Agent Rate</th>
                              <th>Call Agent</th>
                              <th onClick={() => this.sort('saleRate')}>Call Sale Rate</th>
                              <th onClick={() => this.sort('callSale')}>Call Sale</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataMostrar.map((item, index) => (
                              <>
                                <tr>
                                  <td>{item.date}</td>
                                  <td>{item.cdr_type}</td>
                                  <td>{item.from_number}</td>
                                  <td>{item.to_number}</td>
                                  <td>{item.description}</td>
                                  <td>{item.duration}</td>
                                  <td>{item.sale_duration}</td>
                                  <td>{item.customer_id}</td>
                                  <td>{item.agent_id}</td>
                                  <td>{item.supplier_id}</td>
                                  <td>{item.call_cost_rate}</td>
                                  <td>{item.call_cost}</td>
                                  <td>{item.call_agent_rate}</td>
                                  <td>{item.call_agent.toFixed(4)}</td>
                                  <td>{item.call_sale_rate}</td>
                                  <td>{item.call_sale.toFixed(4)}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                        <Pagination
                          totalItemsCount={totalCalls}
                          onChange={(page) => this.handlePageClick(page)}
                          activePage={this.state.currentPage}
                          itemsCountPerPage={this.state.perPage}
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default CdrList;
