export default {
  top: [
    {
      name: 'User',
      url: '/user',
      icon: 'mdi mdi-account-circle',
    },
    {
      name: 'Agents',
      url: '/agent',
      icon: 'mdi mdi-account-card-details',
    },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'mdi mdi-human-handsup',
    },
    {
      name: 'Calls',
      url: '/calls',
      icon: 'mdi mdi-phone-in-talk',
      children: [
        {
          name: 'Load/Process CDRs',
          url: '/cdr',
        },
        {
          name: 'CDR List',
          url: '/callList',
        },
        {
          name: 'Overrides',
          url: '/overrides',
        },
        {
          name: 'Anomalies',
          url: '/anomalies',
        },
        {
          name: 'Charges',
          url: '/charges',
        },
      ],
    },
    {
      name: 'Invoice',
      url: '/invoice',
      icon: 'mdi mdi-paperclip',
    },
    {
      name: 'Payments',
      url: '/payments',
      icon: 'mdi mdi-credit-card-multiple',
    },
    {
      name: 'Price',
      url: '/price',
      icon: 'mdi mdi-coin',
      children: [
        {
          name: 'Prefix Translates',
          url: '/prefix-translate',
        },
        {
          name: 'Customer Price',
          url: '/customer-price',
        },
        {
          name: 'Packages',
          url: '/package',
        },
        {
          name: 'Suppliers',
          url: '/supplier',
        },
        {
          name: 'Check prices',
          url: '/check-prices',
        },
      ],
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'mdi mdi-settings-outline',
      children: [
        {
          name: 'Vat',
          url: '/vat',
        },
        {
          name: 'Ext Types',
          url: '/ext-type',
        },
        {
          name: 'Gateways',
          url: '/gateway',
        },
        {
          name: 'Locks',
          url: '/lock',
        },
        {
          name: 'Products',
          url: '/product',
        },
        {
          name: 'Currency Exchanges',
          url: '/exchange',
        },
        {
          name: 'Register Numbers',
          url: '/register',
        },
        {
          name: 'Audits',
          url: '/audits',
        },
        {
          name: 'Mega Mail',
          url: '/megaMail',
        },
      ],
    },
    {
      name: 'Reports System',
      url: '/reports',
      icon: 'mdi mdi-file-delimited-outline',
      
    },

    {
      divider: true,
    },
  ],
};
