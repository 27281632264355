import React from 'react';
import { mdiText } from '@mdi/js';
import Icon from '@mdi/react';

export default function SidebarToggleButton({ isSidebarCollapsed, toggleSidebar }) {
  return (
      <div onClick={toggleSidebar} className="container-toggle-sidebar">
        <div className="toggle-sidebar">
        
                        {isSidebarCollapsed ? (<Icon
                          path={mdiText}
                          title="Expand"
                          size={3}
                          className="mr-1 ml-4"
                          horizontal
                          color="grey"
                          
                        />): <Icon
                        path={mdiText}
                        title="Collapse"
                        size={3}
                        className="mr-1"
                        // horizontal
                        color="grey"
                      />}
            {/* <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAACXBIWXMAAABgAAAAYADwa0LPAAAARUlEQVRo3u3VwQkAIAwDQHX/nXUCRShYpHffQkleaQ0AoK5+uM3scDdZR3aqKAUAarPE2RQAqO3FEvf4i73vd0ABAADyLCDKAxaJK5TYAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIxLTA0LTA2VDEzOjM0OjI1KzAwOjAwAZwFLQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMS0wNC0wNlQxMzozNDoyNSswMDowMHDBvZEAAAAASUVORK5CYII='
             alt="loading..." width="40%"
             /> */}
        </div>
      </div>
  
  );
}


