import React from 'react';

import { Table } from 'reactstrap';
import { MDBIcon } from 'mdbreact';

import ModalEditPackages from './Modal/ModalEditPackages';
import ModalCopyPackages from './Modal/ModalCopyPackages';

class TablePackages extends React.Component {
        
  render() {
    const { data: { data } } = this.props;
    
    if (!data)
      return null;
    
    return (
      <Table className="table table-striped">
        <thead>
        <tr>
            <th scope="col">Name</th>
            <th scope="col">Minutes</th>
            <th scope="col">Actions</th>
        </tr>
        </thead>
        {
          data.map((item, index) => (
            <tr key={index}>
              <td className="col-principal">{item.name}</td>
              <td>{item.minutes}</td>
              <td className="d-flex">
                <MDBIcon icon="trash" size="lg" className="trash" onClick={() => this.props.destroyPackages(item.id)} />
                <ModalEditPackages data={item} select_description={this.props.select_description} select_customer_price={this.props.select_customer_price} getPackages={this.props.getPackages} initialListSelect={this.props.initialListSelect} />
                <ModalCopyPackages item={item.id} getPackages={this.props.getPackages} toCopy={item.name} />
              </td>
            </tr>
          ))
        }
      </Table>
    );
  }
}

export default TablePackages;