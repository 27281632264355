/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
import React from 'react';

import { Table } from 'reactstrap';

import DataApi from '../../../../megacall/services/Customers/DataApi';
import Input from '../../Utils/Input';
import Pagination from 'react-js-pagination';
import ModalNewExtensionPbx from './Modal/ModalNewExtensionPbx';
import SelectCmx from '../../Utils/Select';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Swal from 'sweetalert2';
import SubTableExtensionPbx from './SubTableExtensionPbx';

class TableExtensionPbx extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      arrrayId: '',
      usuarioName: '',
      usuarioUsername: '',
      usuarioPassword: '',
      usuarioGateway: '',
      data: '',
      gateways: '',
      ext_type: '',
      gateways_all: '',
      ext_type_all: '',
      username: '',
      usuarioGatewaySearch: '',
      extTypeSearch: '',
      extType_all: '',
      enabled_all: [
        { label: 'Enable', value: 1 },
        { label: 'Disable', value: 0 },
      ],
      enabled: 3,
      enabledSearch: '',
      checkBox: false,
      checkClass: '',
      dato: [],
      info: '',
      sortBy: '',
      click: 1,
    };
    this.generatePassword = this.generatePassword.bind(this);
    this.getExtensionPbx = this.getExtensionPbx.bind(this);
    this.destroyExtension = this.destroyExtension.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.checkReduce = this.checkReduce.bind(this);
    this.saveCheck = this.saveCheck.bind(this);
    this.sendCredential = this.sendCredential.bind(this);
    this.sendExtCredential = this.sendExtCredential.bind(this);
  }

  async componentDidMount() {
    await this.getExtensionPbx(1);
  }

  async getExtensionPbx(page) {
    const {
      customersId: { response },
    } = this.props;
    const { username, usuarioGatewaySearch, extTypeSearch, enabled, checkBox, sortBy } = this.state;
    this.setState({ checkBox: !checkBox, checkClass: '' });
    //flag para añadir el orderBy por defecto
    const object = {
      username: username,
      gateways: usuarioGatewaySearch.value,
      extType: extTypeSearch.value,
      enabled: enabled,
      first: this.state.first === false ? null : true,
      sortBy: sortBy,
      order: !this.state.order,
      perPage: this.state.perPage,
    };

    this.setState({ page: page });

    const results = await DataApi.GetExtensionPBX(page, response, object);

    const gateways_all = ModificationArray.modification(results.data.gateways);
    const extType_all = ModificationArray.modification(results.data.ext_type);

    let item;

    if (results.data.voips[0] !== undefined) {
      results.data.gateways.map(items => {
        if (items.id === results.data.voips[0].gateway_id) {
          item = items;
        }
      });

      this.setState({
        usuarioId: results.data.voips[0].id,
        usuarioName: results.data.voips[0].name,
        usuarioUsername: results.data.voips[0].username,
        usuarioPassword: results.data.voips[0].password,
        usuarioGateway: { label: item.display_name, value: item.id },
        gateways_all: gateways_all,
        data: results.data.voips_extension,
        gateways: results.data.gateways,
        ext_type: results.data.ext_type,
        extType_all: extType_all,
      });
    } else {
      this.setState({
        gateways_all: gateways_all,
        data: results.data.voips_extension,
        gateways: results.data.gateways,
        ext_type: results.data.ext_type,
        extType_all: extType_all,
      });
    }
  }

  /** Esta funcionalidad seria conveniente pasarla a una clase estatica o separarla de esta clase ya que se usa en varios sitios  */
  generatePassword() {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let string_length = 12;
    let randomstring = '';
    for (let i = 0; i < string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }

    this.setState({ usuarioPassword: randomstring });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.data !== this.state.data) {
    }
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        // this.getExtensionPbx();
        break;
      case 2:
        this.setState({ [e.target.name]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
          this.getExtensionPbx();
        }, 50);

        break;
    }
  }
  //TODO: Implementar esta funcion en las otras de busqueda.
  async handleChange2(e, index) {
    if (index) {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });

      setTimeout(() => {
        this.getExtensionPbx();
      }, 500);
    }
  }

  /** Esta funcionalidad hay que unificarla con la de arriba para no repetir codigo la solucion seria pasar un valor dependiendo de si sea buscador o no */
  async handleChangeSearch(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState(prevState => ({ ...prevState.nameI, [nameI]: e }));
        setTimeout(() => {
          this.getExtensionPbx();
        }, 200);
        // this.setState({ enabledSearch: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
          this.getExtensionPbx();
        }, 200);
        break;
    }

    // this.getExtensionPbx();
  }

  async saveUser() {
    const { usuarioName, usuarioUsername, usuarioPassword, usuarioGateway, usuarioId } = this.state;
    const { customersId } = this.props;

    const object = {
      customer_id: customersId.response,
      name: usuarioName,
      username: usuarioUsername,
      password: usuarioPassword,
      gateway_id: usuarioGateway.value,
    };

    const response = await DataApi.UpdateUsername(usuarioId, object);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.getExtensionPbx();
  }

  async destroyExtension(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyEntensionPBX(id);
        // this.getDiallers();
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  /* TODO: checkAll y checkReduce se debe de pasar a una clase estatica se va utiliza en mas de una vista esta funcionalidad hace el checkbox de la tabla  */
  async checkAll() {
    const { checkBox } = this.state;
    const {
      customersId: { response },
    } = this.props;
    const {
      data: { data },
    } = this.state;
    const { username } = this.state;

    const allId = await DataApi.GetIdAll(response);

    const itemsAll = [];

    allId.data.id.map(data => {
      itemsAll.push(data.id);
    });

    if (checkBox) {
      this.setState({ arrrayId: '', checkBox: !checkBox, checkClass: '' });
    } else if (username) {
      this.setState({ arrrayId: data, checkBox: !checkBox, checkClass: 'success' });
    } else {
      this.setState({ arrrayId: itemsAll, checkBox: !checkBox, checkClass: 'success' });
    }
  }

  checkReduce(id, username) {
    const { arrrayId, info } = this.state;
    if (info.includes(username)) {
      // const a = info.filter(username);
      // console.log(a);
    } else {
      this.setState({ info: [...info, username] });
    }
    const index = arrrayId.indexOf(id);
    // console.log(this.state.info);
    // const arrayNames = [...info, username];
    // console.log(arrayNames);

    if (index === -1) {
      this.setState({ arrrayId: [...arrrayId, id] });
    } else {
      arrrayId.splice(index, 1);
      info.splice(index, 1);
      this.setState({ arrrayId: arrrayId, info: info });
    }
  }

  /* TODO: se debe controlar que los botones no esten activos  */
  async saveCheck(type) {
    const { arrrayId, info } = this.state;
    const { customersId } = this.props;

    if (type === 1) {
      await DataApi.EnabledChangeExtension(1, arrrayId, customersId.response);

      this.setState({ arrrayId: '', checkBox: false, checkClass: 'false' });
      const object = { action: 'enabled', username: info };
      this.setState({ info: '' });
      // console.log(info);
      this.props.getAllInfo(3, object);
    } else {
      await DataApi.EnabledChangeExtension(0, arrrayId, customersId.response);

      this.setState({ arrrayId: '', checkBox: false, checkClass: 'false' });
      const object = {
        action: 'disabled',
        username: info,
      };
      this.setState({ info: '' });
      this.props.getAllInfo(3, object);
    }

    let success = document.getElementsByClassName('delete');

    for (let i = 0; i < success.length; i++) {
      success[i].classList.remove('chekc-success');
    }

    this.setState({ arrrayId: '', checkBox: false, checkClass: '' });

    await this.getExtensionPbx();
  }

  async sendExtCredential() {
    const { arrrayId } = this.state;
    const { customersId } = this.props;

    const results = await DataApi.sendAuthExten(customersId.response, arrrayId);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The credentials has been sent!',
        showConfirmButton: false,
        timer: 1500,
      });

      let success = document.getElementsByClassName('delete');
      for (let i = 0; i < success.length; i++) {
        success[i].classList.remove('chekc-success');
      }
      this.setState({ arrrayId: '', checkBox: false, checkClass: '' });
      await this.getExtensionPbx();
    }
  }

  async sendCredential() {
    const { usuarioName, usuarioUsername, usuarioPassword, usuarioGateway } = this.state;
    const { customersId } = this.props;
    const object = {
      name: usuarioName,
      username: usuarioUsername,
      password: usuarioPassword,
      gateway: usuarioGateway.label,
    };

    const results = await DataApi.sendCredential(customersId.response, object);
    // console.log(results);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The credentials has been sent!',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async sort(sortBy) {
    const {
      customersId: { response },
    } = this.props;
    const { order } = this.state;

    let { click } = this.state;

    this.setState({ sortBy: sortBy, first: false });

    if (click >= 1) {
      if (this.state.order === true) {
        this.setState({ order: false });
      } else {
        this.setState({ order: true });
      }
    }
    const page = 1;
    const { username, usuarioGatewaySearch, extTypeSearch, enabled } = this.state;
    const object = {
      username: username,
      gateways: usuarioGatewaySearch.value,
      extType: extTypeSearch.value,
      enabled: enabled,
      sortBy: sortBy,
      order: order,
    };
    const results = await DataApi.GetExtensionPBX(page, response, object);
    // console.log(results.data.voips_extension.data);
    click++;
    this.setState({ data: results.data.voips_extension, click: click });
  }

  render() {
    const {
      customersId: { response },
      lock,
    } = this.props;
    const {
      usuarioName,
      usuarioUsername,
      usuarioPassword,
      usuarioGateway,
      gateways,
      usuarioGatewaySearch,
      ext_type,
      gateways_all,
      username,
      extType_all,
      extTypeSearch,
      checkClass,
      arrrayId,
      data: { data },
      checkBox,
      perPage,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container-input-modal">
            <div className="container-button">
              <h3> User Data</h3>
            </div>
          </div>
          <div className="container-search-pbx">
            <div className="container-search-pbx-one">
              <Input
                nameI={'usuarioName'}
                value={usuarioName}
                change={e => this.handleChange(e, 'usuarioName', 2)}
                name={'Name'}
                disabled
              />
              <Input
                nameI={'usuarioUsername'}
                value={usuarioUsername}
                change={e => this.handleChange(e, 'usuarioUsername', 2)}
                name={'Username'}
                disabled
              />
              <SelectCmx
                index={1}
                nameI={'usuarioGateway'}
                value={usuarioGateway}
                change={this.handleChange}
                name={'Gateways'}
                options={gateways_all}
                isDisabled
              />
            </div>

            <div className="container-search-pbx-two">
              <Input
                nameI={'usuarioPassword'}
                value={usuarioPassword}
                change={e => this.handleChange(e, 'usuarioPassword', 2)}
                name={'Password'}
                disabled
              />
            </div>
          </div>
          {/** Buscadores de los customers */}
          <div className="container-input-modal">
            <div className="container-button">
              <h3> Search PBX List</h3>
            </div>
          </div>
          <div className="container-search-voip ml-3">
            <div className="row">
              <div className="col-6">
                <Input nameI={'username'} value={username} change={this.handleChange2} placeholder={'Username'} />

                <SelectCmx
                  value={usuarioGatewaySearch}
                  change={e => this.handleChangeSearch(e, 'usuarioGatewaySearch', 1)}
                  placeholder={'Gateways'}
                  options={gateways_all}
                />
              </div>
              <div className="col-6 mt-4">
                <SelectCmx
                  index={1}
                  nameI={'extTypeSearch'}
                  value={extTypeSearch}
                  change={this.handleChangeSearch}
                  placeholder={'Ext Type'}
                  options={extType_all}
                  className=""
                />
                <select
                  name="enabled"
                  className="form-control mt-3"
                  onChange={e => this.handleChangeSearch(e, null, 2)}
                >
                  <option selected value="3">
                    All
                  </option>
                  <option value="1">Enabled</option>
                  <option value="0">Disabled</option>
                </select>
                <Input nameI={'perPage'} value={perPage} change={e => this.handleChange(e)} placeholder={'per page'} />
              </div>
            </div>
          </div>
          <br />

          <br />
          <div className="container-table-all">
            <Table className="table table-striped">
              <thead className="container-item">
                <tr style={{ cursor: 'pointer' }}>
                  {/* <th scope="col">
                    <input type="checkbox" onClick={() => this.checkAll()} checked={checkBox} />
                  </th> */}
                  <th scope="col" onClick={() => this.sort('enabled')}>
                    Enabled
                  </th>
                  <th scope="col" onClick={() => this.sort('gateway')}>
                    Gateway
                  </th>
                  <th scope="col" onClick={() => this.sort('extType')}>
                    Ext Type
                  </th>
                  <th scope="col" onClick={() => this.sort('username')}>
                    Username
                  </th>
                  <th scope="col" onClick={() => this.sort('auth')}>
                    Authentication
                  </th>
                  <th scope="col" onClick={() => this.sort('configured')}>
                    Configured
                  </th>
                  <th scope="col" onClick={() => this.sort('added')}>
                    Added
                  </th>
                  <th scope="col" onClick={() => this.sort('disabled')}>
                    Disabled
                  </th>
                  <th scope="col">Notes</th>
                </tr>
              </thead>

              <tbody className="container-item">
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    <SubTableExtensionPbx
                      index={index}
                      item={item}
                      gateways={gateways}
                      ext_type={ext_type}
                      destroyExtension={this.destroyExtension}
                      getExtensionPbx={this.getExtensionPbx}
                      response={response}
                      checkBox={checkClass}
                      checkReduce={this.checkReduce}
                      saveCheck={this.saveCheck}
                      lock={lock}
                    />
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
          <Pagination
            totalItemsCount={total}
            onChange={page => this.getExtensionPbx(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-input-modal">
          <ModalNewExtensionPbx
            customersId={response}
            getExtensionPbx={this.getExtensionPbx}
            gateways={gateways}
            ext_type={ext_type}
            getAllInfo={this.props.getAllInfo}
          />
        </div>
        <div className="container-input-modal">
          <div className="container-button">
            <h3> User Data</h3>
          </div>
        </div>
        <div className="container-search-pbx">
          <div className="container-search-pbx-one">
            <Input
              nameI={'usuarioName'}
              value={usuarioName}
              change={e => this.handleChange(e, 'usuarioName', 2)}
              name={'Name'}
            />
            <Input
              nameI={'usuarioUsername'}
              value={usuarioUsername}
              change={e => this.handleChange(e, 'usuarioUsername', 2)}
              name={'Username'}
            />
            <SelectCmx
              index={1}
              nameI={'usuarioGateway'}
              value={usuarioGateway}
              change={this.handleChange}
              name={'Gateways'}
              options={gateways_all}
            />
          </div>

          <div className="container-search-pbx-two">
            <Input
              nameI={'usuarioPassword'}
              value={usuarioPassword}
              change={e => this.handleChange(e, 'usuarioPassword', 2)}
              name={'Password'}
            />
          </div>

          <div className="container-search-pbx-button">
            <button type="submit" onClick={this.generatePassword} className="btn btn-primary mt-4">
              <i className=" mdi mdi-backup-restore mdi-22px"></i> Generate Pass
            </button>
            <button type="submit" className="btn btn-success mt-4" onClick={this.saveUser}>
              <i className=" mdi mdi-content-save mdi-22px"></i> Save
            </button>
            <button type="submit" className="btn btn-warning mt-4 text-white" onClick={this.sendCredential}>
              <i className=" mdi mdi-email-lock  mdi-22px "></i> Send credentials
            </button>
          </div>
        </div>
        {/** Buscadores de los customers */}
        <div className="container-input-modal">
          <div className="container-button">
            <h3> Search PBX List</h3>
          </div>
        </div>
        <div className="container-search-voip ml-3">
          <div className="row">
            <div className="col-6">
              <Input nameI={'username'} value={username} change={this.handleChange2} placeholder={'Username'} />

              <SelectCmx
                value={usuarioGatewaySearch}
                change={e => this.handleChangeSearch(e, 'usuarioGatewaySearch', 1)}
                placeholder={'Gateways'}
                options={gateways_all}
              />
            </div>
            <div className="col-6 mt-4">
              <SelectCmx
                index={1}
                nameI={'extTypeSearch'}
                value={extTypeSearch}
                change={this.handleChangeSearch}
                placeholder={'Ext Type'}
                options={extType_all}
                className=""
              />
              <select name="enabled" className="form-control mt-3" onChange={e => this.handleChangeSearch(e, null, 2)}>
                <option selected value="3">
                  All
                </option>
                <option value="1">Enabled</option>
                <option value="0">Disabled</option>
              </select>
              <Input nameI={'perPage'} value={perPage} change={e => this.handleChange(e)} placeholder={'per page'} />
            </div>
          </div>
        </div>
        <br />
        {arrrayId.length > 0 ? (
          <div className="check-all-enabled ml-2">
            <button type="button" value="Enabled" className="btn btn-primary fs-6" onClick={() => this.saveCheck(1)}>
              Enables
            </button>
            <button type="button" value="Disabled" className="btn btn-primary fs-6" onClick={() => this.saveCheck(2)}>
              Disables
            </button>
            <button type="button" className="btn btn-warning fs-6 text-white" onClick={this.sendExtCredential}>
              <i className=" mdi mdi-email-lock  mdi-22px "></i> Send credentials
            </button>
          </div>
        ) : (
          ''
        )}

        <br />
        <div className="container-table-all">
          <Table className="table table-striped">
            <thead className="container-item">
              <tr>
                <th scope="col">
                  <input type="checkbox" onClick={() => this.checkAll()} checked={checkBox} />
                </th>
                <th scope="col" onClick={() => this.sort('enabled')}>
                  Enabled
                </th>
                <th scope="col" onClick={() => this.sort('gateway')}>
                  Gateway
                </th>
                <th scope="col" onClick={() => this.sort('extType')}>
                  Ext Type
                </th>
                <th scope="col" onClick={() => this.sort('username')}>
                  Username
                </th>
                <th scope="col" onClick={() => this.sort('auth')}>
                  Authentication
                </th>
                <th scope="col" onClick={() => this.sort('configured')}>
                  Configured
                </th>
                <th scope="col" onClick={() => this.sort('added')}>
                  Added
                </th>
                <th scope="col" onClick={() => this.sort('disabled')}>
                  Disabled
                </th>
                <th scope="col">Notes</th>
                <th scope="col">Actions </th>
              </tr>
            </thead>

            <tbody className="container-item">
              {data.map((item, index) => (
                <React.Fragment key={index}>
                  <SubTableExtensionPbx
                    index={index}
                    item={item}
                    gateways={gateways}
                    ext_type={ext_type}
                    destroyExtension={this.destroyExtension}
                    getExtensionPbx={this.getExtensionPbx}
                    response={response}
                    checkBox={checkClass}
                    checkReduce={this.checkReduce}
                    saveCheck={this.saveCheck}
                  />
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          totalItemsCount={total}
          onChange={page => this.getExtensionPbx(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableExtensionPbx;
