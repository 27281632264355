import React from 'react';
import ModalNewSipTrunk from '../SipTrunk/Modals/ModalNewSipTrunk';
import SubTableSipTrunk from '../SipTrunk/Modals/SubTableSipTrunk';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import { Table } from 'reactstrap';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';

import Swal from 'sweetalert2';
import { mdiReload } from '@mdi/js';

class TableSipTrunk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      gateways_all: '',
      checkBox: false,
      arrrayId: '',
      info: '',
      checkClass: '',
    };
    this.getSipTrunk = this.getSipTrunk.bind(this);
    this.destroySipTrunk = this.destroySipTrunk.bind(this);
    this.checkReduce = this.checkReduce.bind(this);
    this.saveCheck = this.saveCheck.bind(this);
    this.sort = this.sort.bind(this);
  }

  async componentDidMount() {
    await this.getSipTrunk();
  }

  async getSipTrunk() {
    const {
      customersId: { response },
    } = this.props;

    const results = await DataApi.GetSipTrunk(response);
    const gateways_all = ModificationArray.modification(results.data[1]);
    this.setState({
      data: results.data[0],
      gateways_all: gateways_all,
    });
  }

  async destroySipTrunk(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroySipTrunk(id);
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
   this.getSipTrunk();
  }

  async saveCheck(type) {
    const { arrrayId } = this.state;

    if (type === 1) {
      await DataApi.EnabledChangeSipTrunk(1, arrrayId);
      this.setState({ arrrayId: '', checkBox: false, checkClass: 'false' });
    } else {
      await DataApi.EnabledChangeSipTrunk(0, arrrayId);

      this.setState({ arrrayId: '', checkBox: false, checkClass: 'false' });
    }

    let success = document.getElementsByClassName('delete');

    for (let i = 0; i < success.length; i++) {
      success[i].classList.remove(`chekc-success-${i}`);
    }
    await this.getSipTrunk();
    this.setState({ arrrayId: '', checkBox: false, checkClass: '' });
  }

  checkReduce(id) {
    // console.log(id);
    const { arrrayId } = this.state;
    const index = arrrayId.indexOf(id);
    if (index === -1) {
      this.setState({ arrrayId: [...arrrayId, id] });
    } else {
      arrrayId.splice(index, 1);
      this.setState({ arrrayId: arrrayId });
    }
  }

  async sort(sortBy) {
    const { customersId } = this.props;
    const { order } = this.state;
    this.setState({ order: !order });

    const object = {
      sortBy: sortBy,
      order: order,
    };
    const results = await DataApi.GetSipTrunk(customersId.response, object);

    const gateways_all = ModificationArray.modification(results.data[1]);
    this.setState({
      data: results.data[0],
      gateways_all: gateways_all,
    });
  }

  render() {
    const { data, gateways_all, checkClass, arrrayId } = this.state;
    const { lock } = this.props;

    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container-input-modal">
            <div className="container-button mb-3">
              <h3>CMX Account</h3>
            </div>
          </div>

          <div className="container-table-all">
            <Table className="table table-striped">
              <thead className="container-item">
                <tr>
                  <th scope="col">enabled</th>
                  <th scope="col" onClick={() => this.sort('id_sippy')}>
                    Id Sippy
                  </th>
                  <th scope="col" onClick={() => this.sort('name_sippy')}>
                    Name Sippy
                  </th>
                  <th scope="col" onClick={() => this.sort('channels')}>
                    Channels
                  </th>
                  <th scope="col" onClick={() => this.sort('sip_trunk_ip')}>
                    Ip
                  </th>
                  <th scope="col" onClick={() => this.sort('username')}>
                    Username
                  </th>
                  <th scope="col" onClick={() => this.sort('password')}>
                    Password
                  </th>
                  <th scope="col" onClick={() => this.sort('gateways_display_name')}>
                    Gateway
                  </th>
                  <th scope="col" onClick={() => this.sort('limit_balance')}>
                    Limit Balance
                  </th>
                  <th scope="col" onClick={() => this.sort('auto_recharge')}>
                    Auto Recharge
                  </th>
                  <th scope="col" onClick={() => this.sort('limit_daily')}>
                    Limit Daily
                  </th>
                  <th scope="col">Send Copy to Support</th>
                </tr>
              </thead>
              <tbody className="container-item">
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    <SubTableSipTrunk
                      item={item}
                      index={index}
                      customersId={this.props.customersId}
                      getSipTrunk={this.getSipTrunk}
                      destroySipTrunk={this.destroySipTrunk}
                      gateways_all={gateways_all}
                      checkBox={checkClass}
                      checkReduce={this.checkReduce}
                      saveCheck={this.saveCheck}
                      lock={lock}
                    />
                  </React.Fragment>
                ))}
              </tbody>
            </Table>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-input-modal">
          <label></label>
          <div className="container-button mb-3">
            <h3>CMX Account</h3>
          </div>
          <ModalNewSipTrunk
            customersId={this.props.customersId}
            getSipTrunk={this.getSipTrunk}
            gateways_all={gateways_all}
          />
        </div>
        {arrrayId.length > 0 ? (
          <div className="check-all-enabled ml-2">
            <button type="button" value="Enabled" className="btn btn-primary fs-6" onClick={() => this.saveCheck(1)}>
              Enable
            </button>
            <button type="button" value="Disabled" className="btn btn-primary fs-6" onClick={() => this.saveCheck(2)}>
              Disable
            </button>
          </div>
        ) : (
          ''
        )}

        <div className="container-table-all">
          <Table className="table table-striped">
            <thead className="container-item">
              <tr>
                <th scope="col">enabled</th>
                <th scope="col" onClick={() => this.sort('id_sippy')}>
                  Id Sippy
                </th>
                <th scope="col" onClick={() => this.sort('name_sippy')}>
                  Name Sippy
                </th>
                <th scope="col" onClick={() => this.sort('channels')}>
                  Channels
                </th>
                <th scope="col" onClick={() => this.sort('sip_trunk_ip')}>
                  Ip
                </th>
                <th scope="col" onClick={() => this.sort('username')}>
                  Username
                </th>
                <th scope="col" onClick={() => this.sort('password')}>
                  Password
                </th>
                <th scope="col" onClick={() => this.sort('gateways_display_name')}>
                  Gateway
                </th>
                <th scope="col" onClick={() => this.sort('limit_balance')}>
                  Limit Balance
                </th>
                <th scope="col" onClick={() => this.sort('auto_recharge')}>
                  Auto Recharge
                </th>
                <th scope="col" onClick={() => this.sort('limit_daily')}>
                  Limit Daily
                </th>
                <th scope="col">Send Copy to Support</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody className="container-item">
              {data.map((item, index) => (
                <React.Fragment key={index}>
                  <SubTableSipTrunk
                    item={item}
                    index={index}
                    customersId={this.props.customersId}
                    getSipTrunk={this.getSipTrunk}
                    destroySipTrunk={this.destroySipTrunk}
                    gateways_all={gateways_all}
                    checkBox={checkClass}
                    checkReduce={this.checkReduce}
                    saveCheck={this.saveCheck}
                  />
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
  }
}

export default TableSipTrunk;
