/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';

import Swal from 'sweetalert2';

import Input from '../../Utils/Input';
import SelectCmx from '../../Utils/Select';
import DataApi from '../../../../megacall/services/SuppliersServices/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Validations from '../../../../megacall/utilsFunctions/Validations';

class ModalNewSuppliers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      name: "",
      sippy_name: "",
      vat: "",
      vat_all: "",
      call_cost: "0",
      inbound_mobile: "",
      inbound_landline: "",
      notes: "",
      color: 'invalidate'
    }
    this.toggle = this.toggle.bind(this);
    this.callApi = this.callApi.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.send = this.send.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  async componentDidMount() {
      await this.callApi();
  }

  async callApi() {
    const results = await DataApi.GetSelectSuppliers();

    const modificatio_vat = ModificationArray.modification(results.data);

    this.setState({ vat_all: modificatio_vat });
  }

  handleChange(e, nameI, index) {

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async send() {
    const { name, sippy_name, vat, call_cost, inbound_mobile, inbound_landline, notes } = this.state;

    const json = {
      name: name,
      sippyName: sippy_name,
      vatId: vat.value,         
      minCallCost: call_cost,
      notes: notes,
      inboundMobilePrice: inbound_mobile,
      inboundLandlinePrice: inbound_landline
    }

    const validateInput = Validations.validationInput([
      { value: name, name: 'name' }
    ]);

    const validateSelect = Validations.validationSelect([
      {value: vat, name: 'vat'},
    ]);

    if(validateInput && validateSelect) {
          const response = await DataApi.SetNewSuppliers(json);
          
          if (response.status === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Create the new supplier',
              showConfirmButton: false,
              timer: 1500
            });
          }else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'There was a problem, try again or contact support',
              showConfirmButton: false,
              timer: 1500
            });
          }

          this.setState(prevState => ({  modal: !prevState.modal,  name: "", sippy_name: "", vat: "", call_cost: "0", inbound_mobile: "",  inbound_landline: "", notes: "" }));
    }else  {
        Swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Required fields were not filled in.',
          showConfirmButton: false,
          timer: 1500
        });

      }
  }

  render() {
    const { name, sippy_name, vat_all, vat, call_cost,
        inbound_mobile, inbound_landline, notes, color } = this.state;

    return (
      <React.Fragment>
        <div className="container-button">
            <h3>Suppliers List</h3>
            <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                <i className=" mdi mdi-plus-circle-outline"></i> Add Supplier
            </a>
        </div>
        <Modal className="modal-new-agents" isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>New Suppliers</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Input nameI={'name'} color={color} value={name} change={this.handleChange} name={'Name'} state={name}/>
              {/* <Input nameI={'sippy_name'} value={sippy_name} change={this.handleChange}  name={'Name in Sippy'} state={sippy_name}/> */}
              <SelectCmx validate={"validation"} color={color} index={1} nameI={'vat'} value={vat} change={this.handleChange} name={'Vat'} options={vat_all} id='sel' />
              <div className="mt-3 mb-2"></div>
              <Input nameI={'call_cost'} type={'number'} value={call_cost} change={this.handleChange}  name={'Min. call cost'} state={call_cost}/>
              <Input nameI={'inbound_mobile'} type={'number'} value={inbound_mobile} change={this.handleChange} name={'Inbound Mobile Price'} state={inbound_mobile}/>

              <Input nameI={'inbound_landline'} type={'number'} value={inbound_landline} change={this.handleChange} name={'Inbound Landline Price'} state={inbound_landline}/>
              
              <label>Notes</label>
              <textarea name="notes" className="form-control" id="notes"  value={notes}
                        onChange={(e) => this.handleChange(e)} />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.send}>Send</Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewSuppliers;
