import React from 'react';
import Board from 'react-trello';

// const data = {
//   lanes: [
//     {
//       id: '1',
//       title: 'Pending',
//       label: '2/2',
//       cards: [
//         { id: 'Card1213123', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins' },
//         {
//           id: 'Card23232323',
//           title: 'Pay Rent',
//           description: 'Transfer via NEFT',
//           label: '5 mins',
//           metadata: { sha: 'be312a1' },
//         },
//       ],
//     },
//     {
//       id: '2',
//       title: 'Customer Pending',
//       label: '2/2',
//       cards: [
//         { id: 'Card1232323', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins' },
//         {
//           id: 'Card2232323',
//           title: 'Pay Rent',
//           description: 'Transfer via NEFT',
//           label: '5 mins',
//           metadata: { sha: 'be312a1' },
//         },
//       ],
//     },
//     {
//       id: '3',
//       title: 'Admin Pending',
//       label: '2/2',
//       cards: [
//         { id: 'Card132323', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins' },
//         {
//           id: 'Card24444',
//           title: 'Pay Rent',
//           description: 'Transfer via NEFT',
//           label: '5 mins',
//           metadata: { sha: 'be312a1' },
//         //   style:{backgroundColor: 'red'}
//         },
//       ],
//     },
//     {
//       id: '4',
//       title: 'Rejected',
//       label: '2/2',
//       cards: [
//         { id: 'Card122223', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins' },
//         {
//           id: 'Card2443',
//           title: 'Pay Rent',
//           description: 'Transfer via NEFT',
//           label: '5 mins',
//           metadata: { sha: 'be312a1' },
//         },
//       ],
//     },
//     {
//       id: '5',
//       title: 'Succesfull',
//       label: '2/2',
//       cards: [
//         { id: 'Card1234234', title: 'Write Blog', description: 'Can AI make memes', label: '30 mins' },
//         {
//           id: 'Card2444',
//           title: 'Pay Rent',
//           description: 'Transfer via NEFT',
//           label: '5 mins',
//           metadata: { sha: 'be312a1' },
//         },
//       ],
//     },
//   ],
// };

let eventBus = undefined;

const setEventBus = handle => {
  eventBus = handle;
};
//To add a card
// eventBus.publish({
//   type: 'ADD_CARD',
//   laneId: 'COMPLETED',
//   card: { id: 'M1', title: 'Buy Milk', label: '15 mins', description: 'Also set reminder' },
// });

//To update a card
// eventBus.publish({
//   type: 'UPDATE_CARD',
//   laneId: 'COMPLETED',
//   card: { id: 'M1', title: 'Buy Milk (Updated)', label: '20 mins', description: 'Also set reminder (Updated)' },
// });

//To remove a card
// eventBus.publish({ type: 'REMOVE_CARD', laneId: 'PLANNED', cardId: 'M1' });

//To move a card from one lane to another. index specifies the position to move the card to in the target lane
// eventBus.publish({ type: 'MOVE_CARD', fromLaneId: 'PLANNED', toLaneId: 'WIP', cardId: 'Plan3', index: 0 });

//To update the lanes
// eventBus.publish({ type: 'UPDATE_LANES', lanes: newLaneData });

export default class App extends React.Component {
  render() {
    return (
      <>
        <div>
          <Board
            data={this.props.data}
            style={{ backgroundColor: 'white', marginLeft: '200px', marginTop: '80px' }}
            eventBusHandle={this.props.setEventBus}
            onCardMoveAcrossLanes={ (fromLaneId, toLaneId, cardId, index) => this.props.onCardMoveAcrossLanes(fromLaneId, toLaneId, cardId, index)}
          />
        </div>
      </>
    );
  }
}
