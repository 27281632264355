import CallApi from './CallApi';

export default class DataApi {
  /** Customers */
  static async GetCustomers(page, object) {
    const results = await CallApi.LISTCUSTOMERS(page, object);

    return results;
  }

  static async GetCustomerForms(id) {
    const results = await CallApi.GETCUSTOMERFORMS(id);

    return results;
  }

  static async UpdateCustomer(id, object) {
    const results = await CallApi.UPDATECUSTOMERS(id, object);

    return results;
  }

  static async NewCustomer(object) {
    const results = await CallApi.NEWCUSTOMERS(object);

    return results;
  }
  static async sendInfoMail(id, object) {
    const results = await CallApi.SENDINFOMAIL(id, object);

    return results;
  }
  static async dontSendInfo(id, object) {
    const results = await CallApi.DONTSENDINFO(id, object);

    return results;
  }
  static async sendFinalInfo(object) {
    const results = await CallApi.SENDFINALEMAIL(object);

    return results;
  }
  static async getEmailInfo(object) {
    const results = await CallApi.GETEMAILINFO(object);

    return results;
  }
  static async getAllReminders() {
    const results = await CallApi.GETALLEMAILINFO();

    return results;
  }


  static async GetSelects() {
    const results = await CallApi.GETSELECTS();

    return results;
  }

  static async CustomerDesactive(id, enabled) {
    const results = await CallApi.CUSTOMERDESACTIVE(id, enabled);

    return results;
  }

  /** Contacts */
  static async GetContacts(page, id, object) {
    const results = await CallApi.GETCONTACTS(page, id, object);

    return results;
  }

  static async NewContacts(object) {
    const results = await CallApi.NEWCONTACTS(object);

    return results;
  }

  static async UpdateContacts(id, object) {
    const results = await CallApi.UPDATECONTACTS(id, object);

    return results;
  }

  static async DestroyContact(id) {
    const results = await CallApi.DESTROYCONTACT(id);

    return results;
  }

  /** Emails */
  static async GetEmails(page, id, object) {
    const results = await CallApi.GETEMAILS(page, id, object);

    return results;
  }

  static async NewEmails(object) {
    const results = await CallApi.NEWEMAILS(object);

    return results;
  }

  static async UpdateEmails(id, object) {
    const results = await CallApi.UPDATEEMAILS(id, object);

    return results;
  }

  static async DestroyEmails(id) {
    const results = await CallApi.DESTROYEMAILS(id);

    return results;
  }

  /** Did */
  static async GetDidInternational(page, id, object) {
    const results = await CallApi.GETDIDINTERNATIONAL(page, id, object);

    return results;
  }

  static async GetDidCallingCard(page, id, object) {
    const results = await CallApi.GETDIDCALLINGCARD(page, id, object);

    return results;
  }

  static async getSelect() {
    const results = await CallApi.GETSELECT();

    return results;
  }

  static async NewDid(object) {
    const results = await CallApi.NEWDID(object);

    return results;
  }

  static async NewDidMobile(object) {
    const results = await CallApi.NEWDIDMOBILE(object);

    return results;
  }

  static async UpdateEditDid(id, object) {
    const results = await CallApi.UPDATEDID(id, object);

    return results;
  }

  static async UpdateEditDidMobile(id, object) {
    const results = await CallApi.UPDATEDIDMOBILE(id, object);

    return results;
  }

  static async DestroyDid(id) {
    const results = await CallApi.DESTROYDID(id);

    return results;
  }
  static async getDidOrders(id) {
    const results = await CallApi.GETDIDORDERS(id);

    return results;
  }
  static async updateOrderStatus(object) {
    const results = await CallApi.UPDATEORDERSTATUS(object);

    return results;
  }
  static async sendEmail(object) {
    const results = await CallApi.SENDEMAILORDERNOTE(object);

    return results;
  }

  static async EnabledChange(id, type, array) {
    const results = await CallApi.ENABLEDCHANGE(id, type, array);

    return results;
  }

  static async CreateEmails(id, object) {
    const results = await CallApi.CREATEEMAILS(id, object);

    return results;
  }

  static async ListEmails(id, type) {
    const results = await CallApi.LISTEMAILS(id, type);

    return results;
  }

  static async EmailsDestroy(id, idCustomer, type) {
    const results = await CallApi.EMAILSDESTROY(id, idCustomer, type);

    return results;
  }

  static async GetIdAllDid(id, type) {
    const results = await CallApi.GETIDDID(id, type);

    return results;
  }

  /** Diallers */
  static async GetDiallers(page, id, object) {
    const results = await CallApi.GETDIALLERS(page, id, object);

    return results;
  }

  static async UpdateDialler(id, object) {
    const results = await CallApi.UPDATEDIALLER(id, object);

    return results;
  }

  static async NewDialler(id, object) {
    const results = await CallApi.NEWDIALLER(id, object);

    return results;
  }

  static async DestroyDiallers(id) {
    const results = await CallApi.DESTROYDIALLERS(id);

    return results;
  }
  /** 3cxPortals */
  static async Get3cx(id, object) {
    const results = await CallApi.GET3CX(id, object);

    return results;
  }

  static async Update3cx(id, object) {
    const results = await CallApi.UPDATE3CX(id, object);

    return results;
  }

  static async New3cx(id, object) {
    const results = await CallApi.NEW3CX(id, object);

    return results;
  }

  static async Destroy3cx(id) {
    const results = await CallApi.DESTROY3CX(id);

    return results;
  }

  /** SipTrunk */

  static async GetSipTrunk(id, object) {
    const results = await CallApi.GETSipTrunk(id, object);

    return results;
  }

  static async UpdateSipTrunk(id, object) {
    const results = await CallApi.UPDATESipTrunk(id, object);

    return results;
  }
  static async sendCredentials(id, object) {
    const results = await CallApi.SENDCREDENTIALS(id, object);

    return results;
  }

  static async UpdateCustomerSip(id, object) {
    const results = await CallApi.UPDATESipTrunkCustomer(id, object);

    return results;
  }

  static async NewSipTrunk(id, object) {
    const results = await CallApi.NEWSipTrunk(id, object);

    return results;
  }

  static async DestroySipTrunk(id) {
    const results = await CallApi.DESTROYSipTrunk(id);

    return results;
  }

  static async EnabledChangeSipTrunk(type, array) {
    const results = await CallApi.ENABLEDCHANGESIPTRUNK(type, array);

    return results;
  }

  /** Extension PBX */
  static async GetExtensionPBX(page, id, object) {
    const results = await CallApi.GETEXTENSIONPBX(page, id, object);

    return results;
  }

  static async NewExtensionPBX(id, object) {
    const results = await CallApi.NEWEXTENSIONPBX(id, object);

    return results;
  }

  static async UpdateExtensionPBX(id, object) {
    const results = await CallApi.UPDATEEXTENSIONPBX(id, object);

    return results;
  }

  static async UpdateUsername(id, object) {
    const results = await CallApi.UPDATEUSERNAME(id, object);

    return results;
  }

  static async DestroyEntensionPBX(id) {
    const results = await CallApi.DESTROYEXTENSIONPBX(id);

    return results;
  }

  static async EnabledChangeExtension(type, array, id) {
    const results = await CallApi.ENABLEDCHANGEEXTENSION(type, array, id);

    return results;
  }


  static async GetIdAll(id) {
    const results = await CallApi.GETIDALL(id);

    return results;
  }

  static async sendCredential(id, object) {
    const results = await CallApi.SENDCREDENTIAL(id, object);

    return results;
  }

  //PAYMENTS
  static async getPayments(id, page, object) {
    const results = await CallApi.GETPAYMENTS(id, page, object);

    return results;
  }
  static async NewPayment(object, id) {
    const results = await CallApi.NEWPAYMENT(object, id);

    return results;
  }
  static async SendEmail(object, id) {
    const results = await CallApi.SENDEMAIL(object, id);

    return results;
  }
  static async getPaymentTypes() {
    const results = await CallApi.GETPAYMENTSTYPES();
    return results;
  }
  static async destroyPayments(id, object) {
    const results = await CallApi.DESTROYCPAYMENTS(id, object);

    return results;
  }
  static async getBalance(object) {
    const results = await CallApi.UPDATEBALANCE(object);

    return results;
  }

  //CHARGES
  static async getProductTypes(object) {
    const results = await CallApi.GETPRODUCTTYPES(object);
    return results;
  }

  static async getCharges(id, page, object) {
    const results = await CallApi.GETCHARGES(id, page, object);

    return results;
  }

  static async getAgentCommision(object) {
    const results = await CallApi.GETAGENTCOMMISION(object);

    return results;
  }
  static async sendAndGenerate(id, object) {
    const results = await CallApi.SENDANDGENERATE(id, object);

    return results;
  }
  static async UpdateCharge(id, object) {
    const results = await CallApi.UPDATECHARGE(id, object);

    return results;
  }
  static async destroyCharge(id, object) {
    const results = await CallApi.DESTROYCHARGE(id, object);

    return results;
  }
  static async getChargeInfo(id, object) {
    const results = await CallApi.GETCHARGEINFO(id, object);

    return results;
  }

  //INVOICES

  static async getInvoices(page, object) {
    const results = await CallApi.GETINVOICES(page, object);

    return results;
  }

  static async getInvoiceInfo(object) {
    const results = await CallApi.GETINVOICEINFO(object);

    return results;
  }
  static async generate(object) {
    const results = await CallApi.GENERATE(object);

    return results;
  }

  static async getReport(object) {
    const results = await CallApi.GETREPORT(object);

    return results;
  }
  static async getInfoInvoices(object) {
    const results = await CallApi.GETINFOINVOICES(object);

    return results;
  }
  static async makeInvoices(object) {
    const results = await CallApi.MAKEINVOICES(object);

    return results;
  }

  /** Commissions */
  static async GetCommissions(id, page) {
    const results = await CallApi.GETCOMMISSIONS(id, page);

    return results;
  }

  static async UpdateCommissions(id, object) {
    const results = await CallApi.UPDATECOMMISSIONS(id, object);

    return results;
  }

  static async NewCommissions(id, object) {
    const results = await CallApi.NEWCOMMISSIONS(id, object);

    return results;
  }

  static async DestroyCommissions(id) {
    const results = await CallApi.DESTROYCOMMISSIONS(id);

    return results;
  }

  /** Notes */
  static async GetNotes(id, page, object) {
    const results = await CallApi.GETNOTES(id, page, object);

    return results;
  }

  static async UpdateNotes(id, object) {
    const results = await CallApi.UPDATENOTES(id, object);

    return results;
  }

  static async NewNotes(id, object) {
    const results = await CallApi.NEWNOTES(id, object);

    return results;
  }

  static async DestroyNotes(id) {
    const results = await CallApi.DESTROYNOTES(id);

    return results;
  }

  /** Audits */
  static async GetAudits(page, object) {
    const results = await CallApi.GETAUDITS(page, object);

    return results;
  }

  /** Count */
  static async Count(id) {
    const results = await CallApi.COUNT(id);

    return results;
  }

  /** Packages */
  static async GetPackages(id, page) {
    const results = await CallApi.GETPACKAGES(id, page);

    return results;
  }
  static async UpdatePackages(id, object) {
    const results = await CallApi.UPDATEPACKAGES(id, object);
    return results;
  }
  static async UpdateMinutes(object) {
    const results = await CallApi.UPDATEMINUTES(object);
    return results;
  }

  static async DestroyPackage(id) {
    const results = await CallApi.DESTROYPACKAGE(id);
    return results;
  }

  

  static async sendAuthExten(id, array) {
    const results = await CallApi.SENDEXTCREDENTIAL(id, array);

    return results;
  }

  // Payment Logs

  static async getLogs(page, object) {
    const results = await CallApi.GETLOGS(page, object);

    return results;
  }
  static async open(object) {
    const results = await CallApi.OPEN(object);

    return results;
  }
  static async sendSuspend(customerId) {
    const results = await CallApi.SENDSUSPEND(customerId);

    return results;
  }

  //Balance Logs
  static async ListBalanceLogs(page, id, object) {
    const results = await CallApi.GETBALANCELOGS(page, id, object);

    return results;
  }
}
