/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import { FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Input from '../../Utils/Input';
import SelectCmx from '../../Utils/Select';
import CheckBox from '../../Utils/Checkbox';

class ModalNewCustomers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customersId: '',
      agents: '',
      identification_types: '',
      languages: '',
      customers_prices: '',
      payment_methods: '',
      charge_types: '',
      currencies: '',
      payment_type: '',
      vats: '',
      customers_name: '',
      balance: '',
      threshold: '',
      identification_number: '',
      amount: '',
      isCheckedDisabled: false,
      start_date: '',
      address: '',
      invoice_name: '',
      payment_methods_name: '',
      payment_types_name: '',
      vats_name: '',
      vats_id: '',
      customer_prices_name: '',
      contact_name: '',
      contact_number: '',
      agents_name: '',
      email: '',
      languages_name: '',
      charge_types_name: '',
      connection_time: '',
      currencie_name: '',
      direct_debit_bank_account: '',
      identification_types_name: '',
      isCheckedEmailProcess: false,
      isCheckedDps: false,
      isCheckedGDPR: false,
      isCheckedInvoicing: false,
      isCheckedEmailLow: false,
      isCheckedEmailMonthly: false,
      isCheckedAgentsInvoicing: false,
      isCheckedNoErrorNegative: false,
      isCheckedNoErrorPositive: false,
      modal: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.getFormsCustomers = this.getFormsCustomers.bind(this);
  }

  initialState() {
    const {
      select: { data },
    } = this.props;

    // console.log(this.props.item);

    const agents = ModificationArray.modification(data.selects.agents);
    const identification_types = ModificationArray.modification(data.selects.identification_types);
    const languages = ModificationArray.modification(data.selects.languages);
    const customers_prices = ModificationArray.modification(data.selects.customers_prices);
    const payment_methods = ModificationArray.modification(data.selects.payment_methods);
    const charge_types = ModificationArray.modification(data.selects.charge_types);
    const charge_accuracies = ModificationArray.modification(data.selects.charge_accuracies);
    const currencies = ModificationArray.modification(data.selects.currencies);
    const payment_type = ModificationArray.modification(data.selects.payment_type);
    const vats = ModificationArray.modification(data.selects.vats);
    const countries = ModificationArray.modification(data.selects.countries);
    this.setState({
      agents: agents,
      identification_types: identification_types,
      languages: languages,
      customers_prices: customers_prices,
      payment_methods: payment_methods,
      charge_types: charge_types,
      charge_accuracies: charge_accuracies,
      currencies: currencies,
      payment_type: payment_type,
      vats: vats,
      customersId: '',
      customers_name: '',
      balance: '',
      threshold: '',
      identification_number: '',
      amount: '',
      isCheckedDisabled: false,
      start_date: '',
      address: '',
      invoice_name: '',
      payment_methods_name: '',
      payment_types_name: '',
      vats_name: '',
      vats_id: '',
      customer_prices_name: '',
      contact_name: '',
      contact_number: '',
      agents_name: '',
      email: '',
      languages_name: '',
      charge_types_name: '',
      connection_time: '',
      currencie_name: '',
      direct_debit_bank_account: '',
      identification_types_name: '',
      isCheckedEmailProcess: false,
      isCheckedDps: false,
      isCheckedGDPR: false,
      isCheckedInvoicing: false,
      isCheckedEmailLow: false,
      isCheckedEmailMonthly: false,
      isCheckedAgentsInvoicing: false,
      isCheckedNoErrorNegative: false,
      isCheckedNoErrorPositive: false,
      countries: countries,
    });
  }

  handleChange(e, nameI, index) {
    const {
      isCheckedEmailProcess,
      isCheckedDps,
      isCheckedGDPR,
      isCheckedInvoicing,
      isCheckedEmailLow,
      isCheckedEmailMonthly,
      isCheckedAgentsInvoicing,
      isCheckedNoErrorNegative,
      isCheckedNoErrorPositive,
      isCheckedDisabled,
    } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 3:
        this.setState({ [nameI]: !isCheckedEmailProcess });
        break;
      case 4:
        this.setState({ [nameI]: !isCheckedDps });
        break;
      case 5:
        this.setState({ [nameI]: !isCheckedGDPR });
        break;
      case 6:
        this.setState({ [nameI]: !isCheckedInvoicing });
        break;
      case 7:
        this.setState({ [nameI]: !isCheckedEmailLow });
        break;
      case 8:
        this.setState({ [nameI]: !isCheckedEmailMonthly });
        break;
      case 9:
        this.setState({ [nameI]: !isCheckedAgentsInvoicing });
        break;
      case 10:
        this.setState({ [nameI]: !isCheckedNoErrorNegative });
        break;
      case 11:
        this.setState({ [nameI]: !isCheckedNoErrorPositive });
        break;
      case 12:
        this.setState({ [nameI]: !isCheckedDisabled });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async save() {
    const {
      customers_name,
      agents_name,
      balance,
      contact_name,
      contact_number,
      address,
      identification_types_name,
      identification_number,
      email,
      invoice_name,
      languages_name,
      customer_prices_name,
      payment_methods_name,
      charge_types_name,
      vats_name,
      threshold,
      charge_accuracies_name,
      connection_time,
      currencie_name,
      payment_types_name,
      direct_debit_bank_account,
      amount,
      isCheckedEmailProcess,
      isCheckedDps,
      isCheckedGDPR,
      isCheckedInvoicing,
      isCheckedDisabled,
      isCheckedEmailLow,
      isCheckedEmailMonthly,
      isCheckedAgentsInvoicing,
      isCheckedNoErrorNegative,
      isCheckedNoErrorPositive,
      country,
    } = this.state;

    const object = {
      customers_name: customers_name,
      balance: balance,
      threshold: threshold,
      identification_number: identification_number,
      top_up: amount,
      enabled: isCheckedDisabled ? 1 : 0,
      address: address,
      invoice_name: invoice_name,
      contact_name: contact_name,
      contact_number: contact_number,
      agents_name: agents_name.value,
      email: email,
      connection_time: connection_time,
      direct_debit_bank_account: direct_debit_bank_account,
      email_process_file: isCheckedEmailProcess ? 1 : 0,
      round_dps: isCheckedDps ? 1 : 0,
      gdpr_concent: isCheckedGDPR ? 1 : 0,
      use_invoicing: isCheckedInvoicing ? 1 : 0,
      email_low_balance: isCheckedEmailLow ? 1 : 0,
      email_process_monthly_file: isCheckedEmailMonthly ? 1 : 0,
      use_agent_invoicing: isCheckedAgentsInvoicing ? 1 : 0,
      no_error_on_negative: isCheckedNoErrorNegative ? 1 : 0,
      no_error_on_positive: isCheckedNoErrorPositive ? 1 : 0,
      identification_id: identification_types_name.value,
      language_id: languages_name.value,
      customer_price_id: customer_prices_name.value,
      payment_method_id: payment_methods_name.value,
      charge_id: charge_types_name.value,
      vat_id: vats_name.value,
      charge_accuracy_id: charge_accuracies_name.value,
      currency_id: currencie_name.value,
      payment_type_id: payment_types_name.value,
      country: country.value,
    };

    const response = await DataApi.NewCustomer(object);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    this.toggle();
  }

  toggle() {
    this.initialState();
    this.getFormsCustomers();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async getFormsCustomers() {
    const id = this.props.id;

    const data = await DataApi.GetCustomerForms(id);

    // console.log(data.data.customers);

    const { customers } = data.data;

    this.setState({
      customers: data,
      customersId: id,
      agentId: data.customers_agent_id,
      enabled: data.data.customers.enabled,
      lock: data.data.status,
      agents_name: { label: customers.agents_name, value: customers.agents_id },
      customer_prices_name: { label: customers.customer_prices_name, value: customers.customer_prices_id },
      payment_methods_name: { label: customers.payment_methods_name, value: customers.payment_methods_id },
      charge_types_name: { label: customers.charge_types_name, value: customers.charge_types_id },
      vats_name: { label: customers.vats_name, value: customers.vats_id },
      charge_accuracies_name: { label: customers.charge_accuracies_name, value: customers.charge_accuracies_id },
      connection_time: customers.connection_time,
      currencie_name: { label: customers.currencie_name, value: customers.currencie_id },
      payment_types_name: { label: customers.payment_types_name, value: customers.payment_types_id },
      amount: customers.amount,
      isCheckedEmailProcess: customers.email_process_file ? true : false,
      isCheckedDps: customers.round_dps ? true : false,
      isCheckedGDPR: customers.gdpr_concent ? true : false,
      isCheckedInvoicing: customers.use_invoicing ? true : false,
      isCheckedEmailLow: customers.email_low_balance ? true : false,
      isCheckedEmailMonthly: customers.email_process_monthly_file ? true : false,
      isCheckedAgentsInvoicing: customers.use_agent_invoicing ? true : false,
      isCheckedNoErrorNegative: customers.no_error_on_negative ? true : false,
      isCheckedNoErrorPositive: customers.no_error_on_positive ? true : false,
    });
  }

  render() {
    const {
      customers_name,
      agents,
      agents_name,
      balance,
      contact_name,
      contact_number,
      address,
      identification_types_name,
      identification_types,
      identification_number,
      email,
      invoice_name,
      languages_name,
      languages,
      customers_prices,
      customer_prices_name,
      payment_methods,
      payment_methods_name,
      charge_types_name,
      charge_types,
      vats_name,
      vats,
      threshold,
      charge_accuracies,
      charge_accuracies_name,
      connection_time,
      currencies,
      currencie_name,
      payment_type,
      payment_types_name,
      direct_debit_bank_account,
      amount,
      isCheckedEmailProcess,
      isCheckedDps,
      isCheckedGDPR,
      isCheckedInvoicing,
      isCheckedEmailLow,
      isCheckedEmailMonthly,
      isCheckedAgentsInvoicing,
      isCheckedNoErrorNegative,
      isCheckedNoErrorPositive,
      country,
      countries,
    } = this.state;

    return (
      <React.Fragment>
        <i onClick={this.toggle} className="mdi mdi-plus-outline mdi-24px"></i>

        <Modal className="modal-new-agents" isOpen={this.state.modal}>
          <div className="card-header-border-bottom">
            <ModalHeader toggle={this.toggle}>
              <h5>New User</h5>
            </ModalHeader>
          </div>
          <ModalBody>
            <FormGroup>
              <div className="card-body">
                <h3 className="">Main Details</h3>
                <br />

                <div className="row bottom-margin-row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <Input
                        nameI={'customers_name'}
                        value={customers_name}
                        change={this.handleChange}
                        name={'Name'}
                        state={customers_name}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <Input
                            nameI={'contact_name'}
                            value={contact_name}
                            change={this.handleChange}
                            name={'Contact name'}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <Input
                            nameI={'contact_number'}
                            value={contact_number}
                            change={this.handleChange}
                            name={'Contact number'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <Input
                        nameI={'balance'}
                        value={balance}
                        type={'number'}
                        change={this.handleChange}
                        name={'Balance'}
                        state={balance}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <SelectCmx
                        index={1}
                        nameI={'agents_name'}
                        value={agents_name}
                        change={this.handleChange}
                        name={'Agent'}
                        options={agents}
                      />
                    </div>
                  </div>
                </div>

                <div className="card-body pt-5 border-top">
                  <h3>Basic Info</h3>
                  <br />
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'address'} value={address} change={this.handleChange} name={'Address'} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <Input nameI={'email'} value={email} change={this.handleChange} name={'Email'} />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <Input
                              nameI={'invoice_name'}
                              value={invoice_name}
                              change={this.handleChange}
                              name={'Invoice name'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'identification_types_name'}
                          value={identification_types_name}
                          change={this.handleChange}
                          name={'Identification type'}
                          options={identification_types}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <Input
                          nameI={'identification_number'}
                          value={identification_number}
                          change={this.handleChange}
                          name={'Identification number'}
                        />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'languages_name'}
                          value={languages_name}
                          change={this.handleChange}
                          name={'Language'}
                          options={languages}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'country'}
                          value={country}
                          change={this.handleChange}
                          name={'Country'}
                          options={countries}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-5 border-top">
                  <h3>Invoicing Details</h3>
                  <br />
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'customer_prices_name'}
                          value={customer_prices_name}
                          change={this.handleChange}
                          name={'Customer Price'}
                          options={customers_prices}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'payment_methods_name'}
                          value={payment_methods_name}
                          change={this.handleChange}
                          name={'Payment method'}
                          options={payment_methods}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'charge_types_name'}
                          value={charge_types_name}
                          change={this.handleChange}
                          name={'Charge type'}
                          options={charge_types}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'vats_name'}
                          value={vats_name}
                          change={this.handleChange}
                          name={'Vat'}
                          options={vats}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'threshold'} value={threshold} change={this.handleChange} name={'Threshold'} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <SelectCmx
                          index={1}
                          nameI={'charge_accuracies_name'}
                          value={charge_accuracies_name}
                          change={this.handleChange}
                          name={'Charge accuracy'}
                          options={charge_accuracies}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input
                          nameI={'connection_time'}
                          value={connection_time}
                          type={'number'}
                          change={this.handleChange}
                          name={'Connection time'}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <SelectCmx
                              index={1}
                              nameI={'currencie_name'}
                              value={currencie_name}
                              change={this.handleChange}
                              name={'Currency'}
                              options={currencies}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <SelectCmx
                              index={1}
                              nameI={'payment_types_name'}
                              value={payment_types_name}
                              change={this.handleChange}
                              name={'Payment type'}
                              options={payment_type}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input
                          nameI={'direct_debit_bank_account'}
                          value={direct_debit_bank_account}
                          change={this.handleChange}
                          name={'Direct debit bank account'}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'amount'} value={amount} change={this.handleChange} name={'Top up'} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-5 border-top">
                  <h3>Flags</h3>
                  <br />
                  <div className="form-group row">
                    <div className="col-sm-6">
                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedGDPR'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedGDPR}
                          index={5}
                        />
                        <label className="form-check-label">GDPR concent</label>
                      </div>
                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedInvoicing'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedInvoicing}
                          index={6}
                        />
                        <label className="form-check-label">Use invoicing</label>
                      </div>
                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedEmailProcess'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedEmailProcess}
                          index={3}
                        />
                        <label className="form-check-label">Email process file</label>
                      </div>
                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedDps'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedDps}
                          index={4}
                        />
                        <label className="form-check-label">Round dps</label>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedEmailLow'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedEmailLow}
                          index={7}
                        />
                        <label className="form-check-label">Email low balance</label>
                      </div>
                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedEmailMonthly'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedEmailMonthly}
                          index={8}
                        />
                        <label className="form-check-label">Email monthly file</label>
                      </div>
                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedAgentsInvoicing'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedAgentsInvoicing}
                          index={9}
                        />
                        <label className="form-check-label">Use agent invoicing</label>
                      </div>

                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedNoErrorNegative'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedNoErrorNegative}
                          index={10}
                        />
                        <label className="form-check-label">No error on negative</label>
                      </div>

                      <div className="control-indicator">
                        <CheckBox
                          nameI={'isCheckedNoErrorPositive'}
                          toggleChange={this.handleChange}
                          isChecked={isCheckedNoErrorPositive}
                          index={11}
                        />
                        <label className="form-check-label">No error on positive</label>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="form-footer pt-5 border-top">
                      <button onClick={this.save} type="submit" className="btn btn-primary btn-block">
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </FormGroup>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewCustomers;
