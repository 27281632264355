import React from 'react';
import { Col, Row } from 'reactstrap';
import { Card } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import DataApi from '../../../megacall/services/Customers/DataApi';
import TableCustomers from '../../elements/CustomersElements/TableCustomers';
import Input from '../../elements/Utils/Input';
import ModalNewCustomers from '../../elements/CustomersElements/Modal/ModalNewCustomers';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import SelectCmx from '../../elements/Utils/Select';

class index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      enabled: 1,
      id: '',
      agent: { label: 'All ', value: '' },
      contactName: '',
      type: '',
      contactNumber: '',
      cif: '',
      email: '',
      phoneMobile: '',
      cli: '',
      select: '',
      selectAgent: '',
      order: '',
      selectOne: true,
      selectTwo: false,
      total: '',
      sortBy: '',
      colors: { label: 'All', value: '' },
      selectColors: [
        { label: 'All', value: '' },
        { label: 'White', value: '1' },
        { label: 'Blue', value: '2' },
        { label: 'Green', value: '3' },
        { label: 'Orange', value: '4' },
        { label: 'Yellow', value: '5' },
        { label: 'Red', value: '6' },
        { label: 'Gray', value: '7' },
      ],
      selectPayment: [
        { label: 'All', value: '' },
        { label: 'Bank Transfer', value: 1 },
        { label: 'Cash Deposit', value: 2 },
        { label: 'Cash Office', value: 3 },
        { label: 'Credit Card', value: 4 },
        { label: 'Direct Debit', value: 5 },
        { label: 'Paypal', value: 6 },
        { label: 'Other', value: 7 },
      ],
      paymentType: { label: 'All', value: '' },
      click: 1,
      perPage: null,
    };
    this.edit = this.edit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getSelect = this.getSelect.bind(this);
    this.getCustomersData = this.getCustomersData.bind(this);
    this.sort = this.sort.bind(this);
    this.clean = this.clean.bind(this);
  }

  /** cambia el estado y funciona de buscador */
  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState(prevState => ({ ...prevState.nameI, [nameI]: e }));
        if (nameI === 'agent') {
          console.log(e);
          sessionStorage.setItem('filter', JSON.stringify(e));
        }
        setTimeout(() => {
          this.getCustomersData(this.state.page);
        }, 500);

        setTimeout(() => {
          this.getCustomersData(this.state.page);
        }, 500);
        break;
      case 2:
        this.setState({ selectOne: true, selectTwo: false, enabled: 1 });

        setTimeout(() => {
          this.getCustomersData(this.state.page);
        }, 500);
        break;
      case 3:
        this.setState({ selectOne: false, selectTwo: true, enabled: 0 });

        setTimeout(() => {
          this.getCustomersData(this.state.page);
        }, 500);
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });

        setTimeout(() => {
          this.getCustomersData(this.state.page);
        }, 500);
        break;
    }
  }

  async componentDidMount() {
    await this.getSelect();
    const filter = JSON.parse(sessionStorage.getItem('filter'));
    console.log(filter);
    if (filter != null) {
      this.setState({ agent: filter });
    }
    await this.getCustomersData(1);
  }

  /** Crea el select */
  async getSelect() {
    const select = await DataApi.GetSelects();

    this.setState({ select: select });
  }

  /** inicializa el estado */
  async getCustomersData(page) {
    const {
      agent,
      id,
      enabled,
      type,
      cif,
      contactName,
      contactNumber,
      email,
      phoneVirtual,
      phoneMobile,
      name,
      cli,
      colors,
      paymentType,
      sortBy,
      perPage,
    } = this.state;

    const json = {
      id: id,
      agent: agent.value,
      enabled: enabled,
      type: type,
      cif: cif,
      cli: cli,
      contactName: contactName,
      contactNumber: contactNumber,
      email: email,
      name: name,
      phoneMobile: phoneMobile,
      phoneVirtual: phoneVirtual,
      colour: colors.value,
      paymentType: paymentType.value,
      perPage: perPage,
      first: this.state.first === false ? null : true,
      sortBy: sortBy,
      order: !this.state.order,
    };

    this.setState({ page: page });

    const results = await DataApi.GetCustomers(page, json);

    let selectAgent = ModificationArray.modification(results.data.agents);
    selectAgent.reverse();
    selectAgent.push({ label: 'All ', value: '' });
    selectAgent.reverse();
    this.setState({ data: results.data.customers, selectAgent: selectAgent });
  }

  async sort(sortBy) {
    // const {
    //   customersId: { response },
    // } = this.props;
    let { click } = this.state;
    // if (this.state.sortBy !== '') {
    //   sortBy = this.state.sortBy;
    // }

    const {
      agent,
      id,
      enabled,
      type,
      cif,
      contactName,
      contactNumber,
      email,
      phoneVirtual,
      phoneMobile,
      name,
      cli,
      colors,
      order,
      paymentType,
      perPage,
    } = this.state;

    this.setState({ sortBy: sortBy, first: false });

    if (click >= 1) {
      if (this.state.order === true) {
        this.setState({ order: false });
      } else {
        this.setState({ order: true });
      }
    }

    const json = {
      id: id,
      agent: agent.value,
      enabled: enabled,
      type: type,
      cif: cif,
      cli: cli,
      contactName: contactName,
      contactNumber: contactNumber,
      email: email,
      name: name,
      phoneMobile: phoneMobile,
      phoneVirtual: phoneVirtual,
      paymentType: paymentType.value,
      colour: colors.value,
      sortBy: sortBy,
      order: order,
      perPage: perPage,
    };

    const results = await DataApi.GetCustomers(this.state.page, json);

    let selectAgent = ModificationArray.modification(results.data.agents);
    selectAgent.reverse();
    selectAgent.push({ label: 'All ', value: '' });
    selectAgent.reverse();
    click++;

    this.setState({ data: results.data.customers, selectAgent: selectAgent, click: click });
  }
  /** Te envia al componente TabsCustomers y manda el id del customers */
  edit(id) {
    this.props.history.push(`/customers/options`, { response: id });
  }

  clean() {
    sessionStorage.removeItem('filter');
    console.log('hola');
    this.setState({
      agent: { label: 'All ', value: '' },
      name: '',
      cif: '',
      email: '',
      contactNumber: '',
      cli: '',
      id: '',
      colors: { label: 'All ', value: '' },
    });
    setTimeout(() => {
      this.getCustomersData(1);
    }, 500);
  }

  render() {
    const {
      agent,
      cif,
      email,
      contactNumber,
      select,
      id,
      cli,
      name,
      data: { data },
      selectAgent,
      selectOne,
      selectTwo,
      colors,
      selectColors,
      defaultFilter,
      selectPayment,
      paymentType,
      perPage,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    return (
      <React.Fragment>
        <div>
          <Row>
            <h1 className="title-table">CUSTOMERS</h1>
            <Col md={12}>
              <Card>
                <div className="container-input-modal">
                  <ModalNewCustomers id={id} data={data} select={select} />
                </div>

                <div className="container-search-customers">
                  <SelectCmx
                    value={agent}
                    change={e => this.handleChange(e, 'agent', 1)}
                    placeholder={'Agent'}
                    options={selectAgent}
                    defaultValue={defaultFilter}
                  />
                  <Input nameI={'name'} value={name} change={this.handleChange} placeholder={'Name'} />
                  <Input nameI={'cif'} value={cif} change={this.handleChange} placeholder={'CIF'} />
                </div>
                <div className="container-search-customers">
                  <Input nameI={'email'} value={email} change={this.handleChange} placeholder={'Email'} />
                  <Input
                    nameI={'contactNumber'}
                    value={contactNumber}
                    change={this.handleChange}
                    placeholder={'Contact'}
                    type={'number'}
                  />
                  <Input nameI={'cli'} value={cli} change={this.handleChange} placeholder={'CLI'} />
                </div>
                <div className="container-search-customers">
                  <Input
                    nameI={'id'}
                    value={id}
                    change={this.handleChange}
                    placeholder={'id'}
                    type={'number'}
                    className="mt-3"
                  />
                  <div className="form-group">
                    <div className="form-check form-check-inline" style={{ marginLeft: '110px', marginTop: '40px' }}>
                      <input
                        className="form-check-input"
                        type="radio"
                        value={'1'}
                        name="contact"
                        onClick={e => {
                          this.handleChange(e, null, 2);
                        }}
                        defaultChecked={selectOne}
                      />
                      <label className="form-check-label">Enable&nbsp;</label>
                      <input
                        className="form-check-input"
                        type="radio"
                        value={'0'}
                        name="contact"
                        onClick={e => {
                          this.handleChange(e, null, 3);
                        }}
                        defaultChecked={selectTwo}
                      />
                      <label className="form-check-label">Disable&nbsp;</label>
                    </div>
                  </div>
                  <SelectCmx
                    value={colors}
                    change={e => this.handleChange(e, 'colors', 1)}
                    placeholder={'Color'}
                    options={selectColors}
                  />
                  <SelectCmx
                    value={paymentType}
                    change={e => this.handleChange(e, 'paymentType', 1)}
                    placeholder={'Payment Type'}
                    options={selectPayment}
                  />
                </div>
                <div className="col-2 ml-5">
                  <Input nameI={'perPage'} value={perPage} change={this.handleChange} placeholder={'per page'} />
                </div>
                <div className="container-item mb-3 ">
                  <button onClick={() => this.clean()} className="btn btn-primary btn-small">
                    Clean Filters
                  </button>
                </div>
                <div className="container-table-all">
                  <TableCustomers data={data} edit={this.edit} sort={this.sort} select={select} />
                </div>
                {data ? (
                  <Pagination
                    totalItemsCount={total}
                    onChange={page => this.getCustomersData(page)}
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                ) : (
                  ''
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default index;
