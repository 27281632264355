/* eslint-disable array-callback-return */
import React from 'react';

import { Table } from 'reactstrap';

import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import ModalNewEmails from './Modal/ModalNewEmails';
import ModalEditEmails from './Modal/ModalEditEmails';
import Input from '../../Utils/Input';

import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';

class TableEmails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      name: '',
    };
    this.getEmails = this.getEmails.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.getEmails();
  }

  /** Seteo el estado */
  async getEmails(page = 1) {
    const { customersId } = this.props;
    const { name } = this.state;

    const object = {
      email: name,
    };

    const results = await DataApi.GetEmails(page, customersId.response, object);
    // console.log(results);
    this.setState({ data: results.data });
  }

  async handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    setTimeout(() => {
      this.getEmails();
    }, 500);
  }

  async destroyEmails(id) {
    const response = await DataApi.DestroyEmails(id);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.getEmails();
  }

  render() {
    const { customersId, lock } = this.props;
    const {
      data: { data },
      name,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container-search-contact">
            <Input nameI={'name'} value={name} change={this.handleChange} placeholder={'Name'} />
          </div>
          <div className="container-table-all">
            <Table className="table table-striped">
              <thead>
                <tr className="container-item">
                  <th scope="col">Email</th>
                  <th scope="col">Type</th>
                  <th scope="col">Send Email?</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => {
                  return (
                    <tr key={index} className="container-item">
                      <td className="container-item-2">{item.email}</td>
                      <td className="container-item-2">
                        {item.email_type_id === 1
                          ? 'Authorized'
                          : item.email_type_id === 2
                          ? 'Support'
                          : item.email_type_id === 3
                          ? 'Accounts'
                          : item.email_type_id === 4
                          ? 'Invoice'
                          : ''}
                      </td>
                      <td className="container-item-4">
                        {item.email_type_id !== 1 ? '' : item.main === 1 ? 'Yes' : 'No'}
                      </td>
                     
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <Pagination
            totalItemsCount={total}
            onChange={page => this.getEmails(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-input-modal">
          <ModalNewEmails customersId={customersId} getEmails={this.getEmails} />
        </div>

        <div className="container-search-contact">
          <Input nameI={'name'} value={name} change={this.handleChange} placeholder={'Name'} />
        </div>
        <div className="container-table-all">
          <Table className="table table-striped">
            <thead>
              <tr className="container-item">
                <th scope="col">Email</th>
                <th scope="col">Type</th>
                <th scope="col">Send Email?</th>
                <th scope="col actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => {
                return (
                  <tr key={index} className="container-item">
                    <td className="container-item-2">{item.email}</td>
                    <td className="container-item-2">
                      {item.email_type_id === 1
                        ? 'Authorized'
                        : item.email_type_id === 2
                        ? 'Support'
                        : item.email_type_id === 3
                        ? 'Accounts'
                        : item.email_type_id === 4
                        ? 'Invoice'
                        : ''}
                    </td>
                    <td className="container-item-4">
                      {item.email_type_id !== 1 ? '' : item.main === 1 ? 'Yes' : 'No'}
                    </td>
                    <td className="container-item d-flex">
                      <ModalEditEmails data={item} customersId={customersId} getEmails={this.getEmails} />
                      <MDBIcon
                        icon="trash"
                        size="lg"
                        className="trash ml-2"
                        onClick={() => this.destroyEmails(item.id)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <Pagination
          totalItemsCount={total}
          onChange={page => this.getEmails(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableEmails;
