import React from 'react';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import { Table } from 'reactstrap';
import { MDBIcon } from 'mdbreact';
import Pagination from 'react-js-pagination';
import ModalNewContacts from './Modal/ModalNewContacts';
import ModalEditContacts from './Modal/ModalEditContacts';
import Input from '../../Utils/Input';
import Swal from 'sweetalert2';

class TableContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      name: '',
      phone: '',
    };
    this.getContacts = this.getContacts.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.getContacts();
  }

  async getContacts(page = 1) {
    const { customersId } = this.props;
    const { name, phone } = this.state;

    const object = {
      name: name,
      phone: phone,
    };

    const results = await DataApi.GetContacts(page, customersId.response, object);

    this.setState({ data: results.data });
  }

  async handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    setTimeout(() => {
      this.getContacts();
    }, 500);
  }

  async destroyContact(id) {
    const response = await DataApi.DestroyContact(id);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'has been saved successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'save error',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.getContacts();
  }

  render() {
    const { customersId, lock } = this.props;
    const {
      data: { data },
      name,
      phone,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container-search-contact">
            <Input nameI={'name'} value={name} change={this.handleChange} placeholder={'Name'} />
            <Input nameI={'phone'} value={phone} change={this.handleChange} placeholder={'Phone'} />
          </div>
          <div className="container-table-all">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Main</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="container-item-2">{item.name}</td>
                    <td className="container-item-3">{item.phone}</td>
                    <td className="container-item-4">{item.main === 1 ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Pagination
            totalItemsCount={total}
            onChange={page => this.getContacts(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="">
          <ModalNewContacts customersId={customersId} getContacts={this.getContacts} />
        </div>

        <div className="container-search-contact">
          <Input nameI={'name'} value={name} change={this.handleChange} placeholder={'Name'} />
          <Input nameI={'phone'} value={phone} change={this.handleChange} placeholder={'Phone'} />
        </div>
        <div className="container-table-all">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Main</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item-2">{item.name}</td>
                  <td className="container-item-3">{item.phone}</td>
                  <td className="container-item-4">{item.main === 1 ? 'Yes' : 'No'}</td>
                  <td className="container-item-5 action-td d-flex ml-1">
                    <ModalEditContacts
                      data={item}
                      customersId={customersId}
                      getContacts={this.getContacts}
                      style={{ cursor: 'pointer' }}
                    />
                    <MDBIcon
                      icon="trash"
                      size="lg"
                      className="trash ml-3"
                      onClick={() => this.destroyContact(item.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          totalItemsCount={total}
          onChange={page => this.getContacts(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableContact;
