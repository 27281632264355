import React from 'react';

class SearchSuppliers extends React.Component {
  
  render() {
    const { selectOne, selectTwo } = this.props;

    return(
        <React.Fragment>
            <div className="row justify-content-between top-information">
                <div className="col-sm-4">
                    <div className="form-group">
    
                        <input
                            name="id"
                            className="form-control"
                            placeholder="ID"
                            onChange={(e) => this.props.hanleChangeFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
    
                        <input
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            onChange={(e) => this.props.hanleChangeFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                value={'1'}
                                name="contact"
                                onChange={(e) => {
                                    this.props.hanleChangeFilter(e, 1)
                                }}
                                checked={selectOne}
                            />
                            <label className="form-check-label">Enable&nbsp;</label>
                            <input
                                className="form-check-input"
                                type="radio"
                                value={'0'}
                                name="contact"
                                onChange={(e) => {
                                    this.props.hanleChangeFilter(e, 2);
                                }}
                                checked={selectTwo}
                            />
                            <label className="form-check-label">Disable&nbsp;</label>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
  }
}

export default SearchSuppliers;
      
