/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import Swal from 'sweetalert2';

import Input from '../../Utils/Input';
import SelectCmx from '../../Utils/Select';
import CheckBox from '../../Utils/Checkbox';
import DataApi from '../../../../megacall/services/UsersServices/DataApi';
import Validations from '../../../../megacall/utilsFunctions/Validations';

class ModalEditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      id: '0',
      value_role_type: '',
      username: '',
      email: '',
      isCheckedDisabled: false,
      enabled: 1,
      item: '',
      color: 'invalidate',
      colorEmail: 'invalidateEmail',
      newpass: '',
    };

    this.initializeState = this.initializeState.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.send = this.send.bind(this);
    this.newPass = this.newPass.bind(this);
  }

  componentDidMount() {
    this.initializeState();
  }

  initializeState() {
    const { item } = this.props;

    this.setState({
      item: item,
      id: item.id,
      username: item.username,
      email: item.email,
      value_role_type: { label: item.display_name, value: item.role_id },
      isCheckedDisabled: item.enabled,
      newpass: '',
    });

    if (item.enabled === 1) {
      this.setState({ isCheckedDisabled: true });
    }
  }

  toggle() {
    this.initializeState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e, nameI, index) {
    const { isCheckedDisabled } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isCheckedDisabled, enabled: isCheckedDisabled ? 0 : 1 });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async send() {
    const { id, username, email, enabled, value_role_type, newpass_field } = this.state;

    const object = {
      username: username,
      email: email,
      role_id: value_role_type.value,
      enabled: enabled ? 1 : 0,
      newpass: newpass_field,
    };

    const validateInput = Validations.validationInput([{ value: username, name: 'username' }]);

    if (validateInput) {
      const response = await DataApi.SetUser(id, object);
      this.setState(prevState => ({
        modal: !prevState.modal,
      }));
      if (response.data === 200) {
        this.props.auto_search();
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'The user has been updated successfully.',
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'There was a problem trying to update the user.',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Some mandatory fields have not been filled',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async newPass() {
    const { id } = this.state;

    const response = await DataApi.GetPassword(id);
    console.log(response);
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
    if (response.data === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The new password is sent to the user successfully.',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'There was a problem trying to send the new password to the user.',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  render() {
    const { username, email, isCheckedDisabled, value_role_type, color, colorEmail, newpass_field } = this.state;

    return (
      <React.Fragment>
        <div className="container-button">
          <a onClick={this.toggle}>
            <i className="mdi mdi-square-edit-outline mdi-24px"></i>
          </a>
        </div>
        <Modal className="modal-new-agents" isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Edit User</ModalHeader>
          <ModalBody>
            <FormGroup>
              {this.props.item.role_id > 3 ? (
                <>
                  <Input name={'Username'} color={color} value={username} disabled />
                  <Input value={email} name={'Email'} disabled />
                  <Input value={value_role_type.label} name={'Roles'} disabled />
                </>
              ) : (
                <>
                  <Input
                    name={'Username'}
                    nameI={'username'}
                    color={color}
                    value={username}
                    state={username}
                    change={this.handleChange}
                  />
                  <Input
                    name={'Email'}
                    nameI={'email'}
                    color={colorEmail}
                    value={email}
                    state={email}
                    change={this.handleChange}
                  />
                  <SelectCmx
                    name={'Roles'}
                    nameI={'value_role_type'}
                    validate={'validation'}
                    value={value_role_type}
                    change={this.handleChange}
                    index={1}
                    id={'sel'}
                    options={this.props.role_type}
                  />
                  <br />
                  <Input
                    name={'New Password'}
                    nameI={'newpass_field'}
                    color={colorEmail}
                    value={newpass_field}
                    state={newpass_field}
                    change={this.handleChange}
                  />
                </>
              )}
            </FormGroup>
            <div className="row ml-2">
              <CheckBox
                nameI={'isCheckedDisabled'}
                toggleChange={this.handleChange}
                isChecked={isCheckedDisabled}
                index={2}
              />
              <label className="form-check-label ml-2">Enabled</label>
            </div>
          </ModalBody>
          <ModalFooter>
            {this.props.item.role_id > 3 ? (
              <Button color="orange" onClick={this.newPass}>
                Send new password
              </Button>
            ) : (
              ''
            )}
            <Button color="primary" onClick={this.send}>
              Save
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditUser;
