import React from 'react';

import DataApi from '../../../../megacall/services/Settings/DataApi';
import CardCurrencyExchange from '../../../elements/SettingsElements/CurrencyExchanges/CardCurrencyExchange';
import ModalNewCurrencyExchange
    from '../../../elements/SettingsElements/CurrencyExchanges/Modal/ModalNewCurrencyExchange';

class Exchanges extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: '',
        };
        this.getCurrencyExchange = this.getCurrencyExchange.bind(this);
    }
    
    async componentDidMount() {
        await this.getCurrencyExchange();
    }
    
    
    async getCurrencyExchange() {
        const results = await DataApi.ListCurrencyExchange();
        
        this.setState({ data: results.data });
    }
    
    render() {
        const { data } = this.state;
        
        if (!data) {
            return null;
        }
        
        return (
            <React.Fragment>
                <ModalNewCurrencyExchange getCurrencyExchange={this.getCurrencyExchange}/>
                <br/>
                {data.map((item) => (
                    item.display_name === 'EUR' ? null : <CardCurrencyExchange data={item} />
                ))}
            </React.Fragment>
        );
    }
}

export default Exchanges;

