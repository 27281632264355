/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  //   Input,
} from 'reactstrap';
import Input from '../../../elements/Utils/Input';
import Swal from 'sweetalert2';
import DataApi from '../../../../megacall/services/Calls/DataApi';

class EditReasonAll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reason: '',
      reprocess: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateReason = this.updateReason.bind(this);
    this.checkReprocess = this.checkReprocess.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async handleChange(e, nameI, index) {

    switch (index) {
      case 1:
        this.setState({ [nameI]: e.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }

    setTimeout(() => {}, 300);
  }

  async updateReason() {
    const { reason, reprocess } = this.state;
    const { checkAll } = this.props;

    const json = {
      ids: checkAll,
      reason: reason,
      reprocess: reprocess === true ? 1 : 0,
    };
    const results = await DataApi.updateReasonMass(json);

    

    if (results.status === 200) {
      setTimeout(() => {
        Swal.fire({
          title: checkAll.length + ' Overrides viewed',
          // text: 'Please resolve them first!',
          icon: 'success',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',          
        });
        
        this.props.getOverrides();
        const done = true;
        this.props.unCheckSaved(done);
      }, 900);
      this.setState({reason: ''})
    }
    this.toggle();
  }

  checkReprocess() {
    const { reprocess } = this.state;
    this.setState({ reprocess: !reprocess });

  }

  async componentDidMount() {
    await this.props.getOverrides();
  }

  render() {
    const { reason } = this.state;
    return (
      <>
        <a
          onClick={this.toggle}
          title="Add Reason"
          className="btn btn-sm btn-warning text-dark"
          style={{ height: '30px' }}
        >
          Add Reason
        </a>

        <Modal isOpen={this.state.modal}>
          <ModalHeader className="" toggle={this.toggle}>
            Add a Reason{' '}
          </ModalHeader>
          <ModalBody>
            <FormGroup className="">
              <Input type={'textarea'} change={this.handleChange} value={reason} nameI="reason" className="" />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <input type="checkbox" onClick={this.checkReprocess} className="" />
            <label className="mr-4">Reprocess</label>
            <Button color="primary" onClick={this.updateReason}>
              Save
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default EditReasonAll;
