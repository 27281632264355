import React from 'react';

import USD from '../../../../assets/images/america.png';
import GBP from '../../../../assets/images/inglaterra.png';
import EUR from '../../../../assets/images/EAg.png';

class CardCurrencyExchange extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            flag: ''
        }
    }
    
    componentDidMount() {
        const { data } = this.props;
        
        switch (data.display_name) {
            case 'USD':
                this.setState({ flag: USD });
                break;
            case 'GBP':
                this.setState({ flag: GBP });
                break;
            default:
                this.setState({ flag: EUR });
                break;
        }
    }
    
    render() {
        const { data } = this.props;
        const { flag } = this.state;
        
        return (
            <React.Fragment>
                <div className="card">
                    <div className="card-body banderas">
                        <div className="container-text">
                            <h5 className="card-title">{data.display_name}</h5>
                            <p className="card-text"><strong>Value: </strong>{data.value}</p>
                            <p className="card-text"><strong>Date: </strong>{data.start_date}</p>
                        </div>
                        <div className="container-bandera">
                            <img src={flag} className="card-img-top" alt={flag} />
                        </div>
                    </div>
                </div>
                <br/>
            </React.Fragment>
        );
    }
}

export default CardCurrencyExchange;