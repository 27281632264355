import React, { Component } from 'react';

import Swal from 'sweetalert2';

import DataApi from '../../../megacall/services/Calls/DataApi';

import { CSVLink } from 'react-csv';

import Loader from 'react-loader-spinner';

class Charges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateCalls: '',
      total_calls: 0,
      anomalies: 0,
      overrides: 0,
      createdCharges: 0,
      data: '',
      disable: false,
      dateToCalls: '',
      maxDate: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.getCalls = this.getCalls.bind(this);
    this.update = this.update.bind(this);
  }

  componentDidMount() {
    const maxDate = new Date().toJSON().split('T')[0];
    this.setState({ maxDate: maxDate });
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e.value });
        break;
      case 2:
        this.setState({ [e.target.name]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }

    setTimeout(() => {}, 300);
  }

  async getCalls(page = 1) {
    const { dateCalls, dateToCalls } = this.state;

    if (dateCalls !== '' && dateToCalls !== '') {
      const fromSplited = dateCalls.split('-', 3);
      const toSplited = dateToCalls.split('-', 3);

      if (fromSplited[0] === toSplited[0] && fromSplited[1] === toSplited[1]) {
        const json = {
          dateFromCalls: dateCalls,
          dateToCalls: dateToCalls,
        };

        const results = await DataApi.getCalls(json);

        this.setState({ data: results.data.datos });
        this.setState({ total_calls: results.data.total_calls });
        this.setState({ anomalies: results.data.anomalies });
        this.setState({ overrides: results.data.overrides });
        this.setState({ createdCharges: results.data.datos.length });

        if (results.data.anomalies > 0) {
          setTimeout(() => {
            Swal.fire({
              title: 'You have Anomalies to resolve!',
              text: 'Please resolve them first!',
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            });
          }, 900);
        }

        if (results.data.datos.length <= 0 && results.data.total_calls > 0) {
          setTimeout(() => {
            Swal.fire({
              title: "This day it's already been processed!",
              text: 'Please try with another day',
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            });
          }, 900);
        }

        if (results.status === 500) {
          setTimeout(() => {
            Swal.fire({
              title: "This day it's already been processed!",
              text: 'Please try with another day',
              icon: 'warning',
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
            });
          }, 900);
        }
      } else {
        setTimeout(() => {
          Swal.fire({
            title: 'Wrong dates!',
            text: 'Please make sure the dates are from the same month and the same year!',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
          });
        }, 900);
      }
    } else {
      setTimeout(() => {
        Swal.fire({
          title: 'NO dates selected!',
          text: 'Please be sure to choose a date range!',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
        });
      }, 900);
    }
  }

  async update() {
    const { dateCalls, dateToCalls } = this.state;
    if (dateCalls !== '' && dateToCalls !== '') {
      this.setState({ disable: true });

      const fromSplited = dateCalls.split('-', 3);
      const toSplited = dateToCalls.split('-', 3);

      if (fromSplited[0] === toSplited[0] && fromSplited[1] === toSplited[1]) {
        const json = {
          dateFromCalls: dateCalls,
          dateToCalls: dateToCalls,
        };

        const results = await DataApi.processUpdate(json);
        this.setState({ disable: false });
        if (results.status === 200) {
          Swal.fire({
            title: 'The calls has been processed',
            text: 'New charges has been added',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
          });
          this.setState({ data: '' });
        }
      } else {
        setTimeout(() => {
          Swal.fire({
            title: 'Wrong dates!',
            text: 'Please make sure the dates are from the same month and the same year!',
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
          });
        }, 900);
      }
    } else {
      setTimeout(() => {
        Swal.fire({
          title: 'NO dates selected!',
          text: 'Please be sure to choose a date range!',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok',
        });
      }, 900);
    }
  }

  render() {
    const {
      data,
      total_calls,
      anomalies,
      overrides,
      createdCharges,
      dateCalls,
      disable,
      dateToCalls,
      maxDate,
    } = this.state;

    if (disable) {
      return (
        <>
          <div className="container-item ">
            <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
            <label className="h1">Loading</label>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="content-wrapper">
          <div className="content">
            {' '}
            <div className="breadcrumb-wrapper mb-5 ml-1 container-item">
              <h1>Charges</h1>
            </div>
            <div className="row container-fluid ml-3">
              <div className="col-12 ">
                <div className="card card-default">
                  <div className="card-header card-header-border-bottom d-flex justify-content-between ">
                    <h2>Customer Report</h2>
                    <button className="btn btn-outline-orange btn-sm text-uppercase" onClick={this.getCalls}>
                      <i className=" mdi mdi-progress-check mr-2"></i>Show Calls
                    </button>
                  </div>

                  <div className="card-body">
                    <div className="row justify-content-between top-information">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Date From calls:</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="From"
                            name="dateCalls"
                            onChange={this.handleChange}
                            max={maxDate}
                          />
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label>Date To calls:</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="From"
                            name="dateToCalls"
                            onChange={this.handleChange}
                            max={maxDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between mt-3 ">
                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <div className="card widget-block p-1 rounded bg-primary2 border ">
                          <div className="card-block ml-4 text-white">
                            <h4 className=" my-2">{total_calls}</h4>
                            <p>Calls</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <div className="card widget-block p-1 rounded bg-danger2 border">
                          <div className="card-block ml-4 text-white">
                            <h4 className="text-white my-2">{anomalies}</h4>
                            <p>Anomalies</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <div className="card widget-block p-1 rounded bg-danger2 border">
                          <div className="card-block ml-4 text-white">
                            <h4 className="text-white my-2">{overrides}</h4>
                            <p>Not checked Overrides</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4 col-lg-4 col-xl-2">
                        <div className="card widget-block p-1 rounded bg-primary2 border">
                          <div className="card-block ml-4 text-white">
                            <h4 className="text-white my-2">{createdCharges}</h4>
                            <p>Created Charges</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {data.length > 0 && anomalies <= 0 ? (
              <div className="row">
                <div className="col-12">
                  <div className="card card-default">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-10 ml-4">
                          <CSVLink
                            data={data}
                            filename={`callsReportfrom_${dateCalls}_${dateToCalls}.csv`}
                            className="btn btn-orange btn-sm text-uppercase ml-3"
                            target="_blank"
                          >
                            <i className=" mdi mdi-export"></i> Export CSV
                          </CSVLink>
                        </div>

                        <button className="btn btn-orange btn-sm text-uppercase ml-3" onClick={this.update}>
                          <i className=" mdi mdi-progress-check mr-2"></i>Process Calls
                        </button>
                      </div>

                      <div className="table-wrap table-responsive mt-3">
                        <table className="table2 table-striped table-bordered container-item ">
                          <thead className="bg-primary2 text-white">
                            <tr>
                              <th>Customer ID</th>
                              <th>Name</th>
                              <th>Total Calls</th>
                              <th>Total Duration</th>
                              <th>Total Sale</th>
                              <th>Balance</th>
                              <th>Threshold</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => (
                              <tr>
                                <td>{item.customer_id}</td>
                                <td>{item.name}</td>
                                <td>{item.total_calls}</td>
                                <td>{item.total_duration}</td>
                                <td>{item.total_sale.toFixed(4)}</td>
                                <td>{item.balance.toFixed(2)}</td>
                                <td>{item.threshold.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Charges;
