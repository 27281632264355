import UrlServices from '../UrlServices';
import axios from 'axios';
import Headboard from '../Headboard';

export default class CallsApi {
    
    static API_URL_SUPPLIERS_GLOBAL = UrlServices.universal;
    
    static async GETSUPPLIERS(page, json) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/list?page=${page}`, json, await Headboard.HEADERS());
        
        return response;
    }
    
    static async GETSELECT() {
        const response = await axios.get(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/list/select`, await Headboard.HEADERS());
        
        return response;
    }
    
    static async GETSUPPLIERSSHOW(id) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/show/${id}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async SETSUPPLIERS(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/create', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async UPDATESUPPLIERSE(id, object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/update/${id}`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    /** PREFIX */
    static async SETSUPPLIERSEPREFIX(id, page, object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/prefix/list/${id}?page=${page}`, object, await Headboard.HEADERS());
        
        return response;
    }

    static async GETALLSUPPLIERS(id) {
        const response = await axios.get(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/prefix/listAll/${id}`, await Headboard.HEADERS());

        return response;
    }
    
    static async DESTROYSUPPLIERSEPREFIX(id, prefix_translates_id) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/prefix/destroy/${id}/${prefix_translates_id}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async UPDATESUPPLIERSEPREFIX(id, prefix_translates_id, object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/prefix/update/${id}/${prefix_translates_id}`, object, await Headboard.HEADERS());
        
        return response;
    }
    
    /** Modal Price List **/    
    static async INSERTSUPPLIERPREFIX(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/prefix/create', object, await Headboard.HEADERS());
        
        return response;
    }
    
    /** Sippy **/
    static async IMPORTEXPORT(id, file) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/sippy/import/export/${id}`, file, await Headboard.HEADERSFILE());
        
        return response;
    }
    
    /** Import Price */
    static async LOAD(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/price/load', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async SELECT() {
        const response = await axios.get(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/price/list', await Headboard.HEADERS());
        return response;
    }
    
    static async DELETE(id) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/price/delete/${id}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async RESULTS(page, object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/price/results?page=${page}`, object, await Headboard.HEADERS());
        
        return response;
    }

    static async RESULTSALL(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/price/resultsAll`, object, await Headboard.HEADERS());

        return response;
    }
    
    static async SEARCHTRANSLATE(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/price/search-translate', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async COUNT(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/price/count', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async IGNORE(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/price/ignore', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async POSTSAVE(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/price/save', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async SAVEDESCRIPTIONS(object) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + 'suppliers/price/save/descriptions', object, await Headboard.HEADERS());
        
        return response;
    }
    
    static async DESACTIVESUPPLIERS(id, data) {
        const response = await axios.post(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/desactive/${id}/${data}`, null, await Headboard.HEADERS());
        
        return response;
    }
    
    static async COUNTSUPPLIERS(id) {
        const response = await axios.get(CallsApi.API_URL_SUPPLIERS_GLOBAL + `suppliers/count/${id}`, await Headboard.HEADERS());
        
        return response;
    }

    
}