import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TableExtTypes from '../../../elements/SettingsElements/ExtTypes/TableExtTypes';

class ExtTypes extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div>
                    <Row>
                        <h1 className="title-table">EXT TYPES</h1>
                        <Col md={12}>
                            <Card>
                                <TableExtTypes />
                            </Card>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default ExtTypes;
