
export default class CopyClipboard{
  static copy(elemento) {

      let aux = document.createElement("input");

      aux.setAttribute("value", elemento);

      document.body.appendChild(aux);

      aux.select();

      document.execCommand("copy");

      document.body.removeChild(aux);

      return true;
  }
}