/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalNewNotes extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            text: '',
        };
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async save() {
        const { customersId } = this.props;
        const { text } = this.state;
        
        const object = {
            text: text,
        };
        
        const response = await DataApi.NewNotes(customersId, object);
        
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Your work has error saved',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        this.toggle();
        this.props.getNotes();
        this.setState({ text: '' });
    }
    
    render() {
        const { text } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <h3>Notes List</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add Notes
                    </a>
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>New Notes</ModalHeader>
                    <ModalBody>
                        <Input nameI={'text'} value={text} change={this.handleChange}
                               name={'text'} type={'textarea'} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>New</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewNotes;