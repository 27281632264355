/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Table } from 'reactstrap';
import Input from '../../Utils/Input/index';
import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import ModalNewCharge from './Modal/ModalNewCharge';
import ModalEditCharge from './Modal/ModalEditCharge';
import SelectCmx from '../../Utils/Select/index';

class TableCharges extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      productTypes: [{ label: 'ALL', value: '' }],
      product: '',
      checkTypes: [
        { label: 'ALL', value: '' },
        { label: 'Recurrents', value: '1' },
        { label: 'Inmediate', value: '2' },
      ],
      type: '',
      options: [{ label: 'enabled', value: 1 }, { label: 'disabled', value: 0 }, { label: 'ALL', value: '' }],
      enabled: '',
      click: 1,
      sortBy: '',
    };
    this.getCharges = this.getCharges.bind(this);
    this.destroyCharges = this.destroyCharges.bind(this);
    this.getBalance = this.getBalance.bind(this);
    this.sort = this.sort.bind(this);
  }

  async componentDidMount() {
    await this.getCharges(1);
    await this.getProductTypes();
    await this.getBalance();
  }

  async getProductTypes() {
    const { productTypes } = this.state;
    const object = {
      product_id: null,
    };

    const results = await DataApi.getProductTypes(object);

    this.setState({ productTypes: [...productTypes, ...results.data[0]], product: results.data[1] });
  }

  async getCharges(page) {
    const { customersId } = this.props;
    const { from_date, to_date, product, description, type, enabled, sortBy, perPage } = this.state;

    this.setState({ page: page });
    let object = {
      from_date: from_date,
      to_date: to_date,
      description: description,
      product: product,
      type: type,
      enabled: enabled,
      perPage,
      first: this.state.first === false ? null : true,
      sortBy: sortBy,
      order: !this.state.order,
    };

  this.setState({ page: page });
  
    const response = await DataApi.getCharges(customersId.response, page, object);
    this.setState({ data: response.data });
  }

  async destroyCharges(id, value) {
    const { customersId } = this.props;

    const object = {
      chargeId: id,
      value: value,
    };

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.destroyCharge(customersId.response, object).then(() => {
          this.getCharges();
          this.getBalance();
        });
        Swal.fire('Deleted!', 'Your charge has been deleted.', 'success');
      }
    });
  }

  async handleChangeFilter(e, index) {
    // console.log(e.value);
    switch (index) {
      case 1:
        this.setState({ from_date: e.target.value });
        setTimeout(() => {
          this.getCharges();
        }, 50);
        break;
      case 2:
        this.setState({ to_date: e.target.value });
        setTimeout(() => {
          this.getCharges();
        }, 50);
        break;
      case 3:
        this.setState({ product: e.value });
        setTimeout(() => {
          this.getCharges();
        }, 50);
        break;
      case 4:
        this.setState({ description: e.target.value });
        setTimeout(() => {
          this.getCharges();
        }, 50);
        break;
      case 5:
        this.setState({ type: e.value });
        setTimeout(() => {
          this.getCharges();
        }, 50);
        break;
      case 6:
        this.setState({ enabled: e.value });
        setTimeout(() => {
          this.getCharges();
        }, 50);
        break;
      default:
        break;
    }
  }

  async getBalance() {
    const { customersId } = this.props;
    const object = {
      customersId: customersId.response,
    };
    const balance = await DataApi.getBalance(object);

    this.setState({ balance: balance.data });
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
          this.getCharges();
        }, 50);
        break;
    }
  }

  async sort(sortBy) {
    let { click } = this.state;
    const { customersId } = this.props;
    const { order } = this.state;

    this.setState({ sortBy: sortBy, first: false });

    if (click >= 1) {
      if (this.state.order === true) {
        this.setState({ order: false });
      } else {
        this.setState({ order: true });
      }
    }

    const page = this.state.page;
    const object = {
      sortBy: sortBy,
      order: order,
    };
    const response = await DataApi.getCharges(customersId.response, page, object);
    click++;
    this.setState({ data: response.data, click: click });
  }

  render() {
    const {
      data: { data },
      productTypes,
      product,
      checkTypes,
      type,
      balance,
      options,
      enabled,
    } = this.state;

    const { current_page, total, per_page, perPage } = this.state.data;

    const { lock } = this.props;

    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container-search-did">
            <div className="mt-5">
              <div className="row">
                <div className="mt-2 mb-4">
                  <h3>BALANCE: {balance} €</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body ">
            <div className="row justify-content-between top-information">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>From Date:</label>
                  <input
                    onChange={e => this.handleChangeFilter(e, 1)}
                    type="date"
                    name="from_date"
                    className="form-control"
                    placeholder="From"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>To Date:</label>
                  <input
                    onChange={e => this.handleChangeFilter(e, 2)}
                    type="date"
                    name="to_date"
                    className="form-control"
                    placeholder="To"
                  />
                </div>
              </div>
            </div>
            <div className="row justify-content-between top-information">
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Type</label>
                  <SelectCmx
                    index={1}
                    nameI={'type'}
                    value={type.value}
                    options={checkTypes}
                    change={e => this.handleChangeFilter(e, 5)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Product</label>
                  <SelectCmx
                    index={1}
                    nameI={'product'}
                    options={productTypes}
                    value={product.value}
                    change={e => this.handleChangeFilter(e, 3)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <label>Description</label>
                  <input
                    index={1}
                    nameI={'description'}
                    onChange={e => this.handleChangeFilter(e, 4)}
                    className="form-control"
                    placeholder="Description"
                  />
                </div>
                <div className="form-group">
                <Input nameI={'perPage'} value={perPage} change={ e => this.handleChange(e)} placeholder={'per page'} />
                  <label>Enabled</label>
                  <SelectCmx
                    index={1}
                    nameI={'product'}
                    options={options}
                    value={enabled.value}
                    change={e => this.handleChangeFilter(e, 6)}
                    className="mt-3"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="container-table-all">
            <Table className="table">
              <thead className="container-item">
                <tr>
                  <th scope="container-item" onClick={() => this.sort('invoiceNumber')}>
                    Invoice Number
                  </th>
                  <th scope="container-item" onClick={() => this.sort('inmediateBalance')}>
                    Inmediate Balance
                  </th>
                  <th scope="container-item" onClick={() => this.sort('description')}>
                    Product
                  </th>
                  <th scope="container-item" onClick={() => this.sort('inmediateInvoice')}>
                    Inmediate Invoice
                  </th>
                  <th scope="container-item" onClick={() => this.sort('fromDate')}>
                    From Date
                  </th>
                  <th scope="container-item" onClick={() => this.sort('toDate')}>
                    To Date
                  </th>
                  <th scope="container-item" onClick={() => this.sort('units')}>
                    Units
                  </th>
                  <th scope="container-item" onClick={() => this.sort('rate')}>
                    Rate
                  </th>
                  <th scope="container-item" onClick={() => this.sort('charge')}>
                    Charge
                  </th>
                  <th scope="container-item" onClick={() => this.sort('description')}>
                    Description
                  </th>
                  <th scope="container-item" onClick={() => this.sort('commision')}>
                    % Comm
                  </th>
                  <th scope="container-item" onClick={() => this.sort('enabled')}>
                    Enabled
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="container-item">{item.invoiceNumber}</td>
                    <td className="container-item">
                      <input
                        type="checkbox"
                        defaultChecked={item.immediate_balance}
                        value={item.immediate_balance}
                        checked={item.immediate_balance}
                      />
                    </td>
                    <td className="container-item">{item.description_en}</td>
                    <td className="container-item">
                      <input
                        type="checkbox"
                        defaultChecked={item.immediate_invoice}
                        value={item.immediate_invoice}
                        checked={item.immediate_invoice}
                      />
                    </td>
                    <td className="container-item">{new Date(item.from_date).toLocaleDateString()}</td>
                    <td className="container-item">{new Date(item.to_date).toLocaleDateString()}</td>
                    <td className="container-item">{item.units}</td>
                    <td className="container-item">{item.rate}</td>
                    <td className="container-item">{item.units * item.rate}</td>
                    <td className="container-item">{item.text_description}</td>
                    <td className="container-item">{item.comm}</td>
                    <td className="container-item">{item.enabled === 1 ? 'Yes' : ' No'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Pagination
            totalItemsCount={total}
            onChange={page => this.getCharges(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <div className="container-search-did">
          <div className="mt-5">
            <div className="row">
              <div className="mt-2 mb-4">
                <h3>BALANCE: {balance} €</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body ">
          <div className="row justify-content-between top-information">
            <div className="col-sm-6">
              <div className="form-group">
                <label>From Date:</label>
                <input
                  onChange={e => this.handleChangeFilter(e, 1)}
                  type="date"
                  name="from_date"
                  className="form-control"
                  placeholder="From"
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label>To Date:</label>
                <input
                  onChange={e => this.handleChangeFilter(e, 2)}
                  type="date"
                  name="to_date"
                  className="form-control"
                  placeholder="To"
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-between top-information">
            <div className="col-sm-4">
              <div className="form-group">
                <label>Type</label>
                <SelectCmx
                  index={1}
                  nameI={'type'}
                  value={type.value}
                  options={checkTypes}
                  change={e => this.handleChangeFilter(e, 5)}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Product</label>
                <SelectCmx
                  index={1}
                  nameI={'product'}
                  options={productTypes}
                  value={product.value}
                  change={e => this.handleChangeFilter(e, 3)}
                />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group">
                <label>Description</label>
                <input
                  index={1}
                  nameI={'description'}
                  onChange={e => this.handleChangeFilter(e, 4)}
                  className="form-control"
                  placeholder="Description"
                />
              </div>
              <div className="form-group ">
          
                  <Input nameI={'perPage'} value={perPage} change={ e => this.handleChange(e)} placeholder={'per page'} />
              
                <label>Enabled</label>
                <SelectCmx
                  index={1}
                  nameI={'product'}
                  options={options}
                  value={enabled.value}
                  change={e => this.handleChangeFilter(e, 6)}
                  className="mt-3"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-input-modal mb-5">
          <ModalNewCharge
            customersId={this.props.customersId}
            getCharges={this.getCharges}
            productTypes={productTypes}
            getAllInfo={this.props.getAllInfo}
            getBalance={this.getBalance}
          />
        </div>
        <div className="container-table-all">
          <Table className="table">
            <thead className="container-item">
              <tr>
                <th scope="container-item" onClick={() => this.sort('invoiceNumber')}>
                  Invoice Number
                </th>
                <th scope="container-item" onClick={() => this.sort('inmediateBalance')}>
                  Inmediate Balance
                </th>
                <th scope="container-item" onClick={() => this.sort('description')}>
                  Product
                </th>
                <th scope="container-item" onClick={() => this.sort('inmediateInvoice')}>
                  Inmediate Invoice
                </th>
                <th scope="container-item" onClick={() => this.sort('fromDate')}>
                  From Date
                </th>
                <th scope="container-item" onClick={() => this.sort('toDate')}>
                  To Date
                </th>
                <th scope="container-item" onClick={() => this.sort('units')}>
                  Units
                </th>
                <th scope="container-item" onClick={() => this.sort('rate')}>
                  Rate
                </th>
                <th scope="container-item" onClick={() => this.sort('charge')}>
                  Charge
                </th>
                <th scope="container-item" onClick={() => this.sort('description')}>
                  Description
                </th>
                <th scope="container-item" onClick={() => this.sort('commision')}>
                  % Comm
                </th>
                <th scope="container-item" onClick={() => this.sort('enabled')}>
                  Enabled
                </th>
                <th scope="container-item actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index} className={`${item.enabled === 0 && '	bg-secondary text-white'}`}>
                  <td className="container-item">{item.invoiceNumber}</td>
                  <td className="container-item">
                    <input
                      type="checkbox"
                      defaultChecked={item.immediate_balance}
                      value={item.immediate_balance}
                      checked={item.immediate_balance}
                    />
                  </td>
                  <td className="container-item">{item.description_en}</td>
                  <td className="container-item">
                    <input
                      type="checkbox"
                      defaultChecked={item.immediate_invoice}
                      value={item.immediate_invoice}
                      checked={item.immediate_invoice}
                    />
                  </td>
                  <td className="container-item">{new Date(item.from_date).toLocaleDateString()}</td>
                  <td className="container-item">{new Date(item.to_date).toLocaleDateString()}</td>
                  <td className="container-item">{item.units}</td>
                  <td className="container-item">{item.rate}</td>
                  <td className="container-item">{item.units * item.rate}</td>
                  <td className="container-item">{item.text_description}</td>
                  <td className="container-item">{item.comm}</td>
                  <td className="container-item">{item.enabled === 1 ? 'Yes' : ' No'}</td>
                  <td className="container-item action-td">
                    {item.immediate_balance === 0 && item.immediate_invoice === 0 ? (
                      <div className="d-flex">
                        <ModalEditCharge
                          customersId={this.props.customersId}
                          getCharges={this.getCharges}
                          chargeId={item.id}
                          productTypes={productTypes}
                          type={type}
                        />
                        <a onClick={() => this.destroyCharges(item.id)} className="">
                          <i className="mdi mdi-trash-can-outline text-danger mdi-24px"></i>
                        </a>
                      </div>
                    ) : (
                      ''
                    )}
                    {item.immediate_balance === 1 && item.immediate_invoice === 0 ? (
                      <a onClick={() => this.destroyCharges(item.id, item.units * item.rate)}>
                        <i className="mdi mdi-trash-can-outline text-danger mdi-24px"></i>
                      </a>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          totalItemsCount={total}
          onChange={page => this.getCharges(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableCharges;
