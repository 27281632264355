import React, { Component } from 'react';

import { Table } from 'reactstrap';
import ModalEditUser from './Modal/ModalEditUser';
import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';
import DataApi from '../../../megacall/services/UsersServices/DataApi';

class TableUsers extends Component {
  constructor(props) {
    super(props);

    this.destroy = this.destroy.bind(this);
  }

  async destroy(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyUser(id);
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deleted! The user has been deleted.',
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.props.auto_search();
    });
  }

  render() {
    const { data } = this.props.data;

    if (!data) return null;

    return (
      <Table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Username</th>
            <th scope="col">Role</th>
            <th scope="col">Enabled</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td className="container-item-1 col-principal">{item.id}</td>
              <td className="container-item-1 ">{item.username}</td>
              <td className="container-item-2">{item.display_name}</td>
              <td className="container-item-3">{item.enabled === 0 ? 'No' : 'Yes'}</td>
              <td className="container-item-4">
                <ModalEditUser item={item} role_type={this.props.role_type} auto_search={this.props.auto_search} />
                {item.enabled ? null : (
                  <MDBIcon icon="trash" size="lg" className="trash" onClick={() => this.destroy(item.id)} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default TableUsers;
