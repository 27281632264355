import React from 'react';

import { MDBIcon } from 'mdbreact';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import Swal from 'sweetalert2';

class ModalCopyPackages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      name: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async save() {
    const { item } = this.props;
    const { name } = this.state;

    const object = {
      name: name,
      item: item
    };

    const result = await DataApi.CopyPackges(object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Copied successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Problems to copied',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.props.getPackages();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { name } = this.state;

    return (
      <>
        <div className="container-button">
          <MDBIcon icon="plus-circle" size="lg" className="edit" onClick={this.toggle} />
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Copy Package</ModalHeader>
            <React.Fragment>
              <ModalBody>
                <Input value={this.props.toCopy} name={'To Copy'} disabled={true}/>
                <Input nameI={'name'} value={name} change={this.handleChange} name={'Name'} />
                <br />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.save}>
                  Copy
                </Button>{' '}
              </ModalFooter>
            </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalCopyPackages;
