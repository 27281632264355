import UrlServices from '../UrlServices';
import axios from 'axios';
import Headboard from '../Headboard';

export default class CallsApi {
  static API_URL_GLOBAL = UrlServices.universal;

  static async LOADCDR(object) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + 'calls/cdr/load', object, await Headboard.HEADERS());

    return response;
  }
  static async ProcessFiles() {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + 'calls/cdr/process', await Headboard.HEADERS());

    return response;
  }

  static async GETSUPPLIERS() {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + `calls/cdr/list`, await Headboard.HEADERS());

    return response;
  }

  static async GETCALLS(page, json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/cdr/callList?page=${page}`, json, await Headboard.HEADERS());

    return response;
  }
  static async CHECKSTATUS() {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + `calls/cdr/checkStatus`,  await Headboard.HEADERS());

    return response;
  }
  static async SHOWERROR() {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + `calls/cdr/showError`,  await Headboard.HEADERS());

    return response;
  }
  //Anomalies
  static async GETANOMALIES(page) {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + `calls/anomalies/list?page=${page}`, await Headboard.HEADERS());

    return response;
  }
  static async GETCALLINFO(json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/anomalies/list`, json, await Headboard.HEADERS());

    return response;
  }
  static async UPDATECDR(json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/anomalies/update`, json, await Headboard.HEADERS());

    return response;
  }
  static async REPROCESSANOMALIES(page) {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + `calls/anomalies/reprocess`, await Headboard.HEADERS());

    return response;
  }

  //Overrides
  
  static async GETOVERRIDES(page, json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/overrides/list?page=${page}`, json, await Headboard.HEADERS());

    return response;
  }
  static async UPDATEREASON(json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/overrides/update`, json, await Headboard.HEADERS());

    return response;
  }
  static async UPDATEREASONMASS(json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/overrides/updateMass`, json, await Headboard.HEADERS());

    return response;
  }
  static async REPROCESSCDR(json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/overrides/reprocess`, json, await Headboard.HEADERS());

    return response;
  }


  //CHARGES

  static async GETCALLSREPORT( json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/charges/list`, json, await Headboard.HEADERS());

    return response;
  }
  static async PROCESSUPDATE( json) {
    const response = await axios.post(CallsApi.API_URL_GLOBAL + `calls/charges/process`, json, await Headboard.HEADERS());

    return response;
  }

}
