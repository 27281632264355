import React from 'react';
import { MDBIcon } from 'mdbreact';
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';
import { Checkbox } from '@material-ui/core';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalEditSipTrunk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      nameSippy: '',
      idSippy: '',
      autoRecharge: '',
      limitBalance: '',
      limitDaily: '',
      emails: '',
      mobileNum: '',
      notes: '',
      sendCopy: '',
      username: '',
      password: '',
      gateways: '',
      isSupportChecked: false,
      support: '',
      gateways_all: '',
      id: '',
      typeSippy: '',
      listTypeSippy: [
        { label: 'Account', value: 0 },
        { label: 'Customer', value: 1 },
        { label: 'Account of Customer', value: 2 },
      ],
      sippy_user: '',
      sippy_pass: '',
      isVisible: true,
      channels: '',
      sipTrunkIp: '',
    };
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
    this.sendCredentials = this.sendCredentials.bind(this);
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  initialState() {
    const { data, customersId } = this.props;

    const typeLabel = ['Account', 'Customer', 'Account of Customer'];

    this.setState({
      customersId: customersId,
      nameSippy: data.name_sippy,
      idSippy: data.id_sippy,
      gateways: { label: data.gateways_display_name, value: data.gateways_id },
      username: data.username,
      password: data.password,
      notes: data.notes,
      limitDaily: data.limit_daily,
      limitBalance: data.limit_balance,
      autoRecharge: data.auto_recharge,
      isSupportChecked: data.send_copy === 1 ? true : false,
      emails: data.emails,
      mobileNum: data.mobile_numbers,
      id: data.id,
      sippy_user: data.sip_user_customer,
      sippy_pass: data.sip_pass_customer,
      isVisible: data.uso_interno === 1 ? false : true,
      typeSippy: { label: typeLabel[data.sip_type], value: data.sip_type },
      channels: data.channels,
      sipTrunkIp: data.sip_trunk_ip,
      sipTrunkUsername: data.sipUsername === null ? '' : data.sipUsername,
      sipTrunkPassword: data.sipPassword === null ? '' : data.sipPassword,
    });
  }

  handleChange(e, nameI, index) {
    const { isSupportChecked, isVisible } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isSupportChecked });
        break;
      case 3:
        this.setState({ [nameI]: !isVisible });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }
  async sendCredentials() {
    const { nameSippy, username, password, gateways } = this.state;
    const {
      customersId: { response },
    } = this.props;
    const object = {
      nameSippy: nameSippy,
      username: username,
      password: password,
      gateways: gateways,
    };

    const sent = await DataApi.sendCredentials(response, object);
    // console.log(sent);
    if (sent.data === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The email has been sent successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Something wrong happen',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  async update() {
    const {
      nameSippy,
      idSippy,
      autoRecharge,
      limitBalance,
      limitDaily,
      emails,
      mobileNum,
      notes,
      isSupportChecked,
      username,
      password,
      gateways,
      id,
      sipTrunkIp,
      typeSippy,
      sippy_user,
      sippy_pass,
      isVisible,
      channels,
      sipTrunkUsername,
      sipTrunkPassword,
    } = this.state;
    const {
      customersId: { response },
    } = this.props;

    const object = {
      name_sippy: nameSippy,
      id_sippy: idSippy,
      auto_recharge: autoRecharge,
      limit_balance: limitBalance,
      limit_daily: limitDaily,
      emails: emails,
      mobile_numbers: mobileNum,
      notes: notes,
      send_copy: isSupportChecked ? 1 : 0,
      username: username,
      password: password,
      gateways: gateways.value,
      id: id,
      channels: channels,
      sipTrunkIp: sipTrunkIp,
      type_Sippy: typeSippy.value,
      sippy_user: sippy_user,
      sippy_pass: sippy_pass,
      uso_interno: isVisible ? 0 : 1,
      sipTrunkUsername: sipTrunkUsername,
      sipTrunkPassword: sipTrunkPassword,
    };

    const results = await DataApi.UpdateSipTrunk(response, object, id);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.getSipTrunk();
    await this.toggle();
  }

  render() {
    const {
      idSippy,
      nameSippy,
      autoRecharge,
      limitDaily,
      limitBalance,
      mobileNum,
      emails,
      gateways,
      isSupportChecked,
      username,
      password,
      notes,
      sipTrunkIp,
      typeSippy,
      listTypeSippy,
      sippy_user,
      sippy_pass,
      isVisible,
      channels,
      sipTrunkUsername,
      sipTrunkPassword,
    } = this.state;
    const { gateways_all } = this.props;

    return (
      <React.Fragment>
        <MDBIcon icon="edit" size="lg" onClick={this.toggle} />

        <Modal size="xl" isOpen={this.state.modal}>
          <ModalHeader className="" toggle={this.toggle}>
            Edit Sippy Trunk{' '}
          </ModalHeader>
          <ModalBody>
            <h3 className="container-item mb-5">Customer Data</h3>
            <FormGroup className="d-flex">
              <div className="col-6">
                <Input nameI={'idSippy'} value={idSippy} change={this.handleChange} name={'Id Sippy'} />
              </div>
              <div className="col-6">
                <Input nameI={'nameSippy'} value={nameSippy} change={this.handleChange} name={'Name Sippy'} />
              </div>
            </FormGroup>
            <FormGroup className="d-flex">
              <div className="col-6">
                <Input nameI={'username'} value={username} change={this.handleChange} name={'Username'} />
              </div>
              <div className="col-6">
                <Input nameI={'password'} value={password} change={this.handleChange} name={'Password'} />
              </div>
            </FormGroup>
            <FormGroup className="d-flex">
              <div className="col-6">
                <Input nameI={'channels'} value={channels} change={this.handleChange} name={'Channels'} />
              </div>
              <div className="col-6">
                <Input nameI={'sipTrunkIp'} value={sipTrunkIp} change={this.handleChange} name={'Ip Authorised'} />
              </div>
            </FormGroup>
            <FormGroup className="d-flex">
              <div className="col-6">
                <Input
                  nameI={'sipTrunkUsername'}
                  value={sipTrunkUsername}
                  change={this.handleChange}
                  name={'Sip Trunk Username'}
                />
              </div>
              <div className="col-6">
                <Input
                  nameI={'sipTrunkPassword'}
                  value={sipTrunkPassword}
                  change={this.handleChange}
                  name={'Sip Trunk Password'}
                />
              </div>
            </FormGroup>
            <FormGroup className="d-flex">
              <div className="col-6">
                <label>Gateways</label>
                <SelectCmx
                  index={1}
                  value={gateways}
                  nameI={'gateways'}
                  change={this.handleChange}
                  placeholder={'Gateways'}
                  options={gateways_all}
                />
              </div>
              <div className="col-6">
                <label>Type of Account</label>
                <SelectCmx
                  index={1}
                  value={typeSippy}
                  nameI={'typeSippy'}
                  change={this.handleChange}
                  placeholder={'Type of Account'}
                  options={listTypeSippy}
                />
              </div>
            </FormGroup>
            <h3 className="container-item mb-4 mt-5">Data</h3>
            {typeSippy.value === 2 ? (
              <FormGroup className="d-flex">
                <div className="col-6">
                  <Input
                    nameI={'sippy_user'}
                    value={sippy_user}
                    change={this.handleChange}
                    name={'Username of Customer'}
                  />
                </div>
                <div className="col-6">
                  <Input
                    nameI={'sippy_pass'}
                    value={sippy_pass}
                    change={this.handleChange}
                    name={'Password of Customer'}
                  />
                </div>
              </FormGroup>
            ) : (
              ''
            )}
            <FormGroup className="d-flex">
              <div className="col-12">
                <Input type="textarea" nameI={'notes'} value={notes} change={this.handleChange} name={'Notes'} />
              </div>
            </FormGroup>
            <FormGroup className="d-flex">
              <div className="col-4">
                <Input
                  type="number"
                  nameI={'limitBalance'}
                  value={limitBalance}
                  change={this.handleChange}
                  name={'Limit Balance'}
                />
                <small className="form-text text-muted">
                  {' '}
                  Limit the account balance to trigger the alarm. If the value is zero, it will not be taken into
                  account for low balance alerts.
                </small>
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  nameI={'autoRecharge'}
                  value={autoRecharge}
                  change={this.handleChange}
                  name={'Amount to auto recharge'}
                />
                <small className="form-text text-muted">
                  Amount that is added to the account balance upon reaching the limit. If the value is zero it will only
                  send a low balance alert. Incompatible with the daily recharge system.
                </small>
              </div>
              <div className="col-4">
                <Input
                  type="number"
                  nameI={'limitDaily'}
                  value={limitDaily}
                  change={this.handleChange}
                  name={'Limit Daily'}
                />
                <small className="form-text text-muted">
                  Total balance to increase at the beginning of the day. If the value is zero, it will not be taken into
                  account for the daily recharge system. Incompatible with the low balance auto recharge.
                </small>
              </div>
            </FormGroup>
            <FormGroup className="d-flex">
              <div className="col-6">
                <Input nameI={'mobileNum'} value={mobileNum} change={this.handleChange} name={'Mobile Numbers'} />
                <small className="form-text text-muted">
                  Mobile numbers where to send an alert SMS. Numbers separated by ';' and without spaces. If the field
                  is empty, no alert will be sent. Only a single SMS will be sent when the limit is reached.
                </small>
              </div>
              <div className="col-6">
                <Input nameI={'emails'} value={emails} change={this.handleChange} name={'Emails'} />
                <small className="form-text text-muted">
                  Emails where to send an alert. Separated by ';' and without spaces. If the field is empty, only to
                  alert@megacall.es is sent. An email will be sent once the limit is reached and each section of 10%
                  below the limit.
                </small>
              </div>
            </FormGroup>
            <FormGroup className="d-flex">
              <div className="col-6">
                <Checkbox
                  index={2}
                  size="small"
                  nameI={'isSupportChecked'}
                  defaultChecked={isSupportChecked}
                  onChange={e => this.handleChange(e, 'isSupportChecked', 2)}
                />
                <label>Send a copy to Support in balance alert.</label>
                <small className="form-text text-muted">
                  If there are no emails in the upper section and it is unchecked, it will not send any alert.
                </small>
              </div>
              <div className="col-6">
                <Checkbox
                  index={3}
                  size="small"
                  nameI={'isVisible'}
                  defaultChecked={isVisible}
                  onChange={e => this.handleChange(e, 'isVisible', 3)}
                />
                <label>Visible on the customer dashboard.</label>
                <small className="form-text text-muted">
                  If it is unchecked, the client will not see it and therefore it will not count to see balances and
                  transfers..
                </small>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <div>
              <Button color="orange" onClick={ this.sendCredentials}>
                Send Credentials
              </Button>{' '}
            </div>
            <Button color="primary" onClick={this.update}>
              Save
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditSipTrunk;
