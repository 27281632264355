/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../elements/Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';
import Select from 'react-select';

class ModalCreateCustomerPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      name: '',
      priceFile: null,
      type: { label: 'Voip', value: 0 },
      type_all: [{ label: 'Voip', value: 0 }, { label: 'Dialer', value: 1 }]
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.save = this.save.bind(this);
  }
  
  toggle() {    
    this.setState(prevState => ({ modal: !prevState.modal, name: '', priceFile: null }));
  }

  
  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  onChangeFile(e) {
    const file = e.target.files;
    this.setState({ priceFile: file[0] });
  }

  async save() {
    const { name, priceFile, type  } = this.state;

    const formData = new FormData();
    formData.append('customer_price_file', priceFile);
    formData.append('name_customer_price', name);
    formData.append('type_customer_price', type.value);

    const response = await DataApi.ImportNewCustomerPrice(formData);
    if (response.data.status !== 'error') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'New Customer Price create',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'There was an error, contact support',
          showConfirmButton: false,
          timer: 1500,
        });
    }
    
    await this.props.getSelect();
    this.toggle();
  }

  render() {
    const { name, type, type_all } = this.state;

    return(
      <>
        <div className="container-button">
          <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
              <i className=" mdi mdi-plus-circle-outline"></i> Create Customer Price
          </a>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>New Customer Price</ModalHeader>

          <ModalBody>
            <div className="container-search">
              <div className="col-lg-12">
                <div className="row ml-3">
                  <div className="col-lg-12">
                    <Input nameI={'name'} value={name}  placeholder={'Name of Customer Price'} change={this.handleChange}/>
                  </div>
                </div>

                <div className="row ml-3">
                  <div className="col-lg-12">
                    <Select
                      value={type}
                      options={type_all}
                      onChange={e => this.handleChange(e, 'type', 1)}
                      className="mb-3"
                    />
                  </div>
                </div>

                <div className="row ml-3">
                  <div className="col-lg-12">
                    <input className="form-control" type="file" onChange={e => this.onChangeFile(e)} />
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={this.save}>Send</Button>{' '}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ModalCreateCustomerPrice;
