import UrlServices from "../UrlServices";
import axios from "axios";
import Headboard from '../Headboard';

export default class CallsApi {
    static API_URL_USERS = UrlServices.universal;

    static async GETUSERS(page, json) {
      const responde = await axios.post(CallsApi.API_URL_USERS + `users/list?page=${page}`, json, await Headboard.HEADERS());

      return responde;
    }

    static async GETROLES(type) {
      const response = await axios.get(CallsApi.API_URL_USERS + 'users/roles/' + type, await Headboard.HEADERS());

      return response;
    }

    static async NEWUSER(object) {
      const response = await axios.post(CallsApi.API_URL_USERS + 'users/create', object, await Headboard.HEADERS());

      return response;
    }

    static async UPDATEUSER(id, object) {
      const response = await axios.post(CallsApi.API_URL_USERS + `users/update/${id}`, object, await Headboard.HEADERS());

      return response;
    }

    static async NEWPASSWORD(id) {
      const response = await axios.get(CallsApi.API_URL_USERS + `users/newPassword/${id}`, await Headboard.HEADERS()); //AQUIIII

      return response;
    }

    static async DELETEUSER(id) {
      const response = await axios.post(CallsApi.API_URL_USERS + `users/destroy/${id}`, null, await Headboard.HEADERS());

      return response;
    }
}