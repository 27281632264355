/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class ModalValues extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      modal: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    let parse = JSON.parse(this.props.value);
    // console.log(parse);
    this.setState({ data: parse });
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { data } = this.state;
    // console.log(data);
    return (
      <>
        <div className="container-button ">
          <a onClick={this.toggle} className="container-item">
            See..
          </a>
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>
            <h3>
              {' '}
              {this.props.nameModal} {this.props.auditableType ? '|||' + this.props.auditableType : ''}{' '}
            </h3>
          </ModalHeader>
          <ModalBody>
            <div className="justify-content-center">
              <p>{data.id != null ? 'id: ' + data.id : ''}</p>
              <p>{data.name != null ? 'name: ' + data.name : ''}</p>
              <p>{data.contact_name != null ? 'contact_name: ' + data.contact_name : ''}</p>
              <p>{data.contact_number != null ? 'contact_number: ' + data.contact_number : ''}</p>
              <p>{data.address != null ? 'address: ' + data.address : ''}</p>
              <p>{data.agent_id != null ? 'agent_id: ' + data.agent_id : ''}</p>
              <p>{data.charge_accuracy_id != null ? 'charge_accuracy_id: ' + data.charge_accuracy_id : ''}</p>
              <p>{data.charge_id != null ? 'charge_id: ' + data.charge_id : ''}</p>
              <p>{data.connection_time != null ? 'connection_time: ' + data.connection_time : ''}</p>
              <p>{data.currency_id != null ? 'currency_id: ' + data.currency_id : ''}</p>
              <p>{data.customer_price_id != null ? 'customer_price_id: ' + data.customer_price_id : ''}</p>
              <p>
                {data.direct_debit_bank_account != null
                  ? 'direct_debit_bank_account: ' + data.direct_debit_bank_account
                  : ''}
              </p>
              <p>{data.email_low_balance != null ? 'email_low_balance: ' + data.email_low_balance : ''}</p>
              <p>{data.email_process_file != null ? 'email_process_file: ' + data.email_process_file : ''}</p>
              <p>
                {data.email_process_monthly_file != null
                  ? 'email_process_monthly_file: ' + data.email_process_monthly_file
                  : ''}
              </p>
              <p>{data.gdpr_concent != null ? 'gdpr_concent: ' + data.gdpr_concent : ''}</p>
              <p>{data.identification_id != null ? 'identification_id: ' + data.identification_id : ''}</p>
              <p>{data.identification_number != null ? 'identification_number: ' + data.identification_number : ''}</p>
              <p>{data.invoice_name != null ? 'invoice_name: ' + data.invoice_name : ''}</p>
              <p>{data.language_id != null ? 'language_id: ' + data.language_id : ''}</p>
              <p>{data.no_error_on_negative != null ? 'no_error_on_negative: ' + data.no_error_on_negative : ''}</p>
              <p>{data.no_error_on_positive != null ? 'no_error_on_positive: ' + data.no_error_on_positive : ''}</p>
              <p>{data.payment_method_id != null ? 'payment_method_id: ' + data.payment_method_id : ''}</p>
              <p>{data.payment_type_id != null ? 'payment_type_id: ' + data.payment_type_id : ''}</p>
              <p>{data.round_dps != null ? 'round_dps: ' + data.round_dps : ''}</p>
              <p>{data.start_date != null ? 'start_date: ' + data.start_date : ''}</p>
              <p>{data.top_up != null ? 'top_up: ' + data.top_up : ''}</p>
              <p>{data.use_agent_invoicing != null ? 'use_agent_invoicing: ' + data.use_agent_invoicing : ''}</p>
              <p>{data.use_invoicing != null ? 'use_invoicing: ' + data.use_invoicing : ''}</p>
              <p>{data.vat_id != null ? 'vat_id: ' + data.vat_id : ''}</p>
              <p>{data.enabled != null ? 'enabled: ' + data.enabled : ''}</p>
              <p>{data.contact_centre_name != null ? 'contact_centre_name: ' + data.contact_centre_name : ''}</p>
              <p>{data.login != null ? 'login: ' + data.login : ''}</p>
              <p>{data.notes != null ? 'notes: ' + data.notes : ''}</p>
              <p>{data.password != null ? 'password: ' + data.password : ''}</p>
              <p>{data.url != null ? 'url: ' + data.url : ''}</p>
              <p>{data.auto_recharge != null ? 'auto_recharge: ' + data.auto_recharge : ''}</p>
              <p>{data.emails != null ? 'emails: ' + data.emails : ''}</p>
              <p>{data.gateways != null ? 'gateways: ' + data.gateways : ''}</p>
              <p>{data.id_sippy != null ? 'id_sippy: ' + data.id_sippy : ''}</p>
              <p>{data.limit_balance != null ? 'limit_balance: ' + data.limit_balance : ''}</p>
              <p>{data.limit_daily != null ? 'limit_daily: ' + data.limit_daily : ''}</p>
              <p>{data.mobile_numbers != null ? 'mobile_numbers: ' + data.mobile_numbers : ''}</p>
              <p>{data.name_sippy != null ? 'name_sippy: ' + data.name_sippy : ''}</p>
              <p>{data.send_copy != null ? 'send_copy: ' + data.send_copy : ''}</p>
              <p>{data.licenses != null ? 'licenses: ' + data.licenses : ''}</p>
              <p>
                {data.number_simultaneous_calls != null
                  ? 'number_simultaneous_calls: ' + data.number_simultaneous_calls
                  : ''}
              </p>
              <p>{data.server_megacall != null ? 'server_megacall: ' + data.server_megacall : ''}</p>
              <p>{data.support != null ? 'support: ' + data.support : ''}</p>
              <p>{data.commission != null ? 'commission: ' + data.commission : ''}</p>
              <p>{data.from_date != null ? 'from_date: ' + data.from_date : ''}</p>
              <p>{data.immediate_balance != null ? 'immediate_balance: ' + data.immediate_balance : ''}</p>
              <p>{data.immediate_invoice != null ? 'immediate_invoice: ' + data.immediate_invoice : ''}</p>
              <p>{data.net_value != null ? 'net_value: ' + data.net_value : ''}</p>
              <p>{data.number_invoice != null ? 'number_invoice: ' + data.number_invoice : ''}</p>
              <p>{data.product_id != null ? 'product_id: ' + data.product_id : ''}</p>
              <p>{data.product_invoice != null ? 'product_invoice: ' + data.product_invoice : ''}</p>
              <p>{data.rate != null ? 'rate: ' + data.rate : ''}</p>
              <p>{data.units != null ? 'units: ' + data.units : ''}</p>
              <p>{data.recurrent != null ? 'recurrent: ' + data.recurrent : ''}</p>
              <p>{data.text != null ? 'text: ' + data.text : ''}</p>
              <p>{data.text_details != null ? 'text_details: ' + data.text_details : ''}</p>
              <p>{data.to_date != null ? 'to_date: ' + data.to_date : ''}</p>
              <p>{data.model_id != null ? 'model_id: ' + data.model_id : ''}</p>
              <p>{data.model_type != null ? 'model_type: ' + data.model_type : ''}</p>
              <p>{data.percentage != null ? 'percentage: ' + data.percentage : ''}</p>
              <p>{data.product__id != null ? 'product__id: ' + data.product__id : ''}</p>
              <p>{data.until != null ? 'until: ' + data.until : ''}</p>
              <p>{data.balance != null ? 'balance: ' + data.balance : ''}</p>
              <p>{data.added != null ? 'added: ' + data.added : ''}</p>
              <p>{data.agent_landline_price != null ? 'agent_landline_price: ' + data.agent_landline_price : ''}</p>
              <p>{data.agent_mobile_price != null ? 'agent_mobile_price: ' + data.agent_mobile_price : ''}</p>
              <p>{data.charge_inbound != null ? 'charge_inbound: ' + data.charge_inbound : ''}</p>
              <p>{data.landline_price != null ? 'landline_price: ' + data.landline_price : ''}</p>
              <p>{data.mobile_price != null ? 'mobile_price: ' + data.mobile_price : ''}</p>
              <p>{data.number != null ? 'number: ' + data.number : ''}</p>
              <p>{data.pointing_to != null ? 'pointing_to: ' + data.pointing_to : ''}</p>
              <p>{data.supplier_id != null ? 'supplier_id: ' + data.supplier_id : ''}</p>
              <p>{data.type_id != null ? 'type_id: ' + data.type_id : ''}</p>
              <p>{data.customer_id != null ? 'customer_id: ' + data.customer_id : ''}</p>
              <p>{data.value != null ? 'value: ' + data.value + ' €' : ''}</p>
              <p>{data.itent ? 'intent: ' + data.intent : ''}</p>
              <p>{data.state ? 'state: ' + data.state : ''}</p>
              <p>{data.Ds_Order ? 'redSys order Number: ' + data.Ds_Order : ''}</p>
              <p>{data.transactions ? 'amount: ' + data.transactions[0].amount.total : ''}</p>
              <p>{data.Ds_Amount ? 'amount: ' + data.Ds_Amount / 100 : ''}</p>
              <p>{data.auth ? 'auth: ' + data.auth : ''}</p>
              <p>{data.provision ? 'provision: ' + data.provision : ''}</p>
              <p>{data.provision === 1 ? 'password provision: ' + data.password : ''}</p>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ModalValues;
