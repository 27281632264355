/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';
import CheckBox from '../../../Utils/Checkbox';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import ModificationArray from '../../../../../megacall/utilsFunctions/ModificationArray';
import Swal from 'sweetalert2';

class ModalEditDid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      number: '',
      supplier: '',
      added: '',
      mobile_price: '',
      landline_price: '',
      agent_mobile_price: '',
      agent_landline_price: '',
      international_number_type_id: '',
      isCheckedCharge: false,
      notes: '',
      pointing: '',
      enabled: '',
      customer_id: '',
      suppliers_all: '',
      internationalNumber_all: '',
      added_date: '',
      disabled_date: ''
    };
    this.initialState = this.initialState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.update = this.update.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  async toggle() {
    await this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  /** Esta funcion inicializa el estado del modal edit */
  async initialState() {
    const { data } = this.props;

    const response = await DataApi.getSelect();

    const suppliers_all = ModificationArray.modification(response.data.suppliers);

    this.setState({
      id: data.id,
      enabled: data.enabled,
      number: data.number,
      supplier: { label: data.suppliers_name, value: data.suppliers_id },
      added: data.added.replaceAll('/', '-'),
      mobile_price: data.mobile_price,
      landline_price: data.landline_price,
      agent_mobile_price: data.agent_mobile_price,
      agent_landline_price: data.agent_landline_price,
      internationalNumber: {
        label: data.suppliers_name,
        value: data.suppliers_id,
      },
      isCheckedCharge: data.charge_inbound ? true : false,
      notes: data.notes,
      pointing: data.pointing_to,
      customer_id: data.customer_id,
      suppliers_all: suppliers_all,
      added_date: data.added,
      disabled_date: data.disabled_date
    });
  }

  handleChange(e, nameI, index) {
    const { isCheckedCharge } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isCheckedCharge });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async update() {
    const {
      number,
      supplier,
      added,
      mobile_price,
      landline_price,
      agent_mobile_price,
      agent_landline_price,
      internationalNumber,
      isCheckedCharge,
      notes,
      pointing,
      id,
      enabled,
      added_date,
      disabled_date
    } = this.state;
    const {
      customersId: { response },
      index,
    } = this.props;

    const object = {
      number: number,
      supplier: supplier.value,
      added: added,
      mobile_price: mobile_price,
      landline_price: landline_price,
      agent_mobile_price: agent_mobile_price,
      agent_landline_price: agent_landline_price,
      international_number_type_id: internationalNumber.value,
      charge_inbound: isCheckedCharge ? 1 : 0,
      notes: notes,
      pointing: pointing,
      customer_id: response,
      enabled: enabled,
      added_date: added_date,
      disabled_date: disabled_date
    };

    const results =
      index === 1 ? await DataApi.UpdateEditDid(id, object) : await DataApi.UpdateEditDidMobile(id, object);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.getDid();
    await this.toggle();
  }

  render() {
    const {
      number,
      supplier,
      mobile_price,
      landline_price,
      agent_landline_price,
      agent_mobile_price,
      isCheckedCharge,
      notes,
      pointing,
      suppliers_all,
      added_date,
      disabled_date
    } = this.state;
    const { index } = this.props;

    return (
      <React.Fragment>
        <div className="container-button">
          <a onClick={this.toggle}>
            <i className="mdi mdi-square-edit-outline mdi-24px"></i>
          </a>
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Edit Contacts</ModalHeader>
          <ModalBody>
            <Input nameI={'number'} type={'number'} value={number} change={this.handleChange} name={'Number'} />
            <Input nameI={'added_date'} value={added_date} change={this.handleChange} name={'Added'} type={'date'}/>
            <Input nameI={'disabled_date'} value={disabled_date} change={this.handleChange} name={'Disabled'} type={'date'}/>
            {index === 1 ? (
              <SelectCmx
                index={1}
                nameI={'supplier'}
                value={supplier}
                change={this.handleChange}
                name={'Supplier'}
                options={suppliers_all}
              />
            ) : null}
            {index === 1 ? (
              isCheckedCharge ? (
                <React.Fragment>
                  <div className="form-group mt-3">
                    <Input
                      nameI={'landline_price'}
                      value={landline_price}
                      change={this.handleChange}
                      name={'Landline Price'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <Input
                      nameI={'mobile_price'}
                      value={mobile_price}
                      type={'number'}
                      change={this.handleChange}
                      name={'Mobile Price'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <Input
                      nameI={'agent_landline_price'}
                      value={agent_landline_price}
                      change={this.handleChange}
                      name={'Agent Landline Price'}
                    />
                  </div>
                  <div className="form-group mt-3">
                    <Input
                      nameI={'agent_mobile_price'}
                      value={agent_mobile_price}
                      change={this.handleChange}
                      name={'Agent Mobile Price'}
                    />
                  </div>                  
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="form-group mt-3">
                    <label>Landline Price</label>
                    <input className="form-control" disabled />
                  </div>
                  <div className="form-group">
                    <label>Mobile Price</label>
                    <input className="form-control" disabled />
                  </div>
                  <div className="form-group">
                    <label>Agent Landline Price</label>
                    <input className="form-control" disabled />
                  </div>
                  <div className="form-group">
                    <label>Agent Mobile Price</label>
                    <input className="form-control" disabled />
                  </div>                  
                </React.Fragment>
              )
            ) : null}
            {index === 1 ? (
              <React.Fragment>
                <CheckBox
                  nameI={'isCheckedCharge'}
                  toggleChange={this.handleChange}
                  isChecked={isCheckedCharge}
                  index={2}
                  name={'Charge'}
                  className="mt-2"
                />
                <Input nameI={'pointing'} value={pointing} change={this.handleChange} name={'Pointing to'} />
              </React.Fragment>
            ) : null}
            <label>Notes</label>
            <textarea
              className="form-control"
              id="notes"
              value={notes}
              name="notes"
              onChange={e => this.handleChange(e)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.update}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditDid;
