export default class CallsStorage {
  static GetStoragePass(key) {
    // const response = sessionStorage.getItem(key);
    const response = localStorage.getItem(key);
    const result = JSON.parse(response);
    // const result2 = JSON.parse(response2);

    if (response) {
      return result;
      // }else if (response2) {
      //     return result2;
    } else {
      return false;
    }
  }

  static SaveStorage(key, object) {
    // sessionStorage.setItem(key, object);
    localStorage.setItem(key, object);
  }
  static SaveStorageLocal(key) {
    localStorage.setItem(key);
  }

  static async GetStorageToken(key) {
    // const response = sessionStorage.getItem(key);
    const response = localStorage.getItem(key);
    const result = JSON.parse(response);
    // const result2 = JSON.parse(response2);

    if (result) {
      return result.token;
      // }
      // else if(result2) {
      //     return result2.token;
    } else {
      return false;
    }
  }

  static RemoveStorage(key) {
    // sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }
}
