import React from 'react';

import Select from 'react-select';

class SelectCmx extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            color: ''
        }
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(value, item, index) {
        this.props.change(value, item, index);
    }

    componentDidMount() {
        const { color } = this.props;

        this.setState({ color: color});
    }

    render() {
        const { value, index, name, options, nameI, validate, placeholder, id, selected, isDisabled, defaultValue} = this.props;
        const { color } = this.state;

        return (
            <div className="container-group mt-2">
                { !name ? null : <label>{name}</label>}
                <Select
                    classNamePrefix={validate}
                    options={options}
                    value={value}
                    className={`select-control ${color}`}
                    placeholder={placeholder}
                    onChange={(e) => this.handleChange(e, nameI, index)}
                    id={id}
                    selected={selected}
                    isDisabled={isDisabled}
                    defaultValue={defaultValue}
                  
                    
                    
                 />
                 
            </div>
        );
    }
}

export default SelectCmx;