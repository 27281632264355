/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Swal from 'sweetalert2';

import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/SuppliersServices/DataApi';

class ModalEditPriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      prefix: '',
      connection_charge: '',
      peak_rate: '',
      charge_seconds: '',
    };
    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.send = this.send.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async send() {
    const { prefix, connection_charge, peak_rate, charge_seconds } = this.state;
    const { id } = this.props;

    const json = {
      supplier_id: id,
      prefix: prefix,
      peak_rate: peak_rate,
      connection_charge: connection_charge,
      charge_seconds: charge_seconds,
    };

    const result = await DataApi.InsertSuppilerPrefix(json);

    console.log(result);
    if (result.data.code === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: result.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.props.getSuppliersData();

    this.setState(prevState => ({
      modal: !prevState.modal,
      prefix: '',
      connection_charge: '',
      peak_rate: '',
      charge_seconds: '',
    }));
  }

  render() {
    const { prefix, connection_charge, peak_rate, charge_seconds } = this.state;

    return (
      <React.Fragment>
        <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase"
         style={{ width: '200px', height: '39px', marginTop: '4px', marginLeft: '20px', paddingTop: '9px' }}
        >
          <i className=" mdi mdi-plus-circle-outline"></i> Add Price
        </a>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Edit Price</ModalHeader>
          <ModalBody>
            <Input nameI={'prefix'} type={'number'} value={prefix} change={this.handleChange} name={'Prefix'} />
            <Input
              nameI={'connection_charge'}
              type={'number'}
              value={connection_charge}
              change={this.handleChange}
              name={'Connection Charge'}
            />
            <Input
              nameI={'peak_rate'}
              type={'number'}
              value={peak_rate}
              change={this.handleChange}
              name={'Peak Rate'}
            />
            <Input
              nameI={'charge_seconds'}
              type={'number'}
              value={charge_seconds}
              change={this.handleChange}
              name={'Charge Seconds'}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.send}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditPriceList;
