import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import { MDBIcon } from 'mdbreact';

class ModalNewEmails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      main: '',
      id: '',
      modal: false,
      type: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.initialState = this.initialState.bind(this);
  }

  initialState() {
    const { data } = this.props;
    // console.log(data);
    this.setState({ email: data.email, main: data.main, id: data.id, type: data.email_type_id });
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async update() {
    const { main, email, id, type } = this.state;
    const {
      customersId: { response },
    } = this.props;

    const object = {
      email: email,
      main: main,
      customer_id: response,
      email_type_id: type,
    };

    const results = await DataApi.UpdateEmails(id, object);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.getEmails();
    await this.toggle();
  }

  render() {
    const { email, type, main } = this.state;

    return (
      <React.Fragment>
        <div className="container-button container-item">
          <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Edit Emails</ModalHeader>
          <ModalBody>
            <Input nameI={'email'} value={email} change={this.handleChange} name={'Email'} />

            <label>Type</label>
            <select name="type" className="form-control mb-2" selected={type} value={type} onChange={this.handleChange}>
              <option selected="select..." disabled>
                Select...
              </option>
              <option value="1">Authorized</option>
              <option value="2">Support</option>
              <option value="3">Accounts</option>
              <option value="4">Invoice</option>
            </select>
            {type === '1' || type === 1 ? (
              <>
                <label>Send Email</label>
                <select name="main" className="form-control" selected={main} value={main} onChange={this.handleChange}>
                  <option selected="select..." disabled>
                    Select...
                  </option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </>
            ) : (
              ''
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.update}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewEmails;
