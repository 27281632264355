/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../Utils/Input';
import SelectCmx from '../../Utils/Select';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';

class ModalNewCustomerPrice extends React.Component {
  constructor(props) {
      super(props);
      
      
      this.state = {
          modal: false,
          description: '',
          price: '',
          charge: '',
          prefix_translate: '',
          customer_price_id: ''
      };
      this.toggle = this.toggle.bind(this);
      this.hanleChange = this.hanleChange.bind(this);
      this.save = this.save.bind(this);
  }
  
  toggle() {
      const { customer_price_id } = this.props;
      
      this.setState(prevState => ({
          modal: !prevState.modal, customer_price_id: customer_price_id
      }));
  }
  
  async save() {
      const { prefix_translate, price, charge, customer_price_id } = this.state;
      
      const object = {
          prefix_translate: prefix_translate.value,
          customer_price_id: customer_price_id,
          price: price,
          connection_charge: charge
      }
      
      const response = await DataApi.CreateCustomerPrice(object);
  
      if (response.status === 200) {
          Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'New price added',
              showConfirmButton: false,
              timer: 1500,
          });
      } else {
          Swal.fire({
              position: 'center',
              icon: 'error',
              title: 'There was an error, contact support',
              showConfirmButton: false,
              timer: 1500,
          });
      }
      
      await this.props.getListCustomerPrice();
      this.toggle();
  }
  
  hanleChange(e, nameI, index) {
      
      switch (index) {
          case 1:
              this.setState({ [nameI]: e });
              break;
          default:
              this.setState({ [e.target.name]: e.target.value });
              break;
      }
  }
  
  render() {
    const { price, charge, prefix_translate } = this.state;
    const { prefix_translate_all } = this.props;
    
    return (
      <React.Fragment>
        <a onClick={this.toggle} className="btn btn-outline-primary mt-4 mb-5">Add Price</a>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>New Price</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <SelectCmx nameI={'prefix_translate'} value={prefix_translate} change={this.hanleChange} index={1} options={prefix_translate_all} />
              <Input nameI={'price'} value={price} change={this.hanleChange} name={'Price'} type={'number'} />
              <Input nameI={'charge'} value={charge} change={this.hanleChange} name={'Connection Charge'} type={'number'} />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>Send</Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewCustomerPrice;