import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import TableGateways from '../../../elements/SettingsElements/Gateways/TableGateways';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import Pagination from 'react-js-pagination';
import ModalNewGateway from '../../../elements/SettingsElements/Gateways/Modal/ModalNewGateway';

class Gateway extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: "",
            enabled: 1
        }
        this.getGatewaysData = this.getGatewaysData.bind(this);
    }
    
    async componentDidMount() {
        await this.getGatewaysData();
    }
    
    async getGatewaysData(page = 1) {
        const { enabled } = this.state;
        
        const json = {
            enabled: enabled
        }
        
        const results = await DataApi.GetListGateways(page, json);
        
        this.setState({ data: results.data});
    }
    
    render() {
        const { data } = this.state;
        const { current_page, total, per_page } = this.state.data;
        
        return (
                <React.Fragment>
                    <div>
                        <Row>
                            <h1 className="title-table">GATEWAY</h1>
                            <Col md={12}>
                                <Card>
                                    <div className="container-input-modal">
                                        <ModalNewGateway getGatewaysData={this.getGatewaysData}/>
                                    </div>
                                    <br/>
                                    <br/>
                                    <div className="container-table-all">
                                        <TableGateways data={data} getGatewaysData={this.getGatewaysData}/>
                                    </div>
                                    
                                    <Pagination
                                        totalItemsCount={total}
                                        onChange={(page) => this.getGatewaysData(page)}
                                        activePage={current_page}
                                        itemsCountPerPage={per_page}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
        );
    }
}

export default Gateway;