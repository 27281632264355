/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import SelectCmx from '../../../elements/Utils/Select';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import '@reach/listbox/styles.css';
import { CSVLink } from 'react-csv';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnsOptions: [],
      select: [],
      orderBy: [],
      conditions: [],
      selectedd: '',
      operators: [],
      // options: [{ label: 'One', value: 1 }, { label: 'Two', value: 2 }, { label: 'Three', value: 3 }],
      // selected: [1, 2],
    };

    this.handleChange = this.handleChange.bind(this);
    this.getSelects = this.getSelects.bind(this);
    this.getConditions = this.getConditions.bind(this);
    this.addtoSelect = this.addtoSelect.bind(this);
    this.getReport = this.getReport.bind(this);
  }

  componentDidMount() {
    this.getSelects();
    this.getConditions();
  }

  handleChange(e, nameI, index) {
    console.log(e);
    var operators = [];
    switch (index) {
      case 1:
        var prueba = { [nameI]: e.target.value };
        this.setState({ [nameI]: e.target.value, operators: [...this.state.operators, prueba] });
        break;
      case 2:
        var prueba2 = { [nameI]: e.target.value };
        this.setState({ [nameI]: e.target.value, operators: [...this.state.operators, prueba2] });

        break;
      case 3:
        var prueba3 = { [nameI]: e.target.value };
        this.setState({ [nameI]: e.target.value, operators: [...this.state.operators, prueba3] });
        break;
      case 4:
        var prueba4 = { [nameI + ' between ']: e.target.value };
        this.setState({ [nameI + ' between ']: e.target.value, operators: [...this.state.operators, prueba4] });
        break;
      case 5:
        var prueba5 = { ' and ': e.target.value };
        this.setState({ [nameI + ' and ']: e.target.value, operators: [...this.state.operators, prueba5] });
        break;
      case 6:
        var prueba6 = { [nameI]: e.target.value };
        this.setState({ [nameI]: e.target.value, operators: [...this.state.operators, prueba6] });
        break;

      case 7:
        var prueba7 = { [nameI + ' equal']: e.target.value };
        this.setState({ [nameI + ' equal']: e.target.value, operators: [...this.state.operators, prueba7] });
        break;
      case 8:
        var prueba8 = { [nameI + ' notEqual']: e.target.value };
        this.setState({ [nameI + ' notEqual']: e.target.value, operators: [...this.state.operators, prueba8] });
        break;
      case 9:
        var prueba9 = { [nameI + ' lessThan']: e.target.value };
        this.setState({ [nameI + ' lessThan']: e.target.value, operators: [...this.state.operators, prueba9] });
        break;
      case 10:
        var prueba10 = { [nameI + ' LessEqual']: e.target.value };
        this.setState({ [nameI + ' LessEqual']: e.target.value, operators: [...this.state.operators, prueba10] });
        break;
      case 11:
        var prueba11 = { [nameI + ' biggerThan']: e.target.value };
        this.setState({ [nameI + ' biggerThan']: e.target.value, operators: [...this.state.operators, prueba11] });
        break;
      case 12:
        var prueba12 = { [nameI + ' biggerEqual']: e.target.value };
        this.setState({ [nameI + ' biggerEqual']: e.target.value, operators: [...this.state.operators, prueba12] });
        break;
      case 13:
        this.setState({ [nameI]: e.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async getSelects() {
    const getSelects = await DataApi.getSelects();
    console.log(getSelects);
    this.setState({
      columnsOptions: getSelects.data[1],
      tables: getSelects.data[0],
    });

    let sel = [];

    this.state.tables.forEach(element => {
      sel = [...sel, { label: element.tabla, value: element.nombre_interno }];
    });

    this.setState({ sel: sel });
  }

  async getConditions() {
    const get = await DataApi.getSelects();
    const filters = get.data[1];
    this.setState({
      conditions: filters,
    });
  }

  addtoSelect(item, index) {
    console.log(item);
    const select = [];
    var { columnsOptions } = this.state;
    // select.push(item);
    this.setState({ select: [...this.state.select, item] });
    console.log(select);
    const prueba = columnsOptions.splice(index, 1);

    prueba.forEach(element => {
      this.setState({ selectedd: [...this.state.selectedd, element] });
    });
  }

  async send() {
    const { table, operators, selectedd, orderBy } = this.state;

    const object = {
      table,
      operators,
      selected: selectedd,
      orderBy,
    };

    const result = await DataApi.getData(object);
    console.log(result);
    this.setState({ reporte: result.data });

    setTimeout(() => {
      this.getReport();
    }, 500);
  }

  getReport() {
    var csv = document.getElementById('csv');

    csv.click();

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  }

  addtoOrderBy(name) {
    const orderBy = [];
    orderBy.push(name);
  }

  removeFromSelected(item, index) {
    const { select } = this.state;
    select.splice(index, 1);
    console.log(item);

    this.setState({ columnsOptions: [...this.state.columnsOptions, item] });
  }
  render() {
    // console.log(this.state.columnsOptions);
    const { columnsOptions, select, conditions, reporte, table, selectedd, operators } = this.state;

    if (!columnsOptions) {
      return null;
    }
    return (
      <>
        <div className="container-fluid">
          <Row>
            <h1 className="title-table">Report System</h1>
            <div className="">
              <div className="">
                <div className="col-6 mb-5 container">
                  <SelectCmx options={this.state.sel} change={e => this.handleChange(e, 'table', 13)} />
                </div>
                {table ? (
                  <>
                    <div className="row ml-3">
                      <Col md={4}>
                        <div className="mb-5 form-group">
                          <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                >
                                  <i className="mdi mdi-format-columns mdi-24px" />
                                  Columns
                                </button>
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  {columnsOptions.map((item, index) => (
                                    <tr key={index}>
                                      <a className="" onClick={() => this.addtoSelect(item, index)}>
                                        {item.nombre}{' '}
                                        <i className="mdi mdi-plus-circle-outline text-primary mdi-24px ml-3" />
                                      </a>
                                    </tr>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={4}>
                        {select.length > 0 ? (
                          <>
                            {' '}
                            <div className="mb-5 form-group">
                              <div className="accordion" id="accordionExample123">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="heading123">
                                    <button
                                      className="accordion-button"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse123"
                                      aria-expanded="true"
                                      aria-controls="collapse123"
                                    >
                                      Selected
                                    </button>
                                  </h2>
                                  <div
                                    id="collapse123"
                                    className="accordion-collapse collapse show"
                                    aria-labelledby="heading123"
                                    data-bs-parent="#accordionExample123"
                                  >
                                    <div className="accordion-body">
                                      <div className="form-group">
                                        {select.map((item, index) => (
                                          <a key={index}>
                                            <ul onClick={() => this.removeFromSelected(item, index)}>
                                              {item.nombre}{' '}
                                              <i className="mdi mdi-trash-can-outline text-danger mdi-24px ml-3" />
                                            </ul>
                                          </a>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div className="mb-5 form-group">
                              <div className="accordion" id="accordionExample123">
                                <div className="accordion-item">
                                  <h2 className="accordion-header" id="heading123">
                                    <button
                                      className="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapse123"
                                      aria-expanded="true"
                                      aria-controls="collapse123"
                                    >
                                      Selected
                                    </button>
                                  </h2>
                                  <div
                                    id="collapse123"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="heading123"
                                    data-bs-parent="#accordionExample123"
                                  >
                                    <div className="accordion-body">
                                      <div className="form-group">
                                        {/* <p>
                                      SELECT {select} FROM {selectTable} WHERE {conditions} ORDER BY {orderBy}
                                    </p> */}
                                        {select.map((item, index) => (
                                          <a key={index}>
                                            <ul onClick={() => this.removeFromSelected(item, index)}>
                                              {item.nombre}{' '}
                                              <i className="mdi mdi-trash-can-outline text-danger mdi-24px ml-3" />
                                            </ul>
                                          </a>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                      <Col md={4}>
                        <div className="mb-5 form-group">
                          <div className="accordion" id="accordionExamplee">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="headinge">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapsee"
                                  aria-expanded="true"
                                  aria-controls="collapsee"
                                >
                                  <i className="mdi mdi-filter mdi-24px" />
                                  Filters
                                </button>
                              </h2>
                              <div
                                id="collapsee"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headinge"
                                data-bs-parent="#accordionExamplee"
                              >
                                <div
                                  className="accordion-body"
                                  style={{ height: '500px', overflow: 'scroll', overflowX: 'hidden' }}
                                >
                                  {conditions.map((option, index) => (
                                    <>
                                      <div key={index} className="form-group">
                                        <div className="mb-5 form-group">
                                          <div className="accordion" id={'accordionExample' + index}>
                                            <div className="">
                                              <p className="accordion-header" id={'heading' + index}>
                                                <p
                                                  className="accordion-button collapsed"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={'#collapse' + index}
                                                  aria-expanded="true"
                                                  aria-controls={'collapse' + index}
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  {option.nombre}
                                                </p>
                                              </p>
                                              <div
                                                id={'collapse' + index}
                                                className="accordion-collapse collapse"
                                                aria-labelledby={'heading' + index}
                                                data-bs-parent={'#accordionExample' + index}
                                              >
                                                <div className="accordion-body">
                                                  <div className="form-group">
                                                    {option.tipo === 1 && option.nombre === 'Id' ? (
                                                      <>
                                                        {' '}
                                                        <div className="form-group">
                                                          <label className="">Id:</label>
                                                          <input
                                                            type="text"
                                                            name="text"
                                                            className="form-control mt-1"
                                                            placeholder="Id"
                                                            onBlur={e => this.handleChange(e, option.nombre_interno, 1)}
                                                          />
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.nombre === 'Language' ? (
                                                      <>
                                                        <select
                                                          className="form-control"
                                                          onChange={e => this.handleChange(e, option.nombre_interno, 2)}
                                                        >
                                                          <option disabled selected>
                                                            Select...
                                                          </option>
                                                          <option value={1}>Spanish</option>
                                                          <option value={2}>English</option>
                                                        </select>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.nombre === 'Currency' ? (
                                                      <>
                                                        <select
                                                          className="form-control"
                                                          onChange={e => this.handleChange(e, option.nombre_interno, 2)}
                                                        >
                                                          <option disabled selected>
                                                            Select...
                                                          </option>
                                                          <option value={1}>EUR</option>
                                                          <option value={2}>GBP</option>
                                                          <option value={3}>USD</option>
                                                        </select>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.nombre === 'Payment Method' ? (
                                                      <>
                                                        <select
                                                          className="form-control"
                                                          onChange={e => this.handleChange(e, option.nombre_interno, 2)}
                                                        >
                                                          <option disabled selected>
                                                            Select...
                                                          </option>
                                                          <option value={1}>Prepaid</option>
                                                          <option value={2}>Postpaid</option>
                                                        </select>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.nombre === 'Payment Type' ? (
                                                      <>
                                                        <select
                                                          className="form-control"
                                                          onChange={e => this.handleChange(e, option.nombre_interno, 2)}
                                                        >
                                                          <option disabled selected>
                                                            Select...
                                                          </option>
                                                          <option value={1}>Bank Transfer</option>
                                                          <option value={2}>Cash Deposit</option>
                                                          <option value={3}>Cash Office</option>
                                                          <option value={4}>Credit Card</option>
                                                          <option value={5}>Direct Debit</option>
                                                          <option value={6}>Paypal</option>
                                                          <option value={7}>Other</option>
                                                        </select>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.nombre === 'Color' ? (
                                                      <>
                                                        <select
                                                          className="form-control"
                                                          onChange={e => this.handleChange(e, option.nombre_interno, 2)}
                                                        >
                                                          <option disabled selected>
                                                            Select...
                                                          </option>
                                                          <option value={1}>White</option>
                                                          <option value={2}>Blue</option>
                                                          <option value={3}>Green</option>
                                                          <option value={4}>Orange</option>
                                                          <option value={5}>Yellow</option>
                                                          <option value={6}>Red</option>
                                                        </select>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.tipo === 2 ? (
                                                      <>
                                                        <select
                                                          className="form-control"
                                                          onChange={e => this.handleChange(e, option.nombre_interno, 2)}
                                                        >
                                                          <option disabled selected>
                                                            Select...
                                                          </option>
                                                          <option value={1}>Yes</option>
                                                          <option value={0}>No</option>
                                                        </select>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.tipo === 3 ? (
                                                      <>
                                                        <div className="form-group">
                                                          <label>Date:</label>
                                                          <input
                                                            onChange={e =>
                                                              this.handleChange(e, option.nombre_interno, 3)
                                                            }
                                                            type="date"
                                                            name="dateFrom"
                                                            className="form-control mt-1"
                                                            placeholder="From"
                                                          />
                                                        </div>

                                                        <div className="row top-information mt-3 ml-3 container-item">
                                                          <div className="col-sm-6 mt-3">
                                                            <label>Between:</label>
                                                            <input
                                                              onChange={e =>
                                                                this.handleChange(e, option.nombre_interno, 4)
                                                              }
                                                              type="date"
                                                              name="dateTo"
                                                              className="form-control mt-1 mb-2"
                                                              placeholder="To"
                                                            />
                                                          </div>
                                                          <div className="col-sm-6 mt-3">
                                                            <label>And:</label>
                                                            <input
                                                              onChange={e =>
                                                                this.handleChange(e, option.nombre_interno, 5)
                                                              }
                                                              type="date"
                                                              name="dateTo"
                                                              className="form-control mt-1"
                                                              placeholder="To"
                                                            />
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.tipo === 4 ? (
                                                      <>
                                                        <label>Like:</label>
                                                        <input
                                                          type="text"
                                                          name="text"
                                                          className="form-control mt-1"
                                                          placeholder="Like..."
                                                          onBlur={e => this.handleChange(e, option.nombre_interno, 6)}
                                                        />
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                    {option.tipo === 5 ? (
                                                      <>
                                                        <label className="mt-3">Equal:</label>
                                                        <input
                                                          type="text"
                                                          name="text"
                                                          className="form-control mt-1"
                                                          placeholder="="
                                                          onBlur={e => this.handleChange(e, option.nombre_interno, 7)}
                                                        />

                                                        <label className="mt-3">Not Equal:</label>
                                                        <input
                                                          type="text"
                                                          name="text"
                                                          className="form-control mt-1"
                                                          placeholder="!="
                                                          onBlur={e => this.handleChange(e, option.nombre_interno, 8)}
                                                        />
                                                        <label className="mt-3">Less Than:</label>
                                                        <input
                                                          type="text"
                                                          name="text"
                                                          className="form-control mt-1"
                                                          placeholder="<"
                                                          onBlur={e => this.handleChange(e, option.nombre_interno, 9)}
                                                        />
                                                        <label className="mt-3">Less Than or Equals</label>
                                                        <input
                                                          type="text"
                                                          name="text"
                                                          className="form-control mt-1"
                                                          placeholder="<="
                                                          onBlur={e => this.handleChange(e, option.nombre_interno, 10)}
                                                        />
                                                        <label className="mt-3">Greater Than:</label>
                                                        <input
                                                          type="text"
                                                          name="text"
                                                          className="form-control mt-1"
                                                          placeholder=">"
                                                          onBlur={e => this.handleChange(e, option.nombre_interno, 11)}
                                                        />

                                                        <label className="mt-3">Greater Than or Equals:</label>
                                                        <input
                                                          type="text"
                                                          name="text"
                                                          className="form-control mt-1"
                                                          placeholder=">="
                                                          onBlur={e => this.handleChange(e, option.nombre_interno, 12)}
                                                        />
                                                      </>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      {table && selectedd ? (
                        <>
                          {' '}
                          <div className="container-item">
                            <button className="btn btn-primary" onClick={() => this.send()}>
                              Get Report
                            </button>
                            {reporte ? (
                              <div style={{ visibility: 'hidden' }}>
                                <CSVLink
                                  data={reporte}
                                  filename={`Report.csv`}
                                  className="btn btn-orange btn-sm text-uppercase ml-3"
                                  target="_blank"
                                  id="csv"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>{' '}
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Row>
        </div>
      </>
    );
  }
}

export default Reports;
