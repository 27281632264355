/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import { CSVLink } from 'react-csv';

class Accounting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      data2: '',
      date: '',
      secondReady: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.getInfo = this.getInfo.bind(this);
    this.getBothCSV = this.getBothCSV.bind(this);
    this.close = this.close.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      default:
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
          this.getInfo();
        }, 500);

        break;
    }
  }

  close() {
    setTimeout(() => {
      this.toggle();
      window.location.reload(true);
    }, 4000);
  }

  async getInfo() {
    const { date } = this.state;

    const object = {
      date: date,
    };

    const response = await DataApi.getInfoInvoices(object);
    console.log(response);
    if (response.data.status === 500) {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'There are no invoices for the selected month',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK!',
      });
      this.setState({
        data: null,
      });
    } else {
      this.setState({
        data: response.data,
      });
    }
  }

  getBothCSV() {
    setTimeout(() => {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The CSV has been generated',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
      });
    }, 500);
    this.getInfo();
  }

  render() {
    const { date, data } = this.state;

    return (
      <>
        <a onClick={() => this.toggle()} className="btn btn-green mt-3 mb-3">
          Accounting
        </a>

        <Modal isOpen={this.state.modal}>
          <ModalHeader className="" toggle={this.toggle}>
            Accounting CSV
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Date</label>
                  <input type="date" className="form-control" value={date} onChange={this.handleChange} name="date" />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {data ? (
              <a className="btn btn-green ml-3">
                <CSVLink
                  data={data}
                  filename={'invoiceExport.csv'}
                  separator={'#'}
                  target="_blank"
                  className="text-white"
                  onClick={() => this.getBothCSV()}
                >
                  <i className=" mdi mdi-export"></i> Export CSV
                </CSVLink>
              </a>
            ) : (
              ''
            )}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Accounting;
