import React from 'react';
import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import SelectCmx from '../../Utils/Select';
import CheckBox from '../../Utils/Checkbox';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import ModalEditMinutes from './Modal/ModalEditMinutes';
import ModalAddBono from './Modal/ModalAddBono';
import { MDBIcon } from 'mdbreact';

class TablePackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      isCheckedPackage: false,
      packages: '',
      packages_name: '',
      package_father_id: ''
    };
    this.getPackages = this.getPackages.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.savePackage = this.savePackage.bind(this);
  }

  async componentDidMount() {
    await this.getPackages();
  }

  async getPackages(page = 1) {
    const { customersId, packages } = this.props;

    const packages_select = ModificationArray.modification(packages);
    const response = await DataApi.GetPackages(customersId.response, page);
    const packages_name = {
      label: response.data.customer.use_package ? response.data.customer.package_name : '',
      value: response.data.customer.use_package ? response.data.customer.package_father_id : '',
    };
    this.setState({ data: response.data.list, isCheckedPackage: response.data.customer.use_package ? true : false, packages: packages_select, packages_name: packages_name, package_father_id: response.data.customer.package_father_id });
  }

  handleChange(e, nameI, index) {
    const { isCheckedPackage } = this.state;
    if (index === 2) {
      this.setState({ [nameI]: !isCheckedPackage });
    } else {
      this.setState({ [nameI]: e });
    }
  }

  async savePackage() {
    const { isCheckedPackage, packages_name } = this.state;
    const { customersId } = this.props;

    const object = { use_package: isCheckedPackage ? 1 : 0, package_id: packages_name.value };
    const response = await DataApi.UpdatePackages(customersId.response, object);

    if (response.status === 200) {
      this.getPackages();
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  async destroyPackage(id) {
    const response = await DataApi.DestroyPackage(id);

    if (response.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.getPackages();
  }

  render() {
    const { data: { data }, packages_name, isCheckedPackage, packages, package_father_id } = this.state;
    const { current_page, total, per_page } = this.state.data;
    const { lock } = this.props;
    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="card-body pt-5 border-top"></div>
          <div className="container-table-all">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Minutes Left</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="container-item-10">{item.start_date}</td>
                    <td className="container-item-11">{item.end_date}</td>
                    <td className="container-item-11">{item.time_left / 60}</td>                   
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Pagination
            totalItemsCount={total}
            onChange={page => this.getAudit(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="card-body pt-5 border-top">
          <div className="row">
            <div className="col-sm-6">
              <div className="control-indicator">
                <CheckBox
                  nameI={'isCheckedPackage'}
                  toggleChange={this.handleChange}
                  isChecked={isCheckedPackage}
                  index={2}
                />
                <label className="form-check-label">Use package</label>
              </div>
            </div>
            <div className="col-sm-6">
              {isCheckedPackage === true ? (
                <div className="form-group">
                  <SelectCmx
                    index={1}
                    nameI={'packages_name'}
                    value={packages_name}
                    change={this.handleChange}
                    name={'Package'}
                    options={packages}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="row">
            <div className="container-button">
              <button type="submit" className="btn btn-success" onClick={this.savePackage}>
                <i className=" mdi mdi-content-save mdi-22px"></i> Save
              </button>
              {isCheckedPackage === true && (package_father_id === 13 || package_father_id === 14 || package_father_id === 15)?
              <ModalAddBono />
              : ""}
            </div>            
          </div>
        </div>
        <div className="container-table-all">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Minutes Left</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item-10">{item.start_date}</td>
                  <td className="container-item-11">{item.end_date}</td>
                  <td className="container-item-11">{item.time_left / 60}</td>
                  <td className="container-item d-flex">
                    <ModalEditMinutes item={item.id} minutes={item.time_left / 60} getPackages={this.getPackages} />
                    <MDBIcon
                        icon="trash"
                        size="lg"
                        className="trash ml-2"
                        onClick={() => this.destroyPackage(item.id)}
                      />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          totalItemsCount={total}
          onChange={page => this.getAudit(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TablePackages;
