/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Input from '../../../Utils/Input';
import Swal from 'sweetalert2';
import DataApi from '../../../../../megacall/services/Customers/DataApi';

class ModalNewEmails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      main: '1',
      modal: false,
      type: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async save() {
    const { main, email, type } = this.state;
    const {
      customersId: { response },
    } = this.props;

    const object = {
      email: email,
      main: main,
      customer_id: response,
      email_type_id: type,
    };

    const results = await DataApi.NewEmails(object);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.getEmails();
    await this.toggle();
    this.setState({ email: '', main: '1', type: '' });
  }

  render() {
    const { email, type } = this.state;

    return (
      <React.Fragment>
        <div className="container-button">
          <h3>Emails List</h3>
          <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
            <i className=" mdi mdi-plus-circle-outline"></i> Add Emails
          </a>
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>New Email</ModalHeader>
          <ModalBody>
            <Input nameI={'email'} value={email} change={this.handleChange} name={'Email'} />

            <label>Type</label>
            <select name="type" className="form-control mb-2" onChange={this.handleChange}>
              <option selected="select..." disabled>
                Select...
              </option>
              <option value="1">Authorized</option>
              <option value="2">Support</option>
              <option value="3">Accounts</option>
              <option value="4">Invoice</option>
            </select>

            {type === '1' ? (
              <>
                <label>Send Email</label>
                <select name="main" className="form-control" onChange={this.handleChange}>
                  <option selected="select..." disabled>
                    Select...
                  </option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </>
            ) : (
              ''
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.save}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewEmails;
