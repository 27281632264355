import React from 'react';

import TableCommissions from '../../elements/AgentsElements/Commisions/TableCommisions';
import Pagination from 'react-js-pagination';
import ModalCommissions from '../../elements/AgentsElements/Modal/ModalCommissions';
import DataApi from '../../../megacall/services/AgentsServices/DataApi';

import Swal from 'sweetalert2';

import { Col, Row } from 'reactstrap';

class Commissions extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: '',
            isCheck: true,
            id: 0,
            percentage: '',
            until: '',
            description: '',
            product_id: '',
            start_date: '',
            untilApi: '',
        };
        this.getAgentsData = this.getAgentsData.bind(this);
        this.destroy = this.destroy.bind(this);
    }
    
    async componentDidMount() {
        this.setState({ isCheck: false });
        
        await this.getAgentsData();
    }
    
    /** inicializa el estado */
    async getAgentsData(page = 1) {
        const { idAgents } = this.props;
        const response = await DataApi.PaginateCommissions(page, idAgents);
        
        this.setState({ data: response.data });
    }
    
    /** borramos  */
    async destroy(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                DataApi.DestroyCommission(id);
                this.getAgentsData();
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success',
                );
            }
        });
    }
    
    render() {
        const { data } = this.state;
        const { current_page, total, per_page } = this.state.data;
        const { idAgents } = this.props;
        
        if (!data) {
            return null;
        }
        
        return (
            <div>
                <Row>
                    <Col md={12}>
                        <div className="container-input-modal">
                            <ModalCommissions idAgents={idAgents} getAgentsData={this.getAgentsData} indexModal={2} />
                        </div>
                        <br/>
                        <br/>
                        <div className="container-table-all">
                            <TableCommissions destroy={this.destroy} getAgentsData={this.getAgentsData} data={data} />
                        </div>
                       
                        <Pagination
                            totalItemsCount={total}
                            onChange={(page) => this.getAgentsData(page)}
                            activePage={current_page}
                            itemsCountPerPage={per_page}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Commissions;

