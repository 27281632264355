import React, { Component } from 'react';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  //   Input,
} from 'reactstrap';
import Swal from 'sweetalert2';
import SelectCmx from '../../../elements/Utils/Select';
import DataApi from '../../../../megacall/services/Calls/DataApi';

class EditAnomalies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [''],
      supplier: '',
      suppliers: '',
      id: '',
      nameTable: '',
      destinationNumber: '',
      originNumber: '',
      supplierDescription: '',
      supplierId: '',
      customerPriceName: '',
      agentCustomerPriceName: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.updateCdr = this.updateCdr.bind(this);
    this.getAllInfo = this.getAllInfo.bind(this);
  }

  toggle() {
    this.props.getCallInfo(this.props.item.cdr_id, this.props.item.cdr_table);
    setTimeout(() => {
      this.getAllInfo();
    }, 500);
    setTimeout(() => {
      this.setState(prevState => ({
        modal: !prevState.modal,
      }));
    }, 600);
  }

  getAllInfo() {
    // this.props.getCallInfo(this.props.item.cdr_id, this.props.item.cdr_table);
    // setTimeout(() => {
    this.setState({
      destinationNumber: this.props.callInfo.destination_number,
      originNumber: this.props.callInfo.origin_number,
      supplierDescription: this.props.callInfo.supplier_description,
      customerPriceName: this.props.customerPriceName,
      agentCustomerPriceName: this.props.agentCustomerPriceName,
      supplier: { value: this.props.callInfo.supplier_id },
      id: this.props.callInfo.id,
      nameTable: this.props.item.cdr_table,
    });
    // }, 200);
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }

    setTimeout(() => {}, 300);
  }

  async updateCdr() {
    const { id, originNumber, destinationNumber, supplier, nameTable } = this.state;
    let supp;

    if (this.props.callInfo.supplier_id === null) {
      supp = supplier;
    } else {
      supp = supplier.value;
    }

    const json = {
      id: id,
      nameTable: nameTable,
      originNumber: originNumber,
      destinationNumber: destinationNumber,
      supplier: supp,
    };
    const results = await DataApi.UpdateCdr(json);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'The call has been updated and reprocessed',
        showConfirmButton: false,
        timer: 1500,
      });
      this.toggle();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Kepp fixing the call',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.getAnomalies;
    this.setState({ supplier: '', originNumber: '', destinationNumber: '' });
  }

  async getSuppliersData(page = 1) {
    const results = await DataApi.GetSuppliers(page);

    this.setState({ suppliers: results.data.data });
  }

  async componentDidMount() {
    this.props.getAnomalies();
    // this.getAllInfo();
  }

  render() {
    const {
      supplier,
      destinationNumber,
      originNumber,
      supplierDescription,
      customerPriceName,
      agentCustomerPriceName,
    } = this.state;
    const { item, suppliers, callInfo } = this.props;

    return (
      <>
        <a onClick={this.toggle} className="">
          <i className="mdi mdi-square-edit-outline mdi-24px"></i>
        </a>

        <Modal isOpen={this.state.modal}>
          <ModalHeader className="" toggle={() => this.toggle()}>
            {item.id}: {item.display_name} in Cdr id: {callInfo.id}{' '}
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>To Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={destinationNumber}
                    onChange={this.handleChange}
                    name="destinationNumber"
                  />
                  {/* <Input nameI={'destinationNumber'} type={'number'} value={destinationNumber} change={ (e) => this.handleChange(e, destinationNumber, 1)}
                               name={'To Number'} /> */}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>From Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={originNumber}
                    onChange={this.handleChange}
                    name="originNumber"
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Call Duration</label>
                  <input type="number" className="form-control" placeholder={callInfo.duration} disabled />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Call Answered</label>
                  <input type="text" className="form-control" placeholder={callInfo.call_date} disabled />
                </div>
              </div>

              {item.display_name === 'SUPPLIER ERRONEOUS' ? (
                <>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Supplier's Name in Sippy</label>{' '}
                      <SelectCmx
                        index={1}
                        nameI={'supplier'}
                        value={supplier.value}
                        options={suppliers}
                        change={e => this.handleChange(e, 'supplier', 1)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Supplier's Name in CDR</label>
                      <input type="text" className="form-control" placeholder={supplierDescription} disabled />
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Supplier ID</label>
                    <input type="text" className="form-control" placeholder={callInfo.supplier_id} disabled />
                  </div>
                </div>
              )}
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Prefix Description</label>
                  <input type="text" className="form-control" placeholder={callInfo.prefix_description} disabled />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Customer's CustomerPrice</label>
                  <input type="text" className="form-control" placeholder={customerPriceName} disabled />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Agent's CustomerPrice</label>
                  <input type="text" className="form-control" placeholder={agentCustomerPriceName} disabled />
                </div>
              </div>
            </div>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.updateCdr}>
              Update and Reprocess
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default EditAnomalies;
