/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import DataApi from '../../../megacall/services/Customers/DataApi';
import Accounting from './Modal/Accounting';


class Invoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: '',
      data: '',
      to_date: '',
      from_date: '',
      invoiceNumber: '',
    };
    this.getInvoices = this.getInvoices.bind(this);
    this.generatePDF = this.generatePDF.bind(this);
    this.getInvoiceInfo = this.getInvoiceInfo.bind(this);
  }

  async componentDidMount() {
    await this.getInvoices();
  }

  async getInvoices(page = 1) {
    const { from_date, to_date, invoiceNumber, customer_id } = this.state;

    const object = {
      from_date: from_date,
      to_date: to_date,
      invoiceNumber: invoiceNumber,
      customer_id: customer_id,
    };

    const response = await DataApi.getInvoices(page, object);

    this.setState({ data: response.data });
  }

  async generatePDF(date, inmediate) {
    const { productInvoice, invoiceInfo, customerInfo } = this.state;

    const dateToSplited = date.split('-', 3);
    const toYear = dateToSplited[0];
    const toMonth = dateToSplited[1];

    const object = {
      productInvoice: productInvoice,
      invoiceInfo: invoiceInfo,
      customerInfo: customerInfo,
      toYear: toYear,
      toMonth: toMonth,
      inmediate: inmediate,
    };

    const results = await DataApi.generate(object);
    if (results.status === 200) {
      let urlRela = results;

      let url = `https://manager-kosmos.megacall.es/storage${urlRela.data.split('storage')[1]}`;

      window.open(url, '_blank');
    }
  }

  async getInvoiceInfo(id, date, inmediate) {
    const object = {
      invoiceNumber: id,
    };

    const results = await DataApi.getInvoiceInfo(object);

    if (results.status === 200) {
      this.setState({ productInvoice: [results.data[0]], invoiceInfo: results.data[1], customerInfo: results.data[2] });
      this.generatePDF(date, inmediate);
    }
  }

  async handleChangeFilter(e, index) {
    switch (index) {
      case 1:
        this.setState({ from_date: e.target.value });
        setTimeout(() => {
          this.getInvoices();
        }, 50);
        break;
      case 2:
        this.setState({ to_date: e.target.value });
        setTimeout(() => {
          this.getInvoices();
        }, 50);
        break;
      case 3:
        this.setState({ invoiceNumber: e.target.value });
        setTimeout(() => {
          this.getInvoices();
        }, 50);
        break;
      case 4:
        this.setState({ customer_id: e.target.value });
        setTimeout(() => {
          this.getInvoices();
        }, 50);
        break;
        default:
          break;
    }
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  render() {
    const {
      data: { data },
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="card-body">
          <div className="row justify-content-between top-information">
            <div className="col-sm-3">
              <div className="form-group">
                <label>From Date:</label>
                <input
                  onChange={e => this.handleChangeFilter(e, 1)}
                  type="date"
                  name="from_date"
                  className="form-control"
                  placeholder="From"
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label>To Date:</label>
                <input
                  onChange={e => this.handleChangeFilter(e, 2)}
                  type="date"
                  name="to_date"
                  className="form-control"
                  placeholder="To"
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label>Invoice Number</label>
                <input
                  index={1}
                  onChange={e => this.handleChangeFilter(e, 3)}
                  className="form-control"
                  placeholder="Invoice Number"
                />
              </div>
            </div>
            <div className="col-sm-3">
              <div className="form-group">
                <label>Customer Id</label>
                <input
                  index={1}
                  onChange={e => this.handleChangeFilter(e, 4)}
                  className="form-control"
                  placeholder="Customer Id"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between container-fluid ml-2">
          <Accounting />
          <button className="btn btn-warning mt-3 mb-3" onClick={() => this.props.history.push('/invoice/monthlyReport')}>
            Monthly Report
          </button>
        </div>
        <div className="container-fluid ml-3">
          <Table className="table table-stripped">
            <thead className="container-item">
              <tr>
                <th scope="container-item">Invoice Number</th>
                <th scope="container-item">Customer Id</th>
                <th scope="container-item">Customer Name</th>
                <th scope="container-item">Date</th>
                <th scope="container-item">Total</th>
                <th scope="container-item">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item">{item.number}</td>
                  <td className="container-item">{item.customer_id}</td>         
                  <td className="container-item">{item.invoice_name}</td>      
                  <td className="container-item">{item.date}</td>
                  <td className="container-item">{item.total.toFixed(2)}</td>
                  <td className="container-item">
                    <div className="row container-item offset-4">
                      {item.rectify === 0 ? (
                        <a onClick={() => this.props.history.push(`/invoice/rectify/${item.id}`)} className="mr-2">
                          <i className="mdi mdi-square-edit-outline mdi-24px"></i>
                        </a>
                      ) : (
                        // <EditInvoice className="ml-5" item={item} getInvoices={this.getInvoices} invoiceId={item.id} />
                        ''
                      )}

                      <a className="" onClick={() => this.getInvoiceInfo(item.id, item.date, item.inmediate)}>
                        <i className="mdi mdi-file-pdf-box-outline mdi-24px text-red"></i>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          totalItemsCount={total}
          onChange={page => this.getInvoices(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
          lastPageText="Last"
        />
      </React.Fragment>
    );
  }
}

export default Invoice;
