import React from 'react';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import DataApiSupplier from '../../../../megacall/services/SuppliersServices/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Select from 'react-select';
import Loader from 'react-loader-spinner';

class ModalMultiEditPriceListMassive extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      price: '',
      charge: '',
      id: '',
      loader: false,
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchTranslate = this.searchTranslate.bind(this);
  }

  toggle() {
    const e = {
      target: {
        value: this.props.description,
      },
    };

    this.searchTranslate(e);

    // setTimeout(() => {
    //   var selector = document.getElementById('selector');
    //   selector.addEventListener('onKeyDown',  this.toggle);
    // }, 200);

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async searchTranslate(e) {
    console.log(e.target.value);
    this.setState({ value: e.target.value });
    setTimeout(() => {
      const { value } = this.state;

      if (value.length >= 2) {
        DataApiSupplier.SearchTranslate({ text: value }).then(items => {
          if (items.data[0].description) {
            const arrayTranslate = items.data[0].description.split(',');

            const translate = ModificationArray.modificationTranslate(arrayTranslate);

            this.setState({ description: translate });
          }
        });
      }
    }, 500);
  }

  async save() {
    const { description_name, loader, peak_rate } = this.state;

    const object = {
      supplier_id: this.props.supplier_id,
      description: description_name.value,
      peak: peak_rate,
    };

    const result = await DataApi.UpdatePriceList(object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
      this.props.getSuppliersData();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { description, description_name, loader, peak_rate } = this.state;
    const { arrayMultiDescription, arrrayId } = this.props;

    return (
      <>
        <div className="">
          {description != null ? (
            <button className="btn btn-primary" onClick={this.toggle}>
              {' '}
              Edit All
            </button>
          ) : (
            <button className="btn btn-primary" onClick={this.toggle}>
              {' '}
              Edit All
            </button>
          )}
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Multiedit</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <Select
                value={description_name}
                options={description}
                onChange={e => this.handleChange(e, 'description_name', 1)}
                onKeyDown={this.searchTranslate}
                className="mb-3"
                placeholder="Type to search..."
                defaultValue={description_name}
                id="selector"
              />
              {description_name ? (
                <>
                  <label>New Peak Rate</label>
                  <input
                    value={peak_rate}
                    onChange={e => this.handleChange(e, 'peak_rate')}
                    className="form-control mb-3"
                    // placeholder=""
                    name="peak_rate"
                  />
                </>
              ) : (
                ''
              )}

              <br />
              {loader === true ? (
                <div className="container-item">
                  <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
                </div>
              ) : (
                ''
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                Send
              </Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalMultiEditPriceListMassive;
