import CallsApi from './CallsApi';

export default class DataApi {

  /** Agents */
  static async Paginate(page: any, json: any): Promise<any> {
    const result: any = await CallsApi.GETPAGINATE(page, json);

    return result;
  }

  static async ListSelect(): Promise<any> {
    const results: any = await CallsApi.GETLISTSELECT();

    return results;
  }

  static async Options(id: string): Promise<any> {
    const result: any = await CallsApi.GETOPTIONS(id);

    return result;
  }

  static async SEND(object: any, id: string): Promise<any> {
    const result: any = await CallsApi.SETAGENTS(object, id);

    return result;
  }

  static async CreateAgents(object: any): Promise<any> {
    const results: any = await CallsApi.SETNEWAGENTS(object);

    return results;
  }

  static async AgentsDedactive(id: any, enabled: any): Promise<any> {
    const results: any = await CallsApi.DESACTIVEAGENTS(id, enabled);

    return results;
  }

  /*** COMMISSIONS ***/
  static async PaginateCommissions(page: any, id: any): Promise<any> {
    const results: any = await CallsApi.GETPAGINATECOMMISSIONS(page, id);

    return results;
  }

  static async ProductCommission(): Promise<any> {
    const result: any = await CallsApi.GETDESCRIPTIONCOMMISSION();

    return result;
  }

  static async SendCommission(object: any, id: string): Promise<any> {
    const result: any = await CallsApi.UPDATECOMMISSION(object, id);

    return result;
  }

  static async DestroyCommission(id: string): Promise<any> {
    const result: any = await CallsApi.DELETECOMMISSION(id);

    return result;
  }

  static async CreateCommission(object: any): Promise<any> {
    const result: any = await CallsApi.CREATECOMMISSION(object);

    return result;
  }

  /** Count */
  static async CountAgents(id: any) {
    const results = await CallsApi.COUNTAGENTS(id);

    return results;
  }

  static async ListAgents(type: string) {
    const result = await CallsApi.GETLISTAGENTS(type);

    return result;
  }

  static async getReport(object: any): Promise<any> {
    const results: any = await CallsApi.GETREPORT(object);

    return results;
  }
}