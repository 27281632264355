import React from 'react';
import { withRouter } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import DataApi from '../../../megacall/services/SuppliersServices/DataApi';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import SelectCmx from '../../elements/Utils/Select';
import Input from '../../elements/Utils/Input';
import Validations from '../../../megacall/utilsFunctions/Validations';
import { CardBody, Table } from 'reactstrap';

import Select from 'react-select';
import TableImport from './TableImport';
import CopyClipboard from '../../../megacall/utilsFunctions/CopyClipboard';
import ModalMultiEdit from './Modal/ModalMultiEdit';
import Pagination from 'react-js-pagination';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';

class TableImportPriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textInputFile: 'Choose file...',
      file: null,
      loading: false,
      change: 1,
      upload_type: '',
      id: '',
      exchange_rate: '',
      upload_type_all: '',
      data: '',
      dataCSV: '',
      dataCSVDuplicates:'',
      value: '',
      prefixes: '',
      missing: '',
      totalrows: '',
      further: '',
      difference: '',
      state: false,
      disable: false,
      select_validation: false,
      stateButton: false,
      stateFilter: false,
      history: '',
      filtro_desc: '',
      filtro_prefix: '',
      csv: '',
      csvLoading: '',
      arrayMultiEdit: [],
      translate: [
        {
          value: 'Seleccione para agregar al portapapeles',
          label: 'Seleccione para agregar al portapapeles',
        },
      ],
      translate_all: [
        {
          value: 'Seleccione para agregar al portapapeles',
          label: 'Seleccione para agregar al portapapeles',
        },
      ],
      isChecked: false,
    };
    this.import = this.import.bind(this);
    this.onChange = this.onChange.bind(this);
    this.callSelect = this.callSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchTranslate = this.searchTranslate.bind(this);
    this.postSave = this.postSave.bind(this);
    this.multiselect = this.multiselect.bind(this);
    this.getPriceList = this.getPriceList.bind(this);
    this.deleteState = this.deleteState.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.selectorRef = React.createRef();
    this.mountDuplicatedEntriesTableForSwal = this.mountDuplicatedEntriesTableForSwal.bind(this);
    this.showDuplicates = this.showDuplicates.bind(this);
  }

  async componentDidMount() {
    await this.callSelect();
  }

  mountDuplicatedEntriesTableForSwal(data) {
    //TODO: Swal withReactContent

    // {"prefix":"00348070", "description":"Spain Premium Numbers N1", "peak_rate":0.75, "connection_charge":0.165, "charge_seconds":"1"}

    let duplicatedEntries = `
      <table class="table table-striped" style="width: 100%;"> 
        <thead>
          <tr>
            <th>Prefix</th>
            <th>Description</th>
            <th>Peak rate</th>
            <th>Connection charge</th>
            <th>Charge seconds</th>
          </tr>
        </thead>
      <tbody>
    `;

    data?.forEach((entry) => {
      duplicatedEntries += `
        <tr>
          <td class="new container-item-3">${entry.prefix}</td>
          <td class="new container-item-4">${entry.description}</td>
          <td class="new container-item-4">${entry.peak_rate}</td>
          <td class="new container-item-4">${entry.connection_charge}</td>
          <td class="new container-item-4">${entry.charge_seconds}</td>
        </tr>
      `
    })

    duplicatedEntries += "</tbody></table>"

    return duplicatedEntries;
  }


  showDuplicates(evt) {
    evt.preventDefault()
    try {
      Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'This suppliers are duplicated',
        width: 1080,
        html: this.mountDuplicatedEntriesTableForSwal(this.state.dataCSVDuplicates),
        showConfirmButton: true,
      });

    } catch(e) {
      console.log(e)
    }
  }

  checkAll() {
    const { data, arrayMultiEdit } = this.state;
    let chk = document.getElementById('chk');
    if (chk.checked === true) {
      data.data.forEach((element, key) => {
        let nameId = 'check' + element.id;
        let checkie = document.getElementById(nameId);
        if (!checkie.checked) {
          checkie.checked = true;
          arrayMultiEdit.push(element.id);
        }
      });
      this.setState({ arrayMultiEdit: arrayMultiEdit });
    } else {
      this.setState({ arrayMultiEdit: [] });
      data.data.forEach((element, key) => {
        let nameId = 'check' + element.id;
        let checkie = document.getElementById(nameId);
        checkie.checked = false;
      });
    }
  }

  multiselect(id) {
    const { arrayMultiEdit } = this.state;

    if (arrayMultiEdit.includes(id) === false) {
      arrayMultiEdit.push(id);
    } else {
      let a = arrayMultiEdit.indexOf(id);
      arrayMultiEdit.splice(a, 1);
    }
    this.setState({ arrayMultiEdit: arrayMultiEdit });
  }

  async handleButtonDownloadCsv() {
    this.setState({ csvLoading: true });
    await this.listAllResults();
    this.selectorRef.current.link.click();
    this.setState({ csvLoading: false });
  }

  async deleteState() {
    this.setState({ arrayMultiEdit: [], isChecked: false });
    this.state.data.data.forEach((element, key) => {
      let nameId = 'check' + element.id;
      let checkie = document.getElementById(nameId);
      if (checkie) {
        checkie.checked = false;
      }
    });
    let chk = document.getElementById('chk');
    if (chk.checked === true) {
      chk.checked = false;
      chk.value = '';
    }
    await this.listResults(2);
  }

  async callSelect() {
    const results = await DataApi.Select();
    let modification = ModificationArray.modification(results.data);
    modification.push({ label: 'All ', value: '' });
    modification.reverse();
    this.setState({ upload_type_all: modification, id: this.props.id, exchange_rate: this.props.last_exchange });
  }

  async postSave() {
    const { id, exchange_rate } = this.state;
    this.setState({ disable: true });
    const results = await DataApi.PostSave({ supplier_id: id, exchange_rate: exchange_rate });

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
      this.props.history.push('/supplier/options');
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work was not saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    this.setState({ disable: false });
    this.setState({ data: '' });
  }

  async count() {
    const { id } = this.state;

    const object = {
      supplier_id: id,
      template: 4,
    };

    const results = await DataApi.Count(object);

    this.setState({
      missing: results.data.missing,
      further: results.data.new,
      difference: results.data.difference,
      totalrows: results.data.total,
    });
  }

  async import() {
    const { file, id, exchange_rate, upload_type } = this.state;

    const formData = new FormData();

    formData.append('upload_file', file);
    formData.append('supplier_id', id);
    formData.append('exchange_rate', exchange_rate);
    formData.append('upload_type', upload_type.value);

    const validateSelect = Validations.validationSelect([{ value: upload_type, name: 'upload_type' }]);
    if (validateSelect) {
      this.setState({ disable: true });
      const response = await DataApi.Load(formData);

      if (response.data.status === 200) {
        if (response.data.message === "success" ) {
          
          await this.listResults();

        } else {
          //TODO: Swal withReactContent
          this.setState({dataCSVDuplicates: response.data.message})

          Swal.fire({
            position: 'center',
            icon: 'info',
            title: 'This suppliers are duplicated',
            width: 1080,
            html: this.mountDuplicatedEntriesTableForSwal(this.state.dataCSVDuplicates),
            showConfirmButton: true,
          });

          await this.listResults();
        }

      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Faild to load the file',
          showConfirmButton: false,
          timer: 1500,
        });
        this.setState({ disable: false });
      }
    }
  }

  /*
   * change 1 : differenc, New
   * change 2 : template, Missing
   * change 3 : difference_peak, Difference
   * */

  async listResults(index) {
    this.setState({ disable: true });
    await this.count();

    const { id, change, filtro_desc, filtro_prefix, data, dataCSV } = this.state;

    const object = {
      supplier_id: id,
      template: !index ? change : index,
      filtro_desc: filtro_desc,
      filtro_prefix: filtro_prefix,
    };

    const results = await DataApi.Results(1, object);

    this.setState({ disable: false, data: results.data, stateButton: true, change: !index ? change : index });
    if (index === 2) {
      this.setState({ stateFilter: true });
    } else {
      this.setState({ stateFilter: false, filtro_desc: '', filtro_prefix: '' });
    }
  }

  async listAllResults(index) {
    const { id, change, data } = this.state;

    const object = {
      supplier_id: id,
      template: change,
    };

    const resultsAll = await DataApi.ResultsAll(object);

    let dataForCSV = resultsAll.data.map(data => {
      return {
        prefix: data.prefix,
        description: data.description,
        orig_megacall_desc: data.orig_megacall_desc,
        supplier_desc: data.supplier_desc,
        peak_diff: data.peak_diff,
        peak_diff_pct: data.peak_diff_pct,
        peak: data.peak,
        conn: data.conn,
        ch_secs: data.ch_secs,
        difference: data.difference,
        old_peak: data.old_peak,
        old_conn: data.old_conn,
      };
    });

    this.setState({ dataCSV: dataForCSV });
  }

  async getPriceList(page) {
    const { id, change } = this.state;

    const object = {
      supplier_id: id,
      template: change,
    };

    const results = await DataApi.Results(page, object);

    this.setState({ data: results.data, stateButton: true });
  }

  onChange(e) {
    const file = e.target.files;

    this.setState({ file: file[0], loading: true, textInputFile: file[0].name });
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        if (nameI === 'translate') {
          let text = e.value;

          CopyClipboard.copy(text);
          this.setState({ state: true });

          setTimeout(() => this.setState({ state: false }), 1500);
        }
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async searchTranslate(e) {
    this.setState({ value: e.target.value });

    setTimeout(() => {
      const { value } = this.state;

      if (value.length >= 2) {
        DataApi.SearchTranslate({ text: value }).then(items => {
          if (items.data[0].description) {
            const arrayTranslate = items.data[0].description.split(',');

            const translate = ModificationArray.modificationTranslate(arrayTranslate);

            this.setState({ translate_all: translate });
          }
        });
      }
    }, 500);
  }

  render() {
    const {
      exchange_rate,
      upload_type,
      upload_type_all,
      data,
      translate,
      translate_all,
      change,
      textInputFile,
      state,
      stateButton,
      missing,
      further,
      difference,
      id,
      arrayMultiEdit,
      disable,
      loading,
      stateFilter,
      filtro_desc,
      filtro_prefix,
      totalrows,
      isChecked,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;
    const { fecha_update } = this.props;

    if (disable) {
      return (
        <>
          <div className="container-loader">
            <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt5" />
          </div>
        </>
      );
    }

    return (
      <React.Fragment>
        <div className="container-file-import">
          <label className="col-sm-4 col-lg-2 col-form-label">Import Price List</label>
          <div className="col-sm-4 col-lg-5">
            <div className="custom-file mb-1">
              <input type="file" className="custom-file-input" id="coverImage" required="" onChange={this.onChange} />
              <label className="custom-file-label">{textInputFile}</label>
            </div>
          </div>
        </div>

        <div className="container-form-import-list">
          <Input
            nameI={'exchange_rate'}
            type={'number'}
            value={exchange_rate ? exchange_rate : this.props.last_exchange}
            change={this.handleChange}
            name={'Exchange Rate'}
            state={exchange_rate}
          />
          <SelectCmx
            index={1}
            nameI={'upload_type'}
            value={upload_type}
            validate={'validation'}
            change={this.handleChange}
            name={'Upload Type'}
            id={'sel'}
            options={upload_type_all}
          />
        </div>

        <div className="row container-cache-delete">
          <div className="col-lg-2">
            <div className="d-flex justify-content-center mt-2">
              {upload_type && loading ? (
                <button onClick={this.import} type="submit" className="btn btn-primary btn-block mb-6">
                  Load
                </button>
              ) : (
                <button type="submit" className="btn btn-primary btn-block mb-6" disabled>
                  Load
                </button>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex justify-content-center mt-2">
               {this.state.dataCSVDuplicates !== '' ? (
                <>
                 <CSVLink
                    data={this.state.dataCSVDuplicates}
                    filename={'suppliers-price-list-duplicates.csv'}
                    className="btn btn-success text-uppercase mb-6 btn-block"
                    target="_blank"
                    ref={this.selectorRef}
                  >
                    <i className=" mdi mdi-progress-download"></i> Download CSV Duplicates
                  </CSVLink>
                  <a onClick={this.showDuplicates} href='#swalDuplicates' className='d-flex align-items-center btn-block mb-6'>
                    <span className="nav-item-label link-info">View duplicates</span>
                  </a>
                </>
                ) : '' }
            </div>
          </div>
        </div>

        <div className="container-list-total">
          <p>
            Total: <b>{totalrows}</b>
          </p>
          <p>New: {further}</p>
          <p>Missing: {missing}</p>
          <p>Difference: {difference}</p>
        </div>

        {stateButton ? (
          <div className="d-flex flex-row justify-content-between">
            <div className="button-price-list">
              <button onClick={() => this.listResults(1)} type="button" className="btn btn-warning">
                New
              </button>
              <button onClick={() => this.listResults(2)} type="button" className="btn btn-warning">
                Missing
              </button>
              <button onClick={() => this.listResults(3)} type="button" className="btn btn-warning">
                Difference
              </button>
            </div>
            <div>
              {this.state.csvLoading ? (
                <>
                  <button
                    className="btn btn-success"
                    onClick={() => this.handleButtonDownloadCsv()}
                    style={{
                      width: '250px',
                      height: '39px',
                      marginTop: '19px',
                      marginRight: '115px',
                      paddingTop: '5px',
                    }}
                  >
                    Downloading...
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="btn btn-success"
                    onClick={() => this.handleButtonDownloadCsv()}
                    style={{
                      width: '250px',
                      height: '39px',
                      marginTop: '19px',
                      marginRight: '115px',
                      paddingTop: '5px',
                    }}
                  >
                    Download CSV
                  </button>
                </>
              )}
            </div>
          </div>
        ) : null}

        {stateFilter ? (
          <div className="row container-cache-delete">
            <div className="col-lg-2">
              <Input nameI={'filtro_desc'} value={filtro_desc} change={this.handleChange} name={'Supplier Desc'} />
            </div>
            <div className="col-lg-2">
              <Input nameI={'filtro_prefix'} value={filtro_prefix} change={this.handleChange} name={'Prefix'} />
            </div>
            <div className="col-lg-8">
              <button type="button" className="btn btn-primary" onClick={() => this.listResults(2)}>
                Filter
              </button>
            </div>
          </div>
        ) : null}

        {stateFilter ? (
          <div className="row container-cache-delete">
            <div className="col-lg-12">
              <div className="form-group">
                {state ? (
                  <div className="alert alert-success" role="alert">
                    Copiado con exito
                  </div>
                ) : null}
                <Select
                  value={translate}
                  options={translate_all}
                  onChange={e => this.handleChange(e, 'translate', 1)}
                  onKeyDown={this.searchTranslate}
                />
              </div>
            </div>
          </div>
        ) : null}

        {stateFilter ? (
          <div className="container-cache-delete">
            <ModalMultiEdit deleteState={this.deleteState} arrayMultiEdit={arrayMultiEdit} />
          </div>
        ) : null}

        <React.Fragment>
          <CardBody>
              {
                <p className="text-primary">
                  {' '}
                  <b>Last Update by Vicky: {fecha_update === '0000-00-00 00:00:00' ? '-' : fecha_update}</b>
                </p>
              }
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>
                    {stateFilter ? (
                      <input type="checkbox" id="chk" onClick={() => this.checkAll()} defaultChecked={isChecked} />
                    ) : (
                      ''
                    )}
                  </th>
                  <th>Prefix</th>
                  <th>Megacall Desc</th>
                  <th>Orig Megacall Desc</th>
                  <th>Supplier Desc</th>
                  <th>Peak Diff</th>
                  <th>Peak Diff Pct</th>
                  <th>Peak</th>
                  <th>Conn</th>
                  <th>Ch Secs</th>
                  <th>Template</th>
                  <th>Difference</th>
                  <th>Old Peak</th>
                  <th>Old Conn</th>
                  <th>Ignore</th>
                </tr>
              </thead>
              <tbody>
                {data.data
                  ? data.data.map((item, index) => (
                      <React.Fragment key={index}>
                        <TableImport change={change} multiselect={this.multiselect} data={item} index={index} id={id} />
                      </React.Fragment>
                    ))
                  : null}
              </tbody>
            </Table>
            {stateButton ? (
              <div className="col-lg-2">
                <button onClick={this.postSave} id="btn-upload" type="submit" className="btn btn-success btn-block">
                  Save
                </button>
              </div>
            ) : null}
          </CardBody>
          <Pagination
            totalItemsCount={total ? total : 0}
            onChange={page => this.getPriceList(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default withRouter(TableImportPriceList);
