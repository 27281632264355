/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';

import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalNewPayment extends React.Component {
  constructor(props) {
    super(props);

    var date = new Date();
    var currentDate = date.toISOString().substring(0, 10);

    this.state = {
      modal: false,
      receivedDate: currentDate,
      value: '',
      notes: '',
      paymentType: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async save() {
    const { receivedDate, value, notes, paymentType } = this.state;
    const { customersId } = this.props;

    const object = {
      receivedDate: receivedDate,
      value: value,
      notes: notes,
      paymentType: paymentType,
    };

    if (receivedDate && value && paymentType) {
      const response = await DataApi.NewPayment(customersId, object);

      if (response.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your work has been saved',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok!',
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              title: 'You want to send an email to the customer? ',
              text: 'Just to let him know.',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, send it!',
              cancelButtonText: 'No',
            }).then(async result => {
              if (result.isConfirmed) {
                const response = await DataApi.SendEmail(customersId, object);
                if (response.status === 200) {
                  Swal.fire('Sended!', 'The email has been sent.', 'success');
                }
              }
            });
          }
          this.toggle();
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Your work has error saved',
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.props.getPayments();
    await this.props.getBalance();
    this.setState({ receivedDate: '', value: '', note: '', paymentType: '' });
  }

  render() {
    const { receivedDate, value, notes, paymentType } = this.state;
    const { typesPayments } = this.props;
    return (
      <React.Fragment>
        <div className="container-button">
          <h3>Payments List</h3>
          <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
            <i className=" mdi mdi-plus-circle-outline"></i> Add Payment
          </a>
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>New Payment</ModalHeader>
          <ModalBody>
            <Input
              nameI={'receivedDate'}
              value={receivedDate}
              change={this.handleChange}
              name={'Received Date'}
              type={'date'}
            />
            <Input nameI={'value'} value={value} change={this.handleChange} name={'Value'} />
            <SelectCmx
              index={1}
              nameI={'paymentType'}
              value={paymentType.value}
              change={e => this.handleChange(e, 'paymentType', 1)}
              name={'Payment Type'}
              options={typesPayments}
              className="mb-4"
            />
            <div className="mt-3">
              <Input nameI={'notes'} value={notes} change={this.handleChange} name={'Notes'} className="mt-4" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.save}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewPayment;
