import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import TableLocks from '../../../elements/SettingsElements/Locks/TableLocks';
import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Settings/DataApi';

class Locks extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: '',
        };
        this.getLocks = this.getLocks.bind(this);
    }
    
    async componentDidMount() {
        await this.getLocks();
    }
    
    async getLocks(page = 1) {
        const results = await DataApi.GetLocks(page);
        
        this.setState({ data: results.data });
    }
    
    
    render() {
        const { data } = this.state;
        const { current_page, total, per_page } = this.state.data;
        
        return (
            <div>
                <Row>
                    <h1 className="title-table">LOCKS</h1>
                    <Col md={12}>
                        <Card>
                            <div className="container-input-modal">
                                <div className="container-button">
                                    <h3>User Locks</h3>
                                </div>
                            </div>
                            <br/>
                            <br/>
                            <div className="container-table-all">
                                <TableLocks data={data} getLocks={this.getLocks} />
                            </div>
                            <Pagination
                                totalItemsCount={total}
                                onChange={(page) => this.getLocks(page)}
                                activePage={current_page}
                                itemsCountPerPage={per_page}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Locks;