import UrlServices from '../UrlServices';
import axios from 'axios';
import Headboard from '../Headboard';

export default class CallsApi {
    static API_URL_GLOBAL = UrlServices.universal;

    static async GETRULES(page, json) {
      const response = await axios.post(CallsApi.API_URL_GLOBAL + `sbc/authrule/list?page=${page}`, json, await Headboard.HEADERS());
      return response;
    }
    static async SETNEWRULE(object) {
      const response = await axios.post(CallsApi.API_URL_GLOBAL + 'sbc/authrule/create', object, await Headboard.HEADERS());
      return response;
    }
    static async DESTROYRULE(id) {
      const response = await axios.post(CallsApi.API_URL_GLOBAL + `sbc/authrule/destroy/${id}`, null, await Headboard.HEADERS());
      return response;
    }
    static async GETINFORULE(id) {
      const response = await axios.post(CallsApi.API_URL_GLOBAL + `sbc/authrule/show/${id}`, null, await Headboard.HEADERS());
      return response;
    }
    static async UPDATERULE(object, id) {
      const response = await axios.post(CallsApi.API_URL_GLOBAL + `sbc/authrule/update/${id}`, object, await Headboard.HEADERS());
      return response;
    }
}
