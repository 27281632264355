/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Table } from 'reactstrap';

class TableSuppliers extends React.Component {
    render() {
        const { data } = this.props.data;
        
        if (!data)
            return null;
        
        return (
            <Table className="table table-striped">
                <thead>
                <tr>
                    <th scope="col">ID</th>
                    <th scope="col">NAME</th>
                    <th scope="col">NAME IN SIPPY</th>
                    <th scope="col">ENABLE</th>
                    <th scope="col action">ACTIONS</th>
                </tr>
                </thead>
                <tbody>
                {data.map((item, index) => (
                    <tr key={index}>
                        <td className="container-item-1 style-id">{item.id}</td>
                        <td className="container-item-2">{item.name}</td>
                        <td className="container-item-3">{item.sippy_name}</td>
                        <td className="container-item-4">{item.enabled === 0 ? 'No' : 'Yes'}</td>
                        <td className="container-item-5 action-td">
                            <a onClick={() => this.props.edit(item.id, item.enabled)}>
                                <i className="mdi mdi-square-edit-outline mdi-24px"></i>
                            </a>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        );
    }
}

export default TableSuppliers;
