/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';
import SelectCmx from '../../Utils/Select';
import 'mdbreact/dist/css/mdb.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import Select from 'react-select';
import Swal from 'sweetalert2';
import gif from '../../../../assets/images/tenor.gif';


class ModalNewPackages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            name: '',
            minute: '',
            customer_price: '',
            prefix_translates: '',
            prefix_translates_all: '',
            customer_price_all: '',
            multiSelect: '',
            load: false,
        };
        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initialState = this.initialState.bind(this);
    }
    
    toggle() {
        this.initialState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.customer_price_all !== this.state.customer_price_all) {
        
        }
    }
    
    initialState() {
        const { select_customer_price, select_description } = this.props;
        
        this.setState({ prefix_translates_all: select_description, customer_price_all: select_customer_price });
    }
    
    handleChange(e, nameI, index) {
        switch (index) {
            case 1:
                this.setState({ [nameI]: e });
                break;
            case 2:
                this.setState({ multiSelect: e });
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
                break;
        }
    }
    
    async save() {
        this.setState({ load: true });
        const { multiSelect, name, minute, customer_price } = this.state;
        
        const object = {
            name: name, //primero guardamos name, minutes, fecha de cracion y update en tabla customer_prices
            minutes: minute,
            created_at: new Date().toJSON().slice(0, 19).replace('T', ' '),
            updated_at: new Date().toJSON().slice(0, 19).replace('T', ' '),
            customer_price_id: customer_price.value, //es el id del select,
            descriptions: multiSelect,
        };
        
        const result = await DataApi.SavePackges(object);
        
        if (result.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Saved successfully',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Problems saving',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        this.props.getPackages();
        
        this.setState({ load: false });
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    render() {
        const { name, minute, prefix_translates_all, customer_price_all, customer_price, load } = this.state;
        
        if (load) {
            return <img src={gif} alt="loading..." />;
        }
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <h3>Package List</h3>
                    <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
                        <i className=" mdi mdi-plus-circle-outline"></i> Add Package
                    </a>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>New Package</ModalHeader>
                    <ModalBody>
                        <Input nameI={'name'} value={name} change={this.handleChange}
                               name={'Name'} />
                        <Input nameI={'minute'} value={minute} change={this.handleChange}
                               name={'Minutes'} type={'number'} />
                        
                        {customer_price_all.length === 0 ? null :
                            <React.Fragment>
                                <SelectCmx index={1} nameI={'customer_price'} value={customer_price}
                                           change={this.handleChange} name={'customer_price'}
                                           options={customer_price_all} />
                                
                                <br />
                                <label>Prefix Translates</label>
                                <Select
                                    isMulti
                                    options={prefix_translates_all}
                                    onChange={(e) => this.handleChange(e, null, 2)}
                                />
                            </React.Fragment>
                        }
                    
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalNewPackages;