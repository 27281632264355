import React from 'react';

import logo from '../../../assets/images/logo.png';
import DataApi from '../../../megacall/services/Login/DataApi';
import * as Swal from 'sweetalert2';
import CallsStorage from '../../../megacall/services/Token/CallsStorage';
import { Card } from 'react-bootstrap';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  /** Captura el evento del intro  */
  async onKeyPress(e) {
    if (e.which === 13) {
      await this.login();
    }
  }

  /** Cambia el estado */
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async login() {
    const { username, password } = this.state;

    const object = {
      username: username,
      password: password,
    };

    const response = await DataApi.Login(object);
    console.log(response);
    if (response.data.role === 4 || response.data.role === 5) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'You are not allowed',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    if (
      (response.data.status === 200 && response.data.role === 1) ||
      (response.data.status === 200 && response.data.role === 6) ||
      response.data.role === 2 ||
      response.data.role === 3
    ) {
      console.log(response.data.expires_at);
      CallsStorage.SaveStorage('credentials', JSON.stringify({ token: response.data, pass: true }));
      CallsStorage.SaveStorage('expires', response.data.expires_at);

      await CallsStorage.GetStorageToken('credentials');

      this.props.handleChangeStateLogin();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'invalid credentials',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  render() {
    const { username, password } = this.state;

    return (
      <div className="container-login" onKeyUp={this.onKeyPress}>
        <div className="container-secondari-login">
          <Card>
            <div className="logo-container-principali">
              <img alt="" src={logo} className="container-logo-login" />
            </div>
            <div className="container-text-login card-body p-5">
              <h4 className="text-dark mt-6">Log In</h4>
            </div>

            <div className="container-input">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  placeholder="Usuario"
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  required="required"
                  placeholder="Contraseña"
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-12">
                <button onClick={this.login} type="submit" className="btn btn-lg btn-info btn-block mb-2 mt-4">
                  Sign In
                </button>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default Login;
