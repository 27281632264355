/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ModalNewCustomersNewLike from './Modal/ModalNewCustomersNewLike';
import { Table } from 'reactstrap';

class TableCustomers extends React.Component {
  render() {
    const { data } = this.props;

    if (!data) return null;

    return (
      <React.Fragment>
        <Table className="table table-customer">
          <thead className="container-item">
            <tr>
              <th scope="col" onClick={() => this.props.sort('id')}>
                id
              </th>
              <th scope="col" onClick={() => this.props.sort('name')}>
                Name
              </th>
              <th scope="col" onClick={() => this.props.sort('balance')}>
                Balance
              </th>
              <th scope="col" onClick={() => this.props.sort('Threshold')}>
                Threshold
              </th>
              <th scope="col" onClick={() => this.props.sort('Payment type')}>
                Payment type
              </th>
              <th scope="col" onClick={() => this.props.sort('Amount')}>
                Amount
              </th>
              <th scope="col" onClick={() => this.props.sort('Contact name')}>
                Contact name
              </th>
              <th scope="col" onClick={() => this.props.sort('Contact number')}>
                Contact number
              </th>
              <th scope="col" onClick={() => this.props.sort('Payment Method')}>
                Payment Method
              </th>
              <th scope="col" onClick={() => this.props.sort('Tarif')}>
                Tarif
              </th>
              <th scope="col" onClick={() => this.props.sort('Start date')}>
                Start date
              </th>
              <th scope="col actions">Actions</th>
            </tr>
          </thead>

          <tbody className="container-item">
            {data.map((item, index) => (
              <tr
                key={index}
                className={`controlBalance-${item.colour_name}`}
              >
                <td className="container-item-1 col-principal">{item.id}</td>
                <td className="container-item-2">{item.customers_name}</td>
                <td className="container-item-3">{item.balance.toFixed(2)}</td>
                <td className="container-item-4">{item.threshold.toFixed(2)}</td>
                <td className="container-item-5">{item.payment_types_name}</td>
                <td className="container-item-6">{item.amount}</td>
                <td className="container-item-7">{item.contact_name}</td>
                <td className="container-item-8">{item.contact_number}</td>
                <td className="container-item-9">{item.payment_methods_name}</td>
                <td className="container-item-10">{item.customer_prices_name}</td>
                <td className="container-item-11">{item.start_date}</td>
                <td className="container-item-5 action-td">
                  <a onClick={this.toggle}>
                    <i onClick={() => this.props.edit(item.id)} className="mdi mdi-square-edit-outline mdi-24px"></i>
                    <ModalNewCustomersNewLike
                      data={this.props.data}
                      item={item}
                      id={item.id}
                      select={this.props.select}
                    />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default TableCustomers;
