import React from 'react';

import { Tabs, Tab } from 'react-bootstrap';

import FormsCustomers from './FormsCustomers';
import DataApi from '../../../megacall/services/Customers/DataApi';
import TableContact from './Contacts/TableContact';
import TableEmails from './Emails/TableEmails';
import TableDid from './Did/TableDid';
import TableDiallers from './Diallers/TableDiallers';
import TableExtensionPbx from './ExtensionPBX/TableExtensionPbx';
import TablePayments from './Payments/TablePayments';
import TableCharges from './Charges/TableCharges';
import TableInvoices from './Invoices/TableInvoices';
import TableCommissions from './Commissions/TableCommissions';
import TableNotes from './Notes/TableNotes';
import TableAudits from './Audits/TableAudits';
import TableSipTrunk from './SipTrunk/TableSipTrunk';
import Table3cxPortal from './3cxPortal/Table3cxPortal';
import TablePackages from './Packages/TablePackages';
import Switch from 'react-switch';
import Input from '../../elements/Utils/Input';
import DataApiLock from '../../../megacall/services/Locks/DataApi';
import Swal from 'sweetalert2';
import ModalLogBalance from './Modal/ModalLogBalance';

class TabsCustomers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      activeTab: '1',
      customers: '',
      customersId: '',
      agentId: '',
      enabled: false,
      lock: '',
      infoEmail: {
        pbx: [],
        did: [],
        cc: [],
        charges: [],
        sipTrunk: [],
      },
      didchanges: [],
      ccchanges: [],
      pbxChanges: [],
      cChanges: [],
      totalSpend: 0
    };
    this.toggle = this.toggle.bind(this);
    this.desactiveCustomer = this.desactiveCustomer.bind(this);
    this.getFormsCustomers = this.getFormsCustomers.bind(this);
    this.destroyLocks = this.destroyLocks.bind(this);
    this.getAllInfo = this.getAllInfo.bind(this);
    this.openDir = this.openDir.bind(this);
  }

  async componentDidMount() {
    await this.getFormsCustomers();
  }

  async getFormsCustomers() {
    const id = await this.props.location.state;

    const data = await DataApi.GetCustomerForms(id.response);

    if (data.data.status === 200) {
      this.setState({
        customers: data,
        customersId: id,
        agentId: data.customers_agent_id,
        enabled: data.data.customers.enabled,
        lock: data.data.status,
      });
    } else {
      this.setState({
        lock: data.data.status,
        userBlocked: data.data.info[1].username,
        customerInfo: data.data.customerInfo,
        customersId: id,
        agentId: data.customers_agent_id,
        enabled: data.data.customers.enabled,
        customers: data,
        totalSpend: data.data.totalSpend
      });
    }
  }

  async handleChange(e, nameI) {
    const { enabled } = this.state;

    this.setState({ [nameI]: !enabled });
    setTimeout(() => {
      this.desactiveCustomer();
    }, 500);
  }

  getAllInfo(type, object) {
    let infoEmail = {
      did: [],
      cc: [],
      pbx: [],
      charges: [],
    };

    //TYPE 1 DID
    //TYPE 2 CC
    //TYPE 3 PBX
    //TYPE 4 CHARGES
    switch (type) {
      case 1:
        infoEmail.did = [...this.state.didchanges, ...infoEmail.did, object];
        this.setState({ didchanges: infoEmail.did });
        break;
      case 2:
        infoEmail.cc = [...this.state.ccchanges, ...infoEmail.cc, object];
        this.setState({ ccchanges: infoEmail.cc });
        break;
      case 3:
        infoEmail.pbx = [...this.state.pbxChanges, ...infoEmail.pbx, object];
        this.setState({ pbxChanges: [...infoEmail.pbx] });
        break;
      case 4:
        infoEmail.charges = [object, ...this.state.cChanges, ...infoEmail.charges];
        this.setState({ cChanges: infoEmail.charges });
        break;
      default:
        break;
    }

    infoEmail.did = this.state.didchanges;
    infoEmail.cc = this.state.ccchanges;
    infoEmail.pbx = this.state.pbxChanges;
    infoEmail.charges = [...infoEmail.charges];
    this.setState({ infoEmail: infoEmail });
  }

  toggle1() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
  async openDir() {
    const { customersId } = this.state;
    const credentials = localStorage.getItem('credentials');

    const token = JSON.parse(credentials);

    if (token.token.access_token) {
      const object = {
        customerId: customersId.response,
        token: token.token.access_token,
      };
      const open = await DataApi.open(object);

      if ((open.status = 200)) {
        let url2 = `https://megadrive.megacall.es/storage/Customers/${open.data}`;
        window.open(url2, '_blank');
      }
    }
  }

  async sendSuspend() {
    const { customersId } = this.state;

    const json = {
      customerId: customersId.response,
    };

    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to send the Suspend email to this customer?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, send it!',
    }).then(async result => {
      if (result.isConfirmed) {
        const result = await DataApi.sendSuspend(json);
        if (result.status === 200) {
          Swal.fire('Sended!', 'The email has been sended.', 'success');
        }
      }
    });
  }
  async componentWillUnmount() {
    await this.destroyLocks();
    const id = await this.props.location.state;

    if (
      this.state.infoEmail.did.length > 0 ||
      this.state.infoEmail.cc.length > 0 ||
      this.state.infoEmail.pbx.length > 0 ||
      this.state.infoEmail.charges.length > 0 ||
      this.state.infoEmail.sipTrunk.length > 0
    ) {
      Swal.fire({
        title: 'You have made changes',
        text: 'You want to send the emails to the customer?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const object = {
            infoEmail: this.state.infoEmail,
          };
          const response = await DataApi.sendInfoMail(id.response, object);
          // <ModalEditExtensionPbx />;
          const customer_id = id.response;
          if (response.status === 200) {
            this.props.history.push(`/customers/emailsInfo/${customer_id}`);
          }

          // Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        }
      });
    }
  }

  async destroyLocks() {
    const { customersId, lock } = this.state;

    if (lock === 200) {
      await DataApiLock.DestroyLock(customersId.response, 4);
    }
  }

  async desactiveCustomer() {
    const { enabled } = this.state;
    const id = await this.props.location.state;

    await DataApi.CustomerDesactive(id.response, enabled ? 1 : 0);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { customers, customersId, enabled, lock, userBlocked, customerInfo, totalSpend } = this.state;

    if (lock === 404) {
      return (
        <div className="container-item">
          <h2 className="mb-2 text-red">Customer blocked by {userBlocked}</h2>
          <h3 className="mb-5">
            <span className="style-text-edit">{customersId.response} </span>
            {customers.data.customers.customers_name}
          </h3>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <Tabs defaultActiveKey="edit" mountOnEnter={true}>
                  <Tab eventKey="edit" title={`Main Details`}>
                    {this.state.customerInfo ? (
                      <div className="container">
                        <h3 className="">Main Details</h3>
                        <br />
                        <div className="tab-wrapper "></div>
                        <div className="row bottom-margin-row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <Input value={customerInfo.customers_name} name={'Name'} disabled />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <Input value={customerInfo.contact_name} name={'Contact name'} disabled />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <Input value={customerInfo.contact_number} name={'Contact number'} disabled />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <Input value={customerInfo.agents_name} name={'Agent'} disabled />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <Input value={customerInfo.start_date} name={'Start date'} disabled />
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <div className="form-group">
                              <Input value={customerInfo.disabled_date} name={'Disabled date'} disabled />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                              <Input value={totalSpend.toFixed(2)} name={'Spend This Month'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Input value={customerInfo.threshold.toFixed(2)} name={'Threshold'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Input value={customerInfo.balance.toFixed(2)} name={'Balance'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <ModalLogBalance customersId={customersId}/>
                            </div>
                          </div>

                        </div>

                        <div className="card-body pt-5 border-top">
                          <h3>Basic Info</h3>
                          <br />
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Input value={customerInfo.address} name={'Address'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="row">
                                <div className="col-6">
                                  <div className="form-group">
                                    <Input value={customerInfo.email} name={'Email'} disabled />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group">
                                    <Input
                                      nameI={'invoice_name'}
                                      value={customerInfo.invoice_name}
                                      name={'Invoice name'}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-4">
                              <div className="form-group">
                                <Input
                                  value={customerInfo.identification_types_name}
                                  name={'Identification type'}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group">
                                <Input
                                  value={customerInfo.identification_number}
                                  name={'Identification number'}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="form-group mt-3">
                                <Input value={customerInfo.languages_name} name={'Language'} disabled />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-5 border-top">
                          <h3>Invoicing Details</h3>
                          <br />
                          <div className="row">
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Input value={customerInfo.customer_prices_name} name={'Customer Price'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Input value={customerInfo.payment_methods_name} name={'Payment method'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group mt-3">
                                <Input value={customerInfo.charge_types_name} name={'Charge type'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-group">
                                <Input value={customerInfo.vats_name} name={'Vat'} disabled />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Input value={customerInfo.charge_accuracies_name} name={'Charge accuracy'} disabled />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Input
                                  value={customerInfo.connection_time}
                                  type={'number'}
                                  name={'Connection time'}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-6">
                                  <div className="form-group mt-5">
                                    <Input value={customerInfo.currencie_name} name={'Currency'} disabled />
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="form-group mt-5">
                                    <Input value={customerInfo.payment_types_name} name={'Payment type'} disabled />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-5">
                            <div className="col-sm-6 ">
                              <div className="form-group">
                                <Input
                                  value={customerInfo.direct_debit_bank_account}
                                  name={'Direct debit bank account'}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <Input value={customerInfo.amount} name={'Top up'} disabled />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </Tab>

                  <Tab eventKey="contacts" title={'Contacts'}>
                    <TableContact customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="emails" title={'Emails'}>
                    <TableEmails customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="did" title={'DID Number'}>
                    <TableDid customersId={customersId} index={1} getAllInfo={this.getAllInfo} lock={lock} />
                  </Tab>

                  <Tab eventKey="card" title={'Calling Card'}>
                    <TableDid customersId={customersId} index={2} getAllInfo={this.getAllInfo} lock={lock} />
                  </Tab>

                  <Tab eventKey="diallers" title={'Dialers'}>
                    <TableDiallers customersId={customersId} lock={lock} />
                  </Tab>
                  <Tab eventKey="pbx" title={'Extension PBX'}>
                    <TableExtensionPbx customersId={customersId} getAllInfo={this.getAllInfo} lock={lock} />
                  </Tab>

                  <Tab eventKey="trunk" title={'SIP Trunk'}>
                    <TableSipTrunk customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="3cx" title={'3CX Portals'}>
                    <Table3cxPortal customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="charges" title={'Charges'}>
                    <TableCharges customersId={customersId} getAllInfo={this.getAllInfo} lock={lock} />
                  </Tab>

                  <Tab eventKey="payments" title={'Payments'}>
                    <TablePayments customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="invoices" title={'Invoices'}>
                    <TableInvoices customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="commissions" title={'Commissions'}>
                    <TableCommissions customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="packages" title={'Packages'}>
                    <TablePackages customersId={customersId} lock={lock} packages={customers.data.selects.packages} />
                  </Tab>

                  <Tab eventKey="notes" title={'Notes'}>
                    <TableNotes customersId={customersId} lock={lock} />
                  </Tab>

                  <Tab eventKey="audits" title={'Audits'}>
                    <TableAudits customersId={customersId} lock={lock} />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      if (customers.length < 1) return null;
      return (
        <React.Fragment>
          <div className="container-header-title-customer">
            <div className="container-title-tabs">
              <div>
                <h3>
                  <span className="style-text-edit">{customersId.response} </span>
                  {customers.data.customers.customers_name}
                </h3>
              </div>
              <div className="col-sm-6">
                <button className="btn btn-orange mb-5 newBlue" onClick={() => this.openDir()}>
                  Go Drive!
                </button>
                <button className="btn btn-orange mb-5" onClick={() => this.sendSuspend()}>
                  Send Suspend Mail
                </button>
              </div>
              <div className="checkbox">
                <label>
                  <Switch onColor="#29cc97" onChange={e => this.handleChange(e, 'enabled', 6)} checked={enabled} />
                </label>
              </div>
            </div>
          </div>

          <div className="tab-wrapper ">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <Tabs defaultActiveKey="edit" mountOnEnter={true}>
                    <Tab eventKey="edit" title={`Main Details`}>
                      <FormsCustomers customers={customers} customersId={customersId} />
                    </Tab>

                    <Tab eventKey="contacts" title={'Contacts'}>
                      <TableContact customersId={customersId} />
                    </Tab>

                    <Tab eventKey="emails" title={'Emails'}>
                      <TableEmails customersId={customersId} />
                    </Tab>

                    <Tab eventKey="did" title={'DID Number'}>
                      <TableDid customersId={customersId} index={1} getAllInfo={this.getAllInfo} />
                    </Tab>

                    <Tab eventKey="card" title={'Calling Card'}>
                      <TableDid customersId={customersId} index={2} getAllInfo={this.getAllInfo} />
                    </Tab>

                    <Tab eventKey="diallers" title={'Dialers'}>
                      <TableDiallers customersId={customersId} />
                    </Tab>
                    <Tab eventKey="pbx" title={'Extension PBX'}>
                      <TableExtensionPbx customersId={customersId} getAllInfo={this.getAllInfo} />
                    </Tab>

                    <Tab eventKey="trunk" title={'SIP Trunk'}>
                      <TableSipTrunk customersId={customersId} />
                    </Tab>

                    <Tab eventKey="3cx" title={'3CX Portals'}>
                      <Table3cxPortal customersId={customersId} />
                    </Tab>

                    <Tab eventKey="charges" title={'Charges'}>
                      <TableCharges customersId={customersId} getAllInfo={this.getAllInfo} />
                    </Tab>

                    <Tab eventKey="payments" title={'Payments'}>
                      <TablePayments customersId={customersId} />
                    </Tab>

                    <Tab eventKey="invoices" title={'Invoices'}>
                      <TableInvoices customersId={customersId} />
                    </Tab>

                    <Tab eventKey="commissions" title={'Commissions'}>
                      <TableCommissions customersId={customersId} />
                    </Tab>

                    <Tab eventKey="packages" title={'Packages'}>
                      <TablePackages customersId={customersId} packages={customers.data.selects.packages} />
                    </Tab>

                    <Tab eventKey="notes" title={'Notes'}>
                      <TableNotes customersId={customersId} />
                    </Tab>

                    <Tab eventKey="audits" title={'Audits'}>
                      <TableAudits customersId={customersId} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default TabsCustomers;
