/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import DataApi from '../../../../../megacall/services/Settings/DataApi';
import Swal from 'sweetalert2';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import Input from '../../../Utils/Input';

class ModalEditProduct extends React.Component {
    constructor(props) {
        super(props);
        
        
        this.state = {
            modal: '',
            name_es: '',
            name_en: '',
            cost: '',
            sale: ''
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initialState = this.initialState.bind(this);
        this.save = this.save.bind(this);
    }
    
    toggle() {
        this.initialState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    initialState() {
        const { data } = this.props;
        
        this.setState({
            name_es: data.description_es,
            name_en: data.description_en,
            cost: data.cost,
            sale: data.sale,
        });
    }
    
    handleChange(e) {
       this.setState({ [e.target.name]: e.target.value });
    }
    
    async save() {
        const { name_es, name_en, cost, sale } = this.state;
        const { data: { id } } = this.props;
        
        const object = {
            description: {
                es_ES:  name_es,
                en: name_en
            },
            desc_es: name_es,
            desc_en: name_en,
            cost: cost,
            sale: sale
        };
        
        const response = await DataApi.UpdateProducts(id, object);
        
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'has been saved successfully',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'save error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        await this.props.getProducts();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    
    render() {
        const { name_es, name_en, cost, sale } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <a onClick={this.toggle}>
                        <i className="mdi mdi-square-edit-outline mdi-24px"></i>
                    </a>
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>Update Product</ModalHeader>
                    <ModalBody>
                        <Input nameI={'name_es'} value={name_es} change={this.handleChange} name={'Español'} />
                        <Input nameI={'name_en'} value={name_en} change={this.handleChange} name={'English'} />
                        <Input type="number" nameI={'cost'} value={cost} change={this.handleChange} name="Cost" />
                        <Input type="number" nameI={'sale'} value={sale} change={this.handleChange} name="Sale" />
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.save}>Save</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEditProduct;