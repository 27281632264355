import React from 'react';

import { Table } from 'reactstrap';

class TablePriceSuppliers extends React.Component {
    render() {
        const { data: { data } } = this.props;
        
        if (!data)
            return null;
        
        return (
            <React.Fragment>
                <Table className="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col">Prefix</th>
                        <th scope="col">Description</th>
                        <th scope="col">Peak Rate</th>
                        <th scope="col">Connection Charge</th>
                        <th scope="col">Charge Seconds</th>
                    </tr>
                    </thead>
                    {
                        data.map((item, index) => (
                            <tr key={index}>
                                <td className="container-item-1 col-principal">{item.prefix}</td>
                                <td className="container-item-2">{item.description}</td>
                                <td className="container-item-3">{item.peak_rate.toFixed(6)}</td>
                                <td className="container-item-4">{item.connection_charge.toFixed(6)}</td>
                                <td className="container-item-5">{item.charge_seconds}</td>
                            </tr>
                        ))
                    }
                </Table>
            </React.Fragment>
        );
    }
}

export default TablePriceSuppliers;