import React, { Component } from 'react';

import Pagination from 'react-js-pagination';
import Loader from 'react-loader-spinner';
import DataApi from '../../../megacall/services/Sippy/DataApi';
import ModalNewAuthRule from '../../elements/SippyElements/Modal/ModalNewAuthRule';
import ModalEditAuthRule from '../../elements/SippyElements/Modal/ModalEditAuthRule';

import { MDBIcon } from 'mdbreact';
import Swal from 'sweetalert2';

class AuthRule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accountId: '',
      remoteIp: '',
      cliRule: '',
      cldRule: '',
      data: '',
      loading: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.getRuleList = this.getRuleList.bind(this);
    this.destroyRule = this.destroyRule.bind(this);
  }

  async componentDidMount() {
    await this.getRuleList();
  }

  async getRuleList(page = 1) {
    this.setState({ loading: true, data: '' });
    const { accountId, remoteIp, cliRule, cldRule } = this.state;
    
    const json = {
      account: accountId,
      remoteIp: remoteIp,
      cliRule: cliRule,
      cldRule: cldRule,
    };

    const results = await DataApi.GetRules(page, json);

    this.setState({ 
      loading: false, 
      data: results.data.data,
      total: results.data.total,
      per_page: results.data.per_page,
      current_page: results.data.current_page,
    });
  }

  async destroyRule(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyRule(id).then(() => {
          this.getRuleList();
        });
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Deleted!',
          text: "Your rule has been deleted.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  }

  async handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout(() => {this.getRuleList()}, 300);
  }

  render() {
    const { data, loading, total, current_page, per_page,} = this.state;
    return(
      <>
        <div className="content-wrapper">
          <div className="content">
            <div className="breadcrumb-wrapper">
              <h1>AUTHENTICATION RULES</h1>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="card card-default">
                  <div className="card-header card-header-border-bottom d-flex justify-content-between">
                    <h2>Search</h2>
                  </div>
                  <div className="card-body ">
                    <div className="row justify-content-between top-information">                      
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Account Sippy ID:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Account Sippy ID"
                            name="accountId"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Remote IP:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Remote IP"
                            name="remoteIp"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row top-information">                      
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Incoming CLD/DNIS</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Incoming CLD/DNIS"
                            name="cldRule"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Incoming CLI/ANI</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Incoming CLI/ANI"
                            name="cliRule"
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            {loading === false ? (
              <div className="row">
                <div className="col-12">
                  <div className="card card-default">
                    <div className="card-body">
                      <div className="row" style={{ height: '70px', width: '100%' }}>
                        <div className="col-6">
                          <h3 className="ml-3">Authentication Rules List</h3>
                        </div>
                        <div className="col-6 d-flex flex-row-reverse">
                          <ModalNewAuthRule getRuleList={this.getRuleList} />
                        </div>
                      </div>

                      <div className="table-wrap mt-3 table-responsive">
                        <table className="table3 table-bordered container-item ">
                          <thead className="bg-primary2 text-white">
                            <tr>                              
                              <th>Account ID</th>
                              <th>Remote Ip</th>
                              <th>Incoming CLD/DNIS</th>
                              <th>Incoming CLI/ANI</th>
                              <th>CLD Tr. Rule</th>
                              <th>CLI Tr. Rule</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                          {data.length > 0 && data.map((item, index) => (
                            <tr>
                              <td>{item.sippy_account_id}</td>
                              <td>{item.remote_ip}</td>
                              <td>{item.cld_rule}</td>
                              <td>{item.cli_rule}</td>
                              <td>{item.cld_translation}</td>
                              <td>{item.cli_translation}</td>
                              <td>
                                <ModalEditAuthRule getRuleList={this.getRuleList} id={item.id}/>
                                <MDBIcon icon="trash" size="lg" className="trash mt-2" onClick={() => this.destroyRule(item.id)} />
                              </td>
                            </tr>
                          ))}
                          </tbody>
                        </table>
                        <Pagination
                            totalItemsCount={total}
                            onChange={page => this.getRuleList(page)}
                            activePage={current_page}
                            itemsCountPerPage={per_page}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container-item ">
                <Loader type="ThreeDots" color="#4c84ff" height="300" width="300" className="mt-5" />
                <label className="h1">Loading</label>
              </div>
            )} 

          </div>
        </div>
      </>
    );
  }
}

export default AuthRule;