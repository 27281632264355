import React, { Component } from 'react';

import TableAgents from '../../elements/AgentsElements/TableAgents';
import ModalNewAgents from '../../elements/AgentsElements/Modal/ModalNewAgents';

import Pagination from 'react-js-pagination';

import { Card } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';

import DataApi from '../../../megacall/services/AgentsServices/DataApi';
import SearchAgents from '../../../megacall/components/Search/SearchAgents';

class Index extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: '',
            results: '',
            selectOne: true,
            selectTwo: false,
            enabled: 1,
            index: false,
        };
        this.getAgentsData = this.getAgentsData.bind(this);
        this.edit = this.edit.bind(this);
        this.hanleChangeFilter = this.hanleChangeFilter.bind(this);
    }
    
    async componentWillMount() {
        await this.getAgentsData();
    }
    
    /** Trae los agentes y setea el estado */
    async getAgentsData(page = 1) {
        const { name, contactName, contactNumber, email, enabled } = this.state;
        
        const json = {
            name: name,
            contact_name: contactName,
            contact_number: contactNumber,
            email: email,
            enabled: enabled,
        };
        
        enabled === 0 ? this.setState({ enabled: 0 }) : this.setState({ enabled: 1 });
        
        const results = await DataApi.Paginate(page, json);
        
        this.setState({ data: results.data });
    }
    
    /** Te envia TabsAgents */
    edit(id) {
        this.props.history.push(`/agents/options`, { response: id });
    }
    
    /** Cambia el estado y a la vez sirve de buscador */
    async hanleChangeFilter(e, index) {
        e.persist();
        
        switch (index) {
            case 1:
                this.setState({ contactName: e.target.value });
                setTimeout(() => {
                    this.getAgentsData();
                }, 50);
                break;
            case 2:
                this.setState({ contactNumber: e.target.value });
                setTimeout(() => {
                    this.getAgentsData();
                }, 50);
                break;
            case 3:
                this.setState({ email: e.target.value });
                setTimeout(() => {
                    this.getAgentsData();
                }, 50);
                break;
            case 4:
                this.setState({ selectOne: true, selectTwo: false, enabled: 1 });
                setTimeout(() => {
                    this.getAgentsData();
                }, 50);
                break;
            case 5:
                this.setState({ selectOne: false, selectTwo: true, enabled: 0 });
                setTimeout(() => {
                    this.getAgentsData();
                }, 50);
                break;
            default:
                this.setState({ name: e.target.value });
                setTimeout(() => {
                    this.getAgentsData();
                }, 50);
                break;
        }
    }
    
    render() {
        const { data, selectTwo, selectOne } = this.state;
        const { current_page, total, per_page } = this.state.data;
        
        if (!data) {
            return null;
        }
        
        return (
            <div>
                <Row>
                    <h1 className="title-table">AGENTS</h1>
                    <Col md={12}>
                        <Card>
                            <div className="container-input-modal">
                                <ModalNewAgents />
                            </div>                            
                            <div className="container-search">
                                <SearchAgents
                                    selectTwo={selectTwo}
                                    selectOne={selectOne}
                                    hanleChangeFilter={this.hanleChangeFilter} />
                            </div>
                            <div className="container-table-all">
                                <TableAgents edit={this.edit} data={data} />
                            </div>
                          <Pagination
                              totalItemsCount={total}
                              onChange={(page) => this.getAgentsData(page)}
                              activePage={current_page}
                              itemsCountPerPage={per_page}
                              itemClass="page-item"
                              linkClass="page-link"
                          />
                          <div className="card-header card-header-border-bottom d-flex justify-content-between ">
                              <button className="btn btn-outline-primary btn-sm text-uppercase" onClick={() => this.props.history.push('/agents/monthlyCommission')}>
                                <i className=" mdi mdi-progress-check mr-2"></i>Show Commission Report
                              </button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Index;






