/* eslint-disable array-callback-return */
import React from 'react';


import ModalEditPriceList from './Modal/ModalEditPriceList';
import { MDBIcon } from 'mdbreact';

class IndexTablePriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      descriptions: '',
      prefix: '',
      data: '',
      description: '',
      csv: '',
      select: '',
      arrayMultiDescription: [],
      checkBox: false,
      arrrayId: [],
      index: [],
      paginate: true,
      file: null,
      load: false,
      statePrice: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.clean = this.clean.bind(this);
  }

  multiPrice(statePrice, id, index) {
    this.setState({ statePrice: statePrice });

    if (!statePrice) {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#ffffff';
    } else {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#B0BED9';
    }
    this.props.multiEdit(id);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  selectAll(itemsAll) {
    itemsAll.map((element, index) => {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#B0BED9';
    });
  }

  clean(arrayMultiDescription, arrrayId) {
    if (arrrayId.length > 0) {
      arrrayId.map((element, index) => {
        document.getElementsByClassName(`price-change-${index}`).style.backgroundColor = '#ffffff';
      });
      this.setState({ arrrayId: '', arrayMultiDescription: '' });
    } else if (arrayMultiDescription.length > 0) {
      arrayMultiDescription.map((element, index) => {
        document.getElementsByClassName(`price-change-${index}`).style.backgroundColor = '#ffffff';
      });
      this.setState({ arrrayId: '', arrayMultiDescription: '' });
    }
  }

  render() {
    const { index, item } = this.props;
    const { statePrice } = this.state;

    return (
      <React.Fragment>
        <tr>
          <td className="container-item">{item.prefix}</td>
          <td className="container-item">{item.description}</td>
          <td
            onClick={() => this.multiPrice(!statePrice, item.prefix_translate_id, index)}
            className={`price-change`}
            id="multi-price"
          >
            {item.peak_rate.toFixed(6)}
          </td>
          {/* <td className="container-item">{item.peak_rate.toFixed(6)}</td> */}
          <td className="container-item">{item.connection_charge.toFixed(6)}</td>
          <td className="container-item">{item.charge_seconds}</td>
          <td className="container-item">
            <ModalEditPriceList
              id={item.supplier_id}
              prefix={item.prefix}
              description={item.description}
              connection_charge={item.connection_charge}
              peak_rate={item.peak_rate.toFixed(6)}
              charge_seconds={item.charge_seconds.toFixed(6)}
              prefix_translates_id={item.prefix_translate_id}
              getSuppliersData={this.props.getSuppliersData}
            />
            <MDBIcon
              icon="trash"
              size="lg"
              className="trash ml-3"
              onClick={() => this.props.destroy(item.supplier_id, item.prefix_translate_id)}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default IndexTablePriceList;
