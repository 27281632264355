import CallApi from './CallsApi';

export default class DataApi {
    
    /** Packages */
    static async ListPackages(page) {
        const results = await CallApi.LISTPACKAGE(page);
        
        return results;
    }
    
    static async ListSelect() {
        const results = await CallApi.LISTSELECT();
        
        return results;
    }
    
    static async SavePackges(object) {
        const results = await CallApi.SAVEPACKGES(object);
        
        return results;
    }
    
    static async DestroyPackages(id) {
        const results = await CallApi.DESTROYPACKAGES(id);
        
        return results;
    }
    
    static async GetPackagesPrefixTranslate(id) {
        const results = await CallApi.GETPACKAGESPREFIXTRANSLATE(id);
        
        return results;
    }
    
    static async UpdatePackges(id, object) {
        const results = await CallApi.UPDATEPACKAGES(id, object);
        
        return results;
    }
    static async CopyPackges(object) {
        const results = await CallApi.COPYPACKGES(object);
        
        return results;
    }
    
    /** Suppliers prices */
    static async ListSuppliersPrices() {
        const results = await CallApi.LISTSUPLIERSPRICES();
        
        return results;
    }
    
    static async GetPrice(id, page) {
        const results = await CallApi.GETPRICELIST(id, page);
        
        return results;
    }
    
    /** Customer Price */
    static async ListSelectCustomerPrice() {
        const results = await CallApi.LISTSELECTCUSTOMERPRICE();
        
        return results;
    }
    
    static async GetCustomerPrice(id, page, object) {
        const results = await CallApi.GETCUSTOMERPRICE(id, page, object);
        
        return results;
    }
    
    static async UpdateCustomerPrice(id, object) {
        const results = await CallApi.UPDATECUSTOMERPRICE(id, object);
        
        return results;
    }
    
    static async DestroyCustomerPriec(id) {
        const results = await CallApi.DESTROYCUSTOMERPRICES(id);
        
        return results;
    }
    
    static async GetDescriptions() {
        const results = await CallApi.GETDESCRIPTIONS();
        
        return results;
    }
    
    static async CreateCustomerPrice(object) {
        const results = await CallApi.CREATECUSTOMERPRICES(object);
        
        return results;
    }
    
    static async DownloadPdf(object) {
        const results = await CallApi.DOWNLOADPDF(object);
        
        return results;
    }
    
    static async DownloadTxt(object) {
        const results = await CallApi.DOWNLOADTXT(object);
        
        return results;
    }
    
    static async Destroy(object) {
        const results = await CallApi.DESTROY(object);
        
        return results;
    }
    
    static async UpdateMultiCustomePrice(object) {
        const results = await CallApi.UPDATEMULTICUSTOMERPRICE(object);
        
        return results;
    }

    static async ImportNewCustomerPrice(object) {
        const results = await CallApi.NEWCREATECUSTOMERPRICE(object);
        return results;
    }

    static async ImportToUpdate(id, file) {
        const results = await CallApi.IMPORTUPDATE(id, file);
        return results;
    }

    static async CambiaType(id, object) {
        const results = await CallApi.CHANGETYPE(id, object);
        
        return results;
    }
    
    /** Prefix Translates */
    static async GetPrefixTranslate(page, object) {
        const results = await CallApi.GETPREFIXTRANSLATE(page, object);
        
        return results;
    }
    
    static async UpdatePriceTranslate(id, object) {
        const results = await CallApi.UPDATEPRICETRANSLATE(id, object);
        
        return results;
    }

    static async UpdatePriceList(object) {
        const results = await CallApi.UPDATEPRICELIST(object);
    
        return results;
      }
    
    static async UpdatePriceTranslateDescription( object) {
        const results = await CallApi.UPDATEPRICETRANSLATEDESCRIPTION( object);
        
        return results;
    }
    
    static async SavePriceTranslate(object) {
        const results = await CallApi.SAVEPRICETRANSLATE(object);
        
        return results;
    }

    static async deletePriceTranslate(object) {
        const results = await CallApi.DELETEPRICETRANSLATE(object);
        
        return results;
    }
    
    static async SaveDescription(object) {
        const results = await CallApi.SAVEDESCRIPTION(object);
        
        return results;
    }
    
    static async ExportCsvPriceTranslate(object) {
        const results = await CallApi.EXPORTCSVPRICETRANSLATE(object);
        
        return results;
    }
    
    static async ExportCsvDescription(object) {
        const results = await CallApi.EXPORTCSVDESCRIPTION(object);
        
        return results;
    }
    
    static async ImportCsvPriceTranslate(file) {
        const results = await CallApi.IMPORTCSVPRICETRANSLATE(file);
        
        return results;
    }
    
    static async ImportCsvDescription(file) {
        const results = await CallApi.IMPORTCSVDESCRIPTION(file);
        
        return results;
    }
    
    static async GetAudits() {
        const results = await CallApi.GETAUDITS();
        
        return results;
    }

    static async GetListCountries() {
        const results = await CallApi.GETLISTPAISES();
        
        return results;
    }

    static async GetCompareDesc(object) {
        const results = await CallApi.GETCOMPAREDESC(object);
        
        return results;
    }

    static async ConfirmPrefix(id) {
        const results = await CallApi.CONFIRMDESC(id);
        
        return results;
    }
    static async ModifyDesc(object) {
        const results = await CallApi.UPDATEDESC(object);
        
        return results;
    }
    static async GetListDesc(country) {
        const results = await CallApi.GETMYLISTDESC(country);
        return results;
    }


    /** Sippy **/
    static async ImportExport(id, file) {
        const results = await CallApi.IMPORTEXPORT(id, file);
        return results;
    }

    static async GetListDescriptions() {
        const results = await CallApi.GETLISTDESC();
        return results;
    }

    /** Check Prices **/
    static async getCheckPrice(object) {
        const results = await CallApi.GETCHECKPRICE(object);
        
        return results;
    }
}