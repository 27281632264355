/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Header, SidebarNav, Footer, PageContent, PageAlert, Page } from '../megacall';
import { Dropdown } from 'react-bootstrap';
import Logo from '../assets/images/kosmos.png';
import navAdmin from '../navOfficce';
import navOfficc from '../navAdmin';
import navSuperAdmin from '../navSuperAdmin';
import routes from '../views';
import ContextProviders from '../megacall/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../megacall/helpers/handleTabAccessibility';
import Agents from '../views/pages/Agents/index';
import Suppliers from '../views/pages/Suppliers/index';
import TabsAgents from '../views/elements/AgentsElements/TabsAgents';
import TabsSuppliers from '../views/elements/SuppliersElements/TabsSuppliers';
import Users from '../views/pages/Users/index';
import Login from '../views/pages/Login';
import CallsStorage from '../megacall/services/Token/CallsStorage';
import Welcome from '../views/pages/Welcome';
import Vat from '../views/pages/Settings/Vat';
import ExtTypes from '../views/pages/Settings/ExtTypes';
import Locks from '../views/pages/Settings/Locks';
import PrefixTranslates from '../views/pages/Price/PrefixTranslates';
import CustomerPrice from '../views/pages/Price/CustomerPrice';
import Packages from '../views/pages/Price/Packages';
import Products from '../views/pages/Settings/Products';
import Exchanges from '../views/pages/Settings/Exchanges';
import Gateway from '../views/pages/Settings/Gateway';
import Audits from '../views/pages/Settings/Audits';
import SuppliersPrices from '../views/pages/Price/SuppliersPrices';
import Customers from '../views/pages/Customers';
import TabsCustomers from '../views/elements/CustomersElements/TabsCustomers';
import DataApi from '../megacall/services/Login/DataApi';
import DataApi2 from '../megacall/services/Customers/DataApi';
import Calls from '../views/pages/Calls/index';
import CdrList from '../views/pages/Calls/cdrLists';
import Overrides from '../views/pages/Calls/overrides';
import Anomalies from '../views/pages/Calls/anomalies';
import Charges from '../views/pages/Calls/charges';
import Invoice from '../views/pages/Invoice/index';
import Rectify from '../views/pages/Invoice/rectify';
import monthlyReportView from '../views/pages/Invoice/monthlyReportView';
import monthlyCommission from '../views/pages/Agents/monthlyCommission';
import EmailIInfo from '../views/pages/Emails/index';
import AuthRule from '../views/pages/Sippy/authRule';
import Register from '../views/pages/Settings/Register';
import CheckPrice from '../views/pages/Price/CheckPrice';
import payments from '../views/pages/Payments/index';
import mailSys from '../views/pages/Settings/Mail/index';
import reportSys from '../views/pages/Settings/Reports/Reports';
import axios from 'axios';

const MOBILE_SIZE = 992;

export default class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: true,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      pass: false,
      role: '',
      token: false,
      emails: '',
      actualYear: '',
    };
    this.handleChangeStateLogin = this.handleChangeStateLogin.bind(this);
    this.roleState = this.roleState.bind(this);
    this.logout = this.logout.bind(this);
    this.openDir = this.openDir.bind(this);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  async openDir() {
    const { customersId } = this.state;
    const credentials = localStorage.getItem('credentials');

    const token = JSON.parse(credentials);
    // console.log(token.token.access_token);

    if (token.token.access_token) {
      const object = {
        customerId: customersId.response,
        token: token.token.access_token,
      };
      const open = await DataApi2.open(object);

      if ((open.status = 200)) {
   
      } 
    }
  }
  
  async componentDidMount() {
    const actualYear = new Date().getFullYear();
    this.setState({ actualYear: actualYear });
    const response = CallsStorage.GetStoragePass('credentials');
    setTimeout(() => {
      if (!this.state.username) {
        this.setState({ pass: false });
        CallsStorage.RemoveStorage('credentials');
        localStorage.clear();
        this.props.history.push('/');
      }
    }, 1000);

    if (!response) {
      this.roleState(3);
    } else {
      this.roleState(response.token.role);
    }

    this.setState({ pass: response.pass });

    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);

    this.comprobandoToken = () => {
      if (localStorage.getItem('credentials')) {
        const expires = localStorage.getItem('expires');
        const expira = new Date(expires);
        const hoy = new Date();
        if (expira.getTime() < hoy.getTime()) {
          this.logout();
        }
      }
    }

    //Cada 5 minutos llama a la funcion para comprobar si el token ha caducado
    this.interval = setInterval(() => this.comprobandoToken(), 60000)

    axios.interceptors.response.use((response) => {
      
      if(response.status === 403){
        this.logout();
      }
      return response
    })
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    clearInterval(this.setInterval);
  }

  /** Comprobamos que rol tiene para mostrar un menu o otro */
  async roleState(role) {
    switch (role) {
      case 1:
        this.setState({ role: navSuperAdmin });
        break;
      case 2:
        this.setState({ role: navOfficc });
        break;
      default:
        this.setState({ role: navAdmin });
        break;
    }

    const username = await DataApi.User();
    localStorage.setItem('username', username.data.username);
    this.setState({ username: username.data.username, emails: username.data.email });
  }

  toggleSideCollapse = () => {
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  handleChangeStateLogin() {
    const response = CallsStorage.GetStoragePass('credentials');

    this.roleState(response.token.role);

    this.setState({ pass: response.pass, token: response.token.role });
  }

  //Expirado el token y, al refrescar la página, te pushea al login
  logout() {
    CallsStorage.RemoveStorage('credentials', 'expires');

    this.setState({ pass: false });

    this.props.history.push('/');  
  }

  render() {
    const { sidebarCollapsed, pass, role, username, emails, actualYear } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';

    if (!role) {
      return null;
    }

    return (
      <>
        <React.Fragment>
          {!pass ? (
            <Login handleChangeStateLogin={this.handleChangeStateLogin} />
          ) : (
            <ContextProviders>
              <div className={`app ${sidebarCollapsedClass}`}>
                <PageAlert />
                <div className="app-body">
                  <SidebarNav
                    nav={role}
                    logo={Logo}
                    //logoText="MEGACALL"
                    isSidebarCollapsed={sidebarCollapsed}
                    toggleSidebar={this.toggleSideCollapse}
                    {...this.props}
                  />
                  <Page>
                    <Header
                      username={username}
                      toggleSidebar={this.toggleSideCollapse}
                      isSidebarCollapsed={sidebarCollapsed}
                      routes={routes}
                      {...this.props}
                    >
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-custom-components">{username}</Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="container-dropdown-custom">
                            <div className="d-inline-block-name">
                              <div className="container-name-lo-out">
                                <span className="span-name">{username}</span>
                                <br />
                                <span className="span-emails">
                                  <small className="pt-1">{emails}</small>
                                </span>
                              </div>
                            </div>
                            <div className="container-lo-out">
                              <a onClick={this.logout}>
                                {' '}
                                <i className="mdi mdi-logout"></i> Log Out{' '}
                              </a>
                            </div>
                          </div>
                          {/*<Link to="/">
                                                    <Dropdown.Item onClick={this.logout} eventKey="1">Lo
                                                        gout</Dropdown.Item>
                                                </Link>*/}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Header>
                    <PageContent>
                      <Switch>
                        <React.Fragment>
                          <>
                            <Route path="/" exact component={Welcome} />

                            {/** User **/}
                            <Route path="/user" exact component={Users} />
                            <Route path="/user/options" exact component={Users} />

                            {/** Supplier **/}
                            <Route path="/supplier" exact component={Suppliers} />
                            <Route path="/supplier/options" exact component={TabsSuppliers} />

                            {/** Agents **/}
                            <Route path="/agent" exact component={Agents} />
                            <Route path="/agents/options" exact component={TabsAgents} />
                            <Route path="/agents/monthlyCommission" exact component={monthlyCommission} />

                            {/** Customers **/}
                            <Route path="/customers" exact component={Customers} />
                            <Route path="/customers/options" exact component={TabsCustomers} />
                            <Route path="/customers/emailsInfo" component={EmailIInfo} />

                            {/** Calls **/}
                            <Route path="/cdr" exact component={Calls} />
                            <Route path="/callList" exact component={CdrList} />
                            <Route path="/overrides" exact component={Overrides} />
                            <Route path="/anomalies" exact component={Anomalies} />
                            <Route path="/charges" exact component={Charges} />

                            {/** Invoice **/}
                            <Route path="/invoice" exact component={Invoice} />
                            <Route path="/invoice/rectify/:id" component={Rectify} />
                            <Route path="/invoice/monthlyReport" exact component={monthlyReportView} />
                            {/* <Route path="/overrides" exact component={Overrides} />
                            <Route path="/anomalies" exact component={Anomalies} />
                            <Route path="/charges" exact component={Charges} /> * */}

                            {/** Price **/}
                            <Route path="/prefix-translate" exact component={PrefixTranslates} />
                            <Route path="/customer-price" exact component={CustomerPrice} />
                            <Route path="/package" exact component={Packages} />
                            <Route path="/suppliers-prices" exact component={SuppliersPrices} />
                            <Route path="/check-prices" exact component={CheckPrice} />

                            {/** Settings */}
                            <Route path="/vat" exact component={Vat} />
                            <Route path="/ext-type" exact component={ExtTypes} />
                            <Route path="/audits" exact component={Audits} />
                            <Route path="/gateway" exact component={Gateway} />
                            <Route path="/lock" exact component={Locks} />
                            <Route path="/product" exact component={Products} />
                            <Route path="/register" exact component={Register} />
                            <Route path="/megaMail" exact component={mailSys} />
                            <Route path="/reports" exact component={reportSys} />

                            {/** Exchanges cambiar a un boton encima de todas las vistas **/}
                            <Route path="/exchange" exact component={Exchanges} />

                            {/** Megacall SBC */}
                            <Route path="/authRule" exact component={AuthRule} />

                            {/** Payments */}
                            <Route path="/payments" exact component={payments} />
                          </>
                        </React.Fragment>
                      </Switch>
                    </PageContent>
                  </Page>
                </div>
                <Footer>
                  <span className="ml-4">Megacall v{process.env.REACT_APP_VERSION} - Phoenix Solutions SL. © {actualYear}&nbsp;</span>
                  <span>
                    <a href="https://megacall.es/en/terms-conditions/" target="_blank" rel="noopener noreferrer">
                      Terms
                    </a>{' '}
                    |{' '}
                    <a href="https://megacall.es/en/privacy-policy/" target="_blank" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                  </span>
                  <span className="ml-auto hidden-xs">{/*<img src={Logo} width="70%" alt="" />*/}</span>
                </Footer>
              </div>
            </ContextProviders>
          )}
        </React.Fragment>
      </>
    );
  }
}
