/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Select from 'react-select';
import Input from '../../Utils/Input';
import DataApi from '../../../../megacall/services/Price/DataApi';
import DataApiSupplier from '../../../../megacall/services/SuppliersServices/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import Swal from 'sweetalert2';

class ModalEditPriceTranslate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      description: '',
      description_name: '',
      prefix: '',
      id: '',
      value: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.initialState = this.initialState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.searchTranslate = this.searchTranslate.bind(this);
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  initialState() {
    const { data } = this.props;

    const actual_desc = [{ label: data.description, value: data.description }];

    this.setState({ description_name: actual_desc, description: actual_desc, prefix: data.prefix, id: data.id });
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async searchTranslate(e) {
    this.setState({ value: e.target.value });

    setTimeout(() => {
      const { value } = this.state;

      if (value.length >= 2) {
        DataApiSupplier.SearchTranslate({ text: value }).then(items => {
          if (items.data[0].description) {
            const arrayTranslate = items.data[0].description.split(',');

            const translate = ModificationArray.modificationTranslate(arrayTranslate);

            this.setState({ description: translate });
          }
        });
      }
    }, 500);
  }

  async save() {
    const { description_name, prefix, id } = this.state;

    const object = {
      description: description_name.value,
      prefix: prefix,
    };

    const result = await DataApi.UpdatePriceTranslate(id, object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.props.getPrefixTranslates();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { description, prefix, description_name } = this.state;

    return (
      <React.Fragment>
        <a onClick={this.toggle}>
          <i className="mdi mdi-square-edit-outline mdi-24px"></i>
        </a>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Edit Price Translate</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <Select
                value={description_name}
                options={description}
                onChange={e => this.handleChange(e, 'description_name', 1)}
                onKeyDown={this.searchTranslate}
                className="mb-3"
              />
              <Input
                nameI={'prefix'}
                value={prefix}
                change={this.handleChange}
                name={'Prefix'}
                type={'number'}
                disabled
                className=""
              />

              <br />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                Send
              </Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEditPriceTranslate;
