import React, { Component } from 'react';

import Pagination from 'react-js-pagination';

import { Row, Col } from 'reactstrap';
import { Card } from 'react-bootstrap';

import DataApi from '../../../megacall/services/SuppliersServices/DataApi';
import TableSuppliers from '../../elements/SuppliersElements/TableSuppliers';
import ModalNewSuppliers from '../../elements/SuppliersElements/Modal/ModalNewSuppliers';
import SearchSuppliers from '../../../megacall/components/Search/SearchSuppliers';

class Index extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: '',
            selectOne: true,
            selectTwo: false,
            enabled: 1,
            id: '',
            name: '',
            index: false,
        };
        this.edit = this.edit.bind(this);
        this.hanleChangeFilter = this.hanleChangeFilter.bind(this);
    }
    
    async componentDidMount() {
        await this.getSuppliersData();
    }
    
    /** Inicializa el estado y a la vez es el buscador */
    async getSuppliersData(page = 1) {
        const { name, id, enabled } = this.state;
        
        const json = {
            id: id,
            name: name,
            enabled: enabled,
        };
        
        const results = await DataApi.GetSuppliers(page, json);
        
        this.setState({ data: results.data });
    }
    
    /** Te envia a TabsSuppliers */
    edit(id, enabled) {
        this.props.history.push(`/supplier/options`, { response: id, enabled: enabled });
    }
    
    /** Cambia el estado y a la vez busca */
    async hanleChangeFilter(e, index) {
        
        switch (index) {
            case 1:
                this.setState({ selectOne: true, selectTwo: false, enabled: 1 });
                setTimeout(() => {
                    this.getSuppliersData();
                }, 50);
                break;
            case 2:
                this.setState({ selectOne: false, selectTwo: true, enabled: 0 });
                setTimeout(() => {
                    this.getSuppliersData();
                }, 50);
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
                setTimeout(() => {
                    this.getSuppliersData();
                }, 50);
        }
    }
    
    render() {
        const { data, selectTwo, selectOne } = this.state;
        const { current_page, total, per_page } = this.state.data;
        
        if (!data)
            return null;
        
        return (
            <div>
                <Row>
                    <h1 className="title-table">SUPPLIERS</h1>
                    <Col md={12}>
                        <Card>
                            <div className="container-input-modal">
                                <ModalNewSuppliers />
                            </div>
                            <div className="container-search container-search-supplier">
                                <SearchSuppliers
                                    selectTwo={selectTwo}
                                    selectOne={selectOne}
                                    hanleChangeFilter={this.hanleChangeFilter} />
                            </div>
                            <div className="container-table-all">
                                <TableSuppliers edit={this.edit} data={data} />
                            </div>
                            <Pagination
                                totalItemsCount={total}
                                onChange={(page) => this.getSuppliersData(page)}
                                activePage={current_page}
                                itemsCountPerPage={per_page}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Index;
