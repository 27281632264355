/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import ModificationArray from '../../../../../megacall/utilsFunctions/ModificationArray';
import SelectCmx from '../../../Utils/Select';
import CheckBox from '../../../Utils/Checkbox';

class ModalNewDid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      number: '',
      supplier_all: '',
      supplier: '',
      added: '',
      mobile: '',
      landline: '',
      agentMobile: '',
      agentLandline: '',
      isCheckedCharge: false,
      notes: '',
      pointing: '',
    };
    this.toggle = this.toggle.bind(this);
    this.send = this.send.bind(this);
    this.setInitial = this.setInitial.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async setInitial() {
    const response = await DataApi.getSelect();

    const suppliers = ModificationArray.modification(response.data.suppliers);

    this.setState({ supplier_all: suppliers });
  }

  async toggle() {
    await this.setInitial();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e, nameI, index) {
    const { isCheckedCharge } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isCheckedCharge });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async send() {
    const {
      number,
      supplier,
      added,
      mobile,
      landline,
      agentMobile,
      agentLandline,
      isCheckedCharge,
      notes,
      pointing,
    } = this.state;
    const {
      customersId: { response },
      index,
    } = this.props;

    const object = {
      number: number,
      supplier: supplier.value,
      added: added,
      mobile_price: mobile,
      landline_price: landline,
      agent_mobile_price: agentMobile,
      agent_landline_price: agentLandline,
      charge_inbound: isCheckedCharge ? 1 : 0,
      notes: notes,
      pointing: pointing,
      customer_id: response,
    };
    const object2 = {
      action: 'created',
      number: number,
      supplier: supplier.value,
      added: added,
      mobile_price: mobile,
      landline_price: landline,
      agent_mobile_price: agentMobile,
      agent_landline_price: agentLandline,
      charge_inbound: isCheckedCharge ? 1 : 0,
      notes: notes,
      pointing: pointing,
      customer_id: response,
    };
    let results = index === 1 ? await DataApi.NewDid(object) : await DataApi.NewDidMobile(object);

    // console.log(results.data.data);

    if (results.data.data === 'Number already exist') {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'The Number already exist!',
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (results.data.data === 'successfull') {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
      this.props.getAllInfo(index, object2);
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.getDid();
    await this.toggle();
    this.setState({
      number: '',
      supplier_all: '',
      supplier: '',
      added: '',
      mobile: '',
      landline: '',
      agentMobile: '',
      agentLandline: '',
      notes: '',
      pointing: '',
    });
  }

  render() {
    const {
      number,
      supplier_all,
      supplier,
      mobile,
      landline,
      agentMobile,
      agentLandline,
      isCheckedCharge,
      notes,
      pointing,
    } = this.state;
    const { index } = this.props;

    return (
      <React.Fragment>
        {index === 1 ? (
          <div className="container-button">
            <h3>DID Number List</h3>
            <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
              <i className=" mdi mdi-plus-circle-outline"></i> Add DID Number
            </a>
          </div>
        ) : (
          <div className="container-button">
            <h3>Calling Card List</h3>
            <a onClick={this.toggle} className="btn btn-outline-primary btn-sm text-uppercase">
              <i className=" mdi mdi-plus-circle-outline"></i> Add Calling Card
            </a>
          </div>
        )}
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>New Contacts</ModalHeader>
          <ModalBody>
            <Input nameI={'number'} type={'number'} value={number} change={this.handleChange} name={'Number'} />
            {index === 1 ? (
              <SelectCmx
                index={1}
                nameI={'supplier'}
                value={supplier}
                change={this.handleChange}
                name={'Supplier'}
                options={supplier_all}
              />
            ) : null}
            {index === 1 ? (
              isCheckedCharge ? (
                <React.Fragment>
                  <Input nameI={'landline'} value={landline} change={this.handleChange} name={'Landline Price'} />
                  <Input
                    nameI={'mobile'}
                    value={mobile}
                    type={'number'}
                    change={this.handleChange}
                    name={'Mobile Price'}
                  />
                  <Input
                    nameI={'agentLandline'}
                    value={agentLandline}
                    change={this.handleChange}
                    name={'Agent Landline Price'}
                  />
                  <Input
                    nameI={'agentMobile'}
                    value={agentMobile}
                    change={this.handleChange}
                    name={'Agent Mobile Price'}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="form-group">
                    <label>Landline Price</label>
                    <input className="form-control" disabled />
                  </div>
                  <div className="form-group">
                    <label>Mobile Price</label>
                    <input className="form-control" disabled />
                  </div>
                  <div className="form-group">
                    <label>Agent Landline Price</label>
                    <input className="form-control" disabled />
                  </div>
                  <div className="form-group">
                    <label>Agent Mobile Price</label>
                    <input className="form-control" disabled />
                  </div>
                </React.Fragment>
              )
            ) : null}
            {index === 1 ? (
              <React.Fragment>
                <div className="d-flex">
                  <CheckBox
                    nameI={'isCheckedCharge'}
                    toggleChange={this.handleChange}
                    isChecked={isCheckedCharge}
                    index={2}
                  />
                  <label className="ml-2">Add Inbound Charge</label>
                </div>
                <Input nameI={'pointing'} value={pointing} change={this.handleChange} name={'Pointing to'} />
              </React.Fragment>
            ) : null}
            <label>Notes</label>
            <textarea
              className="form-control"
              id="notes"
              value={notes}
              name="notes"
              onChange={e => this.handleChange(e)}
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.send}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalNewDid;
