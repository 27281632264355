import React from 'react';

import { Table } from 'reactstrap';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import Pagination from 'react-js-pagination';
import { MDBIcon } from 'mdbreact';
import ModalEditNotes from './Modal/ModalEditNotes';
import ModalNewNotes from './Modal/ModalNewNotes';
import Swal from 'sweetalert2';
import Input from '../../Utils/Input';

class TableNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      date: '',
    };
    this.getNotes = this.getNotes.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    await this.getNotes();
  }

  async getNotes(page = 1) {
    const { customersId } = this.props;
    const { date } = this.state;

    const object = {
      date: date,
    };

    const response = await DataApi.GetNotes(customersId.response, page, object);

    this.setState({ data: response });
  }

  async destroyNotes(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyNotes(id);
        this.getNotes();
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  async handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });

    setTimeout(() => {
      this.getNotes();
    }, 500);
  }

  render() {
    const {
      customersId: { response },
      lock,
    } = this.props;
    const {
      data: { data },
      date,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) {
      return null;
    }
    if (lock) {
      return (
        <React.Fragment>
          <div className="container">
            <Input nameI={'date'} value={date} change={this.handleChange} name={'Date'} type={'date'} />
          </div>

          <div className="container-table-all">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Text</th>
                </tr>
              </thead>

              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="container-item-10">{item.date}</td>
                    <td className="container-item-11">{item.text}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <Pagination
            totalItemsCount={total}
            onChange={page => this.getNotes(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-input-modal">
          <ModalNewNotes customersId={response} getNotes={this.getNotes} />
        </div>

        <div className="container-search-did">
          <Input nameI={'date'} value={date} change={this.handleChange} name={'Date'} type={'date'} />
        </div>

        <div className="container-table-all">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Text</th>
                <th scope="col actions">Actions</th>
              </tr>
            </thead>

            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item-10">{item.date}</td>
                  <td className="container-item-11">{item.text}</td>
                  <td className="container-item-5 action-td d-flex">
                    <ModalEditNotes
                      data={item}
                      customersId={response}
                      getNotes={this.getNotes}
                      style={{ cursor: 'pointer' }}
                    />
                    <MDBIcon
                      icon="trash"
                      size="lg"
                      className="red-text ml-3"
                      onClick={() => this.destroyNotes(item.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <Pagination
          totalItemsCount={total}
          onChange={page => this.getNotes(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableNotes;
