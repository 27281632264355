import React, { Component } from 'react';

import { Table } from 'reactstrap';
import { CSVLink } from 'react-csv';

class TableReport extends Component {
  render() {
    const { data } = this.props;
      
    if (!data) { return null; }
      
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-8 mt-5 ml-4">
            <CSVLink
              data={data}
              filename={ 'Agent Commission Report.csv'}
              className="btn btn-orange btn-sm text-uppercase ml-3"
              target="_blank"
            >
              <i className=" mdi mdi-export"></i> Export CSV
            </CSVLink>
          </div>
        </div>
        <Table className="table table-striped">
          <thead className="container-item">
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Customer Id</th>
              <th scope="col">Customer</th>
              <th scope="col">Line Type</th>
              <th scope="col">Date</th>
              <th scope="col">Agent Sales Value</th>
              <th scope="col">Customer Sales / Charge</th>
              <th scope="col">Sales Minutes</th>
              <th scope="col">Commission %</th>
              <th scope="col">Commission Value</th>
            </tr>
          </thead>
          <tbody className="container-item">
          {data.map((item, index) => (
            <tr key={index}>
              <td className="container-item-1 col-principal">{item.id}</td>
              <td className="container-item-2">{item.agent}</td>
              <td className="container-item-3">{item.type}</td>
              <td className="container-item-4">{item.customer_id}</td>
              <td className="container-item-5">{item.customer}</td>
              <td className="container-item-6">{item.line_type}</td>
              <td className="container-item-7">{item.date}</td>
              <td className="container-item-8">{item.sales}</td>
              <td className="container-item-9">{item.customer_sales}</td>
              <td className="container-item-10">{item.sales_minutes}</td>
              <td className="container-item-11">{item.commission_porct}</td>
              <td className="container-item-12">{item.commission_value}</td>
            </tr>
          ))}
          </tbody>
        </Table>
      </React.Fragment>
    );
  }
}

export default TableReport;