import React from 'react';

import { Table } from 'reactstrap';

import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';
import ModalEditCommissionCustomer from './Modal/ModalEditCommissionCustomer';
import ModalNewCommissionCustomers from './Modal/ModalNewCommissionCustomers';
import Swal from 'sweetalert2';
import { MDBIcon } from 'mdbreact';

class TableCommissions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      product: '',
    };
    this.getCommissions = this.getCommissions.bind(this);
    this.destroyCommissions = this.destroyCommissions.bind(this);
  }

  async componentDidMount() {
    await this.getCommissions();
  }

  async getCommissions(page = 1) {
    const { customersId } = this.props;

    const response = await DataApi.GetCommissions(customersId.response, page);

    this.setState({ data: response.data.commission, product: response.data.product });
  }

  async destroyCommissions(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyCommissions(id);
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');

        this.getCommissions();
      }
    });
  }

  render() {
    const {
      customersId: { response },
      lock,
    } = this.props;
    const {
      data: { data },
      product,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) {
      return null;
    }

    if (lock) {
      return (
        <React.Fragment>
          <div className="container-table-all">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Description</th>
                  <th scope="col">Percentage</th>
                  <th scope="col">Until</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td className="container-item-1">{item.description_en}</td>
                    <td className="container-item-2">{item.percentage}</td>
                    <td className="container-item-3">{item.until}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Pagination
            totalItemsCount={total}
            onChange={page => this.getCommissions(page)}
            activePage={current_page}
            itemsCountPerPage={per_page}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="container-input-modal">
          <ModalNewCommissionCustomers product={product} customersId={response} getCommissions={this.getCommissions} />
        </div>

        <div className="container-table-all">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">Percentage</th>
                <th scope="col">Until</th>
                <th scope="col actions">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item-1">{item.description_en}</td>
                  <td className="container-item-2">{item.percentage}</td>
                  <td className="container-item-3">{item.until}</td>
                  <td className="container-item-5 action-td d-flex">
                    <ModalEditCommissionCustomer
                      data={item}
                      customersId={response}
                      product={product}
                      getCommissions={this.getCommissions}
                      style={{ cursor: 'pointer' }}
                    />
                    <MDBIcon
                      icon="trash"
                      size="lg"
                      className="red-text ml-3"
                      onClick={() => this.destroyCommissions(item.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          totalItemsCount={total}
          onChange={page => this.getCommissions(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default TableCommissions;
