/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

import DataApi from '../../../../megacall/services/Sippy/DataApi';
import { FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Input from '../../Utils/Input';
import Swal from 'sweetalert2';

class ModalEditAuthRule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      account_id: '',
      remote_ip: '',
      cld_rule: '',
      cli_rule: '',
      cld_translation_rule: '',
      cli_translation_rule: '',
      i_tariff: '',
      i_routing_group: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.getAllInfo = this.getAllInfo.bind(this);
  }

  toggle() {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  handleChange(e, nameI, index) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async save() {
    //TODO guardar la informacion
    const { account_id, remote_ip, cld_rule, cli_rule, cld_translation_rule, cli_translation_rule, i_tariff, i_routing_group } = this.state;
    let mensaje_error = '';

    //Estos if son necesarios para validar las opciones necesarias para crear una regla correctamente. Los campos son opcionales pero segun el tipo(fila html) debe haber rellenado alguno
    if (account_id === '') {
      mensaje_error = 'Is necesary indicate the id of the account of Sippy';
    } else if (remote_ip === '' && cld_rule === '' && cli_rule === '') {
      mensaje_error = 'It is necessary to fill in one of the following fields:<br>"Remote IP list", "Incoming CLD/DNIS" or "Incoming CLI/ANI"';
    } else if (cld_translation_rule === '' && cli_translation_rule === '') {
      mensaje_error = 'It is necessary to fill in one of the following fields:<br>"CLD Tr. Rule" or "CLI Tr. Rule"';
    } else if (i_tariff === '' && i_routing_group === '') {
      mensaje_error = 'It is necessary to fill in one of the following fields:<br>"ID Tariff" or "ID Routing Group"';
    }

    if (mensaje_error === '') {
      const object = {
        account: account_id,
        remoteIp: remote_ip,
        cliRule: cli_rule,
        cldRule: cld_rule,
        cldTranslation: cld_translation_rule,
        cliTranslation: cli_translation_rule,
        tariff: i_tariff,
        routing: i_routing_group
      };
      const response = await DataApi.ModifyRule(object, this.props.id);
      if(response.data.code === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'The rule has been update',
          showConfirmButton: false,
          timer: 1500,
        });
        this.toggle();
        this.props.getRuleList();
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: response.data.message,
          showConfirmButton: false,
          timer: 2000
        });
      }
    } else {
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: mensaje_error,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }

  async getAllInfo() {
    const results = await DataApi.GetRuleInfo(this.props.id);

    setTimeout(() => {
      this.setState({
        account_id: results.data.sippy_account_id,
        remote_ip: results.data.remote_ip,
        cld_rule: results.data.cld_rule,
        cli_rule: results.data.cli_rule,
        cld_translation_rule: results.data.cld_translation,
        cli_translation_rule: results.data.cli_translation ,
        i_tariff: results.data.tariff_id,
        i_routing_group: results.data.routing_group,
      });
    }, 400);

    this.toggle();
  }

  render() {
    const { account_id, remote_ip, cld_rule, cli_rule, cld_translation_rule, cli_translation_rule, i_tariff, i_routing_group } = this.state;
    return (
      <>
        <a  onClick={this.getAllInfo} className="">
          <i className="mdi mdi-square-edit-outline mdi-24px"></i>
        </a>

        <Modal className="modal-new-agents" isOpen={this.state.modal}>
          <div className="card-header-border-bottom">
            <ModalHeader toggle={this.toggle}>
              <h5>Edit Rule</h5>
            </ModalHeader>
          </div>
          <ModalBody>
            <FormGroup>
              <div className="card-body">
                <div className="row bottom-margin-row">
                  <h3>Identification</h3>
                  <br />
                  <br />

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'account_id'} value={account_id} change={this.handleChange} name={'ID of Account in Sippy'} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <Input nameI={'remote_ip'} value={remote_ip} change={this.handleChange} name={'Remote IP list'} />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <Input nameI={'cld_rule'} value={cld_rule} change={this.handleChange} name={'Incoming CLD/DNIS'} />
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <Input nameI={'cli_rule'} value={cli_rule} change={this.handleChange} name={'Incoming CLI/ANI'} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row bottom-margin-row">
                  <h3>Parameters</h3>
                  <br />
                  <br />

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'cld_translation_rule'} value={cld_translation_rule} change={this.handleChange} name={'CLD Tr. Rule'} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'cli_translation_rule'} value={cli_translation_rule} change={this.handleChange} name={'CLI Tr. Rule'} />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'i_tariff'} value={i_tariff} change={this.handleChange} name={'ID Tariff'} />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Input nameI={'i_routing_group'} value={i_routing_group} change={this.handleChange} name={'ID Routing Group'} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body pt-5 border-top">
                  <div className="row">
                    <div className="col-sm-3">
                      <button onClick={this.save} type="submit" className="btn btn-primary btn-block">Save</button>
                    </div>
                  </div>
                </div>

              </div>
            </FormGroup>
          </ModalBody>
        </Modal>
      </>
    );
  }

}

export default ModalEditAuthRule;
