import React from 'react';

import DataApi from '../../../../../megacall/services/Settings/DataApi';
import Swal from 'sweetalert2';

import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import Input from '../../../Utils/Input';

class ModalNewCurrencyExchange extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: '',
            value: '',
            currency: '2',
        };
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
    }
    
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e, nameI, index) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async save() {
        const { value, currency } = this.state;
        
        const object = {
            value: value,
            currency: currency,
            enabled: 1,
            start_date: new Date().toJSON().slice(0, 10),
            created_at: new Date().toJSON().slice(0, 10),
            updated_at: new Date().toJSON().slice(0, 10),
        };
        
        const response = await DataApi.NewCurrencyExchange(object);
        
        if (response.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'has been saved successfully',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'save error',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        
        await this.props.getCurrencyExchange();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    render() {
        const { value } = this.state;
        
        return (
            <React.Fragment>
                <Card>
                    <div className="container-button">
                        <Button color="info" onClick={this.toggle}>New Currency Exchange</Button>
                    </div>
                    <Modal isOpen={this.state.modal}>
                        <ModalHeader toggle={this.toggle}>New Currency Exchange</ModalHeader>
                        <ModalBody>
                            <Input nameI={'value'} value={value} change={this.handleChange}
                                   name={'Value'} type={'number'} />
                            <div className="form-group">
                                <label>Currency</label>
                                <select name="currency" className="form-control" onChange={this.handleChange}>
                                    <option selected="true" value="2">GBP</option>
                                    <option value="3">USD</option>
                                </select>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.save}>Send</Button>{' '}
                        </ModalFooter>
                    </Modal>
                </Card>
            </React.Fragment>
        );
    }
}

export default ModalNewCurrencyExchange;