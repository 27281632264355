/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Customers/DataApi';

class ModalLogBalance extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();

    this.state = {     
      dateFrom: date.toISOString().substring(0, 10),
      dateTo: date.toISOString().substring(0, 10),
      modal: false,
      data: '',
    };

    this.toggle = this.toggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getLogs = this.getLogs.bind(this);
  }

  toggle() {
    this.getLogs();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout(() => { this.getLogs(); }, 50);
  }

  async getLogs(page = 1) {
    const { dateFrom, dateTo } = this.state;

    const object = {
      dateFrom: dateFrom,
      dateTo: dateTo,
    };

    const results = await DataApi.ListBalanceLogs(page,this.props.customersId.response, object)
    this.setState({ data: results.data });
  }

  render() {
    const { data: { data }, dateFrom, dateTo, modal } = this.state;
    const { current_page, total, per_page } = this.state.data;

    return (
      <>
        <a onClick={this.toggle} className="btn btn-outline-primary btn-sm mt-4">
          <i className=" mdi mdi-chart-histogram"></i> Log Balance
        </a>
        <Modal isOpen={modal}>
          <ModalHeader toggle={this.toggle}>Log Balance</ModalHeader>
          <ModalBody>
            <div className="card-body">
              <div className="row justify-content-between top-information">
                <div className="col-sm-6">
                  <div className="form-group ml-6">
                    <label>Date From</label>
                    <input type="date" name="dateFrom" className="form-control" placeholder="From" value={dateFrom} onChange={e => this.handleChange(e)}/>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group ml-6">
                    <label>Date To</label>
                    <input type="date" name="dateTo" className="form-control" placeholder="To" value={dateTo} onChange={e => this.handleChange(e)}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid ml-6">
              <Table className="table table-stripped">
                <thead className="container-item">
                  <tr>
                    <th scope="container-item">Date</th>
                    <th scope="container-item">Old Balance</th>
                    <th scope="container-item">New Balance</th>
                    <th scope="container-item">Charge</th>
                  </tr>
                </thead>
                <tbody>
                  {data ?
                    data.map((item, index) => (
                    <tr key={index}>
                      <td className="container-item">{item.fired}</td>
                      <td className="container-item">{item.balance.toFixed(2)}</td>
                      <td className="container-item">{item.charge.toFixed(2)}</td>
                      <td className="container-item">{item.value_charge === 0 ? '-' : item.value_charge.toFixed(2)}</td>
                    </tr>
                    ))
                    : ''
                  }
                </tbody>
              </Table>
            </div>
            <Pagination
              totalItemsCount={total}
              onChange={page => this.getLogs(page)}
              activePage={current_page}
              itemsCountPerPage={per_page}
              itemClass="page-item"
              linkClass="page-link"
            />
          </ModalBody>
        </Modal>
      </>
    );
  }

  
}

export default ModalLogBalance;