import React from 'react';
import DataApi from '../../../../megacall/services/Settings/DataApi';

import { Table } from 'reactstrap';
import { MDBIcon } from 'mdbreact';

import ModalEditProduct from './Modal/ModalEditProduct';
import Swal from 'sweetalert2';

class TableProducts extends React.Component {
  constructor(props) {
    super(props);

    this.destroyProducts = this.destroyProducts.bind(this);
  }

  async destroyProducts(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        DataApi.DestroyProducts(id).then(() => {
          this.props.getProducts();
        });
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  render() {
    const {
      data: { data },
    } = this.props;

    if (!data) return null;

    return (
      <Table className="table table-striped">
        <thead>
          <tr>
            <th scope="col">id</th>
            <th scope="col">Description English</th>
            <th scope="col">Description Spanish</th>
            <th scope="col">Cost</th>
            <th scope="col">Sale</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        {data.map((item, index) => (
          <tr key={index}>
            <td className="col-principal">{item.id}</td>
            <td>{item.description_en}</td>
            <td>{item.description_es}</td>
            <td>{item.cost}</td>
            <td>{item.sale}</td>
            <td className="d-flex">
                <MDBIcon icon="trash" size="lg" className="trash mt-2" onClick={() => this.destroyProducts(item.id)} />
                <ModalEditProduct getProducts={this.props.getProducts} data={item} />
            </td>
          </tr>
        ))}
      </Table>
    );
  }
}

export default TableProducts;
