import React from 'react';

import { Input } from 'reactstrap';

class InputGlobal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: 'text',
      color: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, item, index) {
    this.props.change(e, item, index);
  }

  componentDidMount() {
    const { type, color } = this.props;

    this.setState({ color: color });
    type ? this.setState({ type: type }) : this.setState({ type: 'text' });
  }

  render() {
    const { value, item, index, name, nameI, placeholder, disabled, defaultValue, invalid, feedbackError } = this.props;
    const { type, color } = this.state;

    return (
      <div className="form-group">
        <label>{name}</label>
        <Input
          type={type}
          className={`form-control ${color}`}
          value={value}
          placeholder={placeholder}
          name={nameI}
          onChange={e => this.handleChange(e, item, index)}
          disabled={disabled}
          defaultValue={defaultValue}
          invalid={invalid}
        />
        {feedbackError != "" && <div className='invalid-feedback'>{feedbackError}</div>}
      </div>
    );
  }
}

export default InputGlobal;
