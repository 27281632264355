/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

class Gateway extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const username = localStorage.getItem('username');
    this.setState({ username: username });
    var fecha = new Date(); //Fecha actual
    var mes = fecha.getMonth() + 1; //obteniendo mes
    var dia = fecha.getDate(); //obteniendo dia
    if (dia < 10) dia = '0' + dia; //agrega cero si el menor de 10
    if (mes < 10) mes = '0' + mes; //agrega cero si el menor de 10

    // this.setState({ date: fecha.toLocaleDateString() });

    var meses = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    var f = new Date();
    this.setState({ date: f.getDate() + ' de ' + meses[f.getMonth()] + ' de ' + f.getFullYear() });
  }
  render() {
    const { date, username } = this.state;
    return (
      <div style={{ color: ' #001775' }} className="container-item">
        <h1>¡Bienvenido a Kosmos, {username}!</h1>
        <a href="" onClick={() => window.open('https://calendar.google.com/calendar/u/0/r')}>
          <h3> Hoy es {date}</h3>
        </a>
        {/* <img src={'https://i.imgur.com/ZVSSIlq.jpg'} alt="yes" className="mt-4" style={{ width: '600px !important', height: '600px' }} /> */}
      </div>
    );
  }
}

export default Gateway;
