import CallsApi from './CallsApi';


export default class DataApi {
    
    static async DestroyLock(id, modelTypes) {
        const results = await CallsApi.DESTROYLOCKS(id, modelTypes);
        
        return results;
    }
}
