import React from 'react';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../Utils/Input';

class ModalMultiEditCustomerPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      price: '',
      charge: '',
      id: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async save() {
    const { price, charge } = this.state;
    const { arrayMultiCharge, arrayMultiPrice } = this.props;

    const object = {
      connection_charge: charge,
      price: price,
      arrayIdCharge: arrayMultiCharge.length ? arrayMultiCharge : 0,
      arrayIdPrice: arrayMultiPrice.length ? arrayMultiPrice : 0,
    };

    const result = await DataApi.UpdateMultiCustomePrice(object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.props.getListCustomerPrice();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { price, charge } = this.state;
    const { arrayMultiCharge, arrayMultiPrice } = this.props;

    return (
      <>
        <div className="ml-5">
          <button className="btn btn-primary" onClick={this.toggle}>
            Multiedit
          </button>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Multiedit</ModalHeader>
          <React.Fragment>
            <ModalBody>
              {arrayMultiPrice.length ? (
                <Input nameI={'price'} value={price} change={this.handleChange} name={'Price'} />
              ) : null}
              {arrayMultiCharge.length ? (
                <Input nameI={'charge'} value={charge} change={this.handleChange} name={'Charge'} type={'number'} />
              ) : null}

              <br />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.save}>
                Send
              </Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalMultiEditCustomerPrice;
