import React, { Component } from 'react';

import Pagination from 'react-js-pagination';

import { Row, Col } from 'reactstrap';
import { Card } from 'react-bootstrap';

import TableUsers from '../../elements/UsersElements/TableUsers';
import SearchUsers from '../../../megacall/components/Search/SearchUsers';
import ModalNewUser from '../../elements/UsersElements/Modal/ModalNewUser';
import DataApi from '../../../megacall/services/UsersServices/DataApi';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: '',
      selectOne: true,
      selectTwo: false,
      enabled: 1,
      role_type: '',
      value_role_type: '',
      index: false,
    };

    this.getUsersData = this.getUsersData.bind(this);
    this.hanleChangeFilter = this.hanleChangeFilter.bind(this);
    this.changeState = this.changeState.bind(this);
  }

  async componentDidMount() {
    await this.getUsersData();
  }

  async getUsersData(page = 1) {
    const { username, enabled, value_role_type } = this.state;

    const json = {
      username: username,
      role: value_role_type.value,
      enabled: enabled,
    };

    const results = await DataApi.GetUsers(page, json);
    console.log(results);
    const select = await DataApi.ListRoles('all');
    console.log(select);

    let arreglo_role_type = ModificationArray.modification(select.data);
    arreglo_role_type.push({label: "All ", value: ""});
    arreglo_role_type.reverse();
    this.setState({ data: results.data, role_type: arreglo_role_type });
  }

  async hanleChangeFilter(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ selectOne: true, selectTwo: false, enabled: 1 });
        setTimeout(() => {
          this.getUsersData();
        }, 500);
        break;
      case 2:
        this.setState({ selectOne: false, selectTwo: true, enabled: 0 });
        setTimeout(() => {
          this.getUsersData();
        }, 500);
        break;
      case 3:
        this.setState({ [nameI]: e });
        setTimeout(() => {
          this.getUsersData();
        }, 50);
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
          this.getUsersData();
        }, 50);
    }
  }

  changeState() {
    const { index } = this.state;

    this.setState({ index: !index });
  }

  render() {
    const { data, selectTwo, selectOne, role_type, value_role_type } = this.state;
    const { current_page, total, per_page } = this.state.data;

    if (!data) return null;

    return (
      <React.Fragment>
        <Row>
          <h1 className="title-table">USERS</h1>
          <Col md={12}>
            <Card>
              <div className="container-input-modal">
                <ModalNewUser />
              </div>

              <div className="container-search ">
                <SearchUsers
                  selectTwo={selectTwo}
                  selectOne={selectOne}
                  value_role_type={value_role_type}
                  role_type={role_type}
                  hanleChangeFilter={this.hanleChangeFilter}
                />
              </div>
              <div className="container-table-all">
                <TableUsers data={data} role_type={role_type} auto_search={this.getUsersData} />
              </div>
              <Pagination
                totalItemsCount={total}
                onChange={page => this.getUsersData(page)}
                activePage={current_page}
                itemsCountPerPage={per_page}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Index;
