/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import SelectCmx from '../../Utils/Select';
import DataApi from '../../../../megacall/services/Price/DataApi';
import Swal from 'sweetalert2';

class ModalModifyPrefix extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
        modal: false,
        description: '',
        description_name: '',
        prefix: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
  }
    
  initialState() {
    this.setState({ description: this.props.select, description_name: '' });
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({ modal: !prevState.modal }));
  }
    
  handleChange(e, nameI, index) {
    this.setState({ [nameI]: e });
  }
    
  async save() {
    const { description_name } = this.state;
    const object = {
      description_id: description_name.value,
      prefix_id: this.props.prefix_id
    };

    const result = await DataApi.ModifyDesc(object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Saved successfully',
        showConfirmButton: false,
        timer: 1500,
      });
      this.props.getLista();
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Problems saving',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }
    
  render() {
    const { description, description_name } = this.state;
    
    return (
      <React.Fragment>
        <div className="container-button">
          <a onClick={this.toggle} className="btn btn-danger"><i className=" mdi mdi-import"></i> Modify</a>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Correct description</ModalHeader>
          <React.Fragment>
            <ModalBody>
              <SelectCmx index={1} nameI={'description_name'} value={description_name} change={this.handleChange} name={'Description'} options={description} />
              <br />
            </ModalBody>
            <ModalFooter>
              <Button color="outline-primary" onClick={this.save}>Save</Button>{' '}
            </ModalFooter>
          </React.Fragment>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalModifyPrefix;