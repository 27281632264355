import React, { Component } from 'react';

import Forms from './FormsAgents';
import Commissions from '../../pages/Agents/Commission';
import DataApi from '../../../megacall/services/AgentsServices/DataApi';
import DataApiLock from '../../../megacall/services/Locks/DataApi';
import Input from '../../elements/Utils/Input';
import { Col } from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap';
import Switch from 'react-switch';

class TabsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agents: '',
      currencies: '',
      vats: '',
      accuracies: '',
      agentType: '',
      chargeTypes: '',
      customerPrices: '',
      languages: '',
      idAgents: '',
      name: '',
      contactName: '',
      contactNumber: '',
      email: '',
      audits: '',
      locks: '',
      lock: '',
      countCommissions: '',
      countAudit: '',
      id: '',
      enabled: false,
      stat: '',
    };

    this.callCountTabs = this.callCountTabs.bind(this);
    this.renderTitle = this.renderTitle.bind(this);
    this.callApi = this.callApi.bind(this);
    this.toggle = this.toggle.bind(this);
    this.destroyLocks = this.destroyLocks.bind(this);
  }

  async componentWillMount() {
    this.callApi();
    this.callCountTabs();
  }

  async callCountTabs() {
    const id = await this.props.location.state.response;

    const countAll = await DataApi.CountAgents(id);

    this.setState({
      id: id,
      countCommissions: countAll.data.commissions,
      countAudit: countAll.data.audit,
    });
  }

  renderTitle(index) {
    const { countCommissions, countAudit } = this.state;

    switch (index) {
      case 1:
        return countCommissions === 0 ? (
          <span>Commissions</span>
        ) : (
          <span>
            Commissions <span className="badge badge-danger badge-pill "></span>
          </span>
        );
      default:
        return countAudit === 0 ? (
          <span>Audits</span>
        ) : (
          <span>
            Audits <span className="badge badge-danger badge-pill "></span>
          </span>
        ); // cuando se haga
    }
  }

  /*
   *  1 USER
   *  2 SUPPLIER
   *  3 AGENT
   *  4 CUSTOMER
   */
  async componentWillUnmount() {
    // const { agents, locks } = this.state;

    // if (locks === 200) {
    //   await DataApiLock.DestroyLock(agents[0].id, 3);
    // }
    await this.destroyLocks();
    // await this.callApi();
    // await this.callCountTabs();
  }

  
  async callApi() {
    const state = await this.props.location.state;
    const data = await DataApi.Options(state.response);

    this.setState({
      activeTab: '1',
      agents: data.data.agent,
      currencies: data.data.currencies,
      vats: data.data.vats,
      accuracies: data.data.accuracies,
      agentType: data.data.agentType,
      chargeTypes: data.data.chargeTypes,
      customerPrices: data.data.customerPrices,
      languages: data.data.languages,
      idAgents: state.response,
      audits: data.data.audits,
      locks: data.data.status,
    });
    if (data.data.status === 200) {
      this.setState({
        enabled: data.data.agent[0].enabled ? true : false,
        stat: data.data.agent[0].enabled,
      });
    }
    if (data.data.status === 404) {
      this.setState({
        userBlocked: data.data.info[1].username,
        agentInfo: data.data.agentInfo[0],
      });
    }
    

    if (this.state.stat === 1) {
      this.setState({
        stat: 'status: enabled',
      });
    } else if (this.state.stat === 0) {
      this.setState({
        stat: 'status: disabled',
      });
    }
  }
  async destroyLocks() {
    const { locks, agents } = this.state;
   

    if (locks === 200) {
       await DataApiLock.DestroyLock(agents[0].id, 3);
 
    }
  }
  
  async handleChange(nameI) {
    const { enabled } = this.state;

    
    this.setState({ [nameI]: !enabled });
    setTimeout(() => {
      this.desactiveAgents();
    }, 500);

    if (enabled === false) {
      this.setState({ stat: 'status: enabled' });
    } else if (enabled === true) {
      this.setState({ stat: 'status: disabled' });
    }
  }

  async desactiveAgents() {
    const { enabled } = this.state;
    const id = await this.props.location.state;

    await DataApi.AgentsDedactive(id.response, enabled ? 1 : 0);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      agents,
      currencies,
      idAgents,
      vats,
      accuracies,
      agentType,
      chargeTypes,
      customerPrices,
      languages,
      locks,
      id,
      enabled,
      stat,
      userBlocked,
      agentInfo,
    } = this.state;

    // this.state.agentInfo.length > 0 ? '' : '';
    if (locks === 404) {

      return (
        <div className="container-item">
          <h2 className="mb-5 text-red">Agent blocked by {userBlocked}</h2>

          {this.state.agentInfo ? (
            <>
              <div className="container">
                <h3>Main Details</h3>
                <div className="row">
                  <div className="col-sm-6">
                    <Input value={agentInfo.name_agents} name={'Name'} disabled />
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-6">
                        <Input value={agentInfo.contact_name} name={'Contact name'} disabled />
                      </div>
                      <div className="col-6">
                        <Input value={agentInfo.contact_number} name={'Contact number'} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 p-0">
                    <Input value={agentInfo.lenguaje} name={'Language'} disabled />
                  </div>

                  <div className="col-sm-4">
                    <Input value={agentInfo.customer_prices_name} name={'Price List'} disabled />
                  </div>
                  <div className="col-sm-4">
                    <Input type="text" name={'Date'} value={agentInfo.start_date} disabled />
                  </div>
                </div>
                <br />
                <div className="card-body pt-5 border-top">
                  <h3>Basic Info</h3>
                  <br />
                  <div className="row">
                    <div className="col-sm-6">
                      <Input value={agentInfo.address} name={'Address'} disabled />
                    </div>
                    <div className="col-sm-6">
                      <div className="row">
                        <div className="col-12">
                          <Input value={agentInfo.email} name={'Email'} disabled />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <Input value={agentInfo.taxes} name={'Vat'} disabled />
                    </div>
                    <div className="col-sm-4">
                      <Input value={agentInfo.charge_accuracies_name} name={'Charge accuracy'} disabled />
                    </div>
                    <div className="col-sm-4 mt-0">
                      <Input value={agentInfo.agent_types_name} name={'Agent type'} disabled />
                    </div>
                    <div className="col-sm-4">
                      <Input value={agentInfo.moneda} name={'Currency'} disabled />
                    </div>
                    <div className="col-sm-4">
                      <Input value={agentInfo.invoice_prefix} name={'Invoice prefix'} disabled />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        value={
                          agentInfo.charge_type_id === 1
                            ? 'default'
                            : agentInfo.charge_type_id === 2
                            ? 'whole_minute'
                            : 'minute_plus'
                        }
                        name={'Charge Type'}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      );
    } else {
      if (agents.length < 1) return null;

      return (
        <React.Fragment>
          <div className="container-header-title-customer">
            <div className="container-title-tabs">
              <div>
                <h3>
                  <span className="style-text-edit">{id} </span>
                  {agents[0].name_agents}
                </h3>
              </div>
              <div className="checkbox">
                <label className="h5 mr-4">{stat}</label>
                <label>
                  <Switch
                    className="bg-success"
                    onColor="#29cc97"
                    onChange={e => this.handleChange('enabled', 6)}
                    checked={enabled}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className="tab-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-sm-12">
                  <Tabs defaultActiveKey="edit" mountOnEnter={true}>
                    <Tab eventKey="edit" title="Edit">
                      <div className="tab-item-wrapper">
                        <Forms
                          agents={agents}
                          currencies={currencies}
                          vats={vats}
                          accuracies={accuracies}
                          agentType={agentType}
                          chargeTypes={chargeTypes}
                          customerPrices={customerPrices}
                          languages={languages}
                        />
                      </div>
                    </Tab>

                    <Tab eventKey="commissions" title={this.renderTitle(1)}>
                      <div className="tab-item-wrapper">
                        <Col sm="12">
                          <Commissions idAgents={idAgents} />
                        </Col>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default TabsPage;
