import React from 'react';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import { MDBIcon } from 'mdbreact';

class ModalEditContacts extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modal: false,
            name: '',
            phone: '',
            main: '1',
            id: '',
        };
        this.toggle = this.toggle.bind(this);
        this.update = this.update.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initialState = this.initialState.bind(this);
    }
    
    initialState() {
        const { data } = this.props;
        
        this.setState({ name: data.name, phone: data.phone, main: data.main, id: data.id });
    }
    
    toggle() {
        this.initialState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async update() {
        const { main, phone, name, id } = this.state;
        const { customersId: { response } } = this.props;
        
        const object = {
            name: name,
            phone: phone,
            main: main,
            customer_id: response,
        };
        
        const results = await DataApi.UpdateContacts(id, object);
        
        if (results.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Your work has error saved',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        await this.props.getContacts();
        await this.toggle();
    }
    
    render() {
        const { name, phone } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>Edit Contacts</ModalHeader>
                    <ModalBody>
                        <Input nameI={'name'} value={name} change={this.handleChange}
                               name={'Name'} />
                        <Input nameI={'phone'} type={'number'} value={phone} change={this.handleChange}
                               name={'Phone'} />
                        
                        <label>Main</label>
                        <select name="main" className="form-control" onChange={this.handleChange}>
                            <option selected="true" disabled="disabled">main</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.update}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEditContacts;