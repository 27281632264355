import React from 'react';

import { MDBIcon } from 'mdbreact';

import ModalEditCustomerPrice from '../PriceElements/Modal/ModalEditCustomerPrice';

class SubTableCustomerPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      statePrice: false,
      stateCharge: false,
    };
    this.multiCharge = this.multiCharge.bind(this);
    this.multiPrice = this.multiPrice.bind(this);
  }

  multiPrice(statePrice, id) {
    const { index } = this.props;

    this.setState({ statePrice: statePrice });

    if (!statePrice) {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#ffffff';
    } else {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#B0BED9';
    }

    this.props.multiEdit(id, 1);
  }

  multiCharge(stateCharge, id) {
    const { index } = this.props;

    this.setState({ stateCharge: stateCharge });

    if (!stateCharge) {
      document.getElementsByClassName('connection-charge')[index].style.backgroundColor = '#ffffff';
    } else {
      document.getElementsByClassName('connection-charge')[index].style.backgroundColor = '#B0BED9';
    }

    this.props.multiEdit(id, 2);
  }

  render() {
    const { data, index } = this.props;
    const { stateCharge, statePrice } = this.state;

    if (!data) return null;

    return (
      <React.Fragment>
        <tr key={index}>
          <td className="col-principal">{data.description}</td>
          <td onClick={() => this.multiPrice(!statePrice, data.id)} className="price-change">
            {data.price.toFixed(6)}
          </td>
          <td onClick={() => this.multiCharge(!stateCharge, data.id)} className="connection-charge">
            {data.connection_charge.toFixed(6)}
          </td>
          <td className="">
            <ModalEditCustomerPrice getListCustomerPrice={this.props.getListCustomerPrice} data={data} />
            <MDBIcon
              icon="trash"
              size="lg"
              className="trash mt-3 ml-2"
              onClick={() => this.props.destroyCustomePrice(data.id)}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

export default SubTableCustomerPrice;
