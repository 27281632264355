import CallsApi from './CallsApi';

export default class DataApi {
  static async GetRules(page ,json) {
    const result = await CallsApi.GETRULES(page, json);
    return result;
  }
  static async NewRule(object) {
    const result = await CallsApi.SETNEWRULE(object);
    return result;
  }
  static async DestroyRule(id) {
    const results = await CallsApi.DESTROYRULE(id);
    return results;
  }
  static async GetRuleInfo(id) {
    const results = await CallsApi.GETINFORULE(id);
    return results;
  }
  static async ModifyRule(object, id) {
    const result = await CallsApi.UPDATERULE(object, id);
    return result;
  }
  
}
