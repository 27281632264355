export default {
  top: [
    {
      name: 'Agents',
      url: '/agent',
      icon: 'mdi mdi-account-circle',
    },
    {
      name: 'Customers',
      url: '/customers',
      icon: 'mdi mdi-human-handsup',
    },
    {
      name: 'Calls',
      url: '/calls',
      icon: 'mdi mdi-phone-in-talk',
      children: [
        {
          name: 'Load/Process CDRs',
          url: '/cdr',
        },
        {
          name: 'CDR List',
          url: '/callList',
        },
        {
          name: 'Overrides',
          url: '/overrides',
        },
        {
          name: 'Anomalies',
          url: '/anomalies',
        },
        {
          name: 'Charges',
          url: '/charges',
        },
      ],
    },
    {
      name: 'Price',
      url: '/price',
      //icon: 'Info',
      icon: 'mdi mdi-coin',
      children: [
        {
          name: 'Customer Price',
          url: '/customer-price',
        },
        {
          name: 'Suppliers',
          url: '/supplier',
        },
      ],
    },
    {
      name: 'Settings',
      url: '/settings',
      //icon: 'Settings',
      icon: 'mdi mdi-settings-outline',
      children: [
        {
          name: 'Mega Mail',
          url: '/megaMail',
        },
        
      ],
    },
    {
      name: 'Report System',
      url: '/reports',
      icon: 'mdi mdi-file-delimited-outline',
      
      
    },
    {
      divider: true,
    },
  ],
};
