import React from 'react';

import { MDBIcon } from 'mdbreact';

import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalEditDialler extends React.Component {
    constructor(props) {
        super(props);
        
        
        this.state = {
            modal: false,
            url: '',
            centerName: '',
            login: '',
            password: '',
            notes: '',
            id: ''
        };
        this.toggle = this.toggle.bind(this);
        this.update = this.update.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initialState = this.initialState.bind(this);
        this.generatePassword = this.generatePassword.bind(this);
    }
    
    toggle() {
        this.initialState();
        
        this.setState(prevState => ({
            modal: !prevState.modal,
        }));
    }
    
    initialState() {
        const { data } = this.props;
        
        this.setState({
            id: data.id,
            url: data.url,
            centerName: data.contact_centre_name,
            login: data.login,
            password: data.password,
            notes: data.notes,
        });
    }
    
    generatePassword() {
        let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        let string_length = 12;
        let randomstring = '';
        for (let i=0; i<string_length; i++) {
        let rnum = Math.floor(Math.random() * chars.length);
        randomstring += chars.substring(rnum,rnum+1);
        }
        
        this.setState({ password: randomstring });
    }
    
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    async update() {
        const { url, centerName, login, password, notes, id } = this.state;
        const { customersId: { response } } = this.props;
        
        const object = {
            url: url,
            contact_centre_name: centerName,
            login: login,
            password: password,
            notes: notes,
            id: id
        };
        
        const results = await DataApi.UpdateDialler(response, object);
        
        if (results.status === 200) {
            Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
            });
        } else {
            Swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Your work has error saved',
                showConfirmButton: false,
                timer: 1500,
            });
        }
        await this.props.getDiallers();
        await this.toggle();
        
    }
    
    render() {
        const { url, centerName, login, password, notes } = this.state;
        
        return (
            <React.Fragment>
                <div className="container-button">
                    <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
                </div>
                <Modal isOpen={this.state.modal}>
                    <ModalHeader toggle={this.toggle}>Edit Diallers</ModalHeader>
                    <ModalBody>
                        <Input nameI={'url'} value={url} change={this.handleChange}
                               name={'Url'} />
                        <Input nameI={'centerName'} value={centerName} change={this.handleChange}
                               name={'Contact Center Name'} />
                        <Input nameI={'login'} value={login} change={this.handleChange}
                               name={'Login'} />
                        <Input nameI={'password'} value={password} change={this.handleChange}
                               name={'Password'} />
                        <input value="Generate Password" type="submit" onClick={this.generatePassword} />
                        <FormGroup>
                            <label>Notes</label>
                            <textarea className="form-control" id="notes" value={notes} name="notes"
                                      onChange={(e) => this.handleChange(e)} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.update}>Send</Button>{' '}
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalEditDialler;