import CallsApi from "./CallsApi";

export  default class DataApi {

    static async GetUsers(page, json) {
      const result = await CallsApi.GETUSERS(page, json);

      return result;
    }

    static async ListRoles(type) {
      const result = await CallsApi.GETROLES(type);

      return result;
    }

    static async SetNewUsers(new_user) {
      const result = await CallsApi.NEWUSER(new_user);

      return result;
    }

    static async SetUser(id, user) {
      const results = await CallsApi.UPDATEUSER(id, user);

      return results;
    }

    static async GetPassword(id) {
      const results = await CallsApi.NEWPASSWORD(id);

      return results;
    }

    static async DestroyUser(id) {
      const results = await CallsApi.DELETEUSER(id);

      return results;
    }
}