/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Col, Row } from 'react-bootstrap';

import Card from '@material-ui/core/Card';
import SelectCmx from '../../../elements/Utils/Select';
import Pagination from 'react-js-pagination';
import DataApi from '../../../../megacall/services/Price/DataApi';
import ModificationArray from '../../../../megacall/utilsFunctions/ModificationArray';
import TableCustomerPrice from '../../../elements/PriceElements/TableCustomerPrice';
import Input from '../../../elements/Utils/Input';
import ModalNewCustomerPrice from '../../../elements/PriceElements/Modal/ModalNewCustomerPrice';
import Swal from 'sweetalert2';
import Loader from 'react-loader-spinner';
import ModalCreateCustomerPrice from '../../../elements/PriceElements/Modal/ModalCreateCustomerPrice';
import ModalCambioCustomerPrice from '../../../elements/PriceElements/Modal/ModalCambioCustomerPrice';

class CustomerPrice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name_customer_price: '',
      name_customer_price_all: '',
      data: '',
      id: '',
      description: '',
      prefix_translate: '',
      load: false,
      file: null,
      txtFile: null,
    };
    this.getSelect = this.getSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getListCustomerPrice = this.getListCustomerPrice.bind(this);
    this.destroy = this.destroy.bind(this);
    this.download = this.download.bind(this);
    this.onChangeFile = this.onChangeFile.bind(this);
    this.importExport = this.importExport.bind(this);
    this.importUpdate = this.importUpdate.bind(this);
  }

  async componentDidMount() {
    await this.getSelect();
  }

  /** inicializa el select */
  async getSelect() {
    const customerPrive = await DataApi.ListSelectCustomerPrice();
    const select = await DataApi.GetListDescriptions();

    //const modification_price = ModificationArray.modification(customerPrive.data);
    const results = ModificationArray.modificationDialDescription(select.data);

    this.setState({ name_customer_price_all: customerPrive.data, prefix_translate: results });
  }

  /** Cambia el estado y a la vez funciona con el buscador */
  async handleChange(e, nameI, index) {
    const load = true;
    switch (index) {
      case 1:
        this.setState({ [nameI]: e, id: e.value });
        setTimeout(() => {
          this.getListCustomerPrice(1, load);
        }, 50);
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
          this.getListCustomerPrice();
        }, 500);
        break;
    }
  }

  async getListCustomerPrice(page = 1, load) {
    this.setState({ load });
    const { id, description } = this.state;

    const object = {
      description: description,
    };

    const results = await DataApi.GetCustomerPrice(id, page, object);

    this.setState({ data: results.data, load: false });
  }

  /** Genera un pdf o un text dependiendo el indice que le pases esta funcion seria conveniente sacarla fuera, ya que seguramente sea utilizada en mas sitios */
  async download(index) {
    const { id } = this.state;

    const object = { customer_price_id: id };
    let urlRela;

    switch (index) {
      case 1:
        let responsePdf = await DataApi.DownloadPdf(object);
        urlRela = responsePdf;
        break;
      default:
        let responseTxt = await DataApi.DownloadTxt(object);
        urlRela = responseTxt;
        break;
    }

    let url = `https://manager-kosmos.megacall.es/storage${urlRela.data.split('storage')[1]}`;
    window.open(url, '_blank');
  }

  onChangeFile(e, index) {
    switch (index) {
      case 1:
        const file = e.target.files;
        this.setState({ file: file[0] });
        break;
      case 2:
        const file_txt = e.target.files;
        this.setState({ txtFile: file_txt[0] });
        break;
      default:
        break;
    }
  }

  async importExport() {
    const { id, file } = this.state;
    this.setState({ load: true });
    const formData = new FormData();
    formData.append('sippy_file', file);
    const response = await DataApi.ImportExport(id, formData);
    if (response.data.status !== 'error') {
      let element = document.createElement('a');
      element.setAttribute('href', response.data.page);
      element.setAttribute('download', 'download');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      this.setState({ load: false });
    } else {
      // console.log('HOLAAAAAAAA');
      // console.log(response.data);
      this.setState({ load: false });
      setTimeout(() => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'There has been a problem with the file, check for blanks.',
          showConfirmButton: true,
          timer: 5500

        });
      });
    }
  }

  async importUpdate() {
    const { id, txtFile } = this.state;
    this.setState({ load: true });
    //TODO llamar al metodo del backen para subir fichero y actualizar en bloque todo.
    const formData = new FormData();
    formData.append('price_file', txtFile);
    const response = await DataApi.ImportToUpdate(id, formData);
    if (response.data.status !== 'error') {
      setTimeout(() => {
        this.getListCustomerPrice(1, true);
      }, 50);
    } else {
      this.setState({ load: false });
      setTimeout(() => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: 'There has been a problem with the file, check for blanks.',
          showConfirmButton: true,
          timer: 5500

        });
      });
    }
  }

  async destroy() {
    const { id } = this.state;

    const object = { customer_price_id: id };

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        DataApi.Destroy(object).then((response) => {
          if (response.status === 200 && !response.data) {
            Swal.fire('Deleted!', 'The Customer Price has been deleted.', 'success');
          } else {
            Swal.fire('Deleted!', 'This Customer Price exists for a customer', 'error');
          }
        });
      }
    });
  }

  render() {
    const { name_customer_price, name_customer_price_all, data, description, prefix_translate, id, load } = this.state;
    const { current_page, total, per_page } = this.state.data;
    console.log(data);
    if (load) {
      return (
        <div
          className="container-item mt-5 position-absolute offset-3"
          style={{ zIndex: '1000', backgroundColor: '#f2f5f9', width: '600px' }}
        >
          <Loader type="ThreeDots" color="#4c84ff" width={300} className="top-100 start-100 mt-3" />
          <div className="d-flex justify-content-center ">
            <h2 className="ml-3" style={{ color: '#4c84ff' }}>
              LOADING...
            </h2>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div>
          <h1 className="title-table">CUSTOMER PRICE</h1>
          <div className="ml-5 mb-5">
            <ModalCreateCustomerPrice getSelect={this.getSelect} />
          </div>

          <div className="container">
            <div className="row ml-3 d-flex">
              <div className="col-lg-10">
                <SelectCmx
                  nameI={'name_customer_price'}
                  value={name_customer_price}
                  change={this.handleChange}
                  name={'Name'}
                  index={1}
                  options={name_customer_price_all}
                />
              </div>
              <div className="col-2 mt-4">
                {!data ? null : (
                  <ModalCambioCustomerPrice
                    type={name_customer_price.type}
                    name={name_customer_price.label}
                    id={name_customer_price.value}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="container-search">
            {!data ? null : (
              <>
                <div className="col-lg-10">
                  <div className="row ml-3">
                    <div className="col-lg-8">
                      <div className="input-group">
                        <input className="form-control" type="file" onChange={(e) => this.onChangeFile(e, 1)} />
                        <span className="input-group-text bg-primary text-light">
                          <a
                            data-toggle="dropdown"
                            onClick={() => this.importExport()}
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-display="static"
                          >
                            Import / Export to Sippy
                          </a>
                        </span>
                      </div>
                      <div className="input-group mt-3">
                        <input className="form-control" type="file" onChange={(e) => this.onChangeFile(e, 2)} />
                        <span className="input-group-text bg-primary text-light">
                          <a
                            data-toggle="dropdown"
                            onClick={() => this.importUpdate()}
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-display="static"
                          >
                            Import TXT File
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="row ml-3">
                    <div className="col-lg-6">
                      <Input
                        nameI={'description'}
                        value={description}
                        change={this.handleChange}
                        placeholder={'Description'}
                      />
                    </div>

                    <div className="col-lg-6">
                      <ModalNewCustomerPrice
                        prefix_translate_all={prefix_translate}
                        customer_price_id={id}
                        getListCustomerPrice={this.getListCustomerPrice}
                      />
                      <button type="submit" className="btn btn-primary mt-4 mb-5" onClick={() => this.download(1)}>
                        Download PDF
                      </button>
                      <button type="submit" className="btn btn-primary mt-4 mb-5" onClick={() => this.download(2)}>
                        Download TXT
                      </button>
                      <button type="submit" className="btn btn-danger mt-4 mb-5" onClick={() => this.destroy()}>
                        Delete
                      </button>
                    </div>
                  </div>

                  <div className="row ml-3">
                    <div className="col-lg-8"></div>
                  </div>

                  <br />
                </div>
              </>
            )}
          </div>

          <TableCustomerPrice data={data} getListCustomerPrice={this.getListCustomerPrice} />
          {total ? (
            <Pagination
              totalItemsCount={total}
              onChange={(page) => this.getListCustomerPrice(page)}
              activePage={current_page}
              itemsCountPerPage={per_page}
              itemClass="page-item"
              linkClass="page-link"
            />
          ) : (
            ''
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerPrice;
