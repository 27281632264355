export default {
  top: [
    {
      name: 'Agents',
      url: '/agent',
      //icon: 'User',
      icon: 'mdi mdi-account-card-details',
    },
    {
      name: 'Customers',
      url: '/customers',
      //icon: 'User'
      icon: 'mdi mdi-human-handsup',
    },
    {
      name: 'Calls',
      url: '/calls',
      //icon: 'Phone'
      icon: 'mdi mdi-phone-in-talk',
      children: [
        {
          name: 'Load/Process CDRs',
          url: '/cdr',
        },
        {
          name: 'CDR List',
          url: '/callList',
        },
        {
          name: 'Overrides',
          url: '/overrides',
        },
        {
          name: 'Anomalies',
          url: '/anomalies',
        },
        {
          name: 'Charges',
          url: '/charges',
        },
      ],
    },
    {
      name: 'Invoice',
      url: '/invoice',
      //icon: 'File'
      icon: 'mdi mdi-paperclip',
    },
    {
      name: 'Payments',
      url: '/payments',
      //icon: 'Plus'
      icon: 'mdi mdi-credit-card-multiple',
    },
    {
      name: 'Price',
      url: '/price',
      //icon: 'Info',
      icon: 'mdi mdi-coin',
      children: [
        {
          name: 'Customer Price',
          url: '/customer-price',
        },
        {
          name: 'Suppliers',
          url: '/supplier',
        },
        {
          name: 'Check prices',
          url: '/check-prices',
        },
      ],
    },
    {
      name: 'Settings',
      url: '/settings',
      //icon: 'Settings',
      icon: 'mdi mdi-settings-outline',
      children: [
        {
          name: 'Vat',
          url: '/vat',
        },
        {
          name: 'Currency  ',
          url: '/exchange',
        },
        {
          name: 'Register Numbers',
          url: '/register',
        },
        {
          name: 'Mega Mail',
          url: '/megaMail',
        },
        {
          name: 'Report System',
          url: '/reports',
        },
      ],
    },
    {
      name: 'Report System',
      url: '/reports',
      icon: 'mdi mdi-file-delimited-outline',
    },
    {
      divider: true,
    },
  ],
};
