import React, { Component } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import RichTextEditor from 'react-rte';
import SelectCmx from '../../../elements/Utils/Select';
import DataApi from '../../../../megacall/services/Settings/DataApi';

class Mail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: RichTextEditor.createEmptyValue(),
      languages: [{ label: 'English', value: 1 }, { label: 'Spanish', value: 2 }],
      mail: '',
      language: '',
      emails: '',
    };
    this.send = this.send.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  //   state = {
  //     value: RichTextEditor.createEmptyValue(),
  //     languages: [{ label: 'English', value: 1 }, { label: 'Spanish', value: 2 }],
  //     mail : ''

  //   };

  onChange = value => {
    this.setState({ value });

    // Send the changes up to the parent component as an HTML string.
    // This is here to demonstrate using `.toString()` but in a real app it
    // would be better to avoid generating a string on each change.
    this.setState({ mail: value.toString('html') });
  };

  async getByLang() {
    const { language, emails } = this.state;
    console.log(language);
    const object = {
      language: language.value,
    };

    const emailsByLang = await DataApi.getEmailsByLang(object);

    console.log(emailsByLang);
    this.setState({ emails: emailsByLang.data });
  }

  send() {
    console.log(this.state.mail);
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        this.getByLang();
        break;
      case 2:
        break;
      case 3:
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }
  render() {
    const { languages, language, product, products, emails } = this.state;
    console.log(emails);
    return (
      <React.Fragment>
        <div className="">
          <Row>
            <h1 className="title-table">Mail System</h1>
            <Col md={12}>
              <div className="container">
                <div className="col-12">
                  <div className="row">
                    <div className="mb-5 form-group col-6">
                      <SelectCmx
                        index={1}
                        nameI={'language'}
                        value={language}
                        change={e => this.handleChange(e, 'language', 1)}
                        name={'Language'}
                        options={languages}
                      />
                    </div>
                    <div className="mb-5 form-group col-6">
                      <SelectCmx
                        index={1}
                        nameI={'product'}
                        value={product}
                        change={e => this.handleChange(e, 'product', 2)}
                        name={'Products'}
                        options={products}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="mb-5 form-group col-12 container-item">
                  {emails
                    ? emails.map(email => {
                        console.log(email);
                        return <p className="">{email.email}</p>;
                      })
                    : ''}

                  {/* <label>Email</label>
                <input type="text" className="form-control" name="email" /> */}
                </div>
              </div>
              <div className="container">
                <Card>
                  <div className="mb-5 form-group col-12 container-item p-3">
                    <input type="text" className="form-control mb-3 mt-3" name="to" placeholder="De" />

                    <input type="text" className="form-control mb-3" name="para" placeholder="Para" />

                    <input type="text" className="form-control mb-3" name="asunto" placeholder="Asunto" />

                   
                      <RichTextEditor value={this.state.value} onChange={this.onChange} className="container-item mb-3" />
               

                    <button onClick={() => this.send()}>Send</button>
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default Mail;
