import React from 'react';
import CallApi from '../../../megacall/services/SuppliersServices/DataApi';

class TableImport extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isChecked: false,
            isSelectMulti: false,
            isColor: false,
        };
        this.hanleChangeColor = this.hanleChangeColor.bind(this);
        this.hanleChange = this.hanleChange.bind(this);
        this.postIgnorePrefix = this.postIgnorePrefix.bind(this);
    }
    
    componentDidMount() {
        this.hanleChangeColor();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.data !== this.props.data) {
            this.hanleChangeColor();
        }
    }
    
    postIgnorePrefix() {
        const { isChecked } = this.state;
        const { id, data } = this.props;
        
        this.setState({ isChecked: !isChecked });
        
        setTimeout(() => {
            isChecked ?
                CallApi.PostIgnorePrefix({ prefixes: data.prefix, value: 1, supplierId: id }) :
                CallApi.PostIgnorePrefix({ prefixes: data.prefix, value: 0, supplierId: id });
        }, 500);
    }
    
    hanleChange() {
        const { data } = this.props;
        const { isSelectMulti } = this.state;
    
        this.setState({ isSelectMulti: !isSelectMulti });
        this.props.multiselect(data.id);
    }
    
    hanleChangeColor() {
        const { data } = this.props;
        
        if(data.difference === 'New') {
            const colorNew = document.querySelectorAll('.new');
    
            colorNew.forEach(element => {
                element.style.backgroundColor = '#cf5918';
            });
            
        }else {
            const colorNew = document.querySelectorAll('.new');
    
            colorNew.forEach(element => {
                element.style.backgroundColor = '#ffffff';
            });
            
        //    if(Math.sign(data.peak_diff) === -1 || (Math.sign(data.peak_diff) === -0)) {
        //        const colorDifference = document.querySelectorAll('.difference');
        //        
         //       colorDifference.forEach(element => {
        //            element.style.backgroundColor = '#289900';
        //        });
        //    } else if (Math.sign(data.peak_diff) === 1 || (Math.sign(data.peak_diff) === 0)) {
      //          const colorDifference = document.querySelectorAll('.difference');
    //
          //      colorDifference.forEach(element => {
          //          element.style.backgroundColor = '#f36a6a';
         //       });
        //    }
        }
    }
    
    render() {
        const { data, index, change } = this.props;
        const { isChecked, isSelectMulti } = this.state;
        
        if (!data)
            return null;
        
        return (
            <React.Fragment>
                <tr key={index}>
                    <td className="new container-item-2">
                        {change === 2 ? 
                        <input type="checkbox" onClick={this.hanleChange} defaultChecked={isSelectMulti} id={'check' + data.id}/>
                        : null}
                    </td>
                    <td className="new container-item-3">{data.prefix}</td>
                    <td className="new container-item-4">{data.description}</td>
                    <td className="new container-item-4">{data.orig_megacall_desc}</td>
                    <td className={`${Math.sign(data.peak_diff) === -1 && 'bg-success' || Math.sign(data.peak_diff) === 1 && 'bg-danger'} new container-item-5`}>{data.supplier_desc}</td>
                    <td className={`${Math.sign(data.peak_diff) === -1 && 'bg-success' || Math.sign(data.peak_diff) === 1 && 'bg-danger'} new container-item-6`}>{data.peak_diff}</td>
                    <td className="new container-item-6">{data.peak_diff_pct}</td>
                    <td className="new container-item-6">{data.peak}</td>
                    <td className="new container-item-6">{data.conn}</td>
                    <td className="new container-item-6">{data.ch_secs}</td>
                    <td className="new container-item-6">{data.template}</td>
                    <td className="new container-item-7">{change === 1 || change === 2 ? 'New' : 'Difference'}</td> {/*se debe establecer de alguna forma las*/}
                    <td className="new container-item-6">{data.old_peak}</td>
                    <td className="new container-item-6">{data.old_conn}</td>
                    <td className="new container-item-2">
                        <input
                            type="checkbox"
                            onClick={this.postIgnorePrefix}
                            defaultChecked={isChecked}
                        />
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}

export default TableImport;
