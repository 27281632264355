import React from 'react';
import { NavLink } from 'react-router-dom';

const NavSingleItem = ({ item }) => {

  if (item.external) {
    const rel = item.target && item.target === '_blank' ? 'noopener noreferrer' : null;

    return (
      <li className="nav-item">
        <a href={item.url} target={item.target} rel={rel}>
           {item.icon &&  <i className={`${item.icon} mdi-24px`}></i>}
          <span className="nav-item-label">{item.name}</span>
        </a>
      </li>
    );
  } else {
    // Force relative URLs to start with a slash
    const url = item.url.charAt(0) === '/' ? item.url : `/${item.url}`;

    return (
      <li className="nav-item">
        <NavLink to={url} activeClassName="active">
          {item.icon && <i className={`${item.icon} mdi-24px`}></i>}
          <span className="nav-item-label">{item.name}</span>
        </NavLink>
      </li>
    );
  }
};

export default NavSingleItem;
