import UrlServices from '../UrlServices';
import axios from 'axios';
import Headboard from '../Headboard';

export default class CallsApi {
  static API_URL_GLOBAL = UrlServices.universal;

  /** vat */
  static async GETLISTSELECT() {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + 'settings/vat/list', await Headboard.HEADERS());

    return response;
  }

  static async GETVATS(id) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/vat/show/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async SAVERATE(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + 'settings/vat/create',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATERATE(id, object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/vat/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** International Number Type */
  static async GETLISTINTERNATIONAL() {
    const response = await axios.get(
      CallsApi.API_URL_GLOBAL + 'settings/international-number-types/list',
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEINTERNATIONALNUMBERTYPE(id, object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/international-number-types/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWINTERNATIONALNUMBERTYPE(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/international-number-types/create`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYINTERNATIONALNUMBERTYPE(id) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/international-number-types/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Ext Types */
  static async GETLISTEXTTYPES() {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + 'settings/ext-types/list', await Headboard.HEADERS());

    return response;
  }

  static async UPDATEEXTTYPES(id, object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/ext-types/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWEXTTYPES(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + 'settings/ext-types/create',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Gateways */
  static async GETLISTGETAWAYS(page, json) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/gateways/list?page=${page}`,
      json,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYGATEWAY(id) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/gateways/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWGATEWAY(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/gateways/create`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Locks */
  static async GETLOCKS(page) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/locks/list?page=${page}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYLOCKS(id) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/locks/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Products */
  static async GETLISTPRODUCTS(page, object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/products/list?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWPRODUCT(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + 'settings/products/create',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async DESTROYPRODUCTS(id) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/products/destroy/${id}`,
      null,
      await Headboard.HEADERS()
    );

    return response;
  }

  static async UPDATEPRODUCTS(id, object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/products/update/${id}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  /** Currency Exchanges */
  static async LISTCURRENCYEXCHANGES() {
    const response = await axios.get(
      CallsApi.API_URL_GLOBAL + 'settings/currency-exchanges/list',
      await Headboard.HEADERS()
    );

    return response;
  }

  static async NEWCURRENCYEXCHANGES(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + 'settings/currency-exchanges/create',
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  //AUDITS
  static async GETAUDITS(object, page) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/audits/list?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  //Registers Numbers
  static async GETLISTNUMBERS(page, object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/register/list?page=${page}`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }

  //MEGAMAIL
  static async GETEMAILBYLANG(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/megaMail/byLang`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
  //REPORT
  static async GETSELECTS() {
    const response = await axios.get(CallsApi.API_URL_GLOBAL + `settings/report/getSelects`, await Headboard.HEADERS());

    return response;
  }
  static async GETDATA(object) {
    const response = await axios.post(
      CallsApi.API_URL_GLOBAL + `settings/report/getData`,
      object,
      await Headboard.HEADERS()
    );

    return response;
  }
}
