import React from 'react';

import { MDBIcon } from 'mdbreact';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalEditMinutes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,      
      minute: '',
    };
    this.toggle = this.toggle.bind(this);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
  }

  async toggle() {
    await this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.customer !== this.state.customer) {
    }
  }

  async initialState() {
    const { minutes } = this.props;

    this.setState({
      minute: minutes,
    });
  }

  handleChange(e) {    
    this.setState({ [e.target.name]: e.target.value });
  }

  async save() {
    const { minute } = this.state;

    const object = {
        item: this.props.item,
      minutes: minute,
    };

    const result = await DataApi.UpdateMinutes(object);

    if (result.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Saved successfully',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Problems saving',
        showConfirmButton: false,
        timer: 1500,
      });
    }

    await this.props.getPackages();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { minute } = this.state;

    return (
      <>
        <div className="container-button">
          <MDBIcon icon="edit" size="lg" className="edit" onClick={this.toggle} />
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Update Minutes</ModalHeader>
            <React.Fragment>
              <ModalBody>
                <Input nameI={'minute'} value={minute} change={this.handleChange} name={'Minutes'} type={'number'} />
                <br />
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={this.save}>
                  Save
                </Button>{' '}
              </ModalFooter>
            </React.Fragment>
        </Modal>
      </>
    );
  }
}

export default ModalEditMinutes;
