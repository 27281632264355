import React from 'react';

import { MDBIcon } from 'mdbreact';

import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Input from '../../../Utils/Input';
import SelectCmx from '../../../Utils/Select';
import { Checkbox } from '@material-ui/core';
import DataApi from '../../../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';

class ModalEdit3cx extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      url: '',
      id: '',
      license: '',
      login: '',
      password: '',
      notes: '',
      simultaneousCalls: '',
      support: '',
      serverMegacall: '',
      isServerChecked: false,
      isSupportChecked: false,
      created_at: '',
      licenses: [{ label: 'STANDARD', value: 1 }, { label: 'PRO', value: 2 }, { label: 'ENTERPRISE', value: 3 }],
      simultaneousCallsNumbers: [
        { label: '8', value: 8 },
        { label: '16', value: 16 },
        { label: '24', value: 24 },
        { label: '32', value: 32 },
        { label: '48', value: 48 },
        { label: '64', value: 64 },
        { label: '96', value: 96 },
        { label: '128', value: 128 },
        { label: '192', value: 192 },
        { label: '256', value: 256 },
        { label: '512', value: 512 },
        { label: '1024', value: 1024 },
      ],
    };
    this.toggle = this.toggle.bind(this);
    this.update = this.update.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialState = this.initialState.bind(this);
    this.generatePassword = this.generatePassword.bind(this);
  }

  toggle() {
    this.initialState();

    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  initialState() {
    const { data, customersId } = this.props;

    this.setState({
      customersId: customersId,
      id: data.id,
      url: data.url,
      license: { label: data.licenses },
      username: data.username,
      password: data.password,
      notes: data.notes,
      simultaneousCalls: { label: data.number_simultaneous_calls },
      isSupportChecked: data.support ? true : false,
      isServerChecked: data.server_megacall ? true : false,
      created_at: new Date(data.created_at),
      updated_at: new Date(data.updated_at),
    });
  }

  generatePassword() {
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let string_length = 12;
    let randomstring = '';
    for (let i=0; i<string_length; i++) {
      let rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum,rnum+1);
    }

    this.setState({ password: randomstring });
  }

  handleChange(e, nameI, index) {
    const { isServerChecked, isSupportChecked } = this.state;

    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        break;
      case 2:
        this.setState({ [nameI]: !isServerChecked });
        break;
      case 3:
        this.setState({ [nameI]: !isSupportChecked });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async update() {
    const {
      url,
      license,
      username,
      password,
      notes,
      simultaneousCalls,
      isSupportChecked,
      isServerChecked,
      created_at,
      id,
    } = this.state;
    const {
      customersId: { response },
    } = this.props;

    const object = {
      url: url,
      licenses: license.label,
      username: username,
      password: password,
      notes: notes,
      number_simultaneous_calls: simultaneousCalls.label,
      support: isSupportChecked ? 1 : 0,
      server_megacall: isServerChecked ? 1 : 0,
      created_at: created_at,
      id: id,
    };

    const results = await DataApi.Update3cx(response, object);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your work has been saved',
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error saved',
        showConfirmButton: false,
        timer: 1500,
      });
    }
    await this.props.get3cx();
    await this.toggle();
  }

  render() {
    const {
      url,
      username,
      password,
      notes,
      license,
      simultaneousCalls,
      licenses,
      simultaneousCallsNumbers,
      isSupportChecked,
      isServerChecked,
    } = this.state;

    return (
      <React.Fragment>
        <div className="container-button">
          <MDBIcon icon="edit" size="lg" onClick={this.toggle} />
        </div>
        <Modal isOpen={this.state.modal}>
          <ModalHeader toggle={this.toggle}>Edit Diallers</ModalHeader>
          <ModalBody>
            <Input nameI={'url'} value={url} change={this.handleChange} name={'Gateway'} />
            <Input nameI={'username'} value={username} change={this.handleChange} name={'Username'} />
            <Input nameI={'password'} value={password} change={this.handleChange} name={'Password'} />
            <div className="mb-2 d-flex justify-content-center">
              <input
                value="Generate Password"
                type="submit"
                onClick={this.generatePassword}
                className="btn-sm btn-outline-primary"
              />
            </div>
            <div className="container-input-modal"></div>
            <SelectCmx
              index={1}
              nameI={'license'}
              value={license}
              change={e => this.handleChange(e, 'license', 1)}
              name={'License'}
              options={licenses}
            />
            <div className="mt-2" />
            <SelectCmx
              index={1}
              nameI={'simultaneousCalls'}
              value={simultaneousCalls}
              change={e => this.handleChange(e, 'simultaneousCalls', 1)}
              name={'Simultaneous Calls'}
              options={simultaneousCallsNumbers}
            />
            <FormGroup>
              <label className="mt-2">Notes</label>
              <textarea
                className="form-control"
                id="notes"
                value={notes}
                name="notes"
                onChange={e => this.handleChange(e)}
              />
            </FormGroup>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Support</label>
                  <Checkbox
                    onChange={e => this.handleChange(e, 'isSupportChecked', 3)}
                    name="Support"
                    nameI="isSupportChecked"
                    isChecked={isSupportChecked}
                    index={3}
                    className="ml-2"
                    checked={isSupportChecked}
                    color="primary"
                  />
                </div>
                <div className="form-group">
                  <label>Megacall Server</label>
                  <Checkbox
                    onChange={e => this.handleChange(e, 'isServerChecked', 2)}
                    name="Megacall Server"
                    nameI="isServerChecked"
                    isChecked={isServerChecked}
                    index={2}
                    className="ml-2"
                    checked={isServerChecked}
                    color="primary"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.update}>
              Send
            </Button>{' '}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ModalEdit3cx;
