import React, { Component } from 'react';

import SelectCmx from '../../../views/elements/Utils/Select';

class SearchUsers extends Component {
    
    render () {
        const { selectOne, selectTwo, role_type, value_role_type } = this.props;

        return (
            <React.Fragment>
                <div className="row justify-content-between top-information container-search-user">
                    <div className="col-sm-4">
                        <div className="form-group">
                            <input
                                className="form-control"
                                placeholder="Username"
                                onChange={(e) => this.props.hanleChangeFilter(e)}
                                name="username"
                            />
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="form-group">
                            <SelectCmx nameI={'value_role_type'} value={value_role_type} change={this.props.hanleChangeFilter} index={3}  options={role_type} />
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="form-group">
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value={'1'}
                                    name="contact"
                                    onChange={(e) => {
                                        this.props.hanleChangeFilter(e, null, 1)
                                    }}
                                    checked={selectOne}
                                />
                                <label className="form-check-label">Enable&nbsp;</label>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    value={'0'}
                                    name="contact"
                                    onChange={(e) => {
                                        this.props.hanleChangeFilter(e, null, 2);
                                    }}
                                    checked={selectTwo}
                                />
                                <label className="form-check-label">Disable&nbsp;</label>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default SearchUsers;

