/* eslint-disable array-callback-return */
import React from 'react';

import { CardBody, Table } from 'reactstrap';

import ModalMultiEditPriceList from './Modal/ModalMultiEditPriceList';
import ModalMultiEditPriceListMassive from './Modal/ModalMultiEditPriceListMassive';
import IndexTablePriceList from './IndexTablePriceList';

class TablePriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      descriptions: '',
      prefix: '',
      data: '',
      description: '',
      csv: '',
      select: '',
      arrayMultiDescription: [],
      checkBox: false,
      arrrayId: [],
      index: [],
      paginate: true,
      file: null,
      load: false,
      statePrice: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.multiEdit = this.multiEdit.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.clean = this.clean.bind(this);
  }

  multiPrice(statePrice, id, index) {
    this.setState({ statePrice: statePrice });

    if (!statePrice) {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#ffffff';
    } else {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#B0BED9';
    }
    this.multiEdit(id);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  selectAll(itemsAll) {
    itemsAll.map((element, index) => {
      document.getElementsByClassName('price-change')[index].style.backgroundColor = '#B0BED9';
    });
  }

  async checkAll() {
    const {
      data: { data },
    } = this.state;

    const itemsAll = [];

    data.map(data => {
      itemsAll.push(data.id);
    });

    this.setState({ arrrayId: itemsAll });

    this.selectAll(itemsAll);
  }

  multiEdit(id) {
    const { arrayMultiDescription, arrrayId } = this.state;
    const indexArray = arrayMultiDescription.findIndex(data => data === id);
    const indexArray2 = arrrayId.findIndex(data => data === id);

    if (indexArray < 0) {
      arrayMultiDescription.length === 0
        ? this.setState({ arrayMultiDescription: [id] })
        : this.setState({ arrayMultiDescription: [...arrayMultiDescription, id] });
    } else {
      arrayMultiDescription.splice(indexArray, 1);
      this.setState({ arrayMultiDescription: arrayMultiDescription });
    }

    if (indexArray2 < 0) {
      arrrayId.length === 0 ? this.setState({ arrrayId: [id] }) : this.setState({ arrrayId: [...arrrayId, id] });
    } else {
      arrrayId.splice(indexArray, 1);
      this.setState({ arrrayId: arrrayId });
    }
  }

  clean(arrayMultiDescription, arrrayId) {
    if (arrrayId.length > 0) {
      arrrayId.map((element, index) => {
        document.getElementsByClassName(`price-change-${index}`).style.backgroundColor = '#ffffff';
      });
      this.setState({ arrrayId: '', arrayMultiDescription: '' });
    } else if (arrayMultiDescription.length > 0) {
      arrayMultiDescription.map((element, index) => {
        document.getElementsByClassName(`price-change-${index}`).style.backgroundColor = '#ffffff';
      });
      this.setState({ arrrayId: '', arrayMultiDescription: '' });
    }
  }

  render() {
    const { data } = this.props.data;
    const { arrayMultiDescription, arrrayId } = this.state;
    if (!data) return null;

    return (
      <React.Fragment>
        {arrrayId.length ? (
          <>
            <div className="d-flex ">
              <ModalMultiEditPriceList
                arrrayId={arrrayId}
                arrayMultiDescription={arrayMultiDescription}
                supplier_id={data[0].supplier_id}
                multiEdit={this.multiEdit}
                getSuppliersData={this.props.getSuppliersData}
              />
              <div className="float-left ">
                <button className="btn btn-warning" onClick={() => this.clean(arrayMultiDescription, arrrayId)}>
                  {' '}
                  Clean
                </button>
              </div>
            </div>
          </>
        ) : this.props.description.length ? (
          <div className="d-flex ml-3">
            <ModalMultiEditPriceListMassive
              arrrayId={arrrayId}
              arrayMultiDescription={arrayMultiDescription}
              supplier_id={data[0] ? data[0].supplier_id : ''}
              multiEdit={this.multiEdit}
              getSuppliersData={this.props.getSuppliersData}
              getPrefixTranslates={this.getPrefixTranslates}
              description={this.props.description}
            />

            <div className="float-left">
              <button className="btn btn-warning" onClick={() => this.clean(arrayMultiDescription, arrrayId)}>
                {' '}
                Clean
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex ml-3">
            <button className="btn btn-primary" disabled>
              Edit All
            </button>
            <div className="float-left">
              <button className="btn btn-warning" onClick={() => this.clean(arrayMultiDescription, arrrayId)} disabled>
                {' '}
                Clean
              </button>
            </div>
          </div>
        )}

        <CardBody>
          <Table className="table container-item">
            <thead>
              <tr>
                <th scope="col">Prefix</th>
                <th scope="col">Description</th>
                <th scope="col">Peak Rate</th>
                <th scope="col">Connection Charge</th>
                <th scope="col">Charge Seconds</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <IndexTablePriceList
                  key={item.supplier_id ? item.supplier_id + item.prefix_translate_id : index}
                  item={item}
                  index={index}
                  getPrefixTranslates={this.getPrefixTranslates}
                  multiEdit={this.multiEdit}
                  arrrayId={arrrayId}
                  getSuppliersData={this.props.getSuppliersData}
                  destroy={this.props.destroy}
                />
              ))}

              
            </tbody>
          </Table>
        </CardBody>
      </React.Fragment>
    );
  }
}

export default TablePriceList;
