import React from 'react';
import { CSVLink } from 'react-csv';
import { Col, Row } from 'react-bootstrap';
import { Table } from 'reactstrap';
import DataApi from '../../../megacall/services/Price/DataApi';
import Pagination from 'react-js-pagination';
import Input from '../Utils/Input';
import TablePriceTranslate from './TablePriceTranslate';
import ModalNewPrefixTranslates from './Modal/ModalNewPrefixTranslates';
import ModificationArray from '../../../megacall/utilsFunctions/ModificationArray';
import ModalMultiEditPriceTranslate from '../PriceElements/Modal/ModalMultiEditPriceTranslate';
import Swal from 'sweetalert2';


class IndexTablePriceTranslate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      descriptions: '',
      prefix: '',
      data: '',
      description: '',
      csv: '',
      select: '',
      arrayMultiDescription: [],
      checkBox: false,
      arrrayId: [],
      index: [],
      paginate: true,
    };

    this.exportCsv = this.exportCsv.bind(this);
    this.getPrefixTranslates = this.getPrefixTranslates.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.multiEdit = this.multiEdit.bind(this);
    this.clean = this.clean.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.deletePriceTranslates = this.deletePriceTranslates.bind(this);
  }

  async componentDidMount() {
    const descriptions = await DataApi.GetListDescriptions();
    this.setState({ select: ModificationArray.modificationDialDescription(descriptions.data) });
  }

  async getPrefixTranslates(page = 1) {
    const { description, prefix, paginate } = this.state;
    const object = {
      description: description,
      prefix: prefix,
    };

    const results = await DataApi.GetPrefixTranslate(page, object);
    this.setState({ data: results.data });

    await this.exportCsv();
  }

  async checkAll() {
    const {
      data: { data },
    } = this.state;

    const itemsAll = [];

    data.map(data => {
      itemsAll.push(data.id);
    });

    this.setState({ arrrayId: itemsAll, arrayMultiDescription: itemsAll });

  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  async exportCsv() {
    const { description, prefix } = this.state;

    const object = {
      description: description,
      prefix: prefix,
    };

    const response = await DataApi.ExportCsvPriceTranslate(object);

    this.setState({ csv: response.data });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.description !== this.state.description || prevState.prefix !== this.state.prefix) {
    }
  }

  clean(arrayMultiDescription, arrrayId) {  

    if (arrrayId.length > 0) {
      this.setState({ arrrayId: [], arrayMultiDescription: [] });

    } else if (arrayMultiDescription.length > 0) {

      this.setState({ arrrayId: [], arrayMultiDescription: [] });
    }
    
    console.log(this.state.arrayMultiDescription, this.state.arrrayId)
  }

  multiEdit(id) {
    const { arrayMultiDescription, arrrayId } = this.state;
    const indexArray = arrayMultiDescription.findIndex(data => data === id);
    const indexArray2 = arrrayId.findIndex(data => data === id);

    if (indexArray < 0) {
      arrayMultiDescription.length === 0
        ? this.setState({ arrayMultiDescription: [id] })
        : this.setState({ arrayMultiDescription: [...arrayMultiDescription, id] });
    } else {
      arrayMultiDescription.splice(indexArray, 1);
      this.setState({ arrayMultiDescription: arrayMultiDescription });
    }

    if (indexArray2 < 0) {
      arrrayId.length === 0 ? this.setState({ arrrayId: [id] }) : this.setState({ arrrayId: [...arrrayId, id] });
    } else {
      arrrayId.splice(indexArray, 1);
      this.setState({ arrrayId: arrrayId });
    }
  }

  async deletePriceTranslates(arrayMultiDescription, arrrayId) {

    Swal.fire({
      title: `Are you sure to delete all selected items?`,
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      
      if (result.isConfirmed) {
        DataApi.deletePriceTranslate({ arrayMultiDescription: arrayMultiDescription }).then(() => {
          this.getPrefixTranslates();
        });

        this.clean(arrayMultiDescription, arrrayId)

        Swal.fire('Deleted!', `All items has been deleted.`, 'success');
      }
    });
  }

  async onKeyPress(e) {
    if (e.which === 13) {
      await this.getPrefixTranslates(1);
    }
  }

  render() {
    const {
      data: { data },
      description,
      prefix,
      csv,
      select,
      arrayMultiDescription,
      checkBox,
      arrrayId,
    } = this.state;
    const { current_page, total, per_page } = this.state.data;

    return (
      <React.Fragment>
        <div>
          <Row onKeyUp={this.onKeyPress}>
            <Col md={12}>
              <div className="container-input-modal">
                <ModalNewPrefixTranslates getPrefixTranslates={this.getPrefixTranslates} select={select} />
              </div>
              <br />
              <br />
              <div className="container-search-prefix">
                <div className="container-principali-search" >
                  <Input nameI={'description'} value={description} change={this.handleChange} name={'Description'} />
                  <Input nameI={'prefix'} value={prefix} change={this.handleChange} name={'Prefix'} type={'number'} />
                </div>
                <div className="container-button-search-prefix">
                  {description.length !== 0 || prefix.length !== 0 ? (
                    <>
                      <div className=" d-flex">
                        <div className="">
                          <button
                            type="submit"
                            className="btn btn-primary mt-4"
                            onClick={() => this.getPrefixTranslates(1)}
                          >
                            <i className=" mdi mdi-feature-search-outline"></i> Search
                          </button>
                        </div>
                        <div className="float-right mt-3">
                          <ModalMultiEditPriceTranslate
                            getPrefixTranslates={this.getPrefixTranslates}
                            description={description}
                            prefix={prefix}
                            arrayMultiDescription={arrayMultiDescription}
                            arrrayId={arrrayId}
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <button type="submit" className="btn btn-primary mt-4" disabled>
                      <i className=" mdi mdi-feature-search-outline"></i> Search
                    </button>
                  )}
                </div>
              </div>
              {data ? (
                <>
                  <div className="container-agents-search">
                    <CSVLink
                      data={csv}
                      filename={'prefix-translates.csv'}
                      className="btn btn-success btn-sm text-uppercase mt-6"
                      target="_blank"
                    >
                      <i className=" mdi mdi-progress-download"></i> Download CSV
                    </CSVLink>

                    <div className="col-lg-4 mt-5">
                      <div className="input-group">
                        <input
                          type="file"
                          className="form-control"
                          onChange={this.onChange}
                          aria-label="File input with dropdown button"
                        />
                        <div className="input-group-append position-relative">
                          <button
                            className="btn btn-primary"
                            type="button"
                            data-toggle="dropdown"
                            onClick={this.importCsv}
                            aria-haspopup="true"
                            aria-expanded="false"
                            data-display="static"
                          >
                            <i className=" mdi mdi-import"></i> Import CSV
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  {arrayMultiDescription.length || arrrayId.length ? (
                    <>
                      <div className="d-flex">
                        <ModalMultiEditPriceTranslate
                          arrrayId={arrrayId}
                          arrayMultiDescription={arrayMultiDescription}
                          getPrefixTranslates={this.getPrefixTranslates}
                          multiEdit={this.multiEdit}
                        />
                        <div className="float-left">
                          <button
                            className="btn btn-warning"
                            onClick={() => this.clean(arrayMultiDescription, arrrayId)}
                          >
                            {' '}
                            Clean
                          </button>
                        </div>
                        <div className="float-left">
                          <button
                            className="btn btn-danger"
                            onClick={() => this.deletePriceTranslates(arrayMultiDescription, arrrayId)}
                          >
                            {' '}
                            Delete All
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <React.Fragment>
                      <div className=" ml-5">
                        <button disabled className="btn btn-primary">
                          Multiedit
                        </button>
                        <button className="btn btn-primary" onClick={() => this.checkAll()}>
                          {' '}
                          Select All
                        </button>
                      </div>
                    </React.Fragment>
                  )}
                  <div className="container-table-all">
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">id</th>
                          <th scope="col">Prefix</th>
                          <th scope="col">Description</th>
                          <th scope="col">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data
                          ? data.map((item, index) => (
                            <TablePriceTranslate
                              key={item.id}
                              csv={csv}
                              description={description}
                              prefix={prefix}
                              item={item}
                              index={index}
                              getPrefixTranslates={this.getPrefixTranslates}
                              multiEdit={this.multiEdit}
                              arrrayId={arrrayId}
                              arrayMultiDescription={arrayMultiDescription}
                            />
                          ))
                          : ''}
                      </tbody>
                    </Table>
                  </div>
                  <Pagination
                    totalItemsCount={total}
                    onChange={page => this.getPrefixTranslates(page)}
                    activePage={current_page}
                    itemsCountPerPage={per_page}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default IndexTablePriceTranslate;
