import React from 'react';
import { Card, CardFooter } from 'reactstrap';
import Input from '../../elements/Utils/Input/index';
import SelectCmx from '../../elements/Utils/Select';
import { MDBIcon } from 'mdbreact';
import DataApi from '../../../megacall/services/Customers/DataApi';
import Swal from 'sweetalert2';
import Validations from '../../../megacall/utilsFunctions/Validations';
import { Table } from 'reactstrap';

class Rectify extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceNumber: this.props.match.params.id,
      receivedDate: '',
      value: 0,
      notes: '',
      productsType: '',
      product: '',
      units: '',
      rate: '',
      agent_commision: false,
      text: '',
      text_details: '',
      until: '',
      percentage: '',
      productsToInvoice: '',
      TotalToSend: '',
      color: 'invalidate',
      productTypes: [],
      productsInInvoice: '',
      invoiceInfo: '',
    };
    this.getInvoiceInfo = this.getInvoiceInfo.bind(this);
    this.send = this.send.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addProductToInvoice = this.addProductToInvoice.bind(this);
    this.getAgentCommision = this.getAgentCommision.bind(this);
    this.getProductTypes = this.getProductTypes.bind(this);
    this.inputClean = React.createRef();
  }

  async componentDidMount() {
    // await this.getInvoiceInfo()

    this.initialState();
    //  this.getInvoiceInfo()
    //  this.getProductTypes();
  }
  initialState() {
    this.getInvoiceInfo();
    this.getProductTypes();
  }

  async getInvoiceInfo() {
    const object = {
      invoiceNumber: this.state.invoiceNumber,
    };
    const results = await DataApi.getInvoiceInfo(object);

    if (results.status === 200) {
      this.setState({ data: [results.data[0]], invoiceInfo: results.data[1] });
      console.log(results);
    }
  }

  async getProductTypes(product_id) {
    const { productTypes } = this.state;
    const object = {
      product_id: product_id,
    };

    const results = await DataApi.getProductTypes(object);

    this.setState({ productTypes: [...productTypes, ...results.data[0]], product: results.data[1] });

    setTimeout(() => {
      this.getAgentCommision(product_id);
    }, 500);
  }

  async getAgentCommision(productId) {
    const { invoiceInfo } = this.state;

    const object = {
      productId: productId,
      customersId: invoiceInfo[0].customer_id,
    };
    const response = await DataApi.getAgentCommision(object);
    var date = new Date();
    var currentDate = date.toISOString().substring(0, 10);

    if (response.data.length > 0 && response.data[0].until > currentDate) {
      const inputCmmsA = document.getElementById('inputComissionUntil');
      const inputCmmsPer = document.getElementById('inputComissionPer');
      const agentCmms = document.getElementById('agentComm');
      agentCmms.checked = true;
      agentCmms.value = true;
      inputCmmsA.disabled = false;
      inputCmmsPer.disabled = false;
      this.setState({
        agent_commision: true,
        until: response.data[0].until,
        percentage: response.data[0].percentage,
      });
    }
  }

  handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ [nameI]: e });
        this.getAgentCommision(e.value);
        break;
      case 2:
        this.setState({ [nameI]: e.target.value });
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        break;
    }
  }

  async send() {
    const { invoiceInfo } = this.state;
    const object = {
      TotalToSend: this.state.TotalToSend,
      rectify: 1,
      invoiceNumber: invoiceInfo[0].number,
    };
    console.log(object);
    const results = await DataApi.sendAndGenerate(invoiceInfo[0].customer_id, object);

    if (results.status === 200) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Your charges has been created',
        showConfirmButton: false,
        timer: 1500,
      });
      this.setState({ TotalToSend: '' });
      this.props.history.push('/invoice');
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Your work has error',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  getProductInfo(description, units, price, product_id, notes) {
    this.getProductTypes(product_id);
    this.setState({ text: '' });
    this.setState({ text: description, units: units, rate: price, product: product_id, notes: notes });
    setTimeout(() => {
      this.getAgentCommision(product_id);
    }, 800);
  }

  async addProductToInvoice() {
    const { product, text, rate, units, percentage, invoiceInfo, until, notes } = this.state;

    const validateInput = Validations.validationInput([{ value: units, name: 'units' }, { value: rate, name: 'rate' }]);
    const validateSelect = Validations.validationSelect([
      { value: product[0] ? product[0] : product, name: 'product' },
    ]);

    if (validateInput && validateSelect) {
      const productsToPush = [
        {
          product: product[0] ? product[0].value : product.value,
          productName: product.label,
          units: units,
          rate: rate,
          text: text,
          percentage: percentage,
          until: until,
          notes: notes,
          text_details: '',
        },
      ];

      this.setState({ productsToInvoice: [...this.state.productsToInvoice, ...productsToPush] });

      const MainInfo = {
        from_date: invoiceInfo[0].date,
      };

      this.setState({ TotalToSend: [MainInfo, [...this.state.productsToInvoice, ...productsToPush]] });

      this.setState({
        product: '',
        units: '',
        rate: '',
        text: '',
        text_details: '',
        notes: '',
        percentage: '',
      });
      this.inputClean.current.value = '';
    }
  }

  render() {
    const {
      product,
      rate,
      units,
      agent_commision,
      percentage,
      until,
      color,
      data,
      invoiceInfo,
      text,
      productTypes,
      TotalToSend,
      notes,
    } = this.state;

    if (!data) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="">
          <h3 className="ml-3">{invoiceInfo[0].number}</h3>

          <div className="container-fluid mt-5 mb-5">
            <Table className="table" bordered hover>
              <thead className="container-item">
                <tr>
                  <th scope="container-item">Product</th>
                  <th scope="container-item">Description</th>
                  <th scope="container-item">Units</th>
                  <th scope="container-item">Price</th>
                  <th scope="container-item actions">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {data[0].map(item => (
                  <tr
                    onClick={e =>
                      this.getProductInfo(item.description, item.units, item.price, item.product_id, item.notes)
                    }
                  >
                    <td className="container-item">{item.description_en}</td>
                    <td className="container-item">{item.description}</td>
                    <td className="container-item">{item.units}</td>
                    <td className="container-item">{item.price}</td>
                    <td className="container-item action-td"></td>
                  </tr>
                ))}
                {/* TOTAL: */}
                <tr disabled={true} style={{ cursor: 'default' }} hover={false}>
                  <td className="container-item" style={{ cursor: 'default' }} />
                  <td className="container-item" style={{ cursor: 'default' }} />
                  <td className="container-item" style={{ cursor: 'default' }} />
                  <td className="container-item" style={{ cursor: 'default' }} />
                  <td className="container-item" style={{ cursor: 'default' }}>
                    <h5>{invoiceInfo[0].total}</h5>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>

          <Card className="mt-5 p-5 container">
            <div className="modal-header">{/* <h5 className="modal-title"></h5> */}</div>
            <div className="row">
              <div className="col-sm-12 mt-3">
                <label>Product</label>
                <SelectCmx
                  index={1}
                  nameI={'product'}
                  options={productTypes}
                  value={product}
                  change={e => this.handleChange(e, 'product', 1)}
                  validate={'validation'}
                  color={color}
                  id="sel"
                />
              </div>
              <div className="col-sm-12 mt-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        color={color}
                        placeholder="Units"
                        value={units}
                        change={e => this.handleChange(e, 'units', 2)}
                        name="Units"
                        ref={this.inputClean}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Input
                        type="text"
                        color={color}
                        placeholder="Rate"
                        value={rate}
                        change={e => this.handleChange(e, 'rate', 2)}
                        name="Rate"
                        ref={this.inputClean}
                      />
                    </div>
                    <div className="col-sm-4 m-0">
                      <label>Value</label>
                      <input
                        type="text"
                        className="form-control text-success"
                        placeholder="Value"
                        name="value"
                        value={units * rate}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Input
                  nameI={'text'}
                  change={e => this.handleChange(e, 'text', 2)}
                  name={'Description'}
                  placeholder="Text"
                  value={text}
                  ref={this.inputClean}
                />
                <Input
                  type="textarea"
                  nameI={'text'}
                  change={e => this.handleChange(e, 'notes', 2)}
                  name={'Notes'}
                  placeholder="Notes"
                  value={notes}
                  ref={this.inputClean}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 mt-4">
                <input
                  type={'checkbox'}
                  nameI={'agent_commision'}
                  value={agent_commision}
                  onClick={() => this.handleCheckbox(5)}
                  name={'Agent Commision'}
                  className="mt-4 mr-1"
                  id="agentComm"
                />
                <label>Agent Commision</label>
              </div>
              <div className="col-sm-4">
                <label>Until</label>
                <input
                  type="date"
                  nameI={'until'}
                  onChange={this.handleChange}
                  name={'Until'}
                  placeholder="Until"
                  id="inputComissionUntil"
                  disabled={true}
                  className="form-control"
                  defaultValue={until}
                />
              </div>
              <div className="col-sm-4 p-0">
                <label>Percentage</label>
                <input
                  nameI={'percentage'}
                  onChange={this.handleChange}
                  name={'Percentage'}
                  placeholder="Percentage"
                  className="form-control"
                  id="inputComissionPer"
                  disabled={true}
                  defaultValue={percentage}
                />
              </div>
            </div>
            <CardFooter className="mt-5 bg-white container-item">
              {/* <div className="row mt-2 d-flex justify-content-end"> */}
              <button type="button" className="btn btn-success" onClick={() => this.addProductToInvoice()}>
                Add Product
              </button>
              {TotalToSend ? (
                // DESCOMENTAR PARA FUNCION CARRITO
                <>
                  <div className="row container-item">
                    <h3 className="mt-1 mb-2">Products Added</h3>
                  </div>

                  <Table className="">
                    <thead className="container-item">
                      <tr>
                        <th>Product</th>
                        <th>Unit</th>
                        <th>Price</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="container-item">
                      {TotalToSend[1].map((item, index) => (
                        <>
                          <tr key={index}>
                            <td className="">{item.productName}</td>
                            <td className="">{item.units}</td>
                            <td className="">{item.rate}</td>
                            <td className="">{item.units * item.rate}</td>
                            <td>
                              <MDBIcon
                                icon="trash"
                                size="lg"
                                className="trash ml-3"
                                onClick={() => this.removeFromCart(index)}
                              />
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                ''
              )}
              {TotalToSend ? (
                <button type="button" className="btn btn-primary" onClick={() => this.send()}>
                  Save and Generate invoice
                </button>
              ) : (
                ''
              )}
              {/* </div> */}
            </CardFooter>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default Rectify;
