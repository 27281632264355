import UrlServices from "../UrlServices";
import axios from "axios";
import Headboard from '../Headboard';

export default class CallsApi {
    static async LOGIN(object) {
        const response = await axios.post(UrlServices.universal + 'login', object);

        return response;
    }
    
    static async GETUSERPIRICIPALI() {
        const response = await axios.get(UrlServices.universal + 'user', await Headboard.HEADERS());
        
        return response;
    }
}


