import React from 'react';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import { Card, Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import TableProducts from '../../../elements/SettingsElements/Products/TableProducts';
import ModalNewProducts from '../../../elements/SettingsElements/Products/Modal/ModalNewProducts';
import Input from '../../../elements/Utils/Input';

class Products extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data: '',
            description: '',
        };
        this.getProducts = this.getProducts.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    async componentDidMount() {
        await this.getProducts();
    }
    
    async getProducts(page = 1) {
        const { description } = this.state;
        
        const object = {
            description: description
        };
        
        const results = await DataApi.GetProducts(page, object);
        
        this.setState({ data: results.data });
    }
    
    handleChange(e, nameI, index) {
        switch (index) {
            case 1:
                this.setState({ [nameI]: e });
                setTimeout(() => {
                    this.getProducts();
                }, 500);
                break;
            default:
                this.setState({ [e.target.name]: e.target.value });
                setTimeout(() => {
                    this.getProducts();
                }, 500);
                break;
        }
        
    }
    
    
    render() {
        const { data, description } = this.state;
        const { current_page, total, per_page } = this.state.data;
        
        return (
            <div>
                <Row>
                    <h1 className="title-table">PRODUCTS</h1>
                    <Col md={12}>
                        <Card>
                            <div className="container-input-modal">
                                <ModalNewProducts getProducts={this.getProducts} />
                            </div>
                            <div className="container-search-customers">
                                <Input nameI={'description'} value={description} change={this.handleChange}
                                       placeholder={'Description'} />
                            </div>
                            <br />
                            <div className="container-table-all">
                                <TableProducts data={data} getProducts={this.getProducts} />
                            </div>
                            <Pagination
                                totalItemsCount={total}
                                onChange={(page) => this.getProducts(page)}
                                activePage={current_page}
                                itemsCountPerPage={per_page}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Products;

