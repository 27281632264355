import CallsStorage from './Token/CallsStorage';

export default class Headboard {

    static async HEADERS() {
        const token = await CallsStorage.GetStorageToken('credentials');

        return {
          headers: {
            'content-type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${ token.access_token }`,
          }
        };
    }

    static async HEADERSFILE() {
        const token = await CallsStorage.GetStorageToken('credentials');

        return {
          headers: {
            'content-type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': `Bearer ${ token.access_token }`
          }
        };
    }
    
    static async HEADERTXT() {
        const token = await CallsStorage.GetStorageToken('credentials');
    
        return {
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'content-type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${ token.access_token }`
            }
        };
    }
    
    static async HEADERSPDF() {
        const token = await CallsStorage.GetStorageToken('credentials');
    
        return {
            headers: {
                'content-type': 'application/pdf',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${ token.access_token }`
            }
        };
    }
}

