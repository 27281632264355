/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Table } from 'reactstrap';
import Pagination from 'react-js-pagination';
import DataApi from '../../../megacall/services/Customers/DataApi';
import SelectCmx from '../../elements/Utils/Select';
import ModalValues from '../../elements/CustomersElements/Audits/modalValues';
import { CSVLink } from 'react-csv';

class Payments extends React.Component {
  constructor(props) {
    super(props);

    const date = new Date();

    this.state = {
      types: [{ label: 'Paypal', value: 'paypal' }, { label: 'RedSys', value: 'redSys' }, { label: 'Bizum', value: 'bizum' }, { label: 'ALL' }],
      type: '',
      dateFrom: date.toISOString().substring(0, 10),
      dateTo: date.toISOString().substring(0, 10),
      customerId: '',
      values: '',
      dataExport: []
    };
    this.getLogs = this.getLogs.bind(this);
  }

  async getLogs(page = 1) {
    const { type, dateFrom, dateTo, customerId } = this.state;
    const object = {
      type,
      dateFrom,
      dateTo,
      customerId,
    };
    const result = await DataApi.getLogs(page, object);

    let values = [];
    let data_export = [];
    result.data.data.forEach(element => {
      let amount = 0;
      if (element.value) {
        const val = JSON.parse(element.value);
        values = [...values, val.transactions ? val.transactions[0].amount.total : val.Ds_Amount / 100];
        this.setState({ values: values });
        amount = val.transactions ? val.transactions[0].amount.total : val.Ds_Amount / 100;
      } else {
        values = [...values, ''];
        this.setState({ values: values });
      }

      if (element.status === '2') {
        data_export = [...data_export, {identificator: element.identificator, status: 'Completado', customer_id: element.customer_id, invoice_name: element.invoice_name, date: element.date, platform: element.platform, amount: amount}];
        this.setState({ dataExport: data_export });
      }
    });

    this.setState({
      data: result.data.data,
      current_page: result.data.current_page,
      total: result.data.total,
      per_page: result.data.per_page
    });
  }

  async componentDidMount() {
    this.getLogs();
  }

  async handleChange(e, nameI, index) {
    switch (index) {
      case 1:
        this.setState({ type: e.value });
        setTimeout(() => {
          this.getLogs();
        }, 50);
        break;
      default:
        this.setState({ [e.target.name]: e.target.value });
        setTimeout(() => {
          this.getLogs();
        }, 50);
        break;
    }
  }

  render() {
    const { types, data, type, values, dateFrom, dateTo, current_page, total, per_page, dataExport, } = this.state;

    if (!data) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="card-body">
          <div className="row justify-content-between top-information">
            <div className="col-sm-3">
              <div className="form-group ml-2">
                <label>Date From</label>
                <input
                  onChange={e => this.handleChange(e)}
                  type="date"
                  name="dateFrom"
                  className="form-control"
                  placeholder="From"
                  value={dateFrom}
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group ml-2">
                <label>Date To</label>
                <input
                  onChange={e => this.handleChange(e)}
                  type="date"
                  name="dateTo"
                  className="form-control"
                  placeholder="From"
                  value={dateTo}
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <SelectCmx
                  change={e => this.handleChange(e, 'type', 1)}
                  name="Transaction Type"
                  value={type}
                  options={types}
                  className="form-control mt-0"
                />
              </div>
            </div>

            <div className="col-sm-3">
              <div className="form-group">
                <label>Customer Id</label>
                <input
                  index={1}
                  onChange={e => this.handleChange(e)}
                  name={'customerId'}
                  className="form-control"
                  placeholder="Customer Id"
                />
              </div>
            </div>

            <div className="col-sm-3 justify-content-center mt-4">
              <div className="form-group">
                <CSVLink data={dataExport} filename={'topup_report.csv'} className="btn btn-success" target="_blank" >
                  <i className=" mdi mdi-export"></i> Export CSV
                </CSVLink>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ml-3">
          <Table className="table table-stripped">
            <thead className="container-item">
              <tr>
                <th scope="container-item">Identificator</th>
                <th scope="container-item">Status</th>
                <th scope="container-item">Customer Id</th>
                <th scope="container-item">Customer Name</th>
                <th scope="container-item">Date</th>
                <th scope="container-item">Type</th>
                <th scope="container-item">Amount</th>
                <th scope="container-item">Values</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="container-item">{item.identificator}</td>
                  <td className="container-item">
                    {' '}
                    {item.status === '1' ? 'Inicializado' : ''}
                    {item.status === '2' ? 'Completado' : ''}
                    {item.status === '3' ? 'Error' : ''}
                  </td>
                  <td className="container-item">{item.customer_id}</td>
                  <td className="container-item">{item.invoice_name}</td>
                  <td className="container-item">{item.date === '0000-00-00 00:00:00' ? 'Not Received' : item.date}</td>
                  <td className="container-item">{item.platform}</td>
                  <td className="container-item">{item.value ? values[index] : ''}</td>
                  <td className="container-item">
                    {item.value ? <ModalValues value={item.value} nameModal={'Log Values'} /> : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <Pagination
          totalItemsCount={total}
          onChange={page => this.getLogs(page)}
          activePage={current_page}
          itemsCountPerPage={per_page}
          itemClass="page-item"
          linkClass="page-link"
        />
      </React.Fragment>
    );
  }
}

export default Payments;
