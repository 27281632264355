import UrlServices from "../UrlServices";
import axios from "axios";
import Headboard from '../Headboard';

export default class CallsApi {

    static API_URL_GLOBAL: string = UrlServices.universal;

    /** Agents */
    static async GETPAGINATE(page: any, json: any) {
        const response = await axios.post(UrlServices.universal + `agents/list?page=${page}`, json, await Headboard.HEADERS());

        return response;
    }

    static async GETLISTSELECT() {
        const response = await axios.get(CallsApi.API_URL_GLOBAL + `agents/listSelect`, await Headboard.HEADERS());

        return response;
    }

    static async GETOPTIONS(id: string) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + `agents/show/${id}`, null, await Headboard.HEADERS());

        return response;
    }

    static async SETAGENTS(object: any, id: string) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + `agents/update/${id}`, object, await Headboard.HEADERS());

        return response;
    }

    static async SETNEWAGENTS(object: any) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + 'agents/create', object, await Headboard.HEADERS());

        return response;
    }

    static async DESACTIVEAGENTS(id: any, enabled: any) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + `agents/enabled-agents/${id}/${enabled}`, null, await Headboard.HEADERS());

        return response;
    }

    /**** COMMISSIONS ****/
    static async GETPAGINATECOMMISSIONS(page: any, id: any) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + `agents/commission/list/${id}?page=${page}`, null, await Headboard.HEADERS());

        return response;
    }

    static async GETDESCRIPTIONCOMMISSION() {
        const response = await axios.get(CallsApi.API_URL_GLOBAL + "agents/commission/list/product", await Headboard.HEADERS());

        return response;
    }

    static async UPDATECOMMISSION(object: any, id: string) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + `agents/commission/update/${id}`, object, await Headboard.HEADERS());

        return response;
    }

    static async DELETECOMMISSION(id: string) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + `agents/commission/destroy/${id}`, null, await Headboard.HEADERS());

        return response;
    }

    static async CREATECOMMISSION(object: any) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + 'agents/commission/create', object, await Headboard.HEADERS());

        return response;
    }

    /** Count */
    static async COUNTAGENTS(id: any) {
        const response = await axios.get(CallsApi.API_URL_GLOBAL + `agents/count/${id}`, await Headboard.HEADERS());

        return response;
    }

    static async GETLISTAGENTS(type: string) {
        const response = await axios.get(CallsApi.API_URL_GLOBAL + 'agents/select/' + type, await Headboard.HEADERS());
  
        return response;
    }

    static async GETREPORT(object: any) {
        const response = await axios.post(CallsApi.API_URL_GLOBAL + 'agents/commission/report', object, await Headboard.HEADERS());

        return response;
    }
}

