import React from 'react';

class SearchPriceList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  async onKeyPress(e) {
    if (e.which === 13) {
      await this.props.getSuppliersData(1);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-control-one" onKeyUp={this.onKeyPress}>
          <input
            name="prefix"
            className="form-control mr-4"
            placeholder="Prefix"
            onChange={e => this.props.hanleChangeFilter(e)}
          />

          <input
            name="description"
            className="form-control"
            placeholder="Description"
            onChange={e => this.props.hanleChangeFilter(e)}
          />

          {this.props.description.length !== 0 || this.props.prefix.length !== 0 ? (
            <>
              <div className=" d-flex">
                <div className="">
                  <button
                    //type="submit"
                    className="btn btn-primary"
                    onClick={() => this.props.getSuppliersData(1)}
                    style={{ width: '150px', height: '38px', marginTop: '0px', marginLeft: '20px', paddingTop: '6px' }}
                  >
                    <i className=" mdi mdi-feature-search-outline"></i> Search
                  </button>
                </div>
              </div>
            </>
          ) : (
            <button
              //type="submit"
              className="btn btn-primary disabled"
              style={{ width: '150px', height: '38px', marginTop: '0px', marginLeft: '20px', paddingTop: '6px' }}
            >
              <i className=" mdi mdi-feature-search-outline"></i> Search
            </button>
          )}
        </div>
        <div className="container-price-list-search">
          <div className="col-lg-6">
            <div className="input-group">
              <input
                type="file"
                className="form-control"
                aria-label="File input with dropdown button"
                onChange={e => this.props.onChange(e)}
              />
              <div className="input-group-append position-relative">
                <button
                  className="btn btn-primary"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  onClick={() => this.props.importExport()}
                  aria-expanded="false"
                  data-display="static"
                >
                  Import / Export to Sippy
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SearchPriceList;
