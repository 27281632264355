import React, { Component } from 'react';
import DataApi from '../../../../megacall/services/Settings/DataApi';
import { Card, Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import TableRegisters from '../../../elements/SettingsElements/Register/TableRegisters';
import Input from '../../../elements/Utils/Input';

class Register extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
        data: '',
        number: '',
        name: '',
        document_num: ''
    };
    this.getNumbers = this.getNumbers.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
    
    async componentDidMount() {
      await this.getNumbers();
    }
    
    async getNumbers(page = 1) {
      const { number, name, document_num } = this.state;
      
      const object = {
        number: number,
        name: name,
        document: document_num
      };
      
      const results = await DataApi.GetNumbers(page, object);
      
      this.setState({ data: results.data });
    }
    
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout(() => { this.getNumbers(); }, 500);
  }
    
  render() {
    const { data, number, name, document_num } = this.state;
    const { current_page, total, per_page } = this.state.data;
    
    return (
      <div>
        <Row>
          <h1 className="title-table">REGISTER NUMBERS</h1>
          <Col md={12}>
            <Card>
              <div className="container-input-modal">
                <div className="container-button">
                    <h3>Register Numbers List</h3>                              
                </div>
              </div>

              <div className="container-search search-producy">
                <Input nameI={'number'} value={number} change={this.handleChange} placeholder={'Number'} />
                <Input nameI={'name'} value={name} change={this.handleChange} placeholder={'Company or Full Name'} />
                <Input nameI={'document_num'} value={document_num} change={this.handleChange} placeholder={'Business Registration number / National Document:'} />
              </div>
              <br />

              <div className="container-table-all">
                <TableRegisters data={data} />
              </div>

              <Pagination
                totalItemsCount={total}
                onChange={(page) => this.getNumbers(page)}
                activePage={current_page}
                itemsCountPerPage={per_page}
                itemClass="page-item"
                linkClass="page-link"
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Register;
