class UrlServices {
  //PRODUCCION
  // static universal = 'https://manager-kosmos.megacall.es/public/api/auth/';
  //DESAROLLO
  static universal = 'https://manager-kosmos.solbyte.dev/api/auth/';
  // static universal = 'https://managerdev-kosmos.megacall.es/public/api/auth/';
  //ASTERISK TEST
  // static universal = 'http://hermes.megacall.es/kosmos-back/public/index.php/api/auth/';
}

export default UrlServices;
